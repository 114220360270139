import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";

export default function Timer(props) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { seconds, setSeconds, maxTime, resendOtp } = props;
  function reset() {
    resendOtp();
    setSeconds(maxTime);
  }

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Grid
      container
      direction={xs ? "column" : "row"}
      spacing={1}
      alignItems={xs ? "flex-start" : "center"}
    >
      <Grid item>
        <Typography variant={md ? "body1" : "body2"}>
          Didn't receive your code?
        </Typography>
      </Grid>

      <Grid item>
        {seconds > 0 ? (
          <Typography variant={md ? "body1" : "body2"}>
            Resend in :{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {seconds + "s"}
            </span>
          </Typography>
        ) : (
          <Typography variant={md ? "body1" : "body2"}>
            <Button color="primary" onClick={reset}>
              Resend code
            </Button>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
