import { Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import "react-day-picker/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loading from "components/shared/loader/loader";
import { setNavIndex } from "components/secured-app/uiSlice";
import {
  fetchCarriers,
  fetchProviders,
  fetchTxns,
  resetQuery,
  setQuery,
} from "./activitySlice";
import SearchBar from "./searchbar";
import ActivityTable from "./table";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(3),
    // [theme.breakpoints.down("md")]: {
    //   marginTop: theme.spacing(1),
    // },
  },
}));

const columns = [
  { id: "transactionDate", label: "Transaction Date", isMobileHeader: true, sortOrder: 1, format: "date", dateFormat: "YYYY-MM-DD h:mm A" },
  { id: "accountNumber", label: "Account#", isMobileHeader: true, sortOrder: 2 },
  { id: "provider", label: "Provider", sortOrder: 3 },
  { id: "nameOnAccount", label: "Account Name", sortOrder: 4 },
  { id: "tranType", label: "Type", sortOrder: 5 },
  { id: "tranStatus", label: "Status", sortOrder: 6 },
  { id: "orderNumber", label: "Receipt Number", sortOrder: 7 },
  { id: "authCode", label: "Authorization Code", sortOrder: 8 },
  { id: "amount", label: "Amount", isMobileHeader: true, sortOrder: 9, align: "right", format: "currency", currency: { symbol: 'currencySymbol', code: 'currencyCode' } },
];

export default function SecuredActivities(props) {
  const { activity } = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { txns, providers, loading, carriers, query } = useSelector(
    (state) => state.activity
  );
  useEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(fetchProviders());
        await dispatch(fetchCarriers());
        await dispatch(fetchTxns());
      } catch (error) {
        console.log("Failed to load data");
      }
    };
    loadData();
  }, []);

  const handleFetch = async (data) => {
    await dispatch(fetchTxns(data));
  };

  const handleQueryUpdate = (query) => {
    dispatch(setQuery(query));
  };

  const handleQueryReset = () => {
    dispatch(resetQuery());
  };

  const handleNavUpdate = (idx) => {
    dispatch(setNavIndex(idx));
  };

  return (
    <div>
      {loading === "pending" && <Loading />}
      <Container maxWidth="xl">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <SearchBar
              providers={providers}
              handleFetch={handleFetch}
              carriers={carriers}
              query={query}
              handleQueryUpdate={handleQueryUpdate}
              handleQueryReset={handleQueryReset}
            />
          </Grid>

          <Grid item xs={12}>
            <ActivityTable
              {...txns}
              handleFetch={handleFetch}
              query={query}
              handleQueryUpdate={handleQueryUpdate}
              handleNavUpdate={handleNavUpdate}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
