import MomentUtils from "@date-io/moment";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { cleanQuery } from "./activitySlice";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {},
  cardHeader: {},
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  select: {
    // [theme.breakpoints.between("sm", "md")]: {
    //   margin: 8,
    // },
  },
  date: {
    marginBottom: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const tranTypes = [
  { id: "Billpay", name: "Bill Pay" },
  { id: "TopUps", name: "Topup" },
];

export default function SearchBar(props) {
  const classes = useStyles();
  const {
    providers,
    carriers,
    handleFetch,
    query,
    handleQueryUpdate,
    handleQueryReset,
  } = props;
  const [queryState, setQueryState] = useState(query);

  const {
    startDate,
    endDate,
    selectedProviders,
    selectedCarriers,
    selectedTranTypes,
    pageNumber
  } = queryState;

  const handleChange = (prop) => (event) => {
    const value = event.target.value;
    setQueryState({ ...queryState, [prop]: value });
  };

  const handleChangeDate = (value, prop) => {
    setQueryState({ ...queryState, [prop]: value });
  };

  function getProviderName(id) {
    const provider = providers.find((p) => p.providerId === id);
    return provider ? provider.name : "";
  }

  function getCarrierName(id) {
    const carrier = carriers.find((c) => c.phoneProviderId === id);
    return carrier ? carrier.name : "";
  }

  function selectedTranType(id) {
    const tranType = tranTypes.find((c) => c.id === id);
    return tranType ? tranType.name : "";
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleQueryUpdate(queryState);
    handleFetch();
  }

  function handleReset() {
    setQueryState({ ...cleanQuery });
    handleQueryReset();
    handleFetch();
  }

  function handleDelete(prop, idx) {
    switch (prop) {
      case "selectedTranTypes":
        const temp1 = [...selectedTranTypes];
        temp1.splice(idx, 1);
        setQueryState({
          ...queryState,
          [prop]: temp1,
        });
        break;

      case "selectedProviders":
        const temp2 = [...selectedProviders];
        temp2.splice(idx, 1);
        setQueryState({
          ...queryState,
          [prop]: temp2,
        });
        break;

      case "selectedCarriers":
        const temp3 = [...selectedCarriers];
        temp3.splice(idx, 1);
        setQueryState({
          ...queryState,
          [prop]: temp3,
        });
        break;

      default:
        break;
    }
  }

  return (
    <Card className={classes.root}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          title={<Typography variant="h6">Transaction Activity - Filters</Typography>}
        />
        <Divider />
        <CardContent className={classes.content}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={2}
            >
              <Grid item xs={6} sm={4} lg={4}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-DD"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  onChange={(value) => handleChangeDate(value, "startDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                  color="secondary"
                  className={classes.date}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={4}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-DD"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  value={endDate}
                  onChange={(value) => handleChangeDate(value, "endDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                  color="secondary"
                  className={classes.date}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <FormControl
                  fullWidth
                  color="secondary"
                  className={classes.select}
                >
                  <InputLabel id="demo-mutiple-chip-label">
                    Transaction Type
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedTranTypes}
                    onChange={handleChange("selectedTranTypes")}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value, index) => (
                          <Chip
                            key={value}
                            label={selectedTranType(value)}
                            className={classes.chip}
                            color="secondary"
                            size="small"
                            onDelete={() =>
                              handleDelete("selectedTranTypes", index)
                            }
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {tranTypes.map((tranType) => (
                      <MenuItem key={tranType.id} value={tranType.id}>
                        {tranType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl
                  fullWidth
                  color="secondary"
                  className={classes.select}
                >
                  <InputLabel id="demo-mutiple-chip-label">
                    Providers
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedProviders}
                    onChange={handleChange("selectedProviders")}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value, index) => (
                          <Chip
                            key={value}
                            label={getProviderName(value)}
                            className={classes.chip}
                            color="secondary"
                            size="small"
                            onDelete={() =>
                              handleDelete("selectedProviders", index)
                            }
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    disabled={!selectedTranTypes.includes("Billpay")}
                  >
                    {providers.map((provider) => (
                      <MenuItem
                        key={provider.providerId}
                        value={provider.providerId}
                      >
                        {provider.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select transaction type (Bill Pay) to filter providers
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl
                  fullWidth
                  color="secondary"
                  className={classes.select}
                >
                  <InputLabel id="demo-mutiple-chip-label">Carriers</InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedCarriers}
                    onChange={handleChange("selectedCarriers")}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value, index) => (
                          <Chip
                            key={value}
                            label={getCarrierName(value)}
                            className={classes.chip}
                            color="secondary"
                            size="small"
                            onDelete={() =>
                              handleDelete("selectedCarriers", index)
                            }
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    disabled={!selectedTranTypes.includes("TopUps")}
                  >
                    {carriers.map((carrier) => (
                      <MenuItem
                        key={carrier.phoneProviderId}
                        value={carrier.phoneProviderId}
                      >
                        {carrier.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select transaction type (Top Up) to filter carriers
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Button color="primary" type="submit" fullWidth>
                Search
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button color="inherit" onClick={handleReset} fullWidth>
                Clear All
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
}
