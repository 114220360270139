import { Container, Divider, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import FIUlogo from "assets/images/fiutt-logo-icon.png";
import CentralBanklogo from "assets/images/central-bank-of-trinidad-and-tobago-seeklogo.svg";
import videoFile from "assets/videos/FULL-YOOZ-3DSecure-2-YOOZMusic.mp4";
import ContentList from "components/public-dashboard/components/contentList";
import { securityFeatures } from "./constants";
import YoozSecure from "assets/images/yooz-secure.png";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import TextList from "components/public-dashboard/components/TextList";
import {API_ROOT} from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  featureImg: {
    width: 100,
    height: 100,
    [theme.breakpoints.up("md")]: {
      width: 200,
      height: 200,
    },
  },
}));

export default function SecureContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container>
      <Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item container xs={12} sm={12} spacing={3} direction="column">
            {securityFeatures.map((feature, index) => (
              <Fragment key={index}>
                <ContentImageBlock
                  titleSm="h5"
                  titleLg="h3"
                  titleXs="h6"
                  color={feature.color}
                  title={feature.title}
                  imgPosition={index % 2 == 0 ? "left" : "right"}
                  list={<TextList items={feature.lines} />}
                  img={feature.img}
                  imgStyles={{ width: 250 }}
                />
                {xs && <Divider variant="middle" />}
              </Fragment>
            ))}
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={12} md={8}>
                <ContentList
                  title="HOW DOES YOOZ PROCESS TRANSACTIONS SECURELY?"
                  items={[
                    <span>
                      We operate on an enhanced identity authentication
                      mechanism
                      <br />
                      where we collaborate closely with the cardholder’s bank to
                      verify
                      <br />
                      their identity. This system safeguards your transaction
                      data while
                      <br />
                      making the payment process smoother and easier for you.
                    </span>,
                  ]}
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <img
                  src={YoozSecure}
                  alt="Title Image"
                  className={classes.media}
                ></img>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={12} md={8}>
                <ContentList
                  title="HOW DOES IT WORK?"
                  items={[
                    <span>
                      We work with the local banks to process transactions. Our
                      payment
                      <br />
                      card industry compliance and safety mechanisms{" "}
                      <span style={{ color: "#FFA500" }}>(3DS2)</span> allow
                      <br />
                      banks to interact with their cardholders on our platform
                      during a<br />
                      transaction. If you attempt to pay your bill or buy top up
                      with us, your
                      <br />
                      bank may require you to enter additional information, so
                      they can
                      <br />
                      verify your identity.
                    </span>,
                    <span>
                      One way they do this is by asking you to submit a One Time
                      <br />
                      Passcode they send to you during the transaction process.
                      A
                      <br />
                      message branded and sent by your bank will appear within
                      our
                      <br />
                      payment page asking that you enter a One Time Passcode.
                      <br />
                      Simultaneously, the code is sent to the contact info they
                      have on file. If you no longer have access to that contact
                      info, you cannot access,
                      nor enter the code. The bank will not approve a
                      transaction if they
                      <br />
                      cannot verify your identity. Only when YOOZ gets approval
                      from your
                      <br />
                      bank‚ do we charge your card.
                    </span>,
                    <span>
                      If you have changed your contact details, you should
                      contact your
                      <br /> bank and advise them.
                    </span>,
                  ]}
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <video controls className={classes.media}>
                  <source src={`${API_ROOT}document/yo-3dsecure-vid`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={12} md={6}>
                <ContentList
                  title={
                    <span>GUIDED BY THE PRINCIPLES & REGULATIONS OF:</span>
                  }
                />
              </Grid>

              <Grid item sm={12} md={3} container justify="center">
                <img
                  src={FIUlogo}
                  alt="Title Image"
                  className={classes.featureImg}
                ></img>
              </Grid>
              <Grid item sm={12} md={3} container justify="center">

                <img
                    src={CentralBanklogo}
                    alt="Title Image"
                    className={classes.featureImg}
                ></img>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    </Container>
  );
}
