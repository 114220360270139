import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CustomToolbar from "./toolbar";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.primary.main,
  },
}));

export default function CustomSecuredAppBar(props) {
  const classes = useStyles();
  const { handleDrawerToggle, user, handleLogout } = props;
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      // color="secondary"
    >
      <CustomToolbar
        handleDrawerToggle={handleDrawerToggle}
        user={user}
        handleLogout={handleLogout}
      />
    </AppBar>
  );
}
