import { Button } from "@material-ui/core";
import React, { Fragment, useLayoutEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getToken } from "middleware/storage";
import PaymentDetailsRegistered from "components/shared/payments/PaymentDetailsRegistered";
import PaymentDetailsUnregistered from "components/shared/payments/PaymentDetailsUnregistered";
import {
  addValidatedStep,
  fetchCC,
  resetBilling,
  setAddressDetails,
  setPaymentDetails,
  toggleCCForm
} from "components/public-dashboard/features/topup/topupSlice";

function PaymentDetails(props) {
  const {
    showCCForm,
    setPaymentDetails,
    toggleCCForm,
    cCards,
    resetBilling,
    setAddressDetails,
  } = props;

  const [loggedIn, SetLogin] = useState(false);
  const dispatch = useDispatch();
  const { activeStep } = useSelector((state) => state.topup);

  useLayoutEffect(() => {
    dispatch(addValidatedStep(activeStep));
    async function validateLogin() {
      const token = getToken();
      if (!token) {
        SetLogin(false);
      } else {
        SetLogin(true);
        fetchCards();
      }
    }

    function fetchCards() {
      dispatch(fetchCC());
    }

    validateLogin();
  }, []);

  function togglerRegistered() {
    const resetPayment = {
      name: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      customerCardId: 0,
      shouldSaveCard: false,
    };
    setPaymentDetails(resetPayment);
    resetBilling();
    toggleCCForm(!showCCForm);
  }

  const cleanAddress = {
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "780",
    emailAddress: "",
    phoneNumber: "1868",
  };

  const handleCardSelectedAddress = (customerCardId) => {
    for (var i = 0; i < cCards.length; i++) {
      if (cCards[i].customerCardId === customerCardId) {
        if (cCards[i].billingAddress.id != 0) {
          const updatedAddressDetails = {
            ...cCards[i].billingAddress,
          };

          setAddressDetails(updatedAddressDetails);
          break;
        } else {
          setAddressDetails(cleanAddress);
          break;
        }
      }
    }
  };

  return (
    <Fragment>
      {loggedIn ? (
        showCCForm ? (
          <PaymentDetailsUnregistered {...props} />
        ) : (
          <PaymentDetailsRegistered
            {...props}
            handleCardSelectedAddress={handleCardSelectedAddress}
          />
        )
      ) : (
        <PaymentDetailsUnregistered {...props} />
      )}
      {loggedIn && (
        <Button
          id="txnTogglerRegistered"
          variant={showCCForm ? "contained" : "outlined"}
          color="primary"
          onClick={() => togglerRegistered()}
          style={{
            borderRadius: 20,
            marginTop: 20,
          }}
        >
          {showCCForm ? "Use Saved Card" : "Use New Card"}
        </Button>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  showCCForm: state.topup.showCCForm,
  paymentDetailsState: state.topup.paymentDetails,
  errors: state.topup.errors,
  addressDetails: state.topup.addressDetails,
  cCards: state.topup.cCards,
});

const mapDispatch = {
  setPaymentDetails,
  toggleCCForm,
  resetBilling,
  setAddressDetails,
};

export default connect(mapStateToProps, mapDispatch)(PaymentDetails);
