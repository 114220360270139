import React, { Fragment } from "react";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import Content from "./content";
import Header from "./header";

export default function Partners() {
  return (
    <Fragment>
      <ContentWrapper>
        <Header />
        <Content />
      </ContentWrapper>
    </Fragment>
  );
}
