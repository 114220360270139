import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import leftQouteImg from "assets/images/quote_left.png";
import rightQuoteImg from "assets/images/quote_right.png";
import img from "assets/images/testimony_img_1.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  img: { height: "100%", width: "100%" },
  container: {
    padding: 20,
  },
}));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function TestimonyCarousel(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { text, note = "", name } = props

  function QuoteContainer(props) {
    return (
      <Container
        maxWidth="md"
        style={{
          textAlign: "center",
          color: theme.palette.secondary.main,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img src={leftQouteImg} alt="left Qoute Img" width="50%" />
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1">
                <i>{props.text}</i>
              </Typography>
            </Grid>
            {note === "" ? ( 
              <Grid item>
                <Typography variant="subtitle1">{props.note}</Typography>
              </Grid>
            ) : ( 
              null
            )}
            <Grid item>
              <Typography variant="subtitle2">{props.name}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img src={rightQuoteImg} alt="right Quote Img" width="50%" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item xs={12}>
          <img src={img} alt="testimony img" className={classes.img} width="100%" />
        </Grid>
      </Grid>
      <Box pb={3}>
        <Carousel
          minimumTouchDrag={80}
          swipeable
          draggable={false}
          showDots
          responsive={responsive}
          infinite
          autoPlay={props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={5000}
          keyBoardControl
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={props.deviceType}
          focusOnSelect={false}
        >
          <QuoteContainer
            text="When I was introduced to YOOZ many years ago, it saved many lives... especially mine! Life’s many emergencies - like the need to call for an ambulance, the children left at school, the bank close and no cash money or credit on our phone but thanks for signing up for YOOZ and voila… Problem solved! I highly recommend YOOZ because it’s the easiest, most hassle-free way to top up!"
            name="Deborah Alexander"
          />

          <QuoteContainer 
            text="I started using Yooz May 2020 and the service is very easy and convenient too. I use Yooz to top-up my daughter's phone and co-workers frequently it is an instant top-up straight to your phone.  In the future I surely will use Yooz to pay my Bill's as well. Thank you Yooz!" 
            name="Maurissa Granderson" 
          />
          <QuoteContainer
            text="I have been using Yooz for years and continue to with the convenience to top up and pay bills straight at your fingertips.  Cannot wait to see other services added especially Amplia. Thank you Yooz."
            name="Natalie Caruth"
          />
          <QuoteContainer
            text="YOOZ is the fastest, most convenient way for me to top up my phone so I definitely recommend that everyone try it!"
            note="Jodie isn’t just a YOOZer… she’s also one of our survey winners. Congrats, Jodie!"
            name="Jodie Hernandez"
          />
          <QuoteContainer
            text="I can’t even imagine life without YOOZ! I use the service to pay my postpaid bill, my home entertainment bill, to top up my employees and top up my friends."
            name="Ataul Ali-Kajjim"
          />
        </Carousel>
      </Box>
    </Fragment>
  );
}
