import {
  Hidden,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import linkedinColor from "assets/images/LinkedIn_color.png";
import FACLogo from "assets/images/fac.png";
import fbColor from "assets/images/fb_color.png";
import igColor from "assets/images/ig_color.png";
import MasterLogo from "assets/images/master_footer.png";
import VisaLogo from "assets/images/visa.png";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import DocumentLink from "components/shared/ui/documentLink";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import YoozSvgIcon from "./yooz_svg_icon";
import YoozMilaSvgIcon from "components/public-dashboard/components/yooz_svg_icon_mila";

function Copyright() {
  const theme = useTheme();
  const xs =  useMediaQuery(theme.breakpoints.down("xs"));
  const mobileStyle = {fontSize: 10.5}
  return (
      <Fragment>
        <span style={xs ? mobileStyle : {}}>2005-{new Date().getFullYear()} MILA Technology Limited. The term MILA refers to MILA Technology Limited and its
        trademark brand YOOZ.<br/>
        Copyright {"© "}
          YOOZ. All Rights Reserved.</span>
      </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#07294f",
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    width: 36,
    height: 36,
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
    width: "100%",
    maxWidth: 360,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  logo: {
    // width: "100%",
    height: 35,
    [theme.breakpoints.down("xs")]: {
      width: 70,
      height: 35,
    },
    // background: "#FFF",
    // borderRadius: 10,
  },
  logos: {},
  copyright: {
    textAlign: "center",
    color: "#FFF",
    background: "#000617",
    padding: 20,
    width: "100%",
  },
  loginBtn: {
    color: "#FFF",
    borderColor: "#FFF",
    borderRadius: 20,
  },
  actionBtn: {
    borderRadius: 20,
  },
  iconButton: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  linkItem: {
    padding: 0,
  },
  socialImg: {
    minWidth: 27,
    minHeight: 27,
  },
  heading: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  mobileContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  mobileHeading: {
    fontSize: theme.typography.subtitle1.fontSize,
    marginLeft: 0,
  },
  mobileIconsContainer: {
    marginTop: theme.spacing(1),
  },
  mobileIcon: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(1),
  },
  mobileLogo: {
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 60,
      height: 30,
    },
  },
}));

function ListItemLinkA(props) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <ListItem>
      <Link
        href={props.path}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textTransform: "uppercase",
          color: "#fff",
        }}
      >
        <Typography variant={xs ? "body2" : "body1"}>{props.text}</Typography>
      </Link>
    </ListItem>
  );
}
export default function AppFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  function ListItemLink(props) {
    return (
      <ListItem>
        <Link
          component={RouterLink}
          to={props.path}
          onClick={() => {
            dispatch(setTabPosition(props.tab));
            window.scrollTo(0, 0);
          }}
          style={{
            textTransform:  props.renderTextExact ? "none" : "uppercase",
            color: "#fff",
          }}
        >
          <Typography variant={xs ? "body2" : "body1"}>{props.text}</Typography>
        </Link>
      </ListItem>
    );
  }

  return (
    <Fragment>
      <Typography component="footer" className={classes.root}>
        <Grid
            xs={12}
            sm={12}
            md={12}
          className={`${classes.container} ${xs && classes.mobileContainer}`}
        >
          <Grid container >
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              container
              spacing={4}
              direction="row"
              alignItems="flex-start"
              justify={"flex-start"}
            >
              {/*<Hidden smDown>*/}
              {/*  <Grid item xs={3} sm={2}>*/}
              {/*    <YoozSvgIcon*/}
              {/*      height="100%"*/}
              {/*      width={xs ? 100 : sm ? 150 : 250}*/}
              {/*      color={"#FFF"}*/}
              {/*    />*/}
              {/*  </Grid>*/}
              {/*</Hidden>*/}
              <Grid item xs={6} sm={3} md={3} style={{ padding: 0 }}>
                <b className={classes.heading}>Company</b>
                <List>
                  <ListItemLink path="/about" text="About Us" tab={5} />
                  <ListItem><DocumentLink fileId="yo-terms-and-conditions" text="Terms & Conditions"/></ListItem>
                  <ListItem><DocumentLink fileId="yo-privacy-policy" text="Privacy Policy"/></ListItem>
                  <ListItemLink path="/contact" text="Contact us" tab={5} />
                </List>
              </Grid>
              <Grid item xs={6} sm={3} md={2} style={{ padding: 0 }}>
                <b className={classes.heading}>Resources</b>
                <List>
                  <ListItemLink path="/faq" renderTextExact={true} text="FAQs" tab={6} />
                  <ListItemLink path="/secure" text="Secure" tab={6} />
                </List>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              container
              spacing={2}
              direction={"row"} // adjust direction for small screens
              justify={"flex-start"}
              alignItems={xs ? "center" : "flex-start"}
              className={xs ? classes.mobileIconsContainer : null} // apply mobile styles
            >
              <Grid item sm={2} xs={2}>
                <IconButton
                  color="primary"
                  component={Link}
                  href="https://www.facebook.com/yoozpay/"
                  target="_blank"
                  rel="noopener"
                  className={classes.iconButton}
                >
                  <img
                    src={fbColor}
                    alt="ig icon"
                    className={classes.socialImg}
                  />
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2}>
                <IconButton
                  color="primary"
                  component={Link}
                  href="https://www.instagram.com/yooz.tt/?hl=en"
                  target="_blank"
                  rel="noopener"
                  className={classes.iconButton}
                >
                  <img
                    src={igColor}
                    alt="ig icon"
                    className={classes.socialImg}
                  />
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2}>
                <IconButton
                  color="primary"
                  component={Link}
                  href="https://www.linkedin.com/company/yoozb2b/"
                  target="_blank"
                  rel="noopener"
                  className={classes.iconButton}
                >
                  <img
                    src={linkedinColor}
                    alt="ig icon"
                    className={classes.socialImg}
                  />
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2}>
                <Grid
                  item
                  md={5}
                  sm={12}
                  xs={12}
                  container
                  spacing={2}
                  direction={xs ? "column" : "row"} // adjust direction for small screens
                  justify={xs ? "center" : "flex-start"}
                  alignItems={xs ? "center" : "flex-start"}
                  className={xs ? classes.mobileIconsContainer : null} // apply mobile styles
                ></Grid>
              </Grid>
              <Grid item>
                {/* Wrap the Mastercard, Visa, and FAC logos in a new Grid container */}
                <Grid
                  container
                  spacing={2}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img
                      src={MasterLogo}
                      alt="Mastercard SecureCode"
                      className={classes.logo}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      src={VisaLogo}
                      alt="Verified by Visa"
                      className={classes.logo}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      src={FACLogo}
                      alt="Powered By First Atlantic"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      <div
          className={classes.copyright}
      ><Grid container>
        <Grid
            item
            xs={4}
            sm={4}
            md={4}
            container
            spacing={4}
            direction="row"
            alignItems="flex-start"
            justify={"flex-start"}
        >
          <YoozMilaSvgIcon
              styles={{ marginLeft: theme.spacing(1.5) }}
              height="100%"
              width={xs ? 70 : sm ? 80 : 110}
              color={"#FFF"}
          />
        </Grid>
        <Grid
            item
            xs={8}
            sm={8}
            md={8}
            container
            spacing={4}
            direction="row"
            alignItems="center"
            justify={"flex-start"}
        >
          <Copyright />
        </Grid>
      </Grid>
      </div>
    </Fragment>
  );
}
