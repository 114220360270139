import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmptyImg from "assets/images/empty-folder.png";
import history from "history/history";
import Loading from "components/shared/loader/loader";
import { setNavIndex } from "components/secured-app/uiSlice";
import {
  cleanQuery,
  fetchMonthlyStats,
  fetchTxns,
  setQuery,
} from "components/secured-app/features/activity/activitySlice";
import CustomChart from "./chart";
import RecentTxns from "./recent_txns";
import Txns from "./txns";
export default function SecuredDashboard(props) {
  const [topupTxns, setTopUpTxns] = useState([]);
  const [billPayTxns, setBillpayTxns] = useState([]);
  const { loading, monthlyStats, query } = useSelector(
    (state) => state.activity
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      try {
        dispatch(
          setQuery({
            ...cleanQuery,
            pageSize: 5,
            selectedTranTypes: ["Billpay"],
          })
        );
        await dispatch(fetchTxns())
          .then(unwrapResult)
          .then((response) => {
            setBillpayTxns(response.pagedItems);
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });

        dispatch(
          setQuery({
            ...cleanQuery,
            pageSize: 5,
            selectedTranTypes: ["TopUps"],
          })
        );
        await dispatch(fetchTxns())
          .then(unwrapResult)
          .then((response) => {
            setTopUpTxns(response.pagedItems);
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });

        dispatch(
          setQuery({
            ...cleanQuery,
            pageSize: 10,
            selectedTranTypes: [],
          })
        );

        await dispatch(fetchMonthlyStats());
      } catch (error) {
        console.log("Failed to load data");
      }
    };
    loadData();
  }, []);

  function handleRedirect(index, path) {
    dispatch(setNavIndex(index));
  }

  function handleRedirectActivity(type) {
    dispatch(setQuery({ ...query, pageSize: 10, selectedTranTypes: [type] }));
    dispatch(setNavIndex(3));
    history.push({
      pathname: "/user/activity",
    });
  }

  function handleRedirectActivityStatus() {
    dispatch(
      setQuery({
        ...query,
        pageSize: 10,
        selectedTranTypes:[],
        selectedTranStatuses: ["Captured"],
        startDate: moment().subtract(6, "months"),
        endDate: new Date(),
      })
    );
    dispatch(setNavIndex(3));
    history.push({
      pathname: "/user/activity",
    });
  }

  return (
    <Box mb={3}>
      <Container maxWidth="lg">
        {loading === "pending" && <Loading />}
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <RecentTxns
              title="Recent Bill Pay"
              handleRedirect={() => handleRedirectActivity("Billpay")}
              disabled={billPayTxns.length > 0 ? false : true}
              children={
                billPayTxns.length > 0 ? (
                  <Txns items={billPayTxns} type="billpay" />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    spacing={5}
                  >
                    <Grid item xs={12} style={{ opacity: 0.5 }}>
                      <img
                        src={EmptyImg}
                        alt="empty folder icon"
                        style={{ height: 128 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ opacity: 0.5, textAlign: "center" }}
                      >
                        You have not made any bill pay transactions
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component={Link}
                        to="/user/billpay"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRedirect(1, "/user/billpay")}
                      >
                        Pay Bills Now
                      </Button>
                    </Grid>
                  </Grid>
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RecentTxns
              title="Recent Topups"
              handleRedirect={() => handleRedirectActivity("TopUps")}
              disabled={topupTxns.length > 0 ? false : true}
              children={
                topupTxns.length > 0 ? (
                  <Txns items={topupTxns} type="topups" />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    spacing={5}
                  >
                    <Grid item xs={12} style={{ opacity: 0.5 }}>
                      <img
                        src={EmptyImg}
                        alt="empty folder icon"
                        style={{ height: 128 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ opacity: 0.5, textAlign: "center" }}
                      >
                        You have not made any topup transactions
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component={Link}
                        to="/user/topup"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRedirect(1, "/user/topup")}
                      >
                        Topup Now
                      </Button>
                    </Grid>
                  </Grid>
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <RecentTxns
              title="Monthly Stats"
              handleRedirect={handleRedirectActivityStatus}
              children={<CustomChart monthlystats={monthlyStats} />}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
