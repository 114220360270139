class Authentication {
  constructor() {
    this.isAuth = false;
  }

  giveAuthAccess = () => {
    this.isAuth = true;
  };

  checkAuthAccess = () => {
    return this.isAuth;
  };

  deleteAuthAccess = () => {
    this.isAuth = false;
  };
}

export default new Authentication();
