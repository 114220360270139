export const soleTraderData = [
  {
    typeOfDocument: "Letter of Interest",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Registration Form **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Collections Agreement **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "PEP Form **",
    description: "Politically Exposed Persons – To be completed by owner/s",
    required: "YES",
  },
  {
    typeOfDocument: "2 Forms of ID",
    description: "Driver's Permit, Passport, National ID",
    required: "YES",
  },
  {
    typeOfDocument: "Utility Bill",
    description:
      "Utility must be current. If the utility is not in your name or name of business, please provide a letter of authorization from the utility owner, their ID along with the utility. ",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Logo",
    description: "Company logo in .png format.",
    required: "Optional",
  },
];

export const limitedLiabilityCompanyData = [
  {
    typeOfDocument: "Letter of Interest",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Registration Form **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Collections Agreement **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "Articles of Incorporation",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Notice of Directors",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Notice of Secretary",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Notice of Address",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "PEP Form **",
    description:
      "Politically Exposed Persons – To be completed by each Director/Shareholder",
    required: "YES",
  },
  {
    typeOfDocument: "2 Forms of ID",
    description:
      "To be provided for each Director/Shareholder. (Driver's Permit, Passport, National ID)",
    required: "YES",
  },
  {
    typeOfDocument: "Proof of Address",
    description:
      "Must be current and provided if the name on any form of ID provided is not the same as the name printed on proof of address document.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Certificate of Incorporation",
    required: "YES",
  },
  {
    typeOfDocument: "Logo",
    description: "Company logo in .png format.",
    required: "Optional",
  },
];

export const nonProfitOrganizationData = [
  {
    typeOfDocument: "Letter of Interest",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Registration Form **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Collections Agreement **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "Memorandum and Articles of Association",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Annual Return",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "PEP Form **",
    description:
      "Politically Exposed Persons – To be completed by each Director/Shareholder",
    required: "YES",
  },
  {
    typeOfDocument: "2 Forms of ID",
    description:
      "To be provided for each Director/Shareholder. (Driver's Permit, Passport, National ID)",
    required: "YES",
  },
  {
    typeOfDocument: "Proof of Address",
    description:
      "Must be current and provided if the name on any form of ID provided is not the same as the name printed on proof of address document.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Certificate of Registration of a Non-Profit Organization",
    required: "YES",
  },
  {
    typeOfDocument: "Logo",
    description: "Company logo in .png format.",
    required: "Optional",
  },
];

export const schoolData = [
  {
    typeOfDocument: "Letter of Interest",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Registration Form **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "YOOZ Collections Agreement **",
    description: "To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "Memorandum and Articles of Association",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "Annual Return",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "PEP Form **",
    description:
      "Politically Exposed Persons – To be completed by each Director/Shareholder",
    required: "YES",
  },
  {
    typeOfDocument: "2 Forms of ID",
    description:
      "To be provided for each Director/Shareholder/Principal/Vice Principal/Treasurer. (Driver's Permit, Passport, National ID)",
    required: "YES",
  },
  {
    typeOfDocument: "Proof of Address",
    description:
      "Must be current and provided if the name on any form of ID provided is not the same as the name printed on proof of address document.",
    required: "YES",
  },
  {
    typeOfDocument: "Registration Certificate",
    description:
      "Confirmation letter from Ministry OR the Act of Parliament for an Association",
    required: "YES",
  },
  {
    typeOfDocument: "Logo",
    description: "Company logo in .png format.",
    required: "Optional",
  },
];

export const scanToPay = [
  {
    typeOfDocument: "Letter of Interest",
    description: "",
    required: "YES",
  },
  {
    typeOfDocument: "1 Form of ID",
    description: "Driver's Permit, Passport, National ID",
    required: "YES",
  },
  {
    typeOfDocument: "Utility Bill",
    description:
      "Utility must be current. If the utility is not in your name or name of business, please provide a letter of authorization from the utility owner, their ID along with the utility.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Required only for registered traders",
    required: "Conditional",
  },
  {
    typeOfDocument: "Logo",
    description:
      "Company logo in .png format. Recommended to personalize your QR Code",
    required: "Optional",
  },
];

export const tables = [
  { title: "Sole Trader", data: soleTraderData },
  { title: "Limited Liability Company", data: limitedLiabilityCompanyData },
  { title: "Non Profit Organization", data: nonProfitOrganizationData },
  { title: "School / Alumni Assoc.", data: schoolData },
];

export const comment = (
  <span>
    ** Will be sent to you after receipt of LOI and discussions with YOOZ
    Representative.
  </span>
);
export const columns = [
  {
    id: "typeOfDocument",
    label: "Type of Document",
    sortOrder: 1,
    isMobileHeader: true,
    align: "left",
  },
  {
    id: "description",
    label: "Description",
    sortOrder: 2,
    isMobileHeader: true,
    align: "left",
  },
  {
    id: "required",
    label: "Required",
    sortOrder: 3,
    isMobileHeader: true,
    align: "center",
  },
];
