import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  subheader: {
    wordWrap: "break-word",
    color: theme.palette.text.primary,
  },
  listItem: {
    padding: 0,
  },
  rightText: {
    textAlign: "right",
  },
}));

export default function TxnTotalListing(props) {
  const classes = useStyles();
  const { vat, convenienceFee, bpTotal } = props;
  const {
    amount,
    donationAmount,
    customerCardRate,
    customerFee,
    customerFeesDisclaimer = "The fee covers credit/debit card online processing and transaction fees.",
  } = props.txnDetails;

  const amountBV = amount / (1 + vat);
  let total = parseFloat(amount) + parseFloat(donationAmount || 0);

  const showConvenienceFee = convenienceFee > 0;
  if (customerFee || customerCardRate) {
    total = parseFloat(bpTotal) + parseFloat(donationAmount || 0);
  }

  return (
    <Fragment>
      <List className={classes.root} dense>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={6}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Amount"
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <ListItemText
                primary={
                  <Typography className={classes.rightText}>
                    <NumberFormat
                      value={amountBV || amount}
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator
                      isNumericString
                      prefix="$"
                      displayType="text"
                    />
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        {vat > 0 && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={6}>
                <ListItemText primary="Vat" />
              </Grid>
              <Grid item sm={6} xs={6}>
                <ListItemText
                  primary={
                    <Typography className={classes.rightText}>
                      <NumberFormat
                        value={vat * amountBV}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix={"$"}
                        displayType="text"
                      />
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {showConvenienceFee && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={6}>
                <ListItemText
                  className={classes.listItemTextLeft}
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Fragment>
                      Transaction Fee
                      <Tooltip title={customerFeesDisclaimer}>
                        <IconButton aria-label="customer-fee-message" size="small" color="secondary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Fragment>
                  }
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <ListItemText
                  primary={
                    <Typography className={classes.rightText}>
                      <NumberFormat
                        value={convenienceFee}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix="$"
                        displayType="text"
                      />
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {parseFloat(donationAmount) > 0 && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={6}>
                <ListItemText primary="Donation" />
              </Grid>
              <Grid item sm={6} xs={6}>
                <ListItemText
                  primary={
                    <Typography className={classes.rightText}>
                      <NumberFormat
                        value={donationAmount}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix="$"
                        displayType="text"
                      />
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={6}>
              <ListItemText
                primaryTypographyProps={{
                  style: { fontWeight: "bold" },
                }}
                primary="Total"
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <ListItemText
                primary={
                  <Typography className={classes.rightText}>
                    <b>
                      <NumberFormat
                        value={total}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix="$"
                        displayType="text"
                      />
                    </b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Fragment>
  );
}
