import { Container, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "components/public-dashboard/components/TextList";
import IconHeader from "components/public-dashboard/components/iconHeader";
const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%",
    },
  },
  img: {
    width: "100%",
  },
  magentaText: {
    color: "#CC3E7D",
  },
}));

export default function PaymentSolutionsHeader() {
  const classes = useStyles();
  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconHeader
            title="PAYMENT GATEWAY"
            iconColor="#000"
            titleColor="#CC3E7D"
            titleLg="h2"
            titleSm="h4"
            titleXs="h5"
          />
        </Grid>
        <Grid item sm={12}>
          <TextList
            items={[
              <span>
                <strong>Boost your Business with</strong>{" "}
                <strong className={classes.magentaText}>
                  YOOZ Payment Gateway{" "}
                </strong>
                - the safest, simplest way to accept payments online!
              </span>,
            ]}
          ></TextList>
        </Grid>
      </Grid>
    </Container>
  );
}
