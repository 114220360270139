import {
  Button,
  Container,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Alert from "@material-ui/lab/Alert";
import signupbanner from "assets/images/signup_banner.jpg";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import AgreementCheckboxes from "components/shared/agreement_checkboxes";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import SignupHeader from "./header";
import MaskedNumber from "./masked_number";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Hyperlink from "../../../shared/hyperlink";
import {API_ROOT} from "../../../../constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  form: {
    minHeight: "80vh",
  },
  inputField: {
    margin: "20px 0",
  },
}));

export default function Signup_form(props) {
  const {
    onSubmit,
    onChangeFormField,
    onChangeFormFieldPhone,
    validateCheckBox,
    isChecked,
    countries,
    mobNum,
    isRegDisable,
    error,
    tosError,
    password,
    confirmPwd
  } = props;
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState("");
  let { referral = "" } = useParams();
  const [referralState, setReferral] = useState("");
  const [receivePromo, setReceivePromo] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [tosAgreed, setToSAgreed] = useState(false);
  useEffect(() => {
    const checkReferral = () => {
      setReferral(referral);
      onChangeFormField("referral", referral)
    };

    checkReferral();
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToSChange = (prop) => (event ) =>{
    validateCheckBox();
  }

  return (
    <Fragment>
      <PageBanner img={signupbanner} />
      <ContentWrapper>
        <SignupHeader />
        <Container maxWidth="md">
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <form onSubmit={(e) => onSubmit(e)}>
                <FormGroup row>
                  <FormControl
                    required
                    color="secondary"
                    className={classes.inputField}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      COUNTRY
                    </InputLabel>

                    {countries && (
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectValue}
                        onChange={(e) => {
                          setSelectValue(e.target.value);
                          // onChangeFormField("countryId", e.target.value)
                          onChangeFormFieldPhone(e);
                        }}
                        label="COUNTRY"
                        id="country"
                      >
                        <MenuItem value={0} disabled>
                          <em>Select Country</em>
                        </MenuItem>

                        {countries.map((e, key) => {
                          return (
                            <MenuItem key={key} value={e.countryId}>
                              {e.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </FormControl>
                  <Grid item xs={12} className={classes.inputField}>
                    <MaskedNumber
                      handleChange={onChangeFormField}
                      value={mobNum}
                      isRegDisable={isRegDisable}
                    />
                  </Grid>
                  <TextField
                    color="secondary"
                    required
                    type="text"
                    disabled={isRegDisable}
                    onChange={(e) =>
                      onChangeFormField("firstName", e.target.value)
                    }
                    className={classes.inputField}
                    fullWidth
                    label="FIRST NAME"
                    variant="outlined"
                    id="firstName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
                    }}
                  />
                  <TextField
                    color="secondary"
                    required
                    type="text"
                    disabled={isRegDisable}
                    onChange={(e) =>
                      onChangeFormField("lastName", e.target.value)
                    }
                    className={classes.inputField}
                    fullWidth
                    label="LAST NAME"
                    variant="outlined"
                    id="lastName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
                    }}
                  />
                  <TextField
                    color="secondary"
                    required
                    type="email"
                    disabled={isRegDisable}
                    onChange={(e) => onChangeFormField("email", e.target.value)}
                    className={classes.inputField}
                    fullWidth
                    label="EMAIL"
                    variant="outlined"
                    id="email"
                  />
                  <TextField
                    color="secondary"
                    required
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{
                      minLength: 8,
                    }}
                    disabled={isRegDisable}
                    onChange={(e) =>
                      onChangeFormField("password", e.target.value.replace(" ", ""))
                    }
                    helperText="Must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                    className={classes.inputField}
                    fullWidth
                    label="PASSWORD"
                    variant="outlined"
                    value={password}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    color="secondary"
                    required
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{
                      minLength: 8,
                    }}
                    disabled={isRegDisable}
                    onChange={(e) => onChangeFormField("confirmPwd", e.target.value.replace(" ", ""))

                    }
                    className={classes.inputField}
                    fullWidth
                    label="CONFIRM PASSWORD"
                    variant="outlined"
                    id="confirmPassword"
                    value={confirmPwd}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    color="secondary"
                    type="text"
                    disabled={isRegDisable}
                    onChange={(e) => {
                      onChangeFormField("referral", e.target.value)
                      setReferral(e.target.value)
                    }}
                    className={classes.inputField}
                    fullWidth
                    label="REFERRAL CODE"
                    variant="outlined"
                    helperText="Please enter the referral code provided. If you did not receive one, please tell us where you heard about YOOZ."
                    id="referral"
                    value={referralState}
                    onInput={(e) => {
                      e.target.value = e.target.value.match(/[A-Za-z0-9- ]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
                    }}
                  />
                  <Grid item xs={12} >
                    <FormControlLabel
                        control={
                          <Checkbox id="receivePromo" checked={receivePromo} onChange={(e) => {
                            onChangeFormField("receivePromo", e.target.checked)
                            setReceivePromo(e.target.checked)
                          }} />
                        }
                        label={
                          <div>
                            I'd like to receive promotional emails.
                          </div>
                        }
                    />
                  </Grid>
                  <AgreementCheckboxes applyDefaultStyles={false} value={isChecked} handleChange={handleToSChange} error={tosError} />
                  <Grid item xs={12}>
                    {error.length > 0 && (
                      <div
                        style={{
                          marginBottom: 25,
                        }}
                      >
                        <Alert severity="error"> {error} </Alert>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      id="submitRegister"
                    >
                      Submit
                    </Button>
                  </Grid>
                </FormGroup>
              </form>
            </Grid>
          </Grid>
        </Container>
      </ContentWrapper>
    </Fragment>
  );
}
