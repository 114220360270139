import { combineReducers } from "redux";
import billpay from "components/public-dashboard/features/billpay/bpSlice";
import paymentCard from "components/secured-app/features/account/paymentMethods/pmslice";
import topup from "components/public-dashboard/features/topup/topupSlice";
import ui from "components/public-dashboard/uiSlice";
import accountReducer from "reducers/accounts";
import activityReducer from "reducers/activity";
import authReducer from "reducers/auth";
import entities from "./entities";
import uiSecured from "components/secured-app/uiSlice";
import activity from "components/secured-app/features/activity/activitySlice";
import uiGlobal from "./uiGlobal";

const rootReducer = combineReducers({
  entities,
  authReducer,
  accountReducer,
  // activityReducer,
  billpay,
  paymentCard,
  ui,
  uiSecured,
  uiGlobal,
  topup,
  activity
});

export default rootReducer;
