import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

export default function AboutusHeader() {
  const theme = useTheme();

  return (
    <Container>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <IconHeader
            title="About Us"
            iconColor={theme.palette.primary.main}
            titleColor="#709C07"
            titleLg="h2"
          />
        </Grid>
        {/* <b><b>YOOZ</b>{" "} </b>{" "} 
        <a style={{ color: "#1cc5ff" }}>
        */}
        <Grid item>
          <TextList
            items={[
              <span>
                  <a style={{ color: "#1cc5ff", fontWeight:"bold" }}>Founded in 2005,</a> the service that is now known as <b>YOOZ</b>{" "}  was conceptualised by a group of innovators who realised that there was a growing need for alternative payment solutions in Trinidad and Tobago and the wider Caribbean. Originally called Easy Top Up, our focus was on the cell phone pre-paid top up business, and we partnered with Resonance South Africa to supply their revolutionary Top Up technology. Operating as Resonance Trinidad Limited, we provided a smoother and faster Top Up experience for our customers.
              </span>,
                <span>

                 <a style={{color: "#1cc5ff", fontWeight: "bold"}}> As early as 2008,</a> we recognised that the market’s needs were changing. People wanted more accessible payment solutions. Under the direction of our founding owner and leader Mr. Michael Lau, the team started to look inward. We pooled resources of local talent in product development and IT to create solutions to meet those needs. This led us to pivot our business to include Bill Payment and a variety of e-commerce solutions that service the telecommunications, utilities, entertainment, distribution and retail industries, while supporting and encouraging small business and entrepreneurship.
              </span>,
                <span>
                    <a style={{color: "#1cc5ff", fontWeight: "bold"}}>Today,</a> this evolution, its success and opportunities for growth has positioned us to be one of <a style={{color: "#1cc5ff", fontWeight: "bold"}}>the most comprehensive payment service providers in Trinidad and Tobago</a>. It was only fitting that the name of a company of local innovators, engineers and customer success specialists, reflect the visionary and founding father, of the evolution, the esteemed, late Mr. Michael Lau. In 2024, we officially changed our company name to MILA Technology Limited, offering <a style={{color: "#1cc5ff", fontWeight: "bold"}}>YOOZ Payment Solutions services to ALL</a>.
              </span>,
              <span>
                Operating with a uniquely dynamic and flexible core payment platform, our goal is to  <a style={{color: "#1cc5ff", fontWeight: "bold"}}>simplify payment services with innovative solutions</a> that facilitate fast, flexible, easy and secure transactions.
              </span>,
              <span>
                We are committed to safeguarding your data and transactions by employing best practices, regulatory and industry standard-compliant auditable processes‚ encryption and tokenisation technology.
              </span>,
                <span>
                Inspired by our culture of inclusion, equal opportunity and embracing change our team develops and fine tunes solutions that create opportunities for both the banked and unbanked to safely, quickly and easily, send and receive payments.
              </span>,
              <span>
                We believe  <a style={{color: "#1cc5ff", fontWeight: "bold"}}>everyone in all sectors of society, deserves a level playing field</a> to transact legitimate business through  <a style={{color: "#1cc5ff", fontWeight: "bold"}}>multiple payment channels</a> that suit their varying needs.
              </span>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
