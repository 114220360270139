import { Button, Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import proTip from "assets/images/protip_topup.png";
import ContentList from "components/public-dashboard/components/contentList";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import TextList from "components/public-dashboard/components/TextList";
import HomeHeader from "components/public-dashboard/features/agents/header";

const useStyles = makeStyles((theme) => ({
  logoHeading: {
    width: 150,
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
    minWidth: 100,
  },
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 70,
      marginBottom: theme.spacing(10),
    },
  },
  overlineText: {
    display: "flex",
    alignItems: "left",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
  },
  it: {
    fontStyle: "italic",
    fontFamily: "Arial Rounded MT",
    textTransform: "none",
  },
  right: {
    textAlign: "right",
  },
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "75%",
  },
  ol: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  yoozBlueLink: {
    color:"#00AFE2"
  },
}));

export default function TopupContent() {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();

  const LinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          dispatch(setTabPosition(navIndex));
        }}
        component={RouterLink}
        to={route}
        // color="primary"
        className={classes.linktext}
      >
        <b>{text}</b>
      </Link>
    );
  };

  return (

    <Container>
            <ContentList
        title="Quick Pay"
        items={[
          <span>
           All you need is your phone number, visa debit card or any credit card and email address. Choose the amount that's right for you!
          </span>,
          <Button
            component={RouterLink}
            to="/quicktopup"
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, 0);
              dispatch(setTabPosition(8));
            }}
            style={{ borderRadius: 20, marginRight: 10 }}
          >
            Click Here
          </Button>,
        ]}
      />
            <ContentList
        title="LOGIN"
        items={[
          <span>
            Keep track of all your top ups and YOOZ It for FREE, anywhere, anytime you want!  Don't have a Login Account? <a href="signup"
                                                                                                                             className={classes.yoozBlueLink}>Sign up</a> for FREE.
          </span>,
          <Button
            component={RouterLink}
            to="/login"
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, 0);
              dispatch(setTabPosition(8));
            }}
            style={{ borderRadius: 20, marginRight: 10 }}
          >
            Click Here
          </Button>,
          
        ]}
      />
            <ContentList
        title="YOOZ Agent Network"
        items={[
          
          <span>Top Up with cash, without the hassle of long lines and travelling long distances.
          <br/><br/>
        With over 900 YOOZ Agents located nationwide, you can Top Up at an easily accessible location within your very own community.
        <br/><br/>
        Look for the YOOZ sign at your nearby shop, supermarket or pharmacy.  
        <br/><br/>
        It’s quick and easy!      
         </span>,
          
        ]}
      />   
    </Container>
  );
}
