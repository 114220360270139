import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "constants/actionTypes";
import { getAsync } from "components/public-dashboard/services/api_util";
import { setAnonTokenApi } from "components/public-dashboard/services/utility";
import { getUserDetail } from "middleware/storage";

export const fetchCountries = createAsyncThunk(
  "paymentCard/fetchCountries",
  async (thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}public/countries`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);


export const fetchCountryStates = createAsyncThunk(
    "paymentCard/fetchCountryStates",
    async (countryCode) => {
      await setAnonTokenApi();
      const response = await getAsync(`${API_ROOT}public/country/${countryCode}/states`);
      if (response.state === 1) {
        return response.data;
      }
      return [];
    }
);


const user = getUserDetail();

const cleanState = {
  addressDetails: {
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
    emailAddress: "",
    phoneNumber: ""
  },
  countries: [],
  countryStates:[],
  showCCForm: false,
  isLoading: false,
};


const pmSlice = createSlice({
  name: "paymentCard",
  initialState: cleanState,
  reducers: {
    setAddressDetails(state, action) {
      state.addressDetails = action.payload;
    },
    setIsLoading(state, action){
        state.isLoading = action.payload;
    }
  },
  extraReducers: {
    [fetchCountries.fulfilled]: (state, action) => {
      state.countries = [...action.payload];
    },
    [fetchCountryStates.fulfilled]: (state, action) => {
      state.countrStates = [...action.payload];
    }
  },
});

export const {
  setAddressDetails,
  setIsLoading
} = pmSlice.actions;

export default pmSlice.reducer;
