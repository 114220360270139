import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { getCardImageByType } from "components/utils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export default function PaymentMethodItem(props) {
  const classes = useStyles();
  const { card, handleClick } = props;

  const handleCustomClick = () => {
    if (handleClick) {
      handleClick("customercard", card);
    }
  };
  return (
    <ListItem button onClick={handleCustomClick}>
      <ListItemAvatar className={classes.avatar}>
        {getCardImageByType(card.cardType)}
      </ListItemAvatar>
      <ListItemText
        primary={card.nickName}
        secondary={
          <Typography color="primary" variant="subtitle2">
            {card.isPrimary ? "Primary" : ""}
          </Typography>
        }
      />
      {handleClick && (
        <ListItemSecondaryAction>
          <Checkbox edge="end" checked={card.isPrimary} color="primary" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
