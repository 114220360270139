import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "containers/auth/login";
import Donate from "./features/donate";
import Signup from "containers/auth/signup";
import About from "./features/about";
import Billpay from "./features/billpay";
import BillPayInfo from "./features/billpay_info";
import scan2payGnS from "./features/scan2payGoodsandServices";
import Scan2Pay from "./features/scan2pay";
import Contact from "./features/contact";
import FAQ from "./features/faq";
import Home from "./features/home";
import Partners from "./features/partners";
import TopupInfo from "./features/topup_info";
import Topup from "./features/topup";
import resetPassword from "containers/auth/resetPassword";
import Agents from "./features/agents";
import CollectDontions from "./features/collect_donations";
import PaymentGateway from "./features/payment_gateway";
import Secure from "./features/secure";
import OnboardingDocuments from "./features/onBoarding_documents";
import AgentOnboardingDocuments from "./features/agent_onBoarding_documents";
import AgentLocation from "./features/agent_locations";
import Scan2PayGns from "./features/scan2payGoodsandServices";
export default function PublicRouter(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={match.url} exact component={Home}></Route>
      <Route path={`/about`} exact component={About}></Route>
      <Route path={`/donate`} exact component={Donate}></Route>
      <Route path={`/contact`} exact component={Contact}></Route>
      <Route path={`/billpay`} exact component={BillPayInfo}></Route>
      <Route path={`/quickpay`} exact component={Billpay}></Route>
      <Route path={`/scan2payGnS`} exact component={Scan2PayGns}></Route>
      <Route path={`/scan2pay`} exact component={Scan2Pay}></Route>
      <Route path={`/quickpay/:providerId/:accountNumber?/:amount?/:email?`} component={Billpay}></Route>      
      <Route path={`/quicktopup`} exact component={Topup}></Route>
      <Route path={`/topup`} exact component={TopupInfo}></Route>
      <Route path={`/partners`} exact component={Partners}></Route>
      <Route path={`/signup/:referral?`} exact component={Signup}></Route>
      <Route path={`/login`} exact component={Login}></Route>
      <Route path={`/faq`} exact component={FAQ}></Route>
      <Route path="/resetpassword" exact component={resetPassword}></Route>
      <Route path={`/agents`} exact component={Agents}></Route>
      <Route path={`/collect-donations`} exact component={CollectDontions}></Route>
      <Route path={`/payment-gateway`} exact component={PaymentGateway}></Route>
      <Route path={`/secure`} exact component={Secure}></Route>
      <Route path={`/OnboardingDocuments`} exact component={OnboardingDocuments}></Route>
      <Route path={`/AgentOnboardingDocuments`} exact component={AgentOnboardingDocuments}></Route>
      <Route path={`/AgentLocations`} exact component={AgentLocation}></Route>
      <Redirect to="/" />
    </Switch>
  );
}
