import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useState } from "react";
import MaskedCvv from "./masked_cvv_input";
import SelectCC from "./select_card";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  addBtn: {
    borderRadius: 20,
  },
}));

export default function PaymentDetailsRegistered(props) {
  const classes = useStyles();
  const { setPaymentDetails, paymentDetailsState, errors, cCards, handleCardSelectedAddress } = props;
  const [paymentDetails, updatePaymentDetails] = useState(paymentDetailsState);

  const handleChange = (prop) => (event) => {
    setPaymentDetails({ ...paymentDetails, [prop]: event.target.value });
    updatePaymentDetails({ ...paymentDetails, [prop]: event.target.value });
    if(prop == "customerCardId"){
      handleCardSelectedAddress(event.target.value)
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectCC
            cards={cCards}
            handleChange={handleChange}
            error={errors.customerCardId}
            value={paymentDetails.customerCardId}
          />
        </Grid>
        <Grid item xs={12}>
          <MaskedCvv
            handleChange={handleChange}
            value={paymentDetails.cvv}
            error={errors.cvv}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
