import { Record } from "immutable";
import {
  CARD_ADD_FAILURE,
  CARD_ADD_REQUEST,
  CARD_ADD_SUCCESS,
  CARD_LIST_FAILURE,
  CARD_LIST_REQUEST,
  CARD_LIST_SUCCESS,
  DETAIL_EDIT_FAILURE,
  DETAIL_EDIT_REQUEST,
  DETAIL_EDIT_SUCCESS,
  NUMBER_ADD_FAILURE,
  NUMBER_ADD_REQUEST,
  NUMBER_ADD_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_REQUEST,
  OTP_VARIFY_SUCCESS,
  PHONE_LIST_FAILURE,
  PHONE_LIST_REQUEST,
  PHONE_LIST_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
} from "constants/actionTypes";

const InitialState = Record({
  isUpdating: false,
  isAddingPhone: false,
  isAddingCard: false,
  isSent: false,
  isVarifying: false,
  isGetting: false,
  isFetchingCards: false,
});

const initialState = new InitialState();

const accountReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NUMBER_ADD_REQUEST:
      return state.setIn(["isAddingPhone"], true).setIn(["error"], null);
    case NUMBER_ADD_SUCCESS:
      return state.setIn(["isAddingPhone"], false).setIn(["error"], null);
    case NUMBER_ADD_FAILURE:
      return state
        .setIn(["isAddingPhone"], false)
        .setIn(["error"], action.error);
    case CARD_ADD_REQUEST:
      return state.setIn(["isAddingCard"], true).setIn(["error"], null);
    case CARD_ADD_SUCCESS:
      return state.setIn(["isAddingCard"], false).setIn(["error"], null);
    case CARD_ADD_FAILURE:
      return state
        .setIn(["isAddingCard"], false)
        .setIn(["error"], action.error);
    case PHONE_LIST_REQUEST:
      return state.setIn(["isGetting"], true).setIn(["error"], null);
    case PHONE_LIST_SUCCESS:
      return state.setIn(["isGetting"], false).setIn(["error"], null);
    case PHONE_LIST_FAILURE:
      return state.setIn(["isGetting"], false).setIn(["error"], action.error);
    case CARD_LIST_REQUEST:
      return state.setIn(["isFetchingCards"], true).setIn(["error"], null);
    case CARD_LIST_SUCCESS:
      return state.setIn(["isFetchingCards"], false).setIn(["error"], null);
    case CARD_LIST_FAILURE:
      return state
        .setIn(["isFetchingCards"], false)
        .setIn(["error"], action.error);
    case DETAIL_EDIT_REQUEST:
      return state.setIn(["isUpdating"], true).setIn(["error"], null);
    case DETAIL_EDIT_SUCCESS:
      return state.setIn(["isUpdating"], false).setIn(["error"], null);
    case DETAIL_EDIT_FAILURE:
      return state.setIn(["isUpdating"], false).setIn(["error"], action.error);
    case OTP_VARIFY_REQUEST:
      return state.setIn(["isVarifying"], true).setIn(["error"], null);
    case OTP_VARIFY_SUCCESS:
      return state.setIn(["isVarifying"], false).setIn(["error"], null);
    case OTP_VARIFY_FAILURE:
      return state.setIn(["isVarifying"], false).setIn(["error"], action.error);
    case RESEND_OTP_REQUEST:
      return state.setIn(["isSent"], true).setIn(["error"], null);
    case RESEND_OTP_SUCCESS:
      return state.setIn(["isSent"], false).setIn(["error"], null);
    case RESEND_OTP_FAILURE:
      return state.setIn(["isSent"], false).setIn(["error"], action.error);
    default:
      return state;
  }
};

export default accountReducer;
