import { createSlice } from "@reduxjs/toolkit";

const cleanState = {
  snackbar: {
    open: false,
    msg: "",
    type: "default",
    autoHideDuration: null,
    action: null,
  },
  loading: false,
};

const uiGlobalSlice = createSlice({
  name: "uiGlobal",
  initialState: cleanState,
  reducers: {
    showSnackBar(state, action) {
      state.snackbar = { open: true, ...action.payload };
    },

    closeSnackBar(state, action) {
      state.snackbar = { ...cleanState };
    },

    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  showSnackBar,
  closeSnackBar,
  setLoading,
} = uiGlobalSlice.actions;

export default uiGlobalSlice.reducer;
