import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { NotFound } from "components/notfound/notfound";
import PublicDashboard from "components/public-dashboard";
import CustomizedSnackbars from "components/public-dashboard/components/snackBar";
import SecuredApp from "components/secured-app";
import Loading from "components/shared/loader/loader";
import history from "history/history";
import ProtectedRoute from "./protected";

export default function RouterHandler() {
  const { snackbar, loading } = useSelector((state) => state.uiGlobal);
  return (
    <Fragment>
      {loading && <Loading />}
      <Router history={history}>
        <Switch>
          <ProtectedRoute path="/user" component={SecuredApp} />
          <Route path="/" component={PublicDashboard}></Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
      <CustomizedSnackbars snackbar={snackbar} />
    </Fragment>
  );
}
