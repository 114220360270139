import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "./TextList";

const useStyles = makeStyles((theme) => ({
  overlineText: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
  },
}));

export default function ContentList(props) {
  const { title, items, color, styles } = props;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container>
      {title && (
        <Grid item xs={12}>
          <Typography
            variant={xs ? "body1" : sm ? "h6" : md ? "h5" : "h4"}
            className={classes.overlineText}
            style={{
              color: color ?? "#1CC5FF",
              ...styles,
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}

      {items && items.length > 0 && (
        <Grid item container xs={12}>
          <TextList items={items} />
        </Grid>
      )}
    </Grid>
  );
}
