import React, { Fragment } from "react";
import billpayBanner from "assets/images/billpay_banner.jpg";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import BillpayContent from "./content";
import BillpayHeader from "./header";

export default function BillPayInfo() {
  return (
    <Fragment>
      <PageBanner img={billpayBanner} />
      <ContentWrapper>
        <BillpayHeader />
        <BillpayContent />
      </ContentWrapper>
    </Fragment>
  );
}
