import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      height: "400px",
    },
  },
  cardHeader: {},
}));

export default function RecentTxns(props) {
  const { title, children, handleRedirect, disabled = false } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={<Typography variant="h6">{title}</Typography>} />
      <Divider />
      <CardContent className={classes.content}>{children}</CardContent>
      <CardActions className={classes.cardAction}>
        <Button color="primary" onClick={handleRedirect} disabled={disabled}>
          View More
        </Button>
      </CardActions>
    </Card>
  );
}
