import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Container, Typography } from "@material-ui/core";
import systemAlertImg from "assets/images/systemAlert.png";
import { Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  img: {
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      padding: theme.spacing(3),
    },
  },
  button: {
    borderRadius: 50,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
    marginRight: theme.spacing(1),
  },
}));

function SystemMessage(props) {
  const classes = useStyles();
  const {
    width,
    title = "",
    message = ""
  } = props;
  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
          <img src={systemAlertImg} alt="error" className={classes.img} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Typography
              variant={
                width === "xl"
                  ? "h2"
                  : width === "lg"
                    ? "h3"
                    : width === "md"
                      ? "h4"
                      : width === "sm"
                        ? "h5"
                        : "h6"
              }
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={
                width === "xl" ? "h6" : width === "lg" ? "body1" : "body2"
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withWidth()(SystemMessage);
