import { Button, Checkbox, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { fetchAddressStates } from "components/public-dashboard/features/topup/topupSlice";
import AgreementCheckboxes from "components/shared/agreement_checkboxes";
import BillingAddress from "components/shared/billing_address";
import MaskedCCInput from "components/shared/payments/masked_cc_input";
import MaskedCvv from "components/shared/payments/masked_cvv_input";
import MaskedExpiryDate from "components/shared/payments/masked_expiry_date";
import { getCardType } from "components/utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DialogWrapper from "./DialogWrapper";
import { fetchCountries } from "./pmslice";


const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(2),
  },
}));
let submitCheck = 0;
export default function AddPaymentCard(props) {
  const classes = useStyles();
  const [countries, setCountries] = useState([])
  const [countryStates, setCountryStates] = useState([])
  const dispatch = useDispatch();
  const { error, addressError, handleSubmit, handleClose, cards } = props;
  const [tosAgreed, setTosAgreed] = useState(false);
  const [tosError, setTosError] = useState("");
  const [paymentDetails, updatePaymentDetails] = useState({
    name: "",
    cardNumber: "",
    countryCode:"",
    expirationDate: "",
    cvv: "",
    isPrimary: true,
    tac: false,
    ppolicy: false,
    nickName: "",
    cardType: ""
  });

  const [billingAddressFields,  updateBillingDetails] = useState({
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    state: "",
    postalCode: "",
    countryCode: "",
    city: ""
  });


  const blankAddressDetails = {
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    city: ""
  }

  let btnRef = useRef();

  useEffect(() => {
    fetchCountryList();
  }, [])

  async function fetchCountryList() {
      const result = await dispatch(fetchCountries());
      setCountries(result.payload);

      const result2 = await dispatch(fetchAddressStates("840"));
      setCountryStates(result2.payload);
  }

  const handleChange = (prop) => (event) => {
    if(btnRef.current.disabled == true){
      btnRef.current.disabled = false;
    }
    if (["isPrimary", "tac", "ppolicy"].includes(prop)) {
      updatePaymentDetails({ ...paymentDetails, [prop]: event.target.checked });
    } else if (prop === "countryCode" && event.target.value !== "") {
      updatePaymentDetails({ ...paymentDetails, [prop]: event.target.value });
      updateBillingDetails({ ...billingAddressFields, [prop]: event.target.value });
    }else {
      updatePaymentDetails({ ...paymentDetails, [prop]: event.target.value });
      updateBillingDetails({ ...billingAddressFields, [prop]: event.target.value });
    }
    if(submitCheck == 1){
      submitCheck = 0;
      btnRef.current.disabled = false;
    }

  };

  const handleToSChange = (prop) => (event ) =>{
    setTosAgreed(event.target.checked);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if(!tosAgreed){
      setTosError("Agree to Terms and Conditions.");
      return;
    }


    submitCheck = 1;
     btnRef.current.disabled = true;
    paymentDetails.cardType = getCardType(paymentDetails.cardNumber);
    await handleSubmit(paymentDetails, billingAddressFields);
  };

  return (
    <DialogWrapper {...props} handleClose={handleClose}>
      <form className={classes.form} onSubmit={onSubmit}>
      <FormControl
        required
        error={error.length > 0}
        component="fieldset"
        className={classes.formControl}
      >

      </FormControl>
        <Grid container spacing={2}>
        <FormLabel component="legend">Card Details</FormLabel>
          <Grid item xs={12}>
            <TextField
              label="Name on Card *"
              variant="outlined"
              error={addressError.name?.length > 0}
              helperText={addressError.name?.length > 0 ? addressError.name:""}
              fullWidth
              autoFocus
              value={paymentDetails.name}
              onChange={handleChange("name")}
              className={classes.formControl}
              color="secondary"
              inputProps={{
                maxLength: 50,
              }}
              onInput={(e) => {
                e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MaskedCCInput
              error={addressError.cardNum}
              color="secondary"
              handleChange={handleChange}
              value={paymentDetails.cardNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaskedExpiryDate
              helperText={addressError.expDate?.length > 0 ? addressError.expDate:""}
              error={addressError.expDate}
              handleChange={handleChange}
              value={paymentDetails.expirationDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaskedCvv
              error={addressError.cvv}
              handleChange={handleChange}
              value={paymentDetails.cvv}
            />
          </Grid>
          <FormLabel component="legend">Billing Address Details</FormLabel>
          <Grid item sm={12} xs={12}>
          <BillingAddress handleChange={handleChange} countries={countries} countryStates={countryStates} errors={addressError} addressDetails={billingAddressFields}/>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentDetails.isPrimary}
                  onChange={handleChange("isPrimary")}
                  required={cards.length === 0 ? true : false}
                />
              }
              label="Set as Primary Card"
            />
          </Grid>
          <Grid item xs={12}>
            <AgreementCheckboxes applyDefaultStyles={false} value={tosAgreed} error={tosError} handleChange={handleToSChange} />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error"> {addressError.alert} </Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button onClick={handleClose} fullWidth variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              ref={btnRef}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </DialogWrapper>
  );
}