import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import React from "react";
//Redux
import { useSelector } from "react-redux";
import PageLoading from "components/shared/loader/pageLoader";
import CustomAppBar from "./appbar";
import AppFooter from "./components/footer";
import PublicRouter from "./public_router";
import { getToken } from "middleware/storage";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  bg: {
    backgroundColor: "#FFF",
    minHeight: "100vh",
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: 10,
    },
    "*::-webkit-scrollbar-track": {
      background: "lightGrey",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 20,
      border: "2px solid lightBlue",
    },
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function PublicDashboard(props) {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.billpay);
  const token = getToken();
  return token ? (
    <Redirect to="/user" />
  ) : (
    <div className={classes.bg}>
      <CssBaseline />
      <CustomAppBar />
      <span id="back-to-top-anchor" />
      <PublicRouter {...props} />

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <AppFooter />
    </div>
  );
}
