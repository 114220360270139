import axios from "axios";
import {
  API_ROOT,
  CARD_ADD_FAILURE,
  CARD_ADD_REQUEST,
  CARD_ADD_SUCCESS,
  CARD_EDIT_FAILURE,
  CARD_EDIT_REQUEST,
  CARD_EDIT_SUCCESS,
  CARD_REMOVE_FAILURE,
  CARD_REMOVE_REQUEST,
  CARD_REMOVE_SUCCESS,
  CARD_LIST_FAILURE,
  CARD_LIST_REQUEST,
  CARD_LIST_SUCCESS,
  DETAIL_EDIT_FAILURE,
  DETAIL_EDIT_REQUEST,
  DETAIL_EDIT_SUCCESS,
  NUMBER_ACTIVATE_FAILURE,
  NUMBER_ACTIVATE_REQUEST,
  NUMBER_ACTIVATE_SUCCESS,
  NUMBER_ADD_FAILURE,
  NUMBER_ADD_REQUEST,
  NUMBER_ADD_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_REQUEST,
  OTP_VARIFY_SUCCESS,
  PHONE_LIST_FAILURE,
  PHONE_LIST_REQUEST,
  PHONE_LIST_SUCCESS,
  PRIMARY_CARD_FAILURE,
  PRIMARY_CARD_REQUEST,
  PRIMARY_CARD_SUCCESS,
  PRIMARY_PHONE_FAILURE,
  PRIMARY_PHONE_REQUEST,
  PRIMARY_PHONE_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
} from "constants/actionTypes";
import { setUserDetails } from "middleware/storage";

function Request(type) {
  return {
    type: type,
  };
}

function Success(type, payload) {
  return {
    type: type,
    entities: payload,
  };
}

function Failure(message, type) {
  return {
    type: type,
    error: message,
  };
}

export function activatePhone(phoneId, customerId, token) {
  return (dispatch, getState) => {
    dispatch(Request(NUMBER_ACTIVATE_REQUEST));
    return axios
      .post(
        API_ROOT +
          `customers/activatephone?phoneId=` +
          phoneId +
          "&customerId=" +
          customerId,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        return dispatch(Success(NUMBER_ACTIVATE_SUCCESS));
      })
      .catch((error) => {
        return dispatch(Failure(error, NUMBER_ACTIVATE_FAILURE));
      });
  };
}

export function primaryPhone(phoneId, customerId, token) {
  return (dispatch, getState) => {
    //getState().entities.accounts.accounts = {};
    dispatch(Request(PRIMARY_PHONE_REQUEST));
    return axios
      .post(
        API_ROOT +
          `customers/primaryphone?phoneId=` +
          phoneId +
          "&customerId=" +
          customerId,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        return dispatch(Success(PRIMARY_PHONE_SUCCESS));
      })
      .catch((error) => {
        return dispatch(Failure(error, PRIMARY_PHONE_FAILURE));
      });
  };
}

export function deactivatePhone(phoneId, customerId, token) {
  return (dispatch, getState) => {
    //getState().entities.accounts.accounts = {};
    dispatch(Request(PRIMARY_PHONE_REQUEST));
    return axios
      .post(
        API_ROOT +
          `customers/deactivatephone?phoneId=` +
          phoneId +
          "&customerId=" +
          customerId,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        return dispatch(Success(PRIMARY_PHONE_SUCCESS));
      })
      .catch((error) => {
        return dispatch(Failure(error, PRIMARY_PHONE_FAILURE));
      });
  };
}

export function primaryCard(id, customerId, token) {
  return (dispatch, getState) => {
    dispatch(Request(PRIMARY_CARD_REQUEST));
    return axios
      .post(
        API_ROOT +
          `customers/primarycard?id=` +
          id +
          "&customerId=" +
          customerId,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        return dispatch(Success(PRIMARY_CARD_SUCCESS));
      })
      .catch((error) => {
        return dispatch(Failure(error, PRIMARY_CARD_FAILURE));
      });
  };
}

export function addNewNumber(phone, token) {
  return (dispatch, getState) => {
    dispatch(Request(NUMBER_ADD_REQUEST));
    return axios
      .post(API_ROOT + `customers/addphone`, phone, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        return dispatch(Success(NUMBER_ADD_SUCCESS));
      })
      .catch((error) => {
        return dispatch(Failure(error, NUMBER_ADD_FAILURE));
      });
  };
}

export function getPhoneList(token) {
  return (dispatch, getState) => {
    getState().entities.accounts.phoneList = [];
    dispatch(Request(PHONE_LIST_REQUEST));
    return axios
      .get(API_ROOT + `customers/getphonelist`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        return dispatch(
          Success(PHONE_LIST_SUCCESS, {
            accounts: { phoneList: response.data.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, PHONE_LIST_FAILURE));
      });
  };
}

export function getCardList(token) {
  return (dispatch, getState) => {
    getState().entities.accounts.payCards = [];
    dispatch(Request(CARD_LIST_REQUEST));
    return axios
      .get(API_ROOT + `customers/getcardlist`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        return dispatch(
          Success(CARD_LIST_SUCCESS, {
            accounts: { payCards: response.data.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, CARD_LIST_FAILURE));
      });
  };
}

export function addNewPaymentCard(credential, token) {
  return (dispatch, getState) => {
    // getState().entities.accounts.payCards = {};
    dispatch(Request(CARD_ADD_REQUEST));
    return axios
      .post(API_ROOT + "customers/addcard", credential, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.state === 1){
          return dispatch(
            Success(CARD_ADD_SUCCESS, {
              // user : {auth: response.data},
            })
          );
        }else{
          return dispatch(Failure(response.data.msg, CARD_ADD_FAILURE));
        }
        
      })
      .catch((error) => {
        return dispatch(Failure(error, CARD_ADD_FAILURE));
      });
  };
}

export function editPaymentCard(credential, token) {
  return (dispatch, getState) => {
    // getState().entities.accounts.payCards = {};
    dispatch(Request(CARD_EDIT_REQUEST));
    return axios
      .post(API_ROOT + "customers/updatecard", credential, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.state === 1){
          return dispatch(
            Success(CARD_EDIT_SUCCESS, {
              // user : {auth: response.data},
            })
          );
        }else{
          return dispatch(Failure(response.data.msg, CARD_EDIT_FAILURE));
        }
        
      })
      .catch((error) => {
        return dispatch(Failure(error, CARD_EDIT_FAILURE));
      });
  };
}

export function removePaymentCard(credential, token) {
  return (dispatch, getState) => {
    // getState().entities.accounts.payCards = {};
    dispatch(Request(CARD_REMOVE_REQUEST));
    return axios
      .post(API_ROOT + "customers/removecard", credential, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.state === 1){
          return dispatch(
            Success(CARD_REMOVE_SUCCESS, {
              // user : {auth: response.data},
            })
          );
        }else{
          return dispatch(Failure(response.data.msg, CARD_REMOVE_FAILURE));
        }
        
      })
      .catch((error) => {
        return dispatch(Failure(error, CARD_REMOVE_FAILURE));
      });
  };
}

export function updateUserDetails(user, token) {
  return (dispatch, getState) => {
    getState().entities.user.details = {};
    dispatch(Request(DETAIL_EDIT_REQUEST));
    return axios
      .post(API_ROOT + "Customers/update", user, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserDetails(response.data.data);
        return dispatch(
          Success(DETAIL_EDIT_SUCCESS, {
            user: { details: response.data.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, DETAIL_EDIT_FAILURE));
      });
  };
}

export function otpVarification(phone) {
  return (dispatch, getState) => {
    getState().entities.user.auth = {};
    dispatch(Request(OTP_VARIFY_REQUEST));
    return axios
      .post(API_ROOT + `users/phoneverify`, phone)
      .then((response) => {
        if (response.data.state) {
          if (response.data.state === 1) {
            return dispatch(
              Success(OTP_VARIFY_SUCCESS, {
                user: { auth: response.data },
              })
            );
          } else {
            return dispatch(Failure(response.data.msg, OTP_VARIFY_FAILURE));
          }
        }

        return dispatch(
          Failure(
            "Server communication error. Please try again later",
            OTP_VARIFY_FAILURE
          )
        );
      })
      .catch((error) => {
        return dispatch(Failure(error.response.data.msg, OTP_VARIFY_FAILURE));
      });
  };
}

export function sendOtp(phone) {
  return (dispatch, getState) => {
    getState().entities.accounts.smsCred = {};
    dispatch(Request(RESEND_OTP_REQUEST));
    return axios
      .post(API_ROOT + `users/sendsmsverifycode`, phone)
      .then((response) => {
        if (response.data.state === 1) {
          return dispatch(
            Success(RESEND_OTP_SUCCESS, {
              accounts: { smsCred: response.data.data },
            })
          );
        } else {
          return dispatch(Failure(response.data, RESEND_OTP_FAILURE));
        }
      })
      .catch((error) => {
        return dispatch(Failure(error, RESEND_OTP_FAILURE));
      });
  };
}
