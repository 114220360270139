import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  overlineText: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    textTransform: "uppercase",
  },
}));

export default function PrimaryTextHeading(props) {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { text, styles,} = props;
  return (
    <div >
       <Typography
            variant={xs ? "body1" : sm ? "h6" : md ? "h5" : "h4"}
            className={classes.overlineText}
            style={{
              color: "#1CC5FF",
              ...styles,
            }}
          >
        {text}
      </Typography>
    </div>
  );
}
