import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import React from "react";
import EditProfile from "./editProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      // height: "400px",
    },
  },
  cardHeader: {},
}));

// export default class UserAccounts extends PureComponent {
export default function AccountProfile(props) {
  const classes = useStyles();

  const {
    user,
    isEditAccountDetail,
    setEditVisibility,
    onEditFieldChange,
    editFormError,
    editDetails,
    editUser,
  } = props;

  return (
    <Box>
      <Card className={classes.root}>
        <CardHeader title={<Typography variant="h6"> Account </Typography>} />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                color="secondary"
                value={user.firstName}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                onClick={setEditVisibility}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                color="secondary"
                value={user.lastName}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                onClick={setEditVisibility}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                color="secondary"
                value={user.email}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button color="primary" onClick={setEditVisibility}>
            Edit
          </Button>
        </CardActions>
      </Card>

      <EditProfile
        open={isEditAccountDetail}
        handleClose={setEditVisibility}
        handleStateChange={onEditFieldChange}
        error={editFormError}
        handleSubmit={editDetails}
        values={editUser}
        title="Edit Profile"
      />
    </Box>
  );
}
