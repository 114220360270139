import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ca0000",
      dark: "#8d0000",
      light: "#d43333",
    },
    secondary: {
      main: "#224E7F",
      dark: "#173658",
      light: "#4e7198",
    },
    background: {
      default: "#fff",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
