import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getToken } from "middleware/storage";
import TxnReview from "components/shared/txn_review";
import { setSubmissionDetails } from "components/public-dashboard/features/billpay/bpSlice";
import useCustomerBPTotal from "hooks/useCustomerBPTotal";
import Loading from "components/shared/loader/loader";
import { useLayoutEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    textAlign: "center",
    fontSize: "1rem",
  },
  section: {
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  changeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: 20,
    width: "auto",
  },
}));

function TxnConfirmation(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [loggedIn, SetLogin] = useState(false);
  const { txnDetails } = props;
  const { isLoading, bpTotal, convenienceFee, fetchCustomerPaymentAmount } =
    useCustomerBPTotal();

  useLayoutEffect(() => {
    fetchCustomerPaymentAmount(txnDetails);
  }, []);

  useEffect(() => {
    async function validateLogin() {
      const token = getToken();
      if (!token) {
        SetLogin(false);
      } else {
        SetLogin(true);
      }
    }

    validateLogin();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <TxnReview
      {...props}
      bpTotal={bpTotal}
      convenienceFee={convenienceFee}
      loggedIn={loggedIn}
      service="billpay"
    />
  );
}

const mapStateToProps = (state) => ({
  txnDetails: state.billpay.txnDetails,
  paymentDetails: state.billpay.paymentDetails,
  addressDetails: state.billpay.addressDetails,
  submissionDetails: state.billpay.submissionDetails,
  providers: state.billpay.providers,
  cCards: state.billpay.cCards,
  errors: state.billpay.errors,
  showCCForm: state.billpay.showCCForm,
  charities: state.billpay.charities,
  countries: state.billpay.countries,
});

const mapDispatch = { setSubmissionDetails };

export default connect(mapStateToProps, mapDispatch)(TxnConfirmation);
