import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import { renderTableCellContent } from "./tableHelper";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  cell: {
    padding: "8px",
  },
});

export default function Row(props) {
  const { row, columns, showCollapse } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const mobileHeadingColumns = columns
    .filter((column) => column.isMobileHeader)
    .sort((a, b) => a.sortOrder - b.sortOrder);

  const renderTableHeader = () => {
    return mobileHeadingColumns.map((column) => (
      <TableCell
        key={column.id}
        component="th"
        scope="row"
        align={column.align || "left"}
        className={classes.cell}
      >
        {renderTableCellContent(row, column)}
      </TableCell>
    ));
  };

  const renderTableDetails = () => {
    return columns
      .filter((column) => !column.isMobileHeader)
      .map((column) => (
        <TableRow key={column.id}>
          <TableCell
            component="th"
            scope="row"
            align={column.align || "left"}
            className={classes.cell}
          >
            <b>{column.label}</b>
          </TableCell>
          <TableCell align={column.align || "left"} className={classes.cell}>
            {column.link && row[column.id] && <a  href={renderTableCellContent(row, column)} target={"_blank"} >{column.render  ? column.render(row[column.id]) : renderTableCellContent(row, column)}</a> }
            {column.link && !row[column.id] && "N/A" }
            {!column.link && renderTableCellContent(row, column)}
          </TableCell>
        </TableRow>
      ));
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {showCollapse && (
          <TableCell className={classes.cell}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {renderTableHeader()}
      </TableRow>
      {showCollapse && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={Object.keys(row).length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  More Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableBody>{renderTableDetails()}</TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
