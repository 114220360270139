import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function PageBanner(props) {
  const { img } = props;
  const classes = useStyles();
  return <img src={img} alt="banner" className={classes.root} />;
}
