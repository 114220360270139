import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AmountPreset from "./amount_preset";
import AmountPresetSpecify from "./amount_preset _specify";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const responsive = {
  tablet: {
    breakpoint: { max: 600, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function AmountPresetCarousel(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <Carousel
      minimumTouchDrag={80}
      swipeable
      draggable={true}
      showDots={true}
      responsive={responsive}
      infinite
      customTransition="all 1s linear"
      keyBoardControl
      removeArrowOnDeviceType={["tablet", "mobile"]}
      focusOnSelect={false}
    >
      {children}
    </Carousel>
  );
}
