import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import scan2pay_img from "assets/images/scan2pay_content.png";
import ContentList from "components/public-dashboard/components/contentList";
import IconHeader from "components/public-dashboard/components/iconHeader";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function Scan2Header() {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <IconHeader
            title={"Scan... Pay... Go!"}
            iconColor="#000"
            titleColor="#C60B17"
            titleLg="h2"
            titleSm="h3"
            titleXs="h4"
            icon={false}

          />
        </Grid>
        {xs && (
          <Grid item xs={6}>
            <img src={scan2pay_img} alt="topup" className={classes.img} />
          </Grid>
        )}
        <Grid item sm={12} md={7}>
          <ContentList
            items={[
              <span>
                Your business is taking off but your payment options are not…{" "}
                <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                  YOOZ Scan2Pay
                </span>{" "}
                is the answer!{" "}
              </span>,
              <span>
                YOOZ Scan2Pay is the first, secure, contactless payment solution
                that lets your customers pay for all your goods and services by
                scanning your unique{" "}
                <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                  YOOZ QR Code{" "}
                </span>
                or clicking your secure{" "}
                <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                  YOOZ Payment Link
                </span>
                .
              </span>,
              <span>
                <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                  Accept debit and credit card payments online
                </span>
                , without the hassle of monthly fees, terminals, e -transfers,
                hidden fees and payment delays.
              </span>,
              <span>
                Your Customers will love it because:
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  <li>
                    <span style={{ marginRight: "5px" }}>✓</span> No APP
                    Downloads required
                  </li>
                  <li>
                    <span style={{ marginRight: "5px" }}>✓</span> No sign up
                    necessary
                  </li>

                </ul>
              </span>,
            ]}
          />
        </Grid>
        {md && (
          <Grid item xs={6} sm={5}>
            <img src={scan2pay_img} alt="topup" className={classes.img} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
