import {
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TopupIcon from "@material-ui/icons/MobileScreenShare";
import ReceiptIcon from "@material-ui/icons/Receipt";
import moment from "moment";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { amber, green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  iconTopup: {
    color: theme.palette.primary.light,
  },
  iconBillPay: {
    color: theme.palette.secondary.light,
  },
}));

export default function Txns(props) {
  const classes = useStyles();

  const { items = [], type } = props;
  return (
    <List>
      {items.map((item, id) => (
        <Fragment key={id}>
          <ListItem>
            <ListItemAvatar>
              {item.tranStatus === "Successful" ? (
                <Tooltip title={item.tranStatus} interactive>
                  <CheckCircleIcon
                    style={{ color: green[300] }}
                    aria-label="delete"
                  />
                </Tooltip>
              ) : item.tranStatus === "Failed" ? (
                <Tooltip title={item.tranStatus} interactive>
                  <CancelIcon style={{ color: red[300] }} />
                </Tooltip>
              ) : (
                <Tooltip title={item.tranStatus} interactive>
                  <TimelapseIcon style={{ color: amber[300] }} />
                </Tooltip>
              )}
            </ListItemAvatar>

            <ListItemText
              primary={
                <Typography color="secondary">
                  <b>
                    {type === "billpay" ? (
                      item.accountNumber
                    ) : (
                      <NumberFormat
                        value={item.accountNumber}
                        format="+#(###) ###-####"
                        displayType={"text"}
                      />
                    )}
                  </b>
                  <Hidden smUp>
                    <Typography color="primary">
                      <b>
                        {item.currencyCode} {item.currencySymbol}
                        {item.amount.toFixed(2)}
                      </b>
                    </Typography>
                    <Typography>{item.provider}</Typography>
                  </Hidden>
                </Typography>
              }
              secondary={moment(item.transactionDate).format("lll")}
            />
            <Hidden xsDown>
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    <Typography color="primary">
                      <b>
                        {item.currencyCode} {item.currencySymbol}
                        {item.amount.toFixed(2)}
                      </b>
                    </Typography>
                  }
                  secondary={
                    <Typography style={{ float: "right" }}>
                      {item.provider}
                    </Typography>
                  }
                />
              </ListItemSecondaryAction>
            </Hidden>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}
