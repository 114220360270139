import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import React from "react";
import SelectableNavList from "./selectableNavList";

export default function SwipeableNavDrawer(props) {
  const { open, toggleDrawer, value, setValue, routes } = props;

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <SelectableNavList
        value={value}
        setValue={setValue}
        toggleDrawer={toggleDrawer(false)}
        routes={routes}
      />
    </SwipeableDrawer>
  );
}
