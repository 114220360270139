import { Container, Link } from "@material-ui/core";
import React from "react";
import ContentList from "components/public-dashboard/components/contentList";
import ContentForm from "./contentForm";

export default function ContactContent() {
  return (
    <Container>
      <ContentList
        title="CORPORATE OFFICE:"
        items={[
          "#8 Gallus Street, Woodbrook, Trinidad and Tobago",
          "yooz@yoozit.today",
          "+1 (868) 235-6182",
        ]}
      />
      <ContentList
        title="CUSTOMER CARE OPERATING HOURS:"
        items={["Monday to Friday", "8am to 5pm"]}
      />
      <ContentList
        title="SOCIAL MEDIA:"
        items={[
          <Link
            href="https://www.facebook.com/yoozpay/"
            target="_blank"
            color="secondary"
          >
            Facebook
          </Link>,
          <Link
            href="https://www.instagram.com/yooz.tt/?hl=en"
            target="_blank"
            color="secondary"
          >
            Instagram
          </Link>,
          <Link
            href="https://www.linkedin.com/company/yoozb2b/"
            target="_blank"
            color="secondary"
          >
            Linkedin
          </Link>,
        ]}
      />
      <ContentList title="MESSAGE US:" items={[<ContentForm />]} />
    </Container>
  );
}
