import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import billPay_img from "assets/images/home_content_3.png";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function TopupHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container>
      <Grid container direction="row" justify="center" alignItems="center">
        {xs && (
          <Grid item xs={6}>
            <IconHeader
              title="Pay Bills"
              iconColor="#C60B17"
              titleColor="#008bee"
              titleLg="h2"
              titleXs="h4"
            />
          </Grid>
        )}
        {xs && (
          <Grid item xs={6}>
            <img src={billPay_img} alt="billpay" className={classes.img} />
          </Grid>
        )}
        <Grid item container xs={12} sm={7}>
          {sm && (
            <Grid item>
              <IconHeader
                title="Pay Bills"
                iconColor="#C60B17"
                titleColor="#008bee"
                titleLg="h2"
              />
            </Grid>
          )}

          <Grid item container>
            <TextList
              items={[
                <span>
                  Fed up of the long lines, the paperwork and the hassle?
                  There’s an easier way to pay!
                </span>,
                <span>Choose the option that's right for you :-)</span>,
                // <Button
                //   component={Link}
                //   to="/public/billpay/now"
                //   color="primary"
                //   size="large"
                //   variant="contained"
                //   onClick={() => {
                //     window.scrollTo(0, 0);
                //     dispatch(setTabPosition(1));
                //   }}
                //   style={{ borderRadius: 20, marginRight: 10 }}
                // >
                //   Pay Online Now
                // </Button>,
                // <b>No Registration Required!</b>,
                // "Scroll down to learn more!",
              ]}
              indentation="3%"
            />
          </Grid>
        </Grid>
        {sm && (
          <Grid item xs={6} sm={5}>
            <img src={billPay_img} alt="billpay" className={classes.img} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
