import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import SmsVerificationDialog from "components/public-dashboard/features/sms_verification";
import MobileNumber from "./mobileNumber";
import AccountPaymentMethods from "./paymentMethods";
import AccountProfile from "./profile";
import UssdSettings from "./ussdSettings";

// export default class UserAccounts extends PureComponent {
function UserAccounts(props) {

  const {
    user,
    isVisible,
    setMobileOtp,
    setVarifyVisibility,
    onOtpVarify,
    otpError,
    resendOtp,
    smsCred,
  } = props;

  return (
    <Box style={{height: "calc(100vh - 64px)"}}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          {user && (
            <Grid item xs={12} md={6}>
              <AccountProfile {...props} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <MobileNumber {...props} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AccountPaymentMethods {...props} />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <UssdSettings {...props} />
          </Grid> */}
        </Grid>
        <SmsVerificationDialog
          open={isVisible}
          mobile={smsCred.phone}
          handleClose={setVarifyVisibility}
          setMobileOtp={setMobileOtp}
          onOtpVarify={onOtpVarify}
          resendOtp={resendOtp}
          error={otpError}
          title="Add Number"
          title2="Verification"
        />
        
      </Container>
    </Box>
  );
}

export default UserAccounts;
