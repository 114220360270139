import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ChangeUssdPin from "./changePin";
import EditIcon from "@material-ui/icons/Edit";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      // height: "400px",
    },
  },
  cardHeader: {},
  wrapText: {
    wordWrap: "break-word",
  },
}));

export default function UssdSettings(props) {
  const classes = useStyles();
  const [editPin, editPinToggle] = useState(false);
  const handleChangePin = () => {
    editPinToggle(!editPin);
  };
  return (
    <Fragment>
      <Card className={classes.root}>
        <CardHeader
          title={<Typography variant="h6">Yooz Mobile Settings</Typography>}
        />
        <Divider />
        <CardContent className={classes.content}>
          <List>
            <ListItem>
              <ListItemText
                primary="Passcode"
                secondary="Change your 4 digit Passcode"
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="delete" onClick={handleChangePin}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <ChangeUssdPin open={editPin} handleClose={handleChangePin} />
    </Fragment>
  );
}
