import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "middleware/storage";
import { setNavIndex } from "components/secured-app/uiSlice";
import SomethingWentWrong from "components/shared/ui/somethingWentWrong";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import {
  addValidatedStep,
  fetchCountries,
  fetchVat,
  setTxnDetails,
  setValidatedSteps,
} from "./topupSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

export default function CountrySelector(props) {
  const { handleNext } = props;
  const classes = useStyles();
  const token = getToken();
  const dispatch = useDispatch();
  const { topupCountries: countries, txnDetails, activeStep, loading } = useSelector(
    (state) => state.topup
  );
  useLayoutEffect(() => {
    const loadCountries = () => {
      try {
        dispatch(fetchCountries());
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
      }
    };

    loadCountries();
  }, [dispatch]);

  const [value, setValue] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (txnDetails.country.countryId > 0 && countries.length > 0 && value == 0) {
      selectCountry(txnDetails.country.countryId);
    }
  }, [countries]);



  const handleChange = (e) => {
    const countryId = e.target.value;
    selectCountry(countryId);
  };

  const selectCountry = (countryId) => {
    setValue(countryId);
    const country = countries.find((c) => c.countryId === countryId);
    dispatch(fetchVat(country.countryId));
    dispatch(setValidatedSteps([]));
    dispatch(
      setTxnDetails({
        ...txnDetails,
        country: country,
        mobileNumber: country.phoneCode,
        carrier: "",
        amount: "",
        presetSelected: { id: "", amount: 0 },
        coverVat: false,
      })
    );
    dispatch(addValidatedStep(activeStep));
    handleNext();
  };

  return (
    <div className={classes.root}>
      {countries.length > 0 && (
        <TextField
          id="country"
          error={error.length > 0}
          select
          label="Country of Top Up"
          value={value}
          onChange={handleChange}
          helperText={error.length > 0 ? error : ""}
          variant="outlined"
          fullWidth
          color="secondary"
        >
          {countries.map((option) => (
            <MenuItem key={option.countryId} value={option.countryId}>
              {option.description}
            </MenuItem>
          ))}
        </TextField>
      )}
      {loading === "idle" && countries.length === 0 && (
        <SomethingWentWrong
          redirect={token ? "/user/topup" : "/quicktopup"}
          redirect2={token ? "/user/contactus" : "/contact"}
          redirectText="Try Again"
          redirectText2="Contact Us"
          onClick1={() => window.location.reload()}
          onClick2={
            token
              ? () => dispatch(setNavIndex(6))
              : () => dispatch(setTabPosition(6))
          }
        />
      )}
    </div>
  );
}
