import { CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Radio from "@material-ui/core/Radio";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { default as React } from "react";
const useStyles = makeStyles({
  root: {
    position: "relative",
    minWidth: 150,
  },
  media: {
    height: 100,
  },
});
export default function CarrierCard(props) {
  const { carrier, selected, handleChange, handleValidateNext } = props;
  const classes = useStyles();
  const theme = useTheme();
  const onHandleChange = () => {
    handleChange();
    handleValidateNext();
  };
  return (
    <Card
      className={classes.root}
      style={{
        border:
          selected === carrier.phoneProviderId
            ? `2px solid ${theme.palette.secondary.main}`
            : "",
      }}
    >
      <CardActionArea onClick={onHandleChange} style={{ textAlign: "center" }}>
        <CardMedia
          className={classes.media}
          image={carrier.logo}
          title={carrier.name}
        />
        <Radio color="primary" checked={selected === carrier.phoneProviderId} />
      </CardActionArea>
    </Card>
  );
}