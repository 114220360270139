import { ListItemAvatar, ListItemText } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getCardImageByType }  from "components/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
  cardLogo: {
    marginRight: theme.spacing(3),
    float: "left",
  },
}));

export default function SelectCC(props) {
  const classes = useStyles();
  const { value, cards, handleChange, error } = props;
  return (
    <div className={classes.root}>
      <TextField
        id="paymentCreditCard"
        error={error.length > 0}
        select
        label="Saved Cards"
        value={value}
        onChange={handleChange("customerCardId")}
        helperText={error.length > 0 ? error : "Click to select a saved card"}
        variant="outlined"
        fullWidth
        color="secondary"
      >
        {cards.map((option) => (
          <MenuItem key={option.customerCardId} value={option.customerCardId}>
            <ListItemAvatar className={classes.cardLogo}>
              {getCardImageByType(option.cardType)}
            </ListItemAvatar>
            <ListItemText primary={option.nickName} />
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
