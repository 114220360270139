import { makeStyles } from "@material-ui/core/styles";
import {InputAdornment,TextField} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { getToken, getUserDetail } from "middleware/storage";
import {
  addValidatedStep,


  removeValidatedStep,
  setTxnDetails
} from "./topupSlice";
import NumberFormat from "react-number-format";
import { fetchCharities } from "./topupSlice";;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        fixedDecimalScale
        decimalScale={2}
        thousandSeparator
        isNumericString
      />
    );
  }

export default function DonationEntry(props) {
  const { handleNext } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { charities,txnDetails, activeStep ,loading} = useSelector((state) => state.topup);
  


  const [value, setValue] = useState(txnDetails.donationAmount);
  const [charityValue, setCharityValue] = useState(txnDetails.charity.providerId);
  const [error, setError] = useState("");
  useEffect(() => {
    const loadCharities = async () => {
      try {
        await dispatch(fetchCharities());
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
      }
    };

    loadCharities();
    dispatch(addValidatedStep(activeStep));
    //setValue(0);
    let donationAmountTemp=txnDetails.donationAmount || 0;
    setError("");
          
          dispatch(
              setTxnDetails({
              ...txnDetails,
              donationAmount: donationAmountTemp,
              })
          );
    
  }, [dispatch]);
  const handleChange = (e) => {
    const donationAmount = e.target.value;
    if(parseFloat(donationAmount)>0)
    {
        setValue(parseFloat(donationAmount));        
        setError("");
        
        dispatch(
            setTxnDetails({
            ...txnDetails,
            donationAmount: parseFloat(donationAmount),
            })
        );
    }
     
  }; 
  
  const handleChangeCharity = (e) => {
    const charityId = e.target.value;       

    //setCharityValue(charityId);
    if (charityId===0){
      setValue(0); 
      dispatch(
        setTxnDetails({
          ...txnDetails,
          charity: "" ,
          donationAmount: 0    
        })
      );
    }
    else {
      const charity = charities.find((c) => c.providerId === charityId);  
      //setCharityValue(charity.providerId)     
      dispatch(
        setTxnDetails({
          ...txnDetails,
          charity: charity    
        })
      );
    }
    //dispatch(addValidatedStep(activeStep));
    //handleNext();
     
  };  


 
  return (
    <div className={classes.root}>
      <TextField
        id="txnCharityNo"
        error={error.length > 0}
        select
        label="Charity"
        defaultValue=''
        value={charityValue}
        onChange={handleChangeCharity}
        helperText={
          error.length > 0 ? error : "This is optional. Please leave empty if you do not wish to donate"
        }
        variant="outlined"
        fullWidth
        color="secondary"
      >
        {<MenuItem key={0} value={0}>
          {"No Donation"}
        </MenuItem>}
        {
           
        charities.map((option) => (            
          <MenuItem key={option.providerId} value={option.providerId}>
            {option.name}
          </MenuItem>
        ))
        }
      </TextField>
      <TextField
 
        id="topupDonationAmount"
        label="Donation Amount (TTD)"
        helperText={error.length > 0 ? error : ""}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        className={classes.formControl}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">TTD</InputAdornment>
            ),
            inputComponent: NumberFormatCustom,
          }}
        error={error.length > 0}
        color="secondary"
        disabled={txnDetails.charity===""}
      />
    </div>
  );
}
