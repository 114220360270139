import merge from "lodash/merge";
import { Schemas } from "store/schema";

const initialState = {
  user: Schemas.USER,
  accounts: Schemas.ACCOUNTS,
  activities: Schemas.ACTIVITY,
  home: Schemas.HOME,
  bills: Schemas.BILLPAY,
};

const entities = (state = initialState, action) => {
  if (action.entities) {
    return merge({}, state, action.entities);
  }
  return state;
};

export default entities;
