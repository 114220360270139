import {
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import YoozSvgIcon from "components/public-dashboard/components/yooz_svg_icon";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    maxWidth: 500,
    width: 250,
    backgroundColor: theme.palette.background.paper,
  },

  selected: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    backgroundColor: `#C0E8F8 !important`,
  },

  listHeader: {
    backgroundColor: theme.palette.secondary.main,
    padding: 0,
    color: "#FFF",
  },

  yoozLogo: {
    height: "100%",
  },

  submenu1: {
    paddingLeft: theme.spacing(4),
  },
  submenu2: {
    paddingLeft: theme.spacing(8),
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  routeHeader: {
    "&.Mui-disabled": {
      opacity: 1,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        fontWeight: 600,
      },
    },
  },
  heading: {
    backgroundColor: theme.palette.secondary.dark,
    color: "#fff",
  },
}));

export default function SelectableNavList(props) {
  const classes = useStyles();
  const { value, setValue, toggleDrawer, routes } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleListItemClick = (event, index) => {
    setValue(index);
    window.scrollTo(0, 0);
    toggleDrawer();
  };

  const renderMenuItem = (route, index) => {
    return (
      <ListItem
        button
        selected={value === route.path}
        onClick={(event) => handleListItemClick(event, index)}
        classes={{
          selected: classes.selected,
          root: classes.routeHeader,
        }}
        component={Link}
        to={route.path}
        key={"route-" + index}
        disabled={!route.path}
      >
        <ListItemText primary={route.label} />
      </ListItem>
    );
  };

  const renderSubMenu = (submenu, parentIndex) => {
    if (!submenu) {
      return;
    }
    return (
      <List
        component="nav"
        disablePadding
        key={`submenu-${parentIndex}`}
        style={{ marginLeft: theme.spacing(2) }}
      >
        {submenu.map((subItem, subIndex) => (
          <React.Fragment key={`submenu-item-${parentIndex}-${subIndex}`}>
            <ListItem
              button
              selected={value === subItem.path}
              component={Link}
              to={subItem.path}
              onClick={(event) => {
                handleListItemClick(event, parentIndex + subIndex + 1);
              }}
              key={`submenu-item-${parentIndex}-${subIndex}`}
              classes={{
                root:
                  subItem.menuLevel === 1
                    ? classes.submenu1
                    : subItem.menuLevel === 2
                    ? classes.submenu2
                    : undefined,
              }}
            >
              <ListItemText primary={subItem.label} />
            </ListItem>
            {subItem.submenu &&
              renderSubMenu(
                subItem.submenu,
                `submenu-${parentIndex}-${subIndex}`
              )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <div className={classes.root}>
      <List
        component="nav"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.listHeader}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="primary"
                component={Link}
                to="/"
                onClick={() => handleListItemClick(null, 0)}
              >
                <YoozSvgIcon
                  height="100%"
                  width={xs ? 70 : sm ? 80 : 110}
                  color={"#FFF"}
                />
              </IconButton>
            </Toolbar>
          </ListSubheader>
        }
      >
        {routes.map((route, index) => (
          <Fragment key={`route-${index}`}>
            <ListItem className={classes.heading}>
              <b>{route.title}</b>
            </ListItem>
            {route.routes && (
              <React.Fragment>
                {route.routes.map((x, index) => {
                  return (
                    <Fragment key={`subroute-${index}`}>
                      {renderMenuItem(x, index)}
                      {renderSubMenu(x.subRoutes, `submenu-${index}`)}
                    </Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );
}
