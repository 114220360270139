import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import signUp_img from "assets/images/home_content_5.png";
import Timer from "components/timer";
import ItemList from "components/public-dashboard/components/ItemList";
import VerificationHeader from "./header";
import MaskedVerificationCode from "./masked_code";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SmsVerificationDialog(props) {
  const { title, title2 } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [code, setCode] = useState("");
  const maxTime = 120;
  const [seconds, setSeconds] = useState(maxTime);

  const {
    open,
    handleClose,
    mobile,
    setMobileOtp,
    onOtpVarify,
    resendOtp,
    error,
  } = props;

  function handleChange(e) {
    setCode(e.target.value);
    setMobileOtp(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    onOtpVarify();
  }

  function onClose() {
    setCode("");
    handleClose();
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      maxWidth={"md"}
    >
      <DialogContent>
        <form
          onSubmit={onSubmit}
          style={{ padding: md ? theme.spacing(3) : 0 }}
        >
          <VerificationHeader
            title={title}
            color="#fbb004"
            title2={title2}
            color2="#fd7900"
            titleLg="h3"
            titleSm="h4"
            titleXs="h5"
            list={
              <ItemList
                items={[
                  <Typography variant={md ? "h6" : "body1"}>
                    Enter the &nbsp;<b>6 digit code</b>&nbsp; sent to your
                    mobile number: <br />
                    <NumberFormat
                      value={mobile}
                      format="#(###) ###-####"
                      displayType={"text"}
                    />
                  </Typography>,
                  <MaskedVerificationCode
                    error={error}
                    value={code}
                    handleChange={handleChange}
                  />,
                  <Timer
                    resendOtp={resendOtp}
                    seconds={seconds}
                    setSeconds={setSeconds}
                    maxTime={maxTime}
                  />,
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    // style={{ borderRadius: 20 }}
                    id="submitSms"
                    disabled={seconds === 0}
                  >
                    Submit
                  </Button>,
                ]}
              />
            }
            img={signUp_img}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
