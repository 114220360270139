import { Hidden, Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ExpandMore } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import YoozSvgIcon from "components/public-dashboard/components/yooz_svg_icon";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import SwipeableNavDrawer from "./swipableNavDrawer";
import RoutesDropdown from "./routesDropdown";
import {
  actionRoutes,
  quickPayRoutes,
  waysToPayRoutes,
  yoozBusinessRoutes,
} from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  menu: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(2),
    },
  },
  actionButton: {
    borderRadius: 20,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "nowrap",
      fontSize: 10,
    },
  },
  signupIcon: {
    borderRadius: 20,
    marginRight: theme.spacing(1),
    background: "#FFF",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "nowrap",
      fontSize: 10,
    },
  },
  loginButton: {
    borderRadius: 20,
    marginRight: theme.spacing(2),
  },
  yoozLogo: {
    width: theme.spacing(8),
    height: "100%",
    // backgroundColor:theme.palette.primary.main
  },
  flex: {
    flexGrow: 1,
  },
  active: {
    color: theme.palette.primary.main,
  },
  tabs: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  indicator: {
    backgroundColor: "#FFF !important",
  },
  noIndicator: {
    backgroundColor: "transparent",
  },
  submenu1: {
    paddingLeft: theme.spacing(2),
  },
  submenu2: {
    paddingLeft: theme.spacing(5),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [open, setOpen] = React.useState(false);
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { tabPosition } = useSelector((state) => state.ui);
  const match = "";
  const isAppBarScrolled = scrollPosition > 0;

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const handleChange = (event, newValue) => {
    dispatch(setTabPosition(newValue));
    window.scrollTo(0, 0);
  };

  const handleChangeMobile = (newValue) => {
    dispatch(setTabPosition(newValue));
    window.scrollTo(0, 0);
  };
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "auth-menu";
  const RenderMobileAuthMenu = () => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        to={actionRoutes.login.path}
        component={Link}
        onClick={() => {
          handleMenuClose();
          window.scrollTo(0, 0);
          handleChange(null, 9);
        }}
      >
        {actionRoutes.login.label}
      </MenuItem>
      <MenuItem
        to={actionRoutes.signup.path}
        component={Link}
        onClick={() => {
          handleMenuClose();
          window.scrollTo(0, 0);
          handleChange(null, 9);
        }}
      >
        {actionRoutes.signup.label}
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    function setTabByRoute() {
      dispatch(setTabPosition(location.pathname));
    }

    setTabByRoute();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  return (
    <AppBar
      position={xs ? "sticky" : "fixed"}
      className={classes.root}
      style={{
        boxShadow:
          scrollPosition > 0
            ? "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            : "none",
      }}
    >
      <Toolbar className={classes.toolbar}>
        {sm && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon color={"inherit"} />
          </IconButton>
        )}
        <IconButton
          edge="start"
          color="primary"
          component={Link}
          to="/"
          onClick={() => handleChange(null, 0)}
        >
          <YoozSvgIcon
            height="100%"
            width={xs ? 70 : sm ? 80 : 110}
            color={"#FFF"}
          />
        </IconButton>
        <div className={classes.flex} />
        <Hidden smDown>
          <RoutesDropdown
            dropdownRoutes={waysToPayRoutes}
            handleChange={handleChange}
            styles={{
              marginRight: theme.spacing(3),
              backgroundColor: "#57b56b",
            }}
          />
          <RoutesDropdown
            dropdownRoutes={yoozBusinessRoutes}
            handleChange={handleChange}
            styles={{
              marginRight: theme.spacing(3),
              backgroundColor: "#cc3e7d",
            }}
            activeColor="#cc3e7d"
          />
        </Hidden>
        <RoutesDropdown
          dropdownRoutes={quickPayRoutes}
          handleChange={handleChange}
        />
        <div className={classes.flex} />
        <Hidden smDown>
          <Button
            color="secondary"
            size={sm ? "small" : "medium"}
            variant={"outlined"}
            to={actionRoutes.login.path}
            component={Link}
            className={`${classes.actionButton}`}
            onClick={() => {
              window.scrollTo(0, 0);
              handleChange(null, 9);
            }}
            style={{
              color: "#FFF",
              borderColor: "#FFF",
            }}
          >
            {actionRoutes.login.label}
          </Button>
          <Button
            color="inherit"
            size={sm ? "small" : "medium"}
            variant={"contained"}
            to={actionRoutes.signup.path}
            component={Link}
            className={`${classes.signupIcon}`}
            onClick={() => {
              window.scrollTo(0, 0);
              handleChange(null, 9);
            }}
            style={{
              borderColor: theme.palette.primary.main,
            }}
          >
            {actionRoutes.signup.label}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            endIcon={<ExpandMore />}
          >
            Login
          </Button>
          {RenderMobileAuthMenu()}
        </Hidden>
      </Toolbar>

      <SwipeableNavDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        value={tabPosition}
        setValue={handleChangeMobile}
        routes={[waysToPayRoutes, yoozBusinessRoutes]}
      />
    </AppBar>
  );
}
