import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import NumberFormat from "react-number-format";

export default function MobileNumberItem(props) {
  const { phone, handleClick, handleChange } = props;
  const handleCustomClick = () => {
    if (handleChange) {
      handleChange("customerphone", phone);
      handleClick();
    }
  };
  return (
    <ListItem button>
      <ListItemText
        primary={
          <NumberFormat
            value={phone.phoneNumber}
            format="#(###) ###-####"
            displayType={"text"}
          />
        }
        secondary={
          <Typography color="primary" variant="subtitle2">
            {phone.isPrimary ? "Primary" : ""}
          </Typography>
        }
      />
      {/*{handleChange && (
        <ListItemSecondaryAction>
          <Checkbox edge="end" checked={phone.isPrimary} color="primary" />
        </ListItemSecondaryAction>
      )}*/}
    </ListItem>
  );
}
