import { Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import readXlsxFile from "read-excel-file";
import {
  getComparator,
  stableSort,
} from "components/shared/table/tableContent";
import Table from "components/shared/table/table";
import RoomIcon from "@material-ui/icons/Room";
import { API_ROOT } from "constants/actionTypes";
import PageLoading from "components/shared/loader/pageLoader";

const useStyles = makeStyles((theme) => ({}));

const AgentLocationTable = () => {
  const classes = useStyles();
  const [data, setData] = useState({ headers: [], rows: [] });

  const [columns, setColumns] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [useFilteredCount, setUseFilteredCount] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  let query = {
    pageNumber: 1,
    pageSize: 10,
    searchTerm: "",
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // File path
        const filePath = `${API_ROOT}document/yo-agent-listing`;

        // Fetch Excel file as array buffer
        const response = await axios.get(filePath, {
          responseType: "arraybuffer",
        });

        // Read Excel file
        const isMobileHeader = (col) => {
          const array = ["Name", "Town", "City"];
          return array.includes(col);
        };
        const [headers, ...rows] = await readXlsxFile(response.data);
        const formattedColumns = headers
          .filter((col) => {
            if (col === "Account #" || col === "Email") {
              return false;
            }
            return true;
          })
          .map((col, index) => {
            var isLink = { link: false };
            if (col === "Google Location") {
              isLink.link = true;
              isLink.render = (value) => {
                if (value) return <RoomIcon style={{ fill: "#57B56B" }} />;

                return <span>N/A</span>;
              };
            }
            return {
              id: col.replace(/\s/g, ""),
              label: col,
              sortOrder: 1,
              isMobileHeader: isMobileHeader(col),
              align: "left",
              ...isLink,
            };
          });

        const formattedRows = rows.map((row, index) => {
          return {
            Name: row[1],
            Street: row[2],
            Town: row[3],
            City: row[4],
            "Contact#": row[5],
            OperatingHours: row[6],
            GoogleLocation: row[7],
          };
        });
        setColumns(formattedColumns);
        handleSort("asc", "Name", formattedRows);

        setData({ headers, rows });
        setIsLoading(false);
      } catch (error) {}
    };

    await fetchData();
  }, []);

  function handleQueryUpdate(q) {
    query = { ...query, ...q };
  }

  function handleSort(order, orderBy, aRows) {
    const rows = aRows || allRows;
    const sortedRows = stableSort(rows, getComparator(order, orderBy));
    setAllRows(sortedRows);
    paginate(sortedRows);
  }

  function paginate(rows) {
    setUseFilteredCount(false);
    let data = rows || allRows;
    // Calculate total pages (consider edge cases)
    const totalPages = Math.ceil(data.length / query.pageSize);
    if (!totalPages) {
      return; // Handle empty data or page size exceeding data length
    }

    // filter records based on search term
    if (query.searchTerm) {
      setUseFilteredCount(true);
      let processedTerm = query.searchTerm.replace(/\[/g, "\\["); // Escape "[" globally
      processedTerm = processedTerm.replace(/\(/g, "\\("); // Escape "[" globally

      data = data.filter((object) => {
        const regex = new RegExp(processedTerm, "i");
        return regex.test(object.Name) || regex.test(object.City);
      });
    }

    // Ensure requested page is within bounds
    query.pageNumber = Math.min(Math.max(query.pageNumber, 1), totalPages);

    // Calculate startIndex and endIndex for efficient slicing
    const startIndex = (query.pageNumber - 1) * query.pageSize;
    const endIndex = Math.min(startIndex + query.pageSize, data.length);

    // Slice the data for the current page
    const paginatedData = data.slice(startIndex, endIndex);
    setFilteredRows(paginatedData);
  }

  function handleFetch() {
    paginate();
  }

  if (isLoading) {
    return <PageLoading loading={isLoading} />;
  } else {
    return (
      <Container>
        <Table
          columns={columns}
          pageIndex={query.pageNumber}
          handleFetch={handleFetch}
          handleQueryUpdate={handleQueryUpdate}
          pagedItems={filteredRows}
          handleSort={handleSort}
          sortingEnabled={true}
          searchEnabled={true}
          totalItems={useFilteredCount ? filteredRows.length : allRows.length}
          headStyle={{ background: "#57B56B", color: "#fff" }}
        />
      </Container>
    );
  }
};

export default AgentLocationTable;
