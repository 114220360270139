import CreditCardIcon from "@material-ui/icons/CreditCard";
import mastercard_logo from "assets/images/mastercard_logo.png";
import visa_logo from "assets/images/visa-logo.png";
import React from "react";

export function getCardType(cardNumber) {
  const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  const master = new RegExp(
    "^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$"
  );

  if (cardNumber.match(visa)) {
    return "VISA";
  } else if (cardNumber.match(master)) {
    return "MASTERCARD";
  }

  return "";
}

export function getCardTypeImage(cardNumber) {
  let cardType = getCardType(cardNumber);
  cardType = cardType.toUpperCase();
  switch (cardType) {
    case "VISA":
      return (
        <img
          src={visa_logo}
          alt="VISA Logo"
          style={{ height: 25, width: "auto" }}
        />
      );
    case "MASTERCARD":
      return (
        <img
          src={mastercard_logo}
          alt="MASTERCARD Logo"
          style={{ height: 25, width: "auto" }}
        />
      );
    default:
      return <CreditCardIcon />;
  }
}

export function getCardImageByType(cardType) {
  cardType = cardType.toUpperCase();
  switch (cardType) {
    case "VISA":
      return <img src={visa_logo} alt="VISA Logo" />;
    case "MASTERCARD":
      return (
        <img
          src={mastercard_logo}
          alt="MASTERCARD Logo"
          style={{ height: 25, width: "auto" }}
        />
      );
    default:
      return <CreditCardIcon />;
  }
}

export function isValidateExpDate(expirationDate) {
  const regexPattern = /^(0[1-9]|1[0-2])([2-9][0-9])$/;
  
  if (!regexPattern.test(expirationDate)) {
    return false; // Invalid format
  }

  const currentYear = new Date().getFullYear() % 100;
  const currentMonth = new Date().getMonth() + 1;
  
  const inputMonth = parseInt(expirationDate.slice(0, 2), 10);
  const inputYear = parseInt(expirationDate.slice(2), 10);
  
  if (inputYear < currentYear) {
    return false; // Year is in the past
  } else if (inputYear === currentYear && inputMonth < currentMonth) {
    return false; // Year is the current year, but month is in the past
  }
  
  return true; // Valid and non-expired expiration date
}

export function isCardDuplicate(cCardList,cardNumber){
  const duplicateCard = cCardList.find((card) => {
    const nickname = card.nickName.substr(-4);
    const cardNum = cardNumber.substr(-4);
    return cardNum === nickname;
  });
  
  if (duplicateCard) {
    return true
  } else {
    return false
  }
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const jwtExpired = (token) => {
  const decondedToken = parseJwt(token);
  var exp = decondedToken.exp;
  const now = Math.round(+new Date()/1000);
  if (now > exp) {
    return true;
  } else {
    return false;
  }
};


export const checkEmailRegex = (email) => 
{
  const regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
  return regex.test(email);
};
