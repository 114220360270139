import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "constants/actionTypes";
import { getAsync } from "components/public-dashboard/services/api_util";
import { setAnonTokenApi } from "components/public-dashboard/services/utility";
import { getUserDetail } from "middleware/storage";

export const fetchCountries = createAsyncThunk(
  "topup/fetchCountries",
  async (thunkAPI, { getState, requestId }) => {
    await setAnonTokenApi();

    const { currentRequestId, loading } = getState().topup;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}users/countries`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchCharities = createAsyncThunk(
  "topup/fetchCharities",
  async (thunkAPI, { getState, requestId }) => {
    await setAnonTokenApi();

    const { currentRequestId, loading } = getState().topup;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}Transaction/GetCharities`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchVat = createAsyncThunk(
  "topup/fetchVat",
  async (countryId, { getState, requestId }) => {
    await setAnonTokenApi();

    const { currentRequestId, loading, txnDetails } = getState().topup;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(
      `${API_ROOT}topup/getVat?countryId=` + countryId
    );
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchAmountPresets = createAsyncThunk(
  "topup/fetchAmountPresets",
  async (thunkAPI, { getState, requestId }) => {
    const { currentRequestId, loading, txnDetails } = getState().topup;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(
      `${API_ROOT}topup/GetPresetTopups?phoneProviderId=` +
        txnDetails.carrier.phoneProviderId
    );
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchCC = createAsyncThunk(
  "topup/fetchCC",
  async (thunkAPI, { getState, requestId }) => {
    const { currentRequestId, loading, txnDetails } = getState().topup;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}customers/getcardlist`);

    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchAddressCountries = createAsyncThunk(
  "topup/fetchAddressCountries",
  async (thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}public/countries`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchAddressStates = createAsyncThunk(
  "topup/fetchAddressStates",
  async (countryCode, thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(
      `${API_ROOT}public/country/${countryCode}/states`
    );
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchPhoneProviders = createAsyncThunk(
  "topup/GetPhoneProviders",
  async (thunkAPI, { getState, requestId }) => {
    const response = await getAsync(`${API_ROOT}topup/GetPhoneProviders`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

const testState = {
  txnDetails: {
    amount: "50",
    mobileNumber: "18683082120",
    email: "",
    transactionType: 2,
    carrier: "1",
    presetSelected: {
      amount: 50,
      id: 1,
      phoneProviderId: 1,
      status: 1,
    },
    country: {
      code: "TT",
      countryId: 2,
      description: "Trinidad and Tobago",
      phoneCode: "+1868",
    },
    carrier: {
      name: "Digicel",
      path: null,
      phoneProviderId: 1,
    },
    coverVat: false,
  },
  paymentDetails: {
    name: "Haifeng Mei",
    cardNumber: "4111111111111111",
    expirationDate: "1234",
    cvv: "123",
    customerCardId: 1,
  },
  errors: {
    amount: "",
    country: "",
    mobileNumber: "",
    name: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    email: "",
    customerCardId: "",
    agreement: "",
  },
  submissionDetails: {
    tac: true,
    ppolicy: true,
  },
  addressDetails: {
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
    emailAddress: "",
    phoneNumber: "",
  },
  error: null,
  showCCForm: false,
  countries: [],
  charities: [],
  amountPresets: [],
  loading: "idle",
  nextStepValidation: false,
  disabled: false,
  activeStep: 0,
  currentRequestId: undefined,
  phoneProviders: [],
};

const cleanState = {
  txnDetails: {
    country: {
      countryId: 1,
    },
    amount: "",
    donationAmount: "",
    charity: "",
    mobileNumber: "",
    email: "",
    transactionType: 2,
    carrier: "",
    presetSelected: { id: "", amount: 0 },
    coverVat: false,
  },
  paymentDetails: {
    name: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    customerCardId: 0,
    shouldSaveCard: false,
  },
  addressDetails: {
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "780",
    emailAddress: "",
    phoneNumber: "",
  },
  errors: {
    name: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    email: "",
    customerCardId: "",
    agreement: "",
    addressDetails: {
      firstName: "",
      lastName: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
      emailAddress: "",
      phoneNumber: "",
    },
  },
  submissionDetails: {
    tac: false,
    ppolicy: false,
    saveCard: false,
  },
  error: null,
  showCCForm: false,
  topupCountries: [],
  countries: [],
  charities: [],
  amountPresets: [],
  loading: "idle",
  disabled: false,
  validatedSteps: [],
  email: "",
  activeStep: 0,
  currentRequestId: undefined,
  cCards: [],
  vat: 0,
  phoneProviders: [],
};

const cleanAddress = {
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  countryCode: "780",
  emailAddress: "",
  phoneNumber: "1868",
};

const topupSlice = createSlice({
  name: "topup",
  initialState: cleanState,
  reducers: {
    setTxnDetails(state, action) {
      state.txnDetails = action.payload;
    },
    setCoverTax(state, action) {
      state.txnDetails.coverVat = action.payload;
    },
    setPaymentDetails(state, action) {
      state.paymentDetails = action.payload;
    },
    setAddressDetails(state, action) {
      state.addressDetails = action.payload;
    },
    setSubmissionDetails(state, action) {
      state.submissionDetails = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    toggleCCForm(state, action) {
      state.showCCForm = action.payload;
    },
    resetTxn(state, action) {
      state = {
        ...cleanState,
      };
      state.validatedSteps = [];
    },
    setValidatedSteps(state, action) {
      state.validatedSteps = action.payload;
    },
    addValidatedStep(state, action) {
      if (state.validatedSteps.indexOf(action.payload) === -1) {
        state.validatedSteps.push(action.payload);
      }
    },
    removeValidatedStep(state, action) {
      const idx = state.validatedSteps.indexOf(action.payload);
      if (idx >= 0) {
        state.validatedSteps.splice(idx, 1);
      }
    },
    resetFromStep(state, action) {
      state.activeStep = action.payload;
    },
    setActiveStep(state, action) {
      if (action.payload === 0) {
        // reset everything
        state.activeStep = 0;
        state.txnDetails = { ...cleanState.txnDetails };
        state.paymentDetails = { ...cleanState.paymentDetails };
        state.addressDetails = { ...cleanState.addressDetails };
        state.submissionDetails = { ...cleanState.submissionDetails };
        state.errors = { ...cleanState.errors };
        state.validatedSteps = [];
      } else {
        state.activeStep = state.activeStep + action.payload;
      }
    },
    resetBilling(state) {
      state.addressDetails = {
        ...cleanAddress,
      };
    },
  },
  extraReducers: {
    [fetchCountries.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchCountries.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.topupCountries = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchCountries.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchCharities.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchCharities.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.charities = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchCharities.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchAmountPresets.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchAmountPresets.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.amountPresets = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchAmountPresets.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchCC.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchCC.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.cCards = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchCC.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchVat.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchVat.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.vat = action.payload;
        state.currentRequestId = undefined;
      }
    },
    [fetchVat.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchAddressCountries.fulfilled]: (state, action) => {
      state.countries = [...action.payload];
    },
    [fetchAddressStates.fulfilled]: (state, action) => {
      state.states = [...action.payload];
    },
    [fetchPhoneProviders.pending]: (state, action) => {
      state.phoneProviders = [];
    },
    [fetchPhoneProviders.fulfilled]: (state, action) => {
      state.phoneProviders = [...action.payload];
    },
    [fetchPhoneProviders.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  setTxnDetails,
  setPaymentDetails,
  setAddressDetails,
  setSubmissionDetails,
  setErrors,
  setLoading,
  toggleCCForm,
  resetTxn,
  setActiveStep,
  setCoverTax,
  addValidatedStep,
  removeValidatedStep,
  resetFromStep,
  setValidatedSteps,
  resetBilling,
} = topupSlice.actions;

export default topupSlice.reducer;
