import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    addValidatedStep,
    fetchPhoneProviders,
    removeValidatedStep,
    setTxnDetails,
} from "components/public-dashboard/features/topup/topupSlice";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CarrierCard from "./carrier_card";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
export default function CarrierSelector(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { phoneProviders, txnDetails, activeStep, topupCountries } = useSelector(
    (state) => state.topup
  );
  useEffect(() => {
    const loadCarriers = async () => {
      try {
        await dispatch(fetchPhoneProviders());
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
      }
    };
    loadCarriers();
  }, [topupCountries]);
  const [selectedCarrier, setSelectedCarrier] = useState(txnDetails.carrier.phoneProviderId);
  const handleChange = (carrier) => {
    invalidateStep();
    setSelectedCarrier(carrier.phoneProviderId);
    dispatch(
      setTxnDetails({
        ...txnDetails,
        carrier,
      })
    );
  };
  const handleValidateNext = () => {
    dispatch(addValidatedStep(activeStep));
  };
  const invalidateStep = () => {
    dispatch(removeValidatedStep(activeStep));
  };
  const sm = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {phoneProviders.map((carrier) => (
          <Grid item xs={6} sm={4} md={3} key={carrier.phoneProviderId}>
            <CarrierCard
              carrier={carrier}
              handleChange={() => handleChange(carrier)}
              selected={selectedCarrier}
              handleValidateNext={handleValidateNext}
              invalidateStep={invalidateStep}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}