import {
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import history from "history/history";
import signUp_img from "assets/images/home_content_5.png";
import ItemList from "components/public-dashboard/components/ItemList";
import VerificationHeader from "./header";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResetPasswordDialog(props) {
  const { title, title2 } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [reset, setReset] = useState({
    password: "",
    confirmPwd: "",
  });
  const { onChangeField, onResetPwd, error, passwordErr, confirmPasswordErr } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (prop) => (e) => {
    setReset({ ...reset, [prop]: e.target.value.replace(" ","") });
    onChangeField(prop, e.target.value.replace(" ",""));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function onSubmit(e) {
    e.preventDefault();
    onResetPwd();
  }

  function onClose() {
    setReset({
      password: "",
      confirmPwd: "",
    });
    history.push("/");
  }
  return (
    <Box style={{ background: "#FFF", height: "100vh" }}>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"md"}
        fullScreen
      >
        <DialogContent>
          <form
            onSubmit={onSubmit}
            style={{ padding: md ? theme.spacing(3) : 0 }}
          >
            <VerificationHeader
              title={title}
              color="#fbb004"
              title2={title2}
              color2="#fd7900"
              titleLg="h3"
              titleSm="h4"
              titleXs="h5"
              list={
                <ItemList
                  items={[
                    <Typography variant={md ? "h6" : "body1"}>
                      Enter your new password
                    </Typography>,
                    <TextField
                      value={reset.password}
                      label="New password"
                      onChange={handleChange("password")}
                      fullWidth
                      variant="outlined"
                      error={passwordErr.length > 0}
                      helperText={
                          passwordErr.length > 0
                          ? passwordErr
                          : "Must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                      }
                      color="secondary"
                      type={showPassword ? 'text' : 'password'} 
                      required
                      inputProps={{
                        minLength: 8,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />,
                    <TextField
                      value={reset.confirmPwd}
                      label="Confirm Password"
                      onChange={handleChange("confirmPwd")}
                      fullWidth
                      variant="outlined"
                      error={confirmPasswordErr.length > 0}
                      helperText={confirmPasswordErr.length > 0 ? confirmPasswordErr : ""}
                      color="secondary"
                      type={showPassword ? 'text' : 'password'} 
                      required
                      inputProps={{
                        minLength: 8,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />,
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{ marginTop: "5%" }}
                    >
                      Submit
                    </Button>,
                  ]}
                />
              }
              img={signUp_img}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
