import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import { Hidden, SwipeableDrawer } from "@material-ui/core";
import SelectableNavList from "./selectableNavList";
import { useSelector, useDispatch } from "react-redux";
import { setNavIndex } from "./uiSlice";
import { useLocation } from "react-router";
import CustomToolbar from "./toolbar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  toolbar: theme.mixins.toolbar,
  whiteText: {
    color: "#FFF",
  },
}));

export default function CustomSecuredDrawer(props) {
  const classes = useStyles();
  const { mobileOpen, handleDrawerToggle, user } = props;
  const dispatch = useDispatch();
  const match = "/user";
  const routes = [
    { path: `${match}`, label: "Dashboard", show: true },
    { path: `${match}/billpay`, label: "Bill Pay", show: true },
    { path: `${match}/topup`, label: "Top Up", show: true },
    { path: `${match}/activity`, label: "Activity", show: true },
    { path: `${match}/account`, label: "Account", show: true },
    { path: `${match}/aboutus`, label: "About Us", show: true },
    { path: `${match}/contactus`, label: "Contact Us", show: true },
    { path: `${match}/faq`, label: "FAQs", show: true },
  ];
  const location = useLocation();

  const { navIndex } = useSelector((state) => state.uiSecured);
  const handleChangeIndex = (newValue) => {
    dispatch(setNavIndex(newValue));
    window.scrollTo(0, 0);
  };

  const handleChangeIndexMobile = (newValue) => {
    dispatch(setNavIndex(newValue));
    handleDrawerToggle();
    window.scrollTo(0, 0);
  };

  const getActiveRoute = (pathname) => {
    const index = routes.findIndex((r) => r.path === pathname);
    if (index >= 0) {
      return index;
    }
    return 0;
  };

  useEffect(() => {
    function setNavIndexByRoute() {
      dispatch(setNavIndex(getActiveRoute(location.pathname)));
    }
    setNavIndexByRoute();
  }, [dispatch]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <SelectableNavList
        value={navIndex}
        setValue={handleChangeIndex}
        routes={routes}
      />
    </div>
  );

  return (
    <Fragment>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            anchor="left"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <CustomToolbar
              handleDrawerToggle={handleDrawerToggle}
              user={user}
            />
            <SelectableNavList
              value={navIndex}
              setValue={handleChangeIndexMobile}
              routes={routes}
            />
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open={mobileOpen}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </Fragment>
  );
}
