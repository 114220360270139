import { Container, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "components/public-dashboard/components/TextList";
import IconHeader from "components/public-dashboard/components/iconHeader";
const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%",
    },
  },
  img: {
    width: "100%",
  },
  magentaText: {
    color: "#CC3E7D",
  },
  headerText: {
    color: "darkorange",
    fontWeight: 800
  },
}));

export default function SecureHeader() {
  const classes = useStyles();
  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconHeader
            title={`is SECURE`}
            iconColor="#000"
            titleColor="#1E7D83"
            titleLg="h2"
            titleSm="h4"
            titleXs="h5"
          />
        </Grid>
        <Grid item sm={12}>
          <TextList
            items={[
              <span>
                At <strong>YOOZ</strong> our{" "}
                <span className={classes.headerText}>#1 priority</span> is to
                ensure that your information and transactions are as safe as
                possible.
              </span>,
              <span>
                <strong>YOOZ</strong> employs the{" "}
                <span className={classes.headerText}>latest security</span>{" "}
                protocols based on
                <span className={classes.headerText}>
                  {" "}
                  international best practices
                </span>{" "}
                and{" "}
                <span className={classes.headerText}>
                  industry standard auditable processes
                </span>
                .
              </span>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
