import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";
import { getCardImageByType } from "components/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listItem: {
    padding:0,
  },
}));

export default function AddressBillingDetailListing(props) {
  const classes = useStyles();
  const { addressDetails, paymentDetails, countries, cardType } = props;
  const fullName = paymentDetails.name;
  const renderListItemText = (primary, secondary) => {
    return (
      <ListItemText
        primaryTypographyProps={{ align: "left" }}
        primary={primary}
        secondary={secondary}
      />
    );
  };

  return (
    <Fragment>
      <List className={classes.root} dense>
        {paymentDetails.cardNumber && (
          <ListItem className={classes.listItem}>
            <ListItemText
              primaryTypographyProps={{
                align: "left",
                style: { display: "flex", alignItems: "center" },
              }}
              primary="Card"
              secondary={
                <Typography
                  color="secondary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 20 }}>
                    {getCardImageByType(cardType)}
                  </span>
                  <NumberFormat
                    value={paymentDetails.cardNumber.replace(
                      paymentDetails.cardNumber.substring(0, 12),
                      "************"
                    )}
                    format="**** **** **** ####"
                    displayType="text"
                  />
                </Typography>
              }
            />
          </ListItem>
        )}

        {fullName && (
          <ListItem className={classes.listItem}>
            {renderListItemText("Name", fullName)}
          </ListItem>
        )}

        <ListItem className={classes.listItem}>
          {renderListItemText(
            "Billing Address",
            `${addressDetails.line1 || ""}${
              addressDetails.line2 ? ", " + addressDetails.line2 : ""
            }${addressDetails.state ? ", " + addressDetails.state : ""}${
              addressDetails.city ? ", " + addressDetails.city : ""
            }${
              addressDetails.postalCode ? ", " + addressDetails.postalCode : ""
            }${
              countries.find((x) => x.isoCode === addressDetails.countryCode)
                ?.name
                ? ", " +
                  countries.find(
                    (x) => x.isoCode === addressDetails.countryCode
                  )?.name
                : ""
            }`
          )}
        </ListItem>
      </List>
    </Fragment>
  );
}
