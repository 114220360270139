import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { postAsync } from "components/public-dashboard/services/api_util";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import { setNavIndex } from "components/secured-app/uiSlice";
import { checkEmailRegex, getCardType, isValidateExpDate } from "components/utils";
import { API_ROOT } from "constants/actionTypes";
import { getToken } from "middleware/storage";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect, useDispatch } from "react-redux";
import TxnResult from "../../../shared/txn_result";
import AddressDetails from "./address_details";
import {
  fetchCharities,
  fetchCountries,
  fetchProviders,
  resetTxn,
  setErrors,
  setLoading,
  setTxnDetails,
} from "./bpSlice";
import PaymentDetails from "./payment_details";
import BillPayMobileStepper from "./stepper_mobile";
import TxnConfirmation from "./txn_confirmation";
import TxnDetails from "./txn_details";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    borderRadius: 20,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperBtns: {
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  actionBtn: {
    borderRadius: 20,
  },
  resetBtn: {
    borderRadius: 20,
    marginTop: theme.spacing(2),
    float: "right",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 20,
  },
}));

const facState = { state: "pending", data: "" };

function BillPayStepper(props) {
  const btnRef = useRef(null);
  const {
    txnDetails,
    setErrors,
    paymentDetails,
    submissionDetails,
    setLoading,
    loading,
    showCCForm,
    resetTxn,
    providers,
    charities,
    addressDetails,
  } = props;
  let provider = "";
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [allStepsValidated, setAllStepsValidated] = useState(false);
  const [facResponse, setFacResponse] = useState(facState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  let steps = [];
  if (txnDetails.providerId != "") {
    provider = providers.find((p) => p.providerId === txnDetails.providerId);
  }
  if (activeStep == 0) {
    steps = [
      { label: "Enter Payment Details" },
      { label: "Enter Card Details" },
      { label: "Enter Billing Address Details (Optional)" },
      { label: "Payment Review" },
    ];
  } else {
    steps = [
      {
        label: "Enter Payment Details",
        value: (
          <Typography color="secondary" variant="h6">
            {provider.name != "" ? provider.name : ""}{" "}
            {txnDetails.accountNumber > 0
              ? ` | Account ${txnDetails.accountNumber}`
              : ""}{" "}
            {txnDetails.accountHolderName != ""
              ? ` - ${txnDetails.accountHolderName}`
              : ""}
            {txnDetails.amount > 0 ? ` | $${txnDetails.amount} TTD` : ""}
          </Typography>
        ),
      },
      { label: "Enter Card Details" },
      { label: "Enter Billing Address Details (Optional)" },
      { label: "Payment Result" },
    ];
  }

  const dispatch = useDispatch();
  const token = getToken();

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(resetTxn);
        await dispatch(fetchProviders());
        await dispatch(fetchCharities());
        await dispatch(fetchCountries());
        setLoading(false);
      } catch (error) {}
    };

    setLoading(true);
    fetchData();
  }, [dispatch, setLoading]);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <TxnDetails />;
      case 1:
        return <PaymentDetails />;
      case 2:
        return <AddressDetails />;
      case 3:
        return (
          <>
            <TxnConfirmation onChangeStep={handleStepChange} />
          </>
        );
      default:
        return "Unknown Step";
    }
  };

  const runValidations = async () => {
    let isValid = true;
    let accountValidationRequest = {};
    let errors = {
      amount: "",
      donationAmount: "",
      accountNumber: "",
      providerId: "",
      charityId: "",
      name: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      email: "",
      comment: "",
      customerCardId: "",
      agreement: "",
      phoneNumber: "",
    };

    let min = 25;
    let max = 5000;
    if (activeStep === 0) {
      const {
        amount,
        accountNumber,
        providerId,
        charityId,
        email,
        donationAmount,
        comment,
        phoneNumber,
      } = txnDetails;
      if (providerId === "") {
        errors.providerId = "You must select a payee";
        isValid = false;
      } else {
        const provider = providers.find((p) => p.providerId === providerId);
        if (provider) {
          min = provider.minimum || min;
          max = provider.limit || max;
        }
        if (amount < min) {
          errors.amount = `Amount cannot be less than $${min.toFixed(2)}`;
          isValid = false;
        }

        if (amount > max) {
          errors.amount = `Amount cannot be more than $${max.toFixed(2)}`;
          isValid = false;
        }
        if (phoneNumber.length <= 6) {
          errors.phoneNumber = `Phone number is invalid`;
          isValid = false;
        }

        if (phoneNumber.length == 4) {
          errors.phoneNumber = `Phone number is required`;
          isValid = false;
        }

        if (accountNumber === "") {
          errors.accountNumber = "Account number cannot be empty.";
          isValid = false;
        } else if (/[^0-9a-zA-Z-]/.test(accountNumber)) {
          errors.accountNumber = "Account number is invalid.";
          isValid = false;
        } else {
          accountValidationRequest = await isValidAccount();
          if (accountValidationRequest !== true) {
            if (accountValidationRequest.data == "Amount") {
              errors.amount = accountValidationRequest.msg;
            } else {
              errors.accountNumber = accountValidationRequest.msg;
            }
            isValid = false;
          }
        }

        if (email === "") {
          errors.email = "Email cannot be empty";
          isValid = false;
        } else if (
          !checkEmailRegex(email)
        ) {
          errors.email = "Email is invalid";
          isValid = false;
        }
      }
    }

    if (activeStep === 1) {
      const { name, cardNumber, expirationDate, cvv, customerCardId } =
        paymentDetails;

      if (!token || (token && showCCForm)) {
        if (name === "") {
          errors.name = "Name cannot be empty.";
          isValid = false;
        } else if (/[^a-zA-Z-'\s]/.test(name)) {
          errors.name = "Name on Card is invalid.";
          isValid = false;
        }

        const cardType = getCardType(cardNumber);
        if (
          cardType.toUpperCase() !== "VISA" &&
          cardType.toUpperCase() !== "MASTERCARD"
        ) {
          errors.cardNumber = "Invalid credit card number.";
          isValid = false;
        }

        if (cardType.toUpperCase() === "VISA") {
          if (cardNumber.length !== 13 && cardNumber.length !== 16) {
            errors.cardNumber = "Incomplete Credit Card Number.";
            isValid = false;
          }
        }

        if (cardType.toUpperCase() === "MASTERCARD") {
          if (cardNumber.length !== 16) {
            errors.cardNumber = "Incomplete Credit Card Number.";
            isValid = false;
          }
        }

        if (expirationDate === "") {
          errors.expirationDate = "Expiration date cannot be empty.";
          isValid = false;
        } else if (expirationDate.length < 4) {
          errors.expirationDate = "Expiration date is incomplete.";
          isValid = false;
        }

        if (!isValidateExpDate(expirationDate)) {
          errors.expirationDate = "Expiration date is invalid.";
          isValid = false;
        }
      }

      if (token && cvv === "") {
        errors.cvv = "CVV cannot be empty.";
        isValid = false;
      } else if (cvv.length < 3) {
        errors.cvv = "CVV must be exactly 3 digits.";
        isValid = false;
      }

      if (token && !showCCForm && customerCardId === 0) {
        errors.customerCardId = "Must select a payment method.";
        isValid = false;
      }
    }

    if (activeStep === 2) {
      const {
        amount,
        accountNumber,
        providerId,
        charityId,
        email,
        donationAmount,
        comment,
        phoneNumber,
      } = txnDetails;
      if (providerId === "") {
        errors.providerId = "You must select a payee.";
        isValid = false;
      } else {
        const provider = providers.find((p) => p.providerId === providerId);
        if (provider) {
          min = provider.minimum || min;
          max = provider.limit || max;
        }
        if (amount < min) {
          errors.amount = `Amount cannot be less than $${min.toFixed(2)}.`;
          isValid = false;
        }

        if (amount > max) {
          errors.amount = `Amount cannot be more than $${max.toFixed(2)}.`;
          isValid = false;
        }

        if (accountNumber === "") {
          errors.accountNumber = "Account number cannot be empty.";
          isValid = false;
        } else if (/[^0-9a-zA-Z-]/.test(accountNumber)) {
          errors.accountNumber = "Account number is invalid.";
          isValid = false;
        }

        if (email === "") {
          errors.email = "Email cannot be empty.";
          isValid = false;
        } else if (
          !checkEmailRegex(email)
        ) {
          errors.email = "Email is invalid";
          isValid = false;
        }
      }
    }

    if (activeStep === 3) {
      const { tac, ppolicy } = submissionDetails;
      if (!tac && !ppolicy) {
        isValid = false;
        errors.agreement =
          "Please agree to the Terms & Conditions and Privacy Policy to proceed.";
      }
    }

    return { isValid, accountValidationRequest, errors };
  };

  const handleNext = async () => {
    let { isValid, accountValidationRequest, errors } = await runValidations();

    if ((activeStep === 0 || activeStep === 1) && isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // want to show finish button at this point so use custom logic
    if (activeStep === 2 && isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      if (!allStepsValidated) {
        setAllStepsValidated(true);
      }
    }

    if (
      activeStep === 3 &&
      isValid &&
      btnRef.current &&
      !btnRef.current.disabled
    ) {
      btnRef.current.disabled = true;
      let googleRecaptchaToken = null;
      if (!executeRecaptcha) {
        googleRecaptchaToken = null;
      }
      googleRecaptchaToken = await executeRecaptcha("postBillPay");
      setFacResponse({ ...facState });
      setLoading(true);
      let charityId = 0;
      if (txnDetails.charityId != "") {
        charityId = txnDetails.charityId;
      }
      const data = {
        ...txnDetails,
        amount: parseFloat(txnDetails.bpTotal),
        donationAmount: parseFloat(txnDetails.donationAmount) || 0,
        charityId,
        ...paymentDetails,
        googleRecaptchaToken,
        billingAddress: addressDetails,
        shouldSaveCard: paymentDetails.shouldSaveCard,
        customerCardRate: parseFloat(txnDetails.customerCardRate),
        customerFee: parseFloat(txnDetails.customerFee),
        billPayAmount: parseFloat(txnDetails.amount)
      };
      let response = await postAsync(
        data,
        `${API_ROOT}Transaction/PostTransactionAsync`
      );
      setLoading(false);
      btnRef.current.disabled = false;
      if (response.state === 1) {
        setFacResponse({ state: "success", data: response.data });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setFacResponse({ ...facState, state: "failed", msg: response.msg });
      }
    }

    setErrors(errors);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.scrollTo(0, 0);
    setAllStepsValidated(false);
    setActiveStep(0);
    dispatch(resetTxn());
  };

  const handleStepChange = (targetStep) => {
    setActiveStep(targetStep);
  };

  const isValidAccount = async () => {
    setLoading(true);
    const response = await postAsync(
      null,
      `${API_ROOT}Transaction/ValidateAccount?providerId=${txnDetails.providerId}&accountNumber=${txnDetails.accountNumber}&amount=${txnDetails.amount}`
    );
    setLoading(false);

    if (response.data && response.data.toUpperCase() === "ACTIVE") {
      dispatch(
        setTxnDetails({
          ...txnDetails,
          accountHolderName: response.nameonaccount,
        })
      );
      return true;
    }
    return response;
  };

  return (
    <div className={classes.root}>
      <BillPayMobileStepper
        steps={steps}
        stepsValidated={allStepsValidated}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleFinish={async (step) => {
          let { isValid, errors } = await runValidations();
          if (isValid) {
            handleStepChange(step);
            return;
          }
          setErrors(errors);
        }}
        handleReset={handleReset}
        getStepContent={getStepContent}
        facResponse={facResponse}
        loading={loading}
        setFacResponse={setFacResponse}
        token={token}
        dispatch={dispatch}
        setNavIndex={setNavIndex}
        setTabPosition={setTabPosition}
      />
      {activeStep === steps.length - 1 && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginBottom: 15 }}
        >
          <Grid item xs={10} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              ref={btnRef}
              fullWidth
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      )}
      {activeStep === steps.length && (
        <div>
          {facResponse.state === "success" && (
            <TxnResult service={"BillPay"} facResponse={facResponse} />
          )}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginBottom: 15 }}
          >
            <Grid item xs={10} sm={4}>
              <Button
                className={classes.resetBtn}
                color="primary"
                variant="contained"
                onClick={handleReset}
                fullWidth
              >
                New Transaction
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  txnDetails: state.billpay.txnDetails,
  paymentDetails: state.billpay.paymentDetails,
  addressDetails: state.billpay.addressDetails,
  submissionDetails: state.billpay.submissionDetails,
  loading: state.billpay.loading,
  showCCForm: state.billpay.showCCForm,
  providers: state.billpay.providers,
  charities: state.billpay.charities,
});

const mapDispatch = { setErrors, fetchProviders, setLoading, resetTxn };

export default connect(mapStateToProps, mapDispatch)(BillPayStepper);
