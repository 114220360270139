import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/shared/loader/loader";
import {
  addValidatedStep,
  fetchAmountPresets,
  removeValidatedStep,
  setCoverTax,
  setTxnDetails,
} from "components/public-dashboard/features/topup/topupSlice";
import AmountPreset from "./amount_preset";
import AmountPresetSpecify from "./amount_preset _specify";
import AmountPresetCarousel from "./amount_presets_mobile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function AmountSelector(props) {
  const { handleNext } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    amountPresets,
    txnDetails,
    activeStep,
    vat,
  } = useSelector((state) => state.topup);
  useEffect(() => {
    const loadPresets = async () => {
      try {
        await dispatch(fetchAmountPresets());
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
      }
    };

    loadPresets();
  }, [dispatch]);

  const [value, setValue] = useState(txnDetails.presetSelected.id);

  const handleChange = (preset) => {
    invalidateStep();
    setValue(preset.id);
    dispatch(
      setTxnDetails({
        ...txnDetails,
        amount: txnDetails.coverVat ? preset.amount * (1 + vat) : preset.amount,
        presetSelected: preset,
      })
    );
  };

  const handleValidateNext = () => {
    dispatch(addValidatedStep(activeStep));
    // handleNext();
  };

  const invalidateStep = () => {
    dispatch(removeValidatedStep(activeStep));
  };

  const handleCoverVat = (checked) => {
    dispatch(
      setTxnDetails({
        ...txnDetails,
        amount: checked
          ? txnDetails.presetSelected.amount * (1 + vat)
          : txnDetails.presetSelected.amount,
        coverVat: checked,
      })
    );
  };

  const sm = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      {sm ? (
        <AmountPresetCarousel>
          <div className={classes.item}>
            <AmountPresetSpecify
              preset={txnDetails.presetSelected}
              handleChange={handleChange}
              selected={value}
              toggleVat={handleCoverVat}
              coverVat={txnDetails.coverVat}
              handleValidateNext={handleValidateNext}
              invalidateStep={invalidateStep}
              vat={vat}
              minAmount={txnDetails.carrier.minimum}
              maxAmount={txnDetails.carrier.maximum}
            />
          </div>
          {amountPresets.map((preset) => (
            <div key={preset.id} className={classes.item}>
              <AmountPreset
                preset={preset}
                handleChange={() => handleChange(preset)}
                selected={value}
                toggleVat={handleCoverVat}
                handleValidateNext={handleValidateNext}
                invalidateStep={invalidateStep}
                coverVat={txnDetails.coverVat}
                vat={vat}
              />
            </div>
          ))}
        </AmountPresetCarousel>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={6}>
            <AmountPresetSpecify
              preset={txnDetails.presetSelected}
              handleChange={handleChange}
              selected={value}
              toggleVat={handleCoverVat}
              coverVat={txnDetails.coverVat}
              handleValidateNext={handleValidateNext}
              invalidateStep={invalidateStep}
              vat={vat}
              minAmount={txnDetails.carrier.minimum}
              maxAmount={txnDetails.carrier.maximum}
            />
          </Grid>
          {amountPresets.map((preset) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={preset.id}>
              <AmountPreset
                preset={preset}
                handleChange={() => handleChange(preset)}
                selected={value}
                toggleVat={handleCoverVat}
                handleValidateNext={handleValidateNext}
                invalidateStep={invalidateStep}
                coverVat={txnDetails.coverVat}
                vat={vat}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
