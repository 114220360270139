import {
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import signUp_img from "assets/images/home_content_5.png";
import Loading from "components/shared/loader/loader";
import ItemList from "components/public-dashboard/components/ItemList";
import VerificationHeader from "./header";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({}));

export default function ForgotPasswordDialog(props) {
  const { title, title2 } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const {
    open,
    handleClose,
    onChangeFormField,
    sendResetLink,
    authReducer,
    error,
  } = props;

  function handleChange(e) {
    setEmail(e.target.value);
    onChangeFormField("emailForReset", e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    sendResetLink();
  }

  function onClose() {
    setEmail("");
    handleClose();
  }
  return authReducer.isLinkSent ? (
    <Loading />
  ) : (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      maxWidth={"md"}
    >
      <DialogContent className={classes.dialog}>
        <form
          onSubmit={onSubmit}
          style={{ padding: md ? theme.spacing(3) : 0 }}
        >
          <VerificationHeader
            title={title}
            color="#fbb004"
            title2={title2}
            color2="#fd7900"
            titleLg="h3"
            titleSm="h4"
            titleXs="h5"
            list={
              <ItemList
                items={[
                  <Typography variant={md ? "h6" : "body1"}>
                    A password reset link will be sent to the following email
                  </Typography>,
                  <TextField
                    value={email}
                    label="Email"
                    onChange={handleChange}
                    variant="outlined"
                    error={error.length > 0}
                    helperText={error.length > 0 ? error : ""}
                    color="secondary"
                    type="email"
                    required
                  />,
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "5%" }}
                  >
                    Submit
                  </Button>,
                ]}
              />
            }
            img={signUp_img}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
