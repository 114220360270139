import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import YoozSvgIcon from "components/public-dashboard/components/yooz_svg_icon";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
    padding: theme.spacing(2),
  },

  overlineText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    textTransform: "uppercase",
  },

  form: {
    "& > *": {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  textFields: {
    // background: "#f9edaa",
    background: "linear-gradient(rgba(248,231,150,.5),rgba(248,231,150,.5))",

    borderRadius: 50,
    color: theme.palette.secondary.main,
  },
  textBox: {
    borderRadius: 50,
  },
  submitBtn: {
    borderRadius: 50,
    // background: "#b8ce8a",
    background: "linear-gradient(rgba(184,206,138,.5),rgba(184,206,138,.5))",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
    // marginLeft: '15px',
    // marginRight: '15px',
  },
  actionBtn: {
    borderRadius: 50,
  },
  wrapper: {
    padding: theme.spacing(2),
    textAlign: "center",
    borderRadius: 20,
    border: "3px solid #FFF",
    // background: "#eff5f0",
    background: "linear-gradient(rgba(239,245,240,.9),rgba(239,245,240,.9))",

    [theme.breakpoints.up("lg")]: {
      background: "transparent",
    },
    [theme.breakpoints.up("sm")]: {
      width: 400,
    },
  },
}));

export default function LoginForm(props) {
  const {
    onLogin,
    onChangeFormField,
    setVisibility,
    loginError,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid
          item
          xl={8}
          lg={6}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Paper className={classes.wrapper} elevation={3}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant={"h4"}
                    color="primary"
                    className={classes.overlineText}
                  >
                    <YoozSvgIcon
                      width={xs ? "100" : "150"}
                      height={"100%"}
                      color={theme.palette.primary.main}
                      minWidth={60}
                    />
                    &nbsp;Login
                  </Typography>
                </Grid>
              </Grid>

              <Typography
                variant={"subtitle2"}
                color="secondary"
                style={{ marginTop: xs ? 10 : 0 }}
              >
                Please enter your email and password
              </Typography>

              <Grid item>
                <form className={classes.form} onSubmit={(e) => onLogin(e)}>
                  <FormControl
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size={"small"}
                  >
                    <Box boxShadow={3} className={classes.textBox}>
                      <InputLabel htmlFor="component-filled" color="secondary">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="email"
                        label="Email"
                        type="email"
                        required
                        classes={{ root: classes.textFields }}
                        onChange={(e) =>
                          onChangeFormField("username", e.target.value)
                        }
                        fullWidth
                        // value="hmei@resonancecaribbean.com"
                      />
                    </Box>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size={"small"}
                  >
                    <Box boxShadow={3} className={classes.textBox}>
                      <InputLabel htmlFor="component-filled" color="secondary">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        label="Password"
                        type="password"
                        required
                        classes={{ colorSecondary: classes.textFields }}
                        onChange={(e) =>
                          onChangeFormField("userPwd", e.target.value)
                        }
                        fullWidth
                      />
                    </Box>
                  </FormControl>
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.submitBtn}
                    size={"medium"}
                  >
                    LOG IN
                  </Button>
                  <Typography
                    variant={"subtitle2"}
                    color="inherit"
                    style={{ margin: 0 }}
                  >
                    New YOOZer?
                    <Button component={RouterLink} to="/signup" color="primary">
                      {" "}
                      Sign Up{" "}
                    </Button>
                  </Typography>
                  <Typography
                    variant={"subtitle2"}
                    color="secondary"
                    style={{ margin: 0 }}
                  >
                    <Button
                      color="secondary"
                      className={classes.actionBtn}
                      size={"small"}
                      onClick={setVisibility}
                    >
                      Forgot Password
                    </Button>
                  </Typography>
                  {loginError && <Alert severity="error">{loginError}</Alert>}
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
