import {
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import YoozSvgIcon from "./yooz_svg_icon";

const useStyles = makeStyles((theme) => ({
  overlineText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    textTransform: "uppercase",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  overlineLink: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    textTransform: "uppercase",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
    "&:hover": { color: `${theme.palette.primary.light} !important` },
  },
  yoozit: {
    fontStyle: "italic",
    fontFamily: "Arial Rounded MT",
    textTransform: "none",
    lineHeight: 1.3,
    marginLeft: theme.spacing(1)
  },
}));

export default function IconHeader(props) {
  const {
    title,
    iconColor,
    titleColor,
    icon = true,
    titleLg = "h1",
    titleSm = "h2",
    titleXs = "h3",
    path = "",
    subTitle = "",
    subTitleColor,
    subTitleLg = "h1",
    subTitleSm = "h2",
    subTitleXs = "h3",
    tabPosition,
    styles,
    subtitleStyles,
    yoozIt = false,
  } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const hasLineBreak = title && title.includes && title.includes("<br>");
  const renderTitle = () => {
    if (hasLineBreak) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      );
    } else {
      return title;
    }
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        {path !== "" ? (
          <Link
            underline="none"
            component={RouterLink}
            to={path}
            onClick={() => {
              dispatch(setTabPosition(tabPosition));
              window.scrollTo(0, 0);
            }}
          >
            <Typography
              variant={xs ? titleXs : sm ? titleSm : titleLg}
              style={{ color: titleColor, ...styles }}
              className={path ? classes.overlineLink : classes.overlineText}
            >
              {icon && (
                <Fragment>
                  <YoozSvgIcon
                    width={xs ? 120 : sm ? "200" : "250"}
                    height={"100%"}
                    color={iconColor}
                  />
                </Fragment>
              )}
              {yoozIt ? (
                <span className={classes.yoozit}>It</span>
              ) : (
                renderTitle()
              )}
            </Typography>
          </Link>
        ) : (
          <Typography
            variant={xs ? titleXs : sm ? titleSm : titleLg}
            style={{ color: titleColor, ...styles }}
            className={classes.overlineText}
          >
            {icon && (
              <Fragment>
                <YoozSvgIcon
                  width={xs ? 120 : sm ? 200 :  250}
                  height={"100%"}
                  color={iconColor}
                />
              </Fragment>
            )}
            {yoozIt ? (
              <span className={classes.yoozit}>It</span>
            ) : (
              renderTitle()
            )}
          </Typography>
        )}
      </Grid>
      {subTitle && (
        <Grid item xs={12}>
          <Typography
            variant={xs ? subTitleXs : sm ? subTitleSm : subTitleLg}
            style={{ color: subTitleColor, marginTop: 0, ...subtitleStyles }}
            className={classes.overlineText}
          >
            {subTitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
