import axios from "axios";
import {
  API_ROOT,
  COUNTRYLIST_FAILURE,
  COUNTRYLIST_REQUEST,
  COUNTRYLIST_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_REQUEST,
  OTP_VARIFY_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PWDLINK_SENT_FAILURE,
  PWDLINK_SENT_REQUEST,
  PWDLINK_SENT_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "constants/actionTypes";
import { setUserDetails } from "middleware/storage";

function Request(type) {
  return {
    type: type,
  };
}

function Success(type, payload) {
  return {
    type: type,
    entities: payload,
  };
}

function Failure(message, type) {
  return {
    type: type,
    error: message,
  };
}

export function userLogin(user) {
  return (dispatch, getState) => {
    getState().entities.user.tokens = {};
    dispatch(Request(LOGIN_REQUEST));
    return axios
      .post(API_ROOT + `users/authenticate`, user)
      .then((response) => {
        if (response.data.state === 1) {
          return dispatch(
            Success(LOGIN_SUCCESS, {
              user: { tokens: response.data.data },
            })
          );
        } else {
          return dispatch(
            Failure(
              "Server communication error. Please try again later",
              LOGIN_FAILURE
            )
          );
        }
      })
      .catch((error) => {
        return dispatch(Failure(error.response.data.msg, LOGIN_FAILURE));
      });
  };
}

export function sendPwdResetLink(email) {
  return (dispatch, getState) => {
    getState().entities.user.queryString = "";
    dispatch(Request(PWDLINK_SENT_REQUEST));
    return axios
      .get(API_ROOT + `users/sendresetpassemail?email=${email}`)
      .then((response) => {
        return dispatch(
          Success(PWDLINK_SENT_SUCCESS, {
            user: { queryString: response.data.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, PWDLINK_SENT_FAILURE));
      });
  };
}

export function resetUserPassword(cred) {
  return (dispatch, getState) => {
    //getState().entities.user.auth = {};
    dispatch(Request(PASSWORD_RESET_REQUEST));
    return axios
      .post(API_ROOT + `users/verifyemailpass`, cred)
      .then((response) => {
        return dispatch(
          Success(PASSWORD_RESET_SUCCESS, {
            user: { reset: response.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, PASSWORD_RESET_FAILURE));
      });
  };
}

export function getUserDetails(token) {
  return (dispatch, getState) => {
    getState().entities.user.details = {};
    dispatch(Request(LOGIN_REQUEST));
    return axios
      .get(API_ROOT + `customers/getbyuserid`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserDetails(response.data.data);

        return dispatch(
          Success(LOGIN_SUCCESS, {
            user: { details: response.data.data },
          })
        );
      })
      .catch((error) => {
        return dispatch(Failure(error, LOGIN_FAILURE));
      });
  };
}

export function userRegistration(user) {
  return (dispatch, getState) => {
    getState().entities.user.auth = {};
    dispatch(Request(SIGNUP_REQUEST));
    return axios
      .post(API_ROOT + "users/register", user)
      .then((response) => {
        if (response.data.state) {
          if (response.data.state ===1) {
            return dispatch(
              Success(SIGNUP_SUCCESS, {
                user: response.data,
              })
            );
          } else {
            return dispatch(Failure(response.data.msg, SIGNUP_FAILURE));
          }
        }
        return dispatch(
          Failure(
            "Server communication error. Please try again later",
            SIGNUP_FAILURE
          )
        );
      })
      .catch((error) => {
        return dispatch(Failure(error.response.data.msg, SIGNUP_FAILURE));
      });
  };
}

export function otpVarification(phone) {
  return (dispatch, getState) => {
    getState().entities.user.auth = {};
    dispatch(Request(OTP_VARIFY_REQUEST));
    return axios
      .post(API_ROOT + `users/phoneverify`, phone)
      .then((response) => {
        if (response.data.state) {
          if (response.data.state === 1) {
            return dispatch(
              Success(OTP_VARIFY_SUCCESS, {
                user: { auth: response.data },
              })
            );
          } else {
            return dispatch(Failure(response.data.msg, OTP_VARIFY_FAILURE));
          }
        }

        return dispatch(
          Failure(
            "Server communication error. Please try again later",
            OTP_VARIFY_FAILURE
          )
        );
      })
      .catch((error) => {
        return dispatch(Failure(error.response.data.msg, OTP_VARIFY_FAILURE));
      });
  };
}

export function sendOtp(phone) {
  return (dispatch, getState) => {
    getState().entities.user.smsCred = {};
    dispatch(Request(RESEND_OTP_REQUEST));
    return axios
      .post(API_ROOT + `users/sendsmsverifycode`, phone)
      .then((response) => {
        if (response.data.state) {
          if (response.data.state === 1) {
            return dispatch(
              Success(RESEND_OTP_SUCCESS, {
                user: { smsCred: response.data.data },
              })
            );
          } else {
            return dispatch(Failure(response.data.msg, RESEND_OTP_FAILURE));
          }
        }

        return dispatch(
          Failure(
            "Server communication error. Please try again later",
            RESEND_OTP_FAILURE
          )
        );
      })
      .catch((error) => {
        return dispatch(Failure(error.response.data.msg, RESEND_OTP_FAILURE));
      });
  };
}

export function getCountryList() {
  return (dispatch, getState) => {
    getState().entities.user.countries = [];
    dispatch(Request(COUNTRYLIST_REQUEST));
    return axios
      .get(API_ROOT + `users/countries`)
      .then((response) => {
        if (response.data.state === 1) {
          return dispatch(
            Success(COUNTRYLIST_SUCCESS, {
              user: { countries: response.data.data },
            })
          );
        } else {
          return dispatch(Failure(response.data, COUNTRYLIST_FAILURE));
        }
      })
      .catch((error) => {
        return dispatch(Failure(error, COUNTRYLIST_FAILURE));
      });
  };
}
