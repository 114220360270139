import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "components/public-dashboard/components/TextList";
import IconHeader from "components/public-dashboard/components/iconHeader";

const useStyles = makeStyles((theme) => ({}));

export default function PaymentSolutionsHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item container xs={12} sm={12}>
          <IconHeader
            iconColor="#000"
            titleColor="#000"
            titleLg="h1"
            titleSm="h1"
            titleXs="h2"
            yoozIt={true}
            styles={{ alignItems: "end" }}
            subTitle="To COLLECT DONATIONS"
            subTitleColor="#906AAE"
            subTitleLg="h3"
            subTitleSm="h4"
            subTitleXs="h5"
            subtitleStyles={{ textAlign: "center" }}
          />
          <TextList
            items={[
              <span>
                The one thing local and international charities have in common
                is a genuine passion for fighting disparities in society.
              </span>,
              <span>
                At <strong>YOOZ</strong> we understand the importance of
                conducting successful donation drives to raise funds to address
                these disparities.
              </span>,
              <span>
                We are also aware that charitable and non-profit organisations
                are guided by stringent transparency, reporting and
                accountability standards. Additionally, attracting unnecessary
                transaction fees can negatively impact the value of donations
                received.
              </span>,
              <span>
                Taking all these factors into consideration, the good news is
                that <strong>YOOZ can help!</strong>
              </span>,
              <br />,
              <span className={classes.helpYouText}>
                <strong>YOOZ </strong>can help you:
              </span>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
