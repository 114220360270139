import React, { Fragment } from "react";
import agentBanner from "assets/images/YOOZ-WEBSITE-AGENT-BANNER.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import AgentHeader from "./header";
import AgentContent from "./content";

export default function Agents() {
  return (
    <Fragment>
      <PageBanner img={agentBanner} />
      <ContentWrapper>
        <AgentHeader />
        <AgentContent />
      </ContentWrapper>
    </Fragment>
  );
}
