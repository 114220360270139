export const columns = [
  {
    id: "typeOfDocument",
    label: "Type of Document",
    sortOrder: 1,
    isMobileHeader: true,
  },
  {
    id: "description",
    label: "Description",
    sortOrder: 2,
    isMobileHeader: true,
  },
  {
    id: "required",
    label: "Required",
    isMobileHeader: true,
    sortOrder: 3,
    align: "center",
  },
];
export const comment =
  "* Will be sent to you after discussions with a YOOZ Representative.";

export const TopUpAgentData = [
  {
    typeOfDocument: "YOOZ Top Up Agent Agreement *",
    description: "Contract - To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "1 Form of ID",
    description: "Driver's Permit, Passport, National ID",
    required: "YES",
  },
  {
    typeOfDocument: "Utility Bill",
    description:
      "Utility must be current. If the utility is not in your name or name of business, please provide a letter of authorization from the utility owner, their ID along with the utility.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Required only for registered traders",
    required: "Conditional",
  },
];

export const BillPaymentAgentData = [
  {
    typeOfDocument: "YOOZ Bill Payment Agent Agreement *",
    description: "Contract - To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "1 Form of ID",
    description: "Driver's Permit, Passport, National ID",
    required: "YES",
  },
  {
    typeOfDocument: "Utility Bill",
    description:
      "Utility must be current. If the utility is not in your name or name of business, please provide a letter of authorization from the utility owner, their ID along with the utility.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Required only for registered traders",
    required: "Conditional",
  },
];

export const TopUpBillPaymentAgentData = [
  {
    typeOfDocument: "YOOZ Bill Payment Agent Agreement *",
    description: "Contract - To be completed by Authorized personnel",
    required: "YES",
  },
  {
    typeOfDocument: "1 Form of ID",
    description: "Driver's Permit, Passport, National ID",
    required: "YES",
  },
  {
    typeOfDocument: "Utility Bill",
    description:
      "Utility must be current. If the utility is not in your name or name of business, please provide a letter of authorization from the utility owner, their ID along with the utility.",
    required: "YES",
  },
  {
    typeOfDocument: "Business Registration Certificate",
    description: "Required only for registered traders",
    required: "Conditional",
  },
];

export const tables = [
  { title: "Top Up Agent", data: TopUpAgentData },
  { title: "Bill Pay Agent", data: BillPaymentAgentData },
  { title: "Top Up & Bill Pay Agent", data: TopUpBillPaymentAgentData },
];
