import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SelectCountry(props) {
  const classes = useStyles();
  const { value = "", countries, handleChange, label, error } = props;

  const countryOptions = countries.map((option) => ({
    value: option.isoCode,
    label: option.name,
  }));

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base) => ({
      ...base,
      height: 55,
      borderColor: error?.length > 0 ? "red" : "grey",
    }),
  };

  const onHandleChange = (event) => {
    handleChange({
      target: { value: event.value, name: "countryCode", id: "countryCode" },
    });
  };

  return (
    <div className={classes.root}>
      <Select
        id="providerId"
        styles={selectStyles}
        options={countryOptions}
        value={countryOptions.find((x) => x.value === value)}
        error={error?.length > 0}
        required
        placeholder="Country"
        onChange={(event) => onHandleChange(event)}
        variant="outlined"
        fullWidth
        color="secondary"
      />
    </div>
  );
}
