import { Container, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "components/public-dashboard/components/TextList";
import IconHeader from "components/public-dashboard/components/iconHeader";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
    },
  },
}));

export default function AgentOnboardingDocHeader() {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <IconHeader
            title="Agent Onboarding Documents"
            iconColor="#000"
            titleColor="#1CC5FF"
            titleLg="h3"
            titleSm="h4"
            titleXs="h5"
            icon={false}
            styles={{ textAlign: "center" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
