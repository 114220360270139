import {
  Avatar,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import YoozSvgIcon from "components/public-dashboard/components/yooz_svg_icon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNavIndex } from "./uiSlice";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
      marginRight: theme.spacing(2),
    },
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    background: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  toolbar: {
    background: theme.palette.primary.main,
    color: "#FFF",
  },
}));

export default function CustomToolbar(props) {
  const classes = useStyles();
  const { handleDrawerToggle, user, handleLogout } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={classes.toolbar}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <IconButton
        edge="start"
        color="primary"
        component={Link}
        to="/user"
        onClick={() => {
          dispatch(setNavIndex(0));
        }}
      >
        <YoozSvgIcon height="100%" width={xs ? 70 : 80} color={"#FFF"} />
      </IconButton>
      {user && (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <Hidden smUp>
            <Grid item>
              <IconButton onClick={handleMenu} color="inherit">
                <Avatar className={classes.avatar}>
                  <Typography variant={xs ? "subtitle2" : "body1"}>
                    {user.firstName.charAt(0)}
                    {user.lastName.charAt(0)}
                  </Typography>
                </Avatar>
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item style={{ textAlign: "right" }}>
              <Typography variant="body1">
                {user.firstName} {user.lastName} <br />
              </Typography>
              <Typography variant="body1">
                <NumberFormat
                  value={user.phoneNumber}
                  format="#(###) ###-####"
                  displayType={"text"}
                />
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleMenu} color="inherit">
                <AccountCircle />
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </Toolbar>
  );
}
