export const assets = {
  //sky : require('../app/assets/Images/cloud.png'),
  // logo: require("../src/assets/images/logo.png"),
  // reg: require("../src/assets/images/registration-image.png"),
  // regIcon: require("../src/assets/images/registration-icon.png"),
  // close: require("../src/assets/images/close-icon.png"),
  // checkIcon: require("../src/assets/images/check-icon.png"),
  // signUp: require("../src/assets/images/signup-main-bg.png"),
  // siteIcon: require("../src/assets/images/site-icon.png"),
  // notification: require("../src/assets/images/notification.png"),
  // user: require("../src/assets/images/user.png"),
  // toggle: require("../src/assets/images/toggle-icon.png"),
  // innerLogo: require("../src/assets/images/inner-logo.png"),
  // combined: require("../src/assets/images/combined-icon.png"),
  // TransactionIcon2: require("../src/assets/images/transaction-icon2.png"),
  // TransactionType2: require("../src/assets/images/transaction-type2.png"),
  // TransactionType4: require("../src/assets/images/transaction-type4.png"),

  // TransactionIcon1: require("../src/assets/images/transaction-icon1.png"),
  // TransactionType1: require("../src/assets/images/transaction-type1.png"),
  // TransactionType3: require("../src/assets/images/transaction-type3.png"),
  // graph: require("../src/assets/images/graph.png"),

  // vide: require("../src/assets/images/vide.png"),
  // video_preview2: require("../src/assets/images/video_preview2.png"),
  // video_preview3: require("../src/assets/images/video_preview3.png"),

  // field_icon2: require("../src/assets/images/field-icon2.png"),
  // field_icon1: require("../src/assets/images/field-icon1.png"),

  // plus_icon: require("../src/assets/images/plus-icon.png"),
  card_logo2: require("../src/assets/images/card-logo2.png"),
  card_logo1: require("../src/assets/images/card-logo1.png"),
  // number_company2: require("../src/assets/images/number-company2.png"),
  // number_company1: require("../src/assets/images/number-company1.png"),
  // Checkmark: require("../src/assets/images/Checkmark.png"),
  // Mastercard: require("../src/assets/images/Mastercard.png"),
  // deleteImg: require("../src/assets/images/Delete.png"),
};
