import { Button, Checkbox, Grid, List } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import DialogWrapper from "./DialogWrapper";
import MobileNumberItem from "./mobileNumberItem";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function EditMobileNumber(props) {
  const classes = useStyles();

  const {
    error,
    handleSubmit,
    handleRemove,
    handleClose,
    phone,
    phoneList,
  } = props;
  const [phoneDetails, editPhoneDetails] = useState({
    isPrimary: phone.isPrimary,
  });

  const [customError, setCustomError] = useState(false);

  const handleChange = (prop) => (event) => {
    editPhoneDetails({ ...phoneDetails, [prop]: event.target.checked });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const primaryPhone = phoneList.find((phone) => phone.isPrimary === true);
    if (primaryPhone && !phone.isPrimary) {
      handleSubmit({
        ...phone,
        isPrimary: phoneDetails.isPrimary,
      });
    } else {
      setCustomError(
        "Cannot unset primary mobile number. Please set another mobile number as primary first."
      );
    }
  };

  function onClose() {
    editPhoneDetails({
      isPrimary: phone.isPrimary,
    });
    handleClose();
  }

  function onRemove() {
    if (phone.isPrimary) {
      setCustomError(
        "Cannot remove primary mobile number. Please set another mobile number as primary first."
      );
    } else {
      handleRemove();
    }
  }

  return (
    <DialogWrapper {...props} handleClose={onClose}>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12}>
            <List>
              <MobileNumberItem phone={phone} />
            </List>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={phoneDetails.isPrimary}
                  onChange={handleChange("isPrimary")}
                />
              }
              label="Primary Mobile Number"
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error"> {error} </Alert>
            </Grid>
          )}
          {customError && (
            <Grid item xs={12}>
              <Alert severity="error"> {customError} </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              onClick={onRemove}
              fullWidth
              color="secondary"
              variant="outlined"
            >
              Remove Number
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </DialogWrapper>
  );
}
