import {
  Button,
  FormGroup,
  TextField,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { API_ROOT } from "constants/actionTypes";
import { postAsync } from "components/public-dashboard/services/api_util";
import { setAnonTokenApi } from "components/public-dashboard/services/utility";
import { showSnackBar } from "reducers/uiGlobal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputAdornment from "@material-ui/core/InputAdornment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ContentForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    error: "",
    type: "error"
  });
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { name, email, message, error } = form;

  const handleChange = (prop) => (e) => {
    let value = e.target.value;
    setForm({ ...form, [prop]: value });
  };

  const verification = () => {
    if (form.name === "") {
      const error = "Name cannot be empty";
      setForm({ ...form, error: error });
      return false;
    } else if (/[^a-zA-Z-'\s]/.test(form.name)) {
      const error = "Name cannot include special symbols or numbers";
      setForm({ ...form, error: error });
      return false;
    }

    if (form.email === "") {
      const error = "Email cannot be empty";
      setForm({ ...form, error: error });
      return false;
    }

    if (form.message === "") {
      const error = "Message cannot be empty";
      setForm({ ...form, error: error });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (verification()) {
      await setAnonTokenApi();
      const response = await postAsync(
        {
          subject: `New contact request from ${form.name}`,
          message: form.message,
          email: form.email,
          mobile: form.mobile,
        },
        `${API_ROOT}public/SendContactEmail`
      );
      if (response.state === 1) {
        //clear state
        setForm({
          name: "",
          email: "",
          message: "",
          mobile: "",
          error: "Message sent. Thank you for contacting YOOZ Support.",
          type: "success"
        });
      } else {
        setForm({
          error: "Failed to send message. Please try again later.",
          type: "error"
        });
      }

    }
  };

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <FormGroup row>
          <TextField
            color="secondary"
            required
            value={name}
            type="text"
            onChange={handleChange("name")}
            className={classes.inputField}
            fullWidth
            label="NAME"
            variant="outlined"
            size={xs ? "small" : "medium"}
            inputProps={{
              maxLength: 50,
            }}
            onInput={(e) => {
              e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
            }}
          />
          <TextField
            color="secondary"
            required
            value={email}
            type="email"
            onChange={handleChange("email")}
            className={classes.inputField}
            fullWidth
            label="EMAIL"
            variant="outlined"
            size={xs ? "small" : "medium"}
          />
          <TextField
            color="secondary"
            value={form.mobile}
            type="tel"
            onChange={handleChange("mobile")}
            className={classes.inputField}
            fullWidth
            label="MOBILE NUMBER"
            variant="outlined"
            size={xs ? "small" : "medium"}
            inputProps={{
              maxLength: 15,
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          />
          <TextField
            color="secondary"
            required
            value={message}
            type="text"
            onChange={handleChange("message")}
            className={classes.inputField}
            fullWidth
            label="MESSAGE"
            variant="outlined"
            size={xs ? "small" : "medium"}
            multiline
            rowsMax={4}
          />
          <Grid item xs={12}>
            {error.length > 0 && (
              <div
                style={{
                  marginBottom: 25,
                }}
              >
                <Alert severity={form.type}> {error} </Alert>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </FormGroup>
      </form>
    </Fragment>
  );
}
