import React, { useState } from "react";
import { TextField, Typography, useTheme, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FFF",
  },
});

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function DialogWrapper(props) {
  const {
    values,
    open = false,
    handleSubmit,
    error,
    handleClose,
    title,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [user, setUser] = useState({
    firstName: values.firstName,
    lastName: values.lastName,
  });

  const handleChange = (prop) => (e) => {
    let value = e.target.value;
    setUser({ ...user, [prop]: value });
  };

  function onSubmit(e) {
    e.preventDefault();
    handleSubmit(user.firstName, user.lastName);
  }

  function onClose() {
    setUser({
      firstName: values.firstName,
      lastName: values.lastName,
    });
    handleClose();
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        style={{ background: theme.palette.primary.main, color: "#FFF" }}
        onClose={onClose}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <form onSubmit={onSubmit} className={classes.form}>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={user.firstName}
                label="First Name"
                onChange={handleChange("firstName")}
                fullWidth
                variant="outlined"
                color="secondary"
                required
                inputProps={{
                  maxLength: 50,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={user.lastName}
                label="Last Name"
                onChange={handleChange("lastName")}
                fullWidth
                variant="outlined"
                color="secondary"
                required
                inputProps={{
                  maxLength: 50,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
                }}
              />
            </Grid>
            {error.length > 0 && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button fullWidth onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      {/* <DialogActions>
        </DialogActions> */}
    </Dialog>
  );
}
