import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#(###)###-####"
    />
  );
}

export default function PhoneNumber(props) {
  const classes = useStyles();

  const { value, handleChange, error="", disabled=false } = props;
  const handleFocus = (event) => event.target.select();

  return (
    <div className={classes.root}>
      <TextField
        id="phoneNumber"
        value={value}
        label="Phone Number *"
        onFocus={handleFocus}
        onChange={handleChange("phoneNumber")}
        fullWidth
        variant="outlined"
        error={error.length > 0}
        helperText={error.length > 0 ? error : ""}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        className={classes.formControl}
        color="secondary"
        disabled={disabled}
      />
    </div>
  );
}
