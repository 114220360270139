import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "middleware/storage";

export default function ProtectedRoute(props) {
  const { component } = props;
  const token = getToken();
  return token ? (
    <div>
      <Route component={component} />
    </div>
  ) : (
    <Redirect to="/login"></Redirect>
  );
}
