import { useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import download from "downloadjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { API_ROOT } from "constants/actionTypes";
import { getToken } from "middleware/storage";
import { showSnackBar } from "reducers/uiGlobal";
import { setNavIndex } from "components/secured-app/uiSlice";
import CircularProgressWithLabel from "components/shared/ui/circularLoader";
import SomethingWentWrong from "components/shared/ui/somethingWentWrong";
import { getBlob } from "components/public-dashboard/services/api_util";
import { setTabPosition } from "components/public-dashboard/uiSlice";

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100%",
    height: "80vh",
  },

  downloadBtn: {
    borderRadius: 20,
    marginTop: 16,
  },
  loader: {
    margin: "auto",
    display: "flex",
    outline: 0,
    position: "relative",
    justifyContent: "center",
  },
}));

export default function TxnResult(props) {
  const { facResponse, service} = props;
  const { state, data } = facResponse;
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("xs"));
  const token = getToken();
  const dispatch = useDispatch();

  async function downloadReceipt(orderId) {
    const response = await getBlob(
      `${API_ROOT}Transaction/DownloadReceipt?orderId=${orderId}`
    );

    if (response.state === 1 && response.data.type === "application/pdf") {
      download(response.data, `${service}_Receipt_${data.orderId}.pdf`);
    } else {
      dispatch(
        showSnackBar({
          msg: "Error downloading file. Please try again later",
          type: "error",
          autoHideDuration: 10000,
        })
      );
    }
  }

  function hideLoader() {
    setLoading(false);
  }

  function handleDownloadMessage(event) {
    if (event.origin !== API_ROOT.slice(0, -1)) return;
    if (event.data.type === 'download') {
      downloadReceipt(event.data.orderId);
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleDownloadMessage);

    return () => {
      window.removeEventListener('message', handleDownloadMessage);
    };
  }, []);

  if (state === "success") {
    return (
      <Fragment>
        {loading && (
          <div className={classes.loader}>
            <CircularProgressWithLabel text="Processing Your Request" />
          </div>
        )}
        <iframe
          src={`data:text/html;charset=utf-8,${escape(data.htmlForm)}`}
          title="fac_form"
          id="fac_form"
          name="fac_form"
          sandbox="allow-forms allow-scripts allow-same-origin"
          className={classes.iframe}
          frameBorder="0"
          onLoad={() => hideLoader()}
        />
      </Fragment>
    );
  }

  return (
    <SomethingWentWrong
      redirect={token ? "/user/topup" : "/quicktopup"}
      redirect2={token ? "/user/contactus" : "/contact"}
      redirectText="Try Again"
      redirectText2="Contact Us"
      onClick1={() => window.location.reload()}
      onClick2={
        token
          ? () => dispatch(setNavIndex(6))
          : () => dispatch(setTabPosition(6))
      }
    />
  );
}
