import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import howToScan from "assets/videos/YOOZ-HOW-TO-SCAN-A-QRCODE.mp4";
import scan2payAnimated from "assets/videos/YOOZ-SCAN2PAY-animated.mp4";
import ContentList from "components/public-dashboard/components/contentList";
import IconHeader from "components/public-dashboard/components/iconHeader";
import {API_ROOT} from "../../../../constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  videoContainer: {
    paddingLeft: 50,
  },
  yoozBlueLink: {
    color: "#00AFE2",
  },
  inlineItems: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    flexWrap: "wrap",
  },
}));

export default function Scan2PayContent() {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const videoList = [`${API_ROOT}document/yo-scan2pay-vid`, `${API_ROOT}document/yo-how2scan-vid`];
  const loadVideos = (list) => {
    return list.map((video, index) => (
      <video
        autoPlay={false}
        controls={true}
        width={xs ? "100%" : "500px"}
        height={xs ? "100%" : "500px"}
        key={index}
      >
        <source src={video} type="video/mp4" />
      </video>
    ));
  };
  return (
    <Container className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <IconHeader
            title="Scan... Pay... Go!"
            titleColor="#C60B17"
            titleLg="h2"
            titleSm="h3"
            titleXs="h4"
            icon={false}
          />
        </Grid>
        <Grid item>
          <ContentList
            items={[
              <div className={classes.inlineItems}>
                {loadVideos(videoList)}
              </div>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
