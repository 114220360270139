import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import withWidth from "@material-ui/core/withWidth";

function CustomChart(props) {
  const { monthlystats, width } = props;
  const [key, setkey] = useState(false);

  useEffect(() => {
    setkey((prevKey) => !prevKey);
  }, [width]);

  return (
    <Chart
      key={key}
      height={"300px"}
      width={"99%"}
      chartType="Bar"
      loader={<div>Loading Chart</div>}
      data={monthlystats}
      options={{
        chart: {
          title: "Last 6 Month Stats",
        },
      }}
    />
  );
}

export default withWidth()(CustomChart);
