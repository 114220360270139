import React, { PureComponent } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  getUserDetails,
  otpVarification,
  sendOtp,
  sendPwdResetLink,
  userLogin,
} from "actions/auth";
import Login from "components/public-dashboard/features/login";
import { setLoading, showSnackBar } from "reducers/uiGlobal";
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_SUCCESS,
  PWDLINK_SENT_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_SUCCESS,
} from "constants/actionTypes";
import history from "history/history";
import {
  removeUserAuth,
  removeUserDetail,
  setToken,
} from "middleware/storage";
import auth from "routing/checkAuth";
import {checkEmailRegex} from "components/utils";
class Auth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobNum: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPwd: "",
      countryId: 0,
      isChecked: false,
      isVisible: false,
      otp: "",
      username: "",
      userPwd: "",
      emailForReset: "",
      isForgot: false,
      error: "",
      loginError: "",
      otpError: "",
      emailError: "",
      loading: false,
    };
    this.inputRefs = Array(6).fill(React.createRef());
    this.inputOtp = Array(6).fill(React.createRef());
    this.props.dispatch(getCountryList()).then((res) => {});
  }

  updateLoading = (loading) => {
    this.props.dispatch(setLoading(loading));
  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  validateLoginForm = () => {
    const { userPwd, username } = this.state;
    if (!username.length) {
      this.setState({ loginError: "*Username can not be empty." });
      return false;
    }
    if (!this.checkEmail(username)) {
      this.setState({ loginError: "Invalid email.Please enter a valid email" });
      return false;
    }
    if (!userPwd.length) {
      this.setState({ loginError: "Password can not be empty." });
      return false;
    }
    return true;
  };

  validateResetEmail = () => {
    const { emailForReset } = this.state;
    if (!emailForReset.length) {
      this.setState({ emailError: "Email can not be empty." });
      return false;
    }
    if (!this.checkEmail(emailForReset)) {
      this.setState({
        emailError: "Invalid email. Please enter a valid email.",
      });
      return false;
    }
    return true;
  };

  checkEmail = (email) => {
    let result = checkEmailRegex(email);
    return result;
  };

  checkPhone = (email) => {
    let regx = /^[+]?\d+$/;
    let result = regx.test(email) === true ? true : false;
    return result;
  };
  validateSignUpForm = () => {
    const {
      mobNum,
      firstName,
      lastName,
      email,
      password,
      confirmPwd,
      countryId,
      isChecked,
    } = this.state;
    if (mobNum.length > 13) {
      this.setState({ error: "Mobile Number should be greater then 12 digit" });
      return false;
    }
    if (!mobNum.length) {
      this.setState({ error: "Mobile Number can not be empty" });
      return false;
    }
    if (!firstName.length) {
      this.setState({ error: "Firstname can not be empty" });
      return false;
    }
    if (!lastName.length) {
      this.setState({ error: "Lastname can not be empty" });
      return false;
    }
    if (!email.length) {
      this.setState({ error: "Email can not  be empty" });
      return false;
    }
    if (!this.checkEmail(email)) {
      this.setState({ error: "Invalid email.Please enter a valid email" });
      return false;
    }
    if (!password.length) {
      this.setState({ error: "Password can not be empty" });
      return false;
    } else if (password.length < 8 || password.length > 12) {
      this.setState({ error: "Password length should be 8 to 12" });
      return false;
    }

    if (!confirmPwd.length) {
      this.setState({ error: "Please confirm the password" });
      return false;
    }
    if (password !== confirmPwd) {
      this.setState({ error: "Password is not matched.Please confirm again" });
      return false;
    }
    if (!isChecked) {
      this.setState({ error: "Please agree with terms and conditions" });
      return false;
    }
    if (countryId <= 0) {
      this.setState({ error: "Please select country" });
      return false;
    }
    return true;
  };

  setMobileOtp = (digit) => {
    this.setState({ otp: digit });
  };

  onOtpVarify = () => {
    const { otp } = this.state;
    const { smsCred } = this.props;
    if (otp.length === 6) {
      const cred = {
        phone: smsCred.phone,
        tempVerificationCodeId: smsCred.tempVerificationCodeId,
        sMSCode: otp,
      };

      removeUserAuth();
      removeUserDetail();
      this.updateLoading(true);
      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          this.setState({ otpError: "" });
          this.login();
        }

        if (res.type === OTP_VARIFY_FAILURE) {
          this.updateLoading(false);
          this.setState({ otpError: res.error });
        }
      });
    } else {
      this.updateLoading(false);
      this.setState({ otpError: "Please complete the OTP" });
    }
  };

  login = () => {
    const { tokens } = this.props;

    setToken(tokens.token);

    auth.giveAuthAccess();

    this.props.dispatch(getUserDetails(tokens.token)).then((res) => {
      this.updateLoading(false);
      history.push("/user", { isAuth: true });
    });
  };

  onLogin = (e) => {
    this.setState({ otpError: "" });

    e.preventDefault();
    this.setState({ loginError: "" });
    if (this.validateLoginForm()) {
      removeUserAuth();
      removeUserDetail();
      const { username, userPwd } = this.state;
      const user = {
        username: username,
        password: userPwd,
      };
      this.updateLoading(true);
      this.props.dispatch(userLogin(user)).then((res) => {
        const { tokens } = this.props;
        if (res.type === LOGIN_SUCCESS && tokens) {
          this.setState({ loginError: "" });
          let cred = { phone: tokens.phoneNumber, isVerify: true };
          this.props.dispatch(sendOtp(cred)).then((res) => {
            if (res.type === RESEND_OTP_SUCCESS) {
              this.updateLoading(false);
              if (res.entities.user.smsCred.twoFactor) {
                this.setState({ mobNum: tokens.phoneNumber });
                this.setState({ isVerify: true });
                this.setPopupVisibility();
              } else {
                this.login();
              }
            }
            if (res.type === RESEND_OTP_FAILURE) {
              this.updateLoading(false);
              this.setState({ loginError: res.error });
            }
          });
        }

        if (res.type === LOGIN_FAILURE) {
          this.updateLoading(false);
          this.setState({ loginError: res.error });
        }
      });
    }
  };

  resendOtp = () => {
    this.setState({ otp: "" });
    this.setState({ otpError: "" });

    const { mobNum, email, isVerify } = this.state;
    let isVerifyed = false;
    if (isVerify) {
      if (isVerify === true) {
        isVerifyed = true;
      } else {
        isVerifyed = false;
      }
    }
    let cred = { phone: mobNum, email: email, isVerify: isVerifyed };
    this.updateLoading(true);
    this.props.dispatch(sendOtp(cred)).then((res) => {
      if (res.type === RESEND_OTP_SUCCESS) {
        this.updateLoading(false);
        this.props.dispatch(
          showSnackBar({
            msg: "A SMS verification code has been sent to: " + mobNum,
            type: "info",
            autoHideDuration: 10000,
          })
        );
      }
      if (res.type === RESEND_OTP_FAILURE) {
        this.updateLoading(false);
        this.setState({ otpError: res.error });
      }
    });
  };

  setPopupVisibility = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible, otp: "", otpError: "" });
  };

  setForgotPwdisibility = () => {
    const { isForgot } = this.state;
    this.setState({ isForgot: !isForgot });
  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  sendResetLink = () => {
    const { emailForReset } = this.state;
    if (this.validateResetEmail()) {
      this.props.dispatch(sendPwdResetLink(emailForReset)).then((res) => {
        if (res.type === PWDLINK_SENT_SUCCESS) {
          this.setState({ emailError: "" });
          this.setForgotPwdisibility();
          this.props.dispatch(
            showSnackBar({
              msg: "Password reset link has been sent to: " + emailForReset,
              type: "info",
              autoHideDuration: 30000,
            })
          );
        }
      });
    }
  };

  setLoginOtpVarify = () => {
    const { isLoginOtpVarifyVisible } = this.state;
    this.setState({
      isLoginOtpVarifyVisible: !isLoginOtpVarifyVisible,
      otp: "",
    });
  };

  onLoginOtpVarify = () => {
    const { otp } = this.state;
    const { tokens, smsCred } = this.props;
    if (otp.replace(/ /g, "").length === 6) {
      const cred = {
        phone: smsCred.phone,
        tempVerificationCodeId: smsCred.tempVerificationCodeId,
        sMSCode: otp,
      };

      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          this.setState({ otpError: "" });

          setToken(tokens.token);

          auth.giveAuthAccess();
          this.props.dispatch(getUserDetails(tokens.token)).then((res) => {
            history.push("/user", { isAuth: true });
          });
        }

        if (res.type === OTP_VARIFY_FAILURE) {
          this.setState({ otpError: res.error });
        }
      });
    }
  };

  render() {
    return (
      <Login
        {...this.props}
        {...this.state}
        onOtpVarify={this.onOtpVarify}
        resendOtp={this.resendOtp}
        setMobileOtp={this.setMobileOtp}
        onChangeFormField={this.onChangeFormField}
        setVisibility={this.setPopupVisibility}
        inputOtp={this.inputOtp}
        setForgotPwdisibility={this.setForgotPwdisibility}
        onLogin={this.onLogin}
        sendResetLink={this.sendResetLink}
        onClickMobileMenu={this.onClickMobileMenu}
        setLoginOtpVarify={this.setLoginOtpVarify}
        onLoginOtpVarify={this.onLoginOtpVarify}
      />
    );
  }
}

function mapStateToProps(state) {
  const { authReducer, entities } = state;
  return {
    authReducer,
    tokens: entities.user.tokens,
    smsCred: entities.user.smsCred,
    details: entities.user.details,
    countries: entities.user.countries,
    validString: entities.user.queryString,
  };
}

export default connect(mapStateToProps)(Auth);
