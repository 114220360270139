import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#(###) ### ####"
      mask="_"
    />
  );
}

export default function MaskedNumber(props) {
  const classes = useStyles();

  const { value, handleChange, isRegDisable } = props;
  return (
    <TextField
      color="secondary"
      required
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      value={value}
      disabled={isRegDisable}
      onChange={(e) => handleChange("mobNum", e.target.value)}
      className={classes.inputField}
      fullWidth
      label="MOBILE NUMBER"
      variant="outlined"
      id="mobile"
    />
  );
}
