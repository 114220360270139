import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  hyperlink: {
    color: theme.palette.secondary.main,
    "&:visited": {
      color: theme.palette.secondary.main,
    },
    "&:link": {
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function Hyperlink({ children, target, href, disabled }) {
  const classes = useStyles();

  return (
    <a
      disabled={disabled}
      target={target}
      href={href}
      className={classes.hyperlink}
    >
      {children}
    </a>
  );
}
