import { useMediaQuery } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
}));

export default function ItemtList(props) {
  const classes = useStyles();
  const { items } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div className={classes.root}>
      <List
        style={{
          color: theme.palette.secondary.main,
          marginLeft: xs
            ? 0
            : sm
            ? theme.spacing(1)
            : md
            ? theme.spacing(2)
            : theme.spacing(5),
          marginBottom: xs
            ? 0
            : sm
            ? theme.spacing(4)
            : md
            ? theme.spacing(5)
            : theme.spacing(6),
        }}
      >
        {items.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    </div>
  );
}
