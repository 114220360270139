import { FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { default as React, useState } from "react";
import NumberFormat from "react-number-format";
import CostBreakdown from "./amount_fee_breakdown";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
    position: "relative",
    minWidth: 150,
  },
  media: {
    height: 140,
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator
      isNumericString
    />
  );
}

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: theme.palette.secondary.main,
    },
  },
}))(TextField);

export default function AmountPresetSpecify(props) {
  const {
    preset,
    selected,
    handleChange,
    minAmount = 50,
    maxAmount = 500,
    toggleVat,
    handleValidateNext,
    invalidateStep,
    coverVat,
    vat,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [amount, setAmount] = useState(preset.id === 0 ? preset.amount : 0);
  const [error, setError] = useState("");
  const amountBV = amount / (1 + vat);
  const amountAV = amount * (1 + vat);
  const [checked, setChecked] = useState(coverVat);
  const handleFocus = (event) =>{
    event.target.select();
    toggleVat(checked);
  };

  const [updated, setUpdated] = useState(0);
  const handleCoverVat = (event) => {
    setChecked(event.target.checked);
    setUpdated(updated + 1);
    if (selected === 0) {
      toggleVat(event.target.checked);
    }
  };

  const handleChangeAmount = (e) => {
    const amount = e.target.value;
    if (parseFloat(amount) > 0) {
      setAmount(parseFloat(amount));
      if (amount < minAmount) {
        setError("Minimum Top Up Amount is $" + minAmount.toFixed(2));
        handleChange({ amount: 0, id: "" });
        invalidateStep();
      } else if (amount > maxAmount) {
        setError("Maximum Top Up Amount is $" + maxAmount.toFixed(2));
        handleChange({ amount: 0, id: "" });
        invalidateStep();
      } else {
        handleChange({ amount: parseFloat(amount), id: 0 });
        handleValidateNext();
        setError("");
      }
    } else {
      setAmount(parseFloat(0));
      handleChange({ amount: 0, id: "" });
      invalidateStep();
    }
    setUpdated(updated + 1);
  };

  return (
    <Card
      className={classes.root}
      style={{
        border:
          selected === 0 ? `2px solid ${theme.palette.secondary.main}` : "",
      }}
    >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" color="secondary">
            Custom Amount:{" "}
            <CssTextField
              id="customAmount"
              value={amount}
              onChange={handleChangeAmount}
              placeholder="Custom Amount"
              onFocus={handleFocus}
              error={error.length > 0}
              helperText={
                error.length > 0
                  ? error
                  : `The minimum topup amount is $${minAmount.toFixed(2)}`
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">TTD</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
              }}
            />
          </Typography>
          {checked  || (selected === 0 && coverVat) ? (
            <CostBreakdown
              amount={amount}
              total={amountAV}
              updated={updated}
              vat={vat}
            />
          ) : (
            <CostBreakdown
              amount={amountBV}
              total={amount}
              updated={updated}
              vat={vat}
            />
          )}
        </CardContent>
        <Radio
          color="primary"
          checked={selected === 0}
          style={{ position: "absolute", top: 6, right: 6 }}
        />
      </CardActionArea>
      <CardActions>
        <FormControlLabel
          control={
            <Switch
              checked={selected === 0 && coverVat}
              onChange={handleCoverVat}
              color="secondary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Cover VAT"
        />
      </CardActions>
    </Card>
  );
}
