import { Record } from "immutable";
import {
  COUNTRYLIST_FAILURE,
  COUNTRYLIST_REQUEST,
  COUNTRYLIST_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_REQUEST,
  OTP_VARIFY_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PWDLINK_SENT_FAILURE,
  PWDLINK_SENT_REQUEST,
  PWDLINK_SENT_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "constants/actionTypes";

const InitialState = Record({
  isSubmitting: false,
  isAuthorize: false,
  isResent: false,
  isVarifying: false,
  isLinkSent: false,
  isResetPassword: false,
  isFetchingCountry: false,
});

const initialState = new InitialState();

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return state.setIn(["isSubmitting"], true).setIn(["error"], null);
    case SIGNUP_SUCCESS:
      return state.setIn(["isSubmitting"], false).setIn(["error"], null);
    case SIGNUP_FAILURE:
      return state
        .setIn(["isSubmitting"], false)
        .setIn(["error"], action.error);

    case LOGIN_REQUEST:
      return state.setIn(["isAuthorize"], true).setIn(["error"], null);
    case LOGIN_SUCCESS:
      return state.setIn(["isAuthorize"], false).setIn(["error"], null);
    case LOGIN_FAILURE:
      return state.setIn(["isAuthorize"], false).setIn(["error"], action.error);
    case PASSWORD_RESET_REQUEST:
      return state.setIn(["isResetPassword"], true).setIn(["error"], null);
    case PASSWORD_RESET_SUCCESS:
      return state.setIn(["isResetPassword"], false).setIn(["error"], null);
    case PASSWORD_RESET_FAILURE:
      return state
        .setIn(["isResetPassword"], false)
        .setIn(["error"], action.error);
    case OTP_VARIFY_REQUEST:
      return state.setIn(["isVarifying"], true).setIn(["error"], null);
    case OTP_VARIFY_SUCCESS:
      return state.setIn(["isVarifying"], false).setIn(["error"], null);
    case OTP_VARIFY_FAILURE:
      return state.setIn(["isVarifying"], false).setIn(["error"], action.error);

    case RESEND_OTP_REQUEST:
      return state.setIn(["isResent"], true).setIn(["error"], null);
    case RESEND_OTP_SUCCESS:
      return state.setIn(["isResent"], false).setIn(["error"], null);
    case RESEND_OTP_FAILURE:
      return state.setIn(["isResent"], false).setIn(["error"], action.error);

    case PWDLINK_SENT_REQUEST:
      return state.setIn(["isLinkSent"], true).setIn(["error"], null);
    case PWDLINK_SENT_SUCCESS:
      return state.setIn(["isLinkSent"], false).setIn(["error"], null);
    case PWDLINK_SENT_FAILURE:
      return state.setIn(["isLinkSent"], false).setIn(["error"], action.error);

    case COUNTRYLIST_REQUEST:
      return state.setIn(["isFetchingCountry"], true).setIn(["error"], null);
    case COUNTRYLIST_SUCCESS:
      return state.setIn(["isFetchingCountry"], false).setIn(["error"], null);
    case COUNTRYLIST_FAILURE:
      return state
        .setIn(["isFetchingCountry"], false)
        .setIn(["error"], action.error);
    default:
      return state;
  }
};

export default authReducer;
