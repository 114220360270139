import { Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import signUp_img from "assets/images/home_content_4.png";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import ContentList from "components/public-dashboard/components/contentList";
import TabbedTables from "components/public-dashboard/components/tabbedTables";
import { columns, comment, tables } from "./constants";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  yoozBlueLink: {
    color: "#00AFE2",
  },
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  },
}));

export default function PartnersContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container className={classes.root}>
      <TabbedTables columns={columns} tables={tables} comment={comment} />
      <h4 style={{ textAlign: "center" }}>{comment}</h4>
      <ContentImageBlock
        title="READY TO GET STARTED?"
        titleSm="h4"
        titleLg="h3"
        titleXs="h6"
        imgPosition="left"
        img={signUp_img}
        color="#1CC5FF"
        list={
          <ContentList
            items={[
              <span>
                Call <b>+1 (868) 235-6182</b> to speak to one of our representatives or email{" "}
                <a
                  href="mailto: servicett@yoozit.today "
                  className={classes.yoozBlueLink}
              >
                  servicett@yoozit.today
                </a> along with your Onboarding documents.
              </span>,
            ]}
          />
        }
      />
    </Container>
  );
}
