import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";
import LoaderButton from "components/public-dashboard/components/loaderButton";
import SomethingWentWrong from "components/shared/ui/somethingWentWrong";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  backButton: {
    marginRight: theme.spacing(1),
    borderRadius: 20,
  },
  finishButton: {
    float:"right",
    marginLeft: theme.spacing(1),
    borderRadius: 20,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionBtn: {
    borderRadius: 20,
  },
  actionsContainer: {
    float: "right",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetBtn: {
    float: "right",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: 20,
  },
}));

export default function BillPayMobileStepper(props) {
  const {
    steps,
    activeStep,
    getStepContent,
    handleNext,
    handleBack,
    handleFinish,
    loading,
    facResponse,
    token,
    dispatch,
    setNavIndex,
    setTabPosition,
    stepsValidated
  } = props;
  const classes = useStyles();

  const renderFacError = () => {
    if (facResponse.msg.length > 0) {
      return <Alert severity="error"> {facResponse.msg} </Alert>

    }
    return (
      <SomethingWentWrong
        redirect={token ? "/user/topup" : "/quicktopup"}
        redirect2={token ? "/user/contactus" : "/contact"}
        redirectText="Try Again"
        redirectText2="Contact Us"
        onClick1={() => window.location.reload()}
        onClick2={
          token
            ? () => dispatch(setNavIndex(6))
            : () => dispatch(setTabPosition(6))
        }
      />
    )
  }
  return (
    <Fragment>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.root}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel optional={step.value}>{step.label}</StepLabel>
            <StepContent>
              <div>{getStepContent(index)}</div>
              <div style={{marginTop: 20}}>{facResponse.state === "failed" && index == steps.length -1 && renderFacError()}</div>
              <div className={classes.actionsContainer}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>

                {/*due to current float stles on loaderbuttons etc, had to place it inbetween back and next*/}
                {activeStep !== steps.length - 1 && stepsValidated &&
                    <Button
                        id="txnButton"
                        variant="contained"
                        color="primary"
                        onClick={()=> handleFinish(3)}
                        className={classes.finishButton}
                    >
                      FINISH
                    </Button>
                }

                {activeStep < steps.length - 1 &&
                  <LoaderButton
                    title="Next"
                    color={stepsValidated ? "default" : "primary"}
                    handleClick={handleNext}
                    loading={loading}
                  />
                }

              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Fragment>
  );
}
