import React from "react";

export default function YoozSvgIcon(props) {
  const { height, width, color, minWidth = 10, maxWidth = "100%" } = props;
  return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
           viewBox="0 0 432 180"
           width={width}
           height={height}
           style={{ paddingLeft: 5, paddingRight: 5, minWidth, maxWidth }}
      >

        <g >
          <g id="Layer_3">
            <g>
              <g>
                <g>
                  <path className="cls-2" fill={color}
                        d="M93.31,54.85c1.67,7,3.39,17.13,4.76,31.35,0,0,1.33,12,1.33,13.16h.33s17.33-28.33,17.33-34.33c0-.94-.46-2.17-.46-2.17-.71-1.54-1.09-3.33-1.09-5.14,0-6.74,5.47-12.2,12.2-12.2s12.21,5.47,12.21,12.2l-.04.98c-.72,20.3-30.79,59.88-47.88,80.59-11.2,13.34-24.35,27.49-40.09,27.83l-.74-.06c-5.29-.41-9.44-4.83-9.44-10.22,0-5.66,4.59-10.24,10.24-10.24,1.22,0,2.39.22,3.47.6l.41.17c.4.17.89.25,1.49.25,6.67,0,21.25-20.26,21.41-20.6l1-1.33-2.17-17.49c-1.83-14.5-6.33-39.99-8.5-44.65-1.48-2.81-.16-.25-.16-.25-.98-1.84-1.52-3.92-1.52-6.14,0-7.21,5.84-13.06,13.05-13.06,6.32,0,11.58,4.47,12.79,10.43l.07.34Z"/>
                  <path className="cls-2" fill={color}
                        d="M184.95,42.54c29.48,0,34.15,21.33,34.15,30.99,0,12.82-4.84,27.82-13.83,39.15-5.67,7-14.99,14.33-32.65,14.33-33.48,0-33.82-29.66-33.82-33.16,0-15.99,9-51.31,46.15-51.31ZM174.11,111.36c16.66,0,21.33-29.66,21.33-39,0-11.15-6.83-14.16-11.66-14.16-15.5,0-21.66,25.49-21.66,38.49,0,7.66,3.83,14.66,12,14.66Z"/>
                  <path className="cls-2" fill={color}
                        d="M267.06,42.54c29.49,0,34.16,21.33,34.16,30.99,0,12.82-4.83,27.82-13.83,39.15-5.66,7-14.99,14.33-32.65,14.33-33.49,0-33.82-29.66-33.82-33.16,0-15.99,9-51.31,46.15-51.31ZM256.23,111.36c16.66,0,21.33-29.66,21.33-39,0-11.15-6.83-14.16-11.66-14.16-15.5,0-21.66,25.49-21.66,38.49,0,7.66,3.83,14.66,11.99,14.66Z"/>
                  <path className="cls-2" fill={color}
                        d="M360.91,45.52l-.7.03h-9.26s1.32-5.62,1.32-5.62c.04-.16.12-.3.15-.46.04-.16.03-.32.06-.48l.06-.24h-.03c.77-4.97-2.36-9.79-7.34-10.95-4.98-1.18-9.93,1.74-11.43,6.54l-.07-.02-.14.63s-.03.05-.03.08c-.01.03-.01.06-.02.08l-2.43,10.43h-14.1s-1.32.02-1.32.02c-5.29,0-9.49,4.26-9.49,9.57s4.31,9.6,9.61,9.6c1.76,0,3.42-.47,4.83-1.3l.47-.29c1.27-.8,3.05-1.09,5.79-1.09h14.33l-45.32,45.62-.56.5c-1.72,1.71-2.77,4.07-2.77,6.68,0,5.21,4.22,9.43,9.43,9.43l2.25-.08h8.24l-1.22,5.22s-.03.06-.03.1c-.01.03-.01.07-.02.1l-.3,1.26.13.03c-.4,4.73,2.68,9.17,7.43,10.29,4.76,1.11,9.5-1.5,11.22-5.92l.1.03.21-.92c.04-.15.1-.28.14-.43.04-.15.04-.31.06-.45l2.19-9.31h16.74l.74.02c4.95-.34,8.88-4.46,8.88-9.51s-4.26-9.51-9.51-9.51c-2.3,0-4.41.82-6.06,2.18l-.39.14c-1.48.68-5.74.68-7.09.68h-14.82l46.69-45.89c1.4-1.47,1.97-3.2,1.97-3.2l.02-.06.05-.25c.4-1.07.61-2.22.61-3.43,0-5.34-3.96-9.72-9.27-9.85Z"/>
                </g>
                <rect className="cls-1" x="-.76" fill="none" width="433.16" height="209.65"/>
              </g>
              <path className="cls-2" fill={color}
                    d="M382.36,103.59c-6.73,0-12.21,5.48-12.21,12.21s5.48,12.21,12.21,12.21,12.21-5.48,12.21-12.21-5.48-12.21-12.21-12.21ZM380.54,113.31h-2.13v6.6h-2.07v-6.6h-2.14v-1.62h6.35v1.62ZM390.53,119.92h-1.98l.16-6.61-1.83,6.61h-1.78l-1.82-6.61.16,6.61h-2v-8.22h3l1.55,5.75,1.53-5.75h3v8.22Z"/>
            </g>
          </g>
        </g>
      </svg>
  );
}
