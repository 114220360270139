import icon1 from "assets/images/pg-icon1.png";
import icon2 from "assets/images/pg-icon2.png";
import icon3 from "assets/images/pg-icon3.png";
import icon4 from "assets/images/pg-icon4.png";
import icon5 from "assets/images/pg-icon5.png";

export const gatewayFeatures = [
  { text: "Accept payments on Checkout.", img: icon4 },
  {
    text: (
      <span>
        No processing or compliance requirements - <strong>YOOZ</strong> does
        the work for you!
      </span>
    ),
    img: icon3,
  },
  { text: "No need for IT or expensive e-commerce sites.", img: icon5 },
  {
    text: "Supports REST API Integration.",
    img: icon1,
  },
  {
    text: "Applies record of customer payments to your accounting system in real time.",
    img: icon2,
  },
];
