import { Container } from "@material-ui/core";
import React, { Fragment } from "react";
import contactbanner from "assets/images/contact_banner.jpg";
import { getToken } from "middleware/storage";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import ContactContent from "./content";
import ContactHeader from "./header";

export default function Contact() {
  const token = getToken();
  if (token) {
    return (
      <Container maxWidth="lg">
        <ContactHeader />
        <ContactContent />{" "}
      </Container>
    );
  }

  return (
    <Fragment>
      {!token && <PageBanner img={contactbanner} />}
      <ContentWrapper>
        <ContactHeader />
        <ContactContent />
      </ContentWrapper>
    </Fragment>
  );
}
