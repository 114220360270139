import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Hyperlink from "components/shared/hyperlink";
import { API_ROOT } from "constants/actionTypes";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function AgreementCheckboxes(props) {
  const classes = useStyles();
  const {
    showCCForm,
    applyDefaultStyles = true,
    value,
    handleChange,
    error = "",
  } = props;
  return (
    <div className={classes.root}>
      <FormControl
        required
        error={error.length > 0}
        component="fieldset"
        className={applyDefaultStyles ? classes.formControl : ""}
      >
        <FormLabel component="legend">I've read and agree with</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox id="tac" checked={value} onChange={handleChange()} />
            }
            label={
              <div>
                <Hyperlink
                  href={`${API_ROOT}document/yo-terms-and-conditions`}
                  target="_blank"
                >
                  <b>Terms & Conditions</b>
                </Hyperlink>{" "}
                and{" "}
                <Hyperlink
                  href={`${API_ROOT}document/yo-privacy-policy`}
                  target="_blank"
                >
                  <b>Privacy Policy</b>
                </Hyperlink>
              </div>
            }
          />
        </FormGroup>
        <FormHelperText id="TC-PP-helper-text">
          {error.length > 0 && error}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
