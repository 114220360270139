import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    float: "right",
    position: "relative",
  },
  actionBtn: {
    borderRadius: 20,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,

  },
}));

export default function LoaderButton(props) {
  const classes = useStyles();
  const { loading, title, handleClick, color ="primary" } = props;

  return (
    <div className={classes.wrapper}>
      <Button
        id="txnButton"
        variant="contained"
        color={color}
        className={classes.actionBtn}
        disabled={loading}
        onClick={handleClick}
      >
        {title}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
