import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getToken } from "middleware/storage";
import TxnReview from "components/shared/txn_review";
import { addValidatedStep, setSubmissionDetails } from "components/public-dashboard/features/topup/topupSlice";

const useStyles = makeStyles((theme) => ({
  root: { border: `1px solid ${theme.palette.secondary.main}` },
  header: {
    color: "#FFF",
    background: theme.palette.secondary.main,
    padding: 10,
    textAlign: "center",
  },
}));

function TxnConfirmation(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { activeStep } = useSelector((state) => state.topup);
  const [loggedIn, SetLogin] = useState(false);
  useEffect(() => {
    async function validateLogin() {
      const token = getToken();
      if (!token) {
        SetLogin(false);
      } else {
        SetLogin(true);
      }
    }

    validateLogin();
    dispatch(addValidatedStep(activeStep));
  }, []);

  return <TxnReview {...props} loggedIn={loggedIn} service="topup" />;
}
const mapStateToProps = (state) => ({
  ...state.topup,
  addressDetails: state.topup.addressDetails,
  countries: state.topup.countries,
});

const mapDispatch = { setSubmissionDetails };

export default connect(mapStateToProps, mapDispatch)(TxnConfirmation);
