import { ANONTOKEN, AUTH, TOKEN, USER } from "constants/actionTypes";

export const setUserAuth = (auth) => {
  removeUserAuth();
  sessionStorage.setItem(AUTH, JSON.stringify(auth));
};

export const getUserAuth = () => {
  const user = sessionStorage.getItem(AUTH);
  if (user) return JSON.parse(user);
  return "";
};

export const removeUserAuth = () => {
  sessionStorage.removeItem(AUTH);
};

export const setUserDetails = (details) => {
  removeUserDetail();
  localStorage.setItem(USER, JSON.stringify(details));
};

export const getUserDetail = () => {
  const user = localStorage.getItem(USER);

  if (user) {
    if (user !== "undefined") {
      return JSON.parse(user);
    }
  }
};
export const get_StoreUserDetails = () => {
  const user = localStorage.getItem(USER);

  if (user) {
    return JSON.parse(user);
  }
};
export const removeUserDetail = () => {
  localStorage.removeItem(USER);
};

export const setToken = (token) => {
  removeToken();
  sessionStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN);

  if (token) return token;
  return "";
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN);
};

export const setAnonToken = (token) => {
  sessionStorage.setItem(ANONTOKEN, token);
};

export const getAnonToken = () => {
  const token = sessionStorage.getItem(ANONTOKEN);
  if (token) return token;
  return "";
};

export const removeAnonToken = () => {
  sessionStorage.removeItem(ANONTOKEN);
};

export const clearAuth = () => {
  removeToken();
  removeUserDetail();
  removeUserAuth();
};

export const setWorkerReg = (workerReg) => {
  removeWorkerReg();
  console.log(JSON.stringify(workerReg))
  sessionStorage.setItem("WORKEREG", JSON.stringify(workerReg));
};

export const removeWorkerReg = () => {
  sessionStorage.removeItem("WORKEREG");
};

export const getWorkerReg = () => {
  const workerReg = sessionStorage.getItem("WORKEREG");
  console.log(JSON.parse(workerReg))
  if (workerReg) return JSON.parse(workerReg);
  return false;
};
