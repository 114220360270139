import { Record } from "immutable";
import {
  ACTIVITY_SEARCH_FAILURE,
  ACTIVITY_SEARCH_REQUEST,
  ACTIVITY_SEARCH_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  MONTHLY_STATS_FAILURE,
  MONTHLY_STATS_REQUEST,
  MONTHLY_STATS_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_REQUEST,
  OTP_VARIFY_SUCCESS,
  PROVIDER_LIST_FAILURE,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  COUNTRY_LIST_FAILURE,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_STATES_FAILURE,
  COUNTRY_STATES_REQUEST,
  COUNTRY_STATES_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  TRANSACTIONS_LIST_FAILURE,
  TRANSACTIONS_LIST_REQUEST,
  TRANSACTIONS_LIST_SUCCESS,
} from "constants/actionTypes";

const InitialState = Record({
  isFetchingProviders: false,
  isFetchingCountries: false,
  isFetchingCountryStates: false,
  isAuthorize: false,
  isResent: false,
  isVarifying: false,
  isSearching: false,
  isFetching: false,
});

const initialState = new InitialState();

const activityReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PROVIDER_LIST_REQUEST:
      return state.setIn(["isFetchingProviders"], true).setIn(["error"], null);
    case PROVIDER_LIST_SUCCESS:
      return state.setIn(["isFetchingProviders"], false).setIn(["error"], null);
    case PROVIDER_LIST_FAILURE:
      return state
        .setIn(["isFetchingProviders"], false)
        .setIn(["error"], action.error);

    case COUNTRY_LIST_REQUEST:
      return state.setIn(["isFetchingCountries"], true).setIn(["error"], null);
    case COUNTRY_LIST_SUCCESS:
      return state.setIn(["isFetchingCountries"], false).setIn(["error"], null);
    case COUNTRY_LIST_FAILURE:
      return state
        .setIn(["isFetchingCountries"], false)
        .setIn(["error"], action.error);

    case COUNTRY_STATES_REQUEST:
      return state.setIn(["isFetchingCountryStates"], true).setIn(["error"], null);
    case COUNTRY_STATES_SUCCESS:
      return state.setIn(["isFetchingCountryStates"], false).setIn(["error"], null);
    case COUNTRY_STATES_FAILURE:
      return state
        .setIn(["isFetchingCountryStates"], false)
        .setIn(["error"], action.error);

    case ACTIVITY_SEARCH_REQUEST:
      return state.setIn(["isSearching"], true).setIn(["error"], null);
    case ACTIVITY_SEARCH_SUCCESS:
      return state.setIn(["isSearching"], false).setIn(["error"], null);
    case ACTIVITY_SEARCH_FAILURE:
      return state.setIn(["isSearching"], false).setIn(["error"], action.error);

    case MONTHLY_STATS_REQUEST:
      return state.setIn(["isFetching"], true).setIn(["error"], null);
    case MONTHLY_STATS_SUCCESS:
      return state.setIn(["isFetching"], false).setIn(["error"], null);
    case MONTHLY_STATS_FAILURE:
      return state.setIn(["isFetching"], false).setIn(["error"], action.error);

    case TRANSACTIONS_LIST_REQUEST:
      return state.setIn(["isFetching"], true).setIn(["error"], null);
    case TRANSACTIONS_LIST_SUCCESS:
      return state.setIn(["isFetching"], false).setIn(["error"], null);
    case TRANSACTIONS_LIST_FAILURE:
      return state.setIn(["isFetching"], false).setIn(["error"], action.error);

    case LOGIN_REQUEST:
      return state.setIn(["isAuthorize"], true).setIn(["error"], null);
    case LOGIN_SUCCESS:
      return state.setIn(["isAuthorize"], false).setIn(["error"], null);
    case LOGIN_FAILURE:
      return state.setIn(["isAuthorize"], false).setIn(["error"], action.error);

    case OTP_VARIFY_REQUEST:
      return state.setIn(["isVarifying"], true).setIn(["error"], null);
    case OTP_VARIFY_SUCCESS:
      return state.setIn(["isVarifying"], false).setIn(["error"], null);
    case OTP_VARIFY_FAILURE:
      return state.setIn(["isVarifying"], false).setIn(["error"], action.error);

    case RESEND_OTP_REQUEST:
      return state.setIn(["isResent"], true).setIn(["error"], null);
    case RESEND_OTP_SUCCESS:
      return state.setIn(["isResent"], false).setIn(["error"], null);
    case RESEND_OTP_FAILURE:
      return state.setIn(["isResent"], false).setIn(["error"], action.error);
    default:
      return state;
  }
};

export default activityReducer;
