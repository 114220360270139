import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

const useStyles = makeStyles((theme) => ({
  AddationalIncomeText: {
    fontSize: 22,
    color: "#224E87",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "right",
  },
}));

export default function AgentHeader(props) {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container>
      <Grid container alignItems="center" justify="center" spacing={3}>
        <Grid item xs={6} md={5}>
          <strong
            style={{ fontSize: md ? 32 : sm ? 18 : 12 }}
            className={classes.AddationalIncomeText}
          >
            EARN ADDITIONAL <br />
            INCOME AS A
          </strong>
        </Grid>
        <Grid item xs={6} md={7}>
          <IconHeader
            title="Agent"
            iconColor="#C60B17"
            titleColor="#008bee"
            titleLg="h2"
            titleSm="h4"
            titleXs="h5"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
