import { useMediaQuery } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Divider from "@material-ui/core/Divider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      marginLeft: "5%",
      // marginBottom: theme.spacing(10),
    },
  },
  summary: {
    color: theme.palette.primary.main,
  },
}));

export default function YoozAccordion(props) {
  const { id, summary, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id + "-content"}
        id={id + "-header"}
      >
        <Typography variant={"body1"}>
          <span
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textTransform: "uppercase",
              color: theme.palette.secondary.main,

            }}
          >
            {summary}
          </span>
        </Typography>
      </AccordionSummary>
      <Divider variant="middle" />
      <AccordionDetails className={classes.body}>
        <Typography variant={"body2"}> {children} </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
