import {
  Typography,
  Paper,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  subheader: {
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
  },
  listItem: {
    padding: 0,
  },
}));

export default function BPTxnDetailListing(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { getProviderName, getCharityName } = props;
  const {
    providerId,
    charityId,
    accountNumber,
    amount,
    authCode,
    donationAmount,
    comment,
    phoneNumber,
    email,
  } = props.values;

  return (
    <Fragment>
      <List className={classes.root} dense>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Payee"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    <b>{getProviderName(providerId)}</b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Account Number"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    <b>{accountNumber}</b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        {comment !== "" && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Comments"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Typography className={classes.subheader}>
                      {comment}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}

        {phoneNumber !== "" && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Phone Number"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Typography className={classes.subheader}>
                      <NumberFormat
                        value={phoneNumber}
                        format="+#(###) ###-####"
                        displayType={"text"}
                      />
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}

        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Amount"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    <b>
                      <NumberFormat
                        value={amount}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix={"$"}
                        displayType="text"
                      />
                    </b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        {charityId !== "" && (
          <Fragment>
            <ListItem className={classes.listItem}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary="Charity"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary={
                      <Typography className={classes.subheader}>
                        {getCharityName(charityId)}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary="Donation Amount"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary={
                      <Typography className={classes.subheader}>
                        <NumberFormat
                          value={donationAmount || 0}
                          fixedDecimalScale
                          decimalScale={2}
                          thousandSeparator
                          isNumericString
                          prefix="$"
                          displayType="text"
                        />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Fragment>
        )}

        {authCode && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Authorization #"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Typography className={classes.subheader}>
                      {authCode}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {email && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Email"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Typography className={classes.subheader}>
                      {email}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
    </Fragment>
  );
}
