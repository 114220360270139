import { API_ROOT } from "constants/actionTypes";
import { setAnonToken } from "middleware/storage";
import { postAsync } from "./api_util";

export async function setAnonTokenApi() {
  const response = await postAsync(
    {
      username: "4b89cdae-f814-48c0-80e5-9691c62f0ef6",
      password:
        "CUN'r{​​​​​​​'4=L{​​​​​​​]QebRmGb>kVX_jrNa?jM7(fSGe?4Ydba$@$Pe",
    },
    `${API_ROOT}users/authenticateAPI `
  );

  if (response.state === 1) {
    setAnonToken(response.data.token);
  }
}
