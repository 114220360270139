import { Fade, IconButton, Popper } from "@material-ui/core";
import { amber } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
    maxWidth: "97%",
    wordBreak: "break-word",
    zIndex: 100,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###"
    />
  );
}

export default function MaskedCvv(props) {
  const classes = useStyles();
  const cvvHelper = "CVV is the three digit number located at the back of your card.";
  const { value, handleChange, error = "" } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    event.target.select();
    setAnchorEl(event.target);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

  };

  return (
    <div className={classes.root}>
      <TextField
        id="creditCardCVV"
        value={value}
        label="CVV *"
        onChange={handleChange("cvv")}
        fullWidth
        onFocus={handleClick("top-end")}
        onBlur={() => setOpen(false)}
        variant="outlined"
        error={error?.length > 0}
        helperText={error.length > 0 ? error : ""}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment:
            <IconButton color="secondary"
            >
              <InfoIcon style={{ color: amber[500] }} />
            </IconButton>

        }}
        className={classes.formControl}
        color="secondary"
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ zIndex: 214748364 }} // higher than mui dialog
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Alert severity="warning" className={classes.alert}>
              <AlertTitle>Note</AlertTitle>
              {ReactHtmlParser(cvvHelper)}
            </Alert>
          </Fade>
        )}
      </Popper>
    </div>
  );
}