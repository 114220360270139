import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "assets/images/home_carousel_1.jpg";
import img2 from "assets/images/home_carousel_2.jpg";
import img3 from "assets/images/home_carousel_3.jpg";
import img4 from "assets/images/home_carousel_4.jpg";
import img5 from "assets/images/home_carousel_5.png";
import img6 from "assets/images/home_carousel_6.jpg";
import img7 from "assets/images/home_carousel_7.png";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// function YoutubeSlide({ url, isSelected }) {
//   return <ReactPlayer width="100%" height="100%" url={url} playing={false} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  dotList: {
    bottom: 20,
  },
  img:{
    width:"100%"
  }
}));

export default function TestimonyCarousel(props) {
  const classes = useStyles();
  return (
    <Carousel
      minimumTouchDrag={50}
      swipeable
      draggable={false}
      showDots
      responsive={responsive}
      infinite
      autoPlay={props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={5000}
      keyBoardControl
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType={props.deviceType}
      className={classes.root}
      dotListClass={classes.dotList}
    >
     
      
      <div>
        <img src={img1} alt="carousel 1" className={classes.img} />
      </div>
      <div>
        <img src={img2} alt="carousel 2" className={classes.img} />
      </div>
      <div>
        <img src={img3} alt="carousel 3" className={classes.img} />
      </div>
      <div>
        <img src={img4} alt="carousel 4" className={classes.img} />
      </div>
      <div>
        <img src={img5} alt="carousel 5" className={classes.img} />
      </div>
      <div>
        <img src={img6} alt="carousel 6" className={classes.img} />
      </div>
      <div>
        <img src={img7} alt="carousel 7" className={classes.img} />
      </div>
      {/* <YoutubeSlide
        key="youtube-1"
        url="https://www.youtube.com/watch?v=eTTAGYriWqg&ab_channel=YOOZ."
      /> */}
    </Carousel>
  );
}
