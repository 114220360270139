import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import MobileNumberItem from "./mobileNumberItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MobileNumberList(props) {
  const classes = useStyles();
  const { phoneList } = props;

  return (
    <List className={classes.root}>
      {phoneList.map((phone) => {
        return <MobileNumberItem key={phone.customerPhoneId} phone={phone} {...props} />;
      })}
    </List>
  );
}
