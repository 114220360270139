import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import Table from "components/shared/table/table";


export default function BaseTable(props) {
  const { columns, data } = props;
  const theme = useTheme();
  return (
    <Fragment>
      <Table
          columns={columns}
          pagedItems={data}
          totalItems={data.length}
          headStyle={{ background: "#57B56B", color: "#fff" }}
          paginationEnabled={false}/>
    </Fragment>
  );
}
