import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { default as React, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { Divider, FormControlLabel } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import CostBreakdown from "./amount_fee_breakdown";
import { setCoverTax } from "components/public-dashboard/features/topup/topupSlice";

const useStyles = makeStyles({
  root: {
    position: "relative",
    minWidth: 150,
  },
  media: {
    height: 140,
  },
});

export default function AmountPreset(props) {
  const {
    preset,
    selected,
    handleChange,
    toggleVat,
    handleValidateNext,
    coverVat,
    vat,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const amount = preset.amount;
  const amountBV = amount / (1 + vat);
  const amountAV = amount * (1 + vat);

  const [checked, setChecked] = useState(false);
  const [updated, setUpdated] = useState(0);
  const handleCoverVat = (event) => {
    setChecked(event.target.checked);
    setUpdated(updated + 1);
    if (selected === preset.id) {
      toggleVat(event.target.checked);
    }
  };

  const onHandleChange = () => {
    toggleVat(checked);
    handleChange();
    handleValidateNext();
  };

  return (
    <Card
      className={classes.root}
      style={{
        border:
          selected === preset.id
            ? `2px solid ${theme.palette.secondary.main}`
            : "",
      }}
    >
      <CardActionArea onClick={onHandleChange}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            color="secondary"
          >
            ${checked || (selected === preset.id && coverVat) ? amountAV.toFixed(2) : amount.toFixed(2)} TTD
          </Typography>
          {checked || (selected === preset.id && coverVat) ? (
            <CostBreakdown amount={amount} total={amountAV} updated={updated} vat={vat}/>
          ) : (
            <CostBreakdown amount={amountBV} total={amount} updated={updated} vat={vat}/>
          )}
        </CardContent>
        <Radio
          color="primary"
          checked={selected === preset.id}
          style={{ position: "absolute", top: 6, right: 6 }}
        />
      </CardActionArea>
      <CardActions>
        <FormControlLabel
          control={
            <Switch
              checked={selected === preset.id && coverVat}
              onChange={handleCoverVat}
              color="secondary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Cover VAT"
        />
      </CardActions>
    </Card>
  );
}
