import {
  addNewNumber,
  addNewPaymentCard,
  deactivatePhone,
  editPaymentCard,
  getCardList,
  getPhoneList,
  primaryCard,
  primaryPhone,
  removePaymentCard,
  sendOtp,
  updateUserDetails,
} from "actions/accounts";
import { getUserDetails, otpVarification } from "actions/auth";
import { assets } from "assets";
import UserAccounts from "components/secured-app/features/account";
import { setIsLoading } from "components/secured-app/features/account/paymentMethods/pmslice";
import Loading from "components/shared/loader/loader";
import { isValidateExpDate, jwtExpired } from "components/utils";
import {
  DETAIL_EDIT_SUCCESS,
  NUMBER_ADD_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_SUCCESS,
  PRIMARY_CARD_SUCCESS,
  PRIMARY_PHONE_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_SUCCESS,
} from "constants/actionTypes";
import history from "history/history";
import {
  clearAuth,
  getToken,
  getUserDetail,
  get_StoreUserDetails,
} from "middleware/storage";
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { showSnackBar } from "reducers/uiGlobal";

const cleanAddressError = {
  name:"",
  expDate:"",
  cardNum:"",
  cvv:"",
  firstName:"",
  lastName:"",
  line1:"",
  country:"",
  countryCode:"",
  city:"",
  state:"",
  email:"",
  alert:""
}

class Accounts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cardNum: "",
      cvv: "",
      expDate: "",
      cardType: "",
      mobile: "",
      isprimary: false,
      newMobile: "",
      phoneCode: "",
      otp: "",
      user: null,
      editUser: null,
      isAddCardIsVisible: false,
      isAddMobileVisible: false,
      isEditAccountDetail: false,
      isEditCardVisibility: false,
      isVisible: false,
      isMobilePrimaryVisible: false,
      error: "",
      addressError:{ ...cleanAddressError},
      otpError: "",
      editFormError: "",
      phoneList: [],
      payCards: [],
      cardImage: assets.card_logo1,
    };
    this.inputRefs = Array(4).fill(React.createRef());
    this.inputOtp = Array(6).fill(React.createRef());
  }

  componentDidMount = async () => {
    const userAuth = getUserDetail();
    const token = getToken();

    if (!token || (token && jwtExpired(token))) {
      clearAuth();
      history.push("/login");
    }
    if (userAuth) {
      this.setState({ user: userAuth, editUser: userAuth }, () => {
        this.props.dispatch(getPhoneList(token)).then((res) => {
          const { phoneList } = this.props;
          this.setState({ phoneList: phoneList });
        });
        this.props.dispatch(getCardList(token)).then((res) => {
          const { cards } = this.props;
          this.setState({ payCards: cards });
        });
      });
    }
  };

  validateMobile = () => {
    const { newMobile } = this.state;
    if (newMobile.length > 10 && newMobile.length < 14) {
      return true;
    } else {
      this.setState({ error: "Mobile Number should be of 11 to 13 digits" });
      return false;
    }
  };

  onEditFieldChange = (field, value) => {
    this.setState({ editUser: { ...this.state.editUser, [field]: value } });
  };

  onEditCardFieldChange = (field, value) => {
    this.setState({
      customercard: { ...this.state.customercard, [field]: value },
    });
  };

  getCardType = (number) => {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) !== null) return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) !== null) return "AMEX";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) !== null) return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) !== null) return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) !== null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) !== null) return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) !== null) return "Visa Electron";

    return "";
  };

  setVarifyVisibility = () => {
    this.setState({ otpError: "" });
    this.setState({ isVisible: !this.state.isVisible });
  };

  setMobilePrimaryVisibility = () => {
    this.setState({ error: "" });
    this.setState({
      isMobilePrimaryVisible: !this.state.isMobilePrimaryVisible,
    });
  };

  setCardPrimaryVisibility = () => {
    this.setState({ error: "" });
    this.setState({ isCardPrimaryVisible: !this.state.isCardPrimaryVisible });
  };

  setCardRemovePopupVisibily = () => {
    this.setState({ error: "" });
    this.setState({
      isCardRemovePopupVisibily: !this.state.isCardRemovePopupVisibily,
    });
  };

  setCardPrimaryWarningPopupVisibility = () => {
    this.setState({ error: "" });
    this.setState({
      isCardPrimaryWarningPopup: !this.state.isCardPrimaryWarningPopup,
    });
  };

  setPhoneRemovePopupVisibily = () => {
    this.setState({ error: "" });
    this.setState({
      isPhoneRemovePopupVisibily: !this.state.isPhoneRemovePopupVisibily,
    });
  };

  setPhonePrimaryWarningPopupVisibility = () => {
    this.setState({ error: "" });
    this.setState({
      isPhonePrimaryWarningPopup: !this.state.isPhonePrimaryWarningPopup,
    });
  };

  onPhoneRemove = async (customerphone) => {
    this.setState({ error: "" });
    const token = getToken();
    this.props
      .dispatch(
        deactivatePhone(
          customerphone.customerPhoneId,
          customerphone.customerId,
          token
        )
      )
      .then((res) => {
        if (res.type === PRIMARY_PHONE_SUCCESS) {
          this.props.dispatch(getPhoneList(token)).then((res) => {
            const { phoneList } = this.props;
            this.setState({ phoneList: phoneList }, () => {
              this.setPhoneRemovePopupVisibily();
              this.setMobilePrimaryVisibility();
              this.props.dispatch(
                showSnackBar({
                  msg: "Mobile number removed successfully",
                  type: "success",
                })
              );
            });
          });
        } else {
          this.setState({ error: res.error.response.data.msg });
        }
      });
  };

  onMobilePrimary = async (customerphone) => {
    const token = getToken();
    this.props
      .dispatch(
        primaryPhone(
          customerphone.customerPhoneId,
          customerphone.customerId,
          token
        )
      )
      .then((res) => {
        if (res.type === PRIMARY_PHONE_SUCCESS) {
          this.props.dispatch(getPhoneList(token)).then((resphone) => {
            const { phoneList } = this.props;
            this.setState({ phoneList: phoneList }, () => {});
            this.setMobilePrimaryVisibility();
            this.props.dispatch(
              showSnackBar({
                msg: "Primary mobile number has been changed",
                type: "success",
              })
            );
          });
        } else {
          this.setState({ error: res.error.response.data.msg });
        }
      });
  };

  onCardRemove = async () => {
    const { customercard } = this.state;
    const token = getToken();
    this.props.dispatch(removePaymentCard(customercard, token)).then((res) => {
      this.setCardRemovePopupVisibily();
      this.setEditCardVisibility();
      this.props.dispatch(getCardList(token)).then((res) => {
        const { cards } = this.props;
        this.setState({ payCards: cards });
        this.props.dispatch(
          showSnackBar({
            msg: "Payment method removed successfully",
            type: "success",
          })
        );
      });
    });
  };

  onCardPrimary = async () => {
    const { customercard } = this.state;
    const token = getToken();
    this.props
      .dispatch(
        primaryCard(customercard.customerCardId, customercard.customerId, token)
      )
      .then((res) => {
        if (res.type === PRIMARY_CARD_SUCCESS) {
          this.props.dispatch(getCardList(token)).then((resphone) => {
            const { cards } = this.props;
            this.setState({ payCards: cards }, () => {
              this.setCardPrimaryVisibility();
            });
          });
        } else {
          this.setState({ error: res.error.response.data.msg });
        }
      });
  };

  onOtpVarify = async () => {
    const { otp } = this.state;
    const { smsCred } = this.props;
    const userDetail = get_StoreUserDetails();
    const token = getToken();
    if (otp.replace(/ /g, "").length === 6) {
      this.setState({ otpError: "" });
      const cred = {
        phone: smsCred.phone,
        tempVerificationCodeId: smsCred.tempVerificationCodeId,
        sMSCode: otp,
      };

      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          const phone = {
            customerId: userDetail.customerId,
            phoneNumber: smsCred.phone,
            isActive: true,
            isPrimary: false,
          };
          this.props.dispatch(addNewNumber(phone, token)).then((res) => {
            if (res.type === NUMBER_ADD_SUCCESS) {
              this.props.dispatch(getPhoneList(token)).then((resphone) => {
                const { phoneList } = this.props;
                this.setState({ phoneList: phoneList }, () => {
                  this.setVarifyVisibility();
                });
              });
            } else {
              this.setState({ error: res.error.response.data.msg });
            }
          });

          this.setState({ otpError: "" });
        }
        if (res.type === OTP_VARIFY_FAILURE) {
          this.setState({ otpError: res.error });
        }
      });
    } else {
      this.setState({ otp: "" });
      this.setState({ otpError: "Please complete the OTP" });
    }
  };

  onAddMobile = (newMobile) => {
    this.setState({ error: "" });
    const phone = {
      phone: newMobile,
    };
    this.props.dispatch(sendOtp(phone)).then((res) => {
      if (res.type === RESEND_OTP_SUCCESS) {
        this.setVarifyVisibility();
        this.setAddMobileVisibility();
      } else {
        this.setState({ error: res.error.msg });
      }
    });
  };

  checkCvv = (cvv) => {
    let regx = /^[0-9]{3,4}$/;
    let result = regx.test(cvv) === true ? true : false;

    if (result) {
      return true;
    } else {
      this.setState({ error: "check cvv number" });
      return false;
    }
  };

  validateCardNumber = (ccNum) => {
    if (!ccNum) {
      this.setState({ error: "check card number" });
      return false;
    }

    if (!Number(ccNum)) {
      this.setState({ error: "check card number" });
      return false;
    }

    if (ccNum.length < 16) {
      this.setState({ error: "check card number should be 16" });
      return false;
    }

    return true;
  };

  checkMobile = (mobile) => {
    if (mobile.length > 10 && mobile.length < 14) {
      return true;
    } else {
      this.setState({ error: "Mobile Number should be of 11 to 13 digits" });
      return false;
    }
  };

  cardValidetion = () => {
    const { cvv, cardNum, expDate, name, cardType, nickname } = this.state;

    if (name.length < 3) {
      this.setState({ error: "Names should be min. 3 char." });
      return false;
    }

    if (!this.validateCardNumber(cardNum)) return false;

    if (!cardType || cardType < 1) {
      this.setState({ error: "Please select card type." });
      return false;
    }

    // if (!mobile || mobile < 1) {
    //   this.setState({ error: "Please select mobile no." });
    //   return false;
    // }

    if (!isValidateExpDate(expDate)) return false;

    if (!this.checkCvv(cvv)) return false;

    if (!nickname || nickname.length < 1) {
      this.setState({ error: "Name should be of greater than 1." });
      return false;
    }

    return true;
  };

  onEditCard = async (customercard) => {
    const {
      expirationDate,
      cardNumber,
      cardType,
      nickName,
      name,
      cvv,
      countryCode,
      state
    } = customercard;

    const {
      firstName,
      lastName,
      line1,
      city,
      emailAddress
    } = customercard.billingAddress;

    let error = "";

    let addressError = { ...cleanAddressError }
    let monthCheck = "" ;
    let yearCheck = ""
    let currentYear = new Date().getFullYear().toString();
    let currentMonth = new Date().getMonth();
    currentMonth = (currentMonth + 1).toString();
    if(currentMonth.length == 1){
      currentMonth = "0"+currentMonth;
    }
    currentYear = currentYear.substr(currentYear.length -2);
    let isValid = true;

    if(name == ""){
      addressError.name = "Card name is required.";
      this .setState({
        addressError
      });
      isValid = false;
    }else {
      if (/[^a-zA-Z-'\s]/.test(name)) {
        addressError.name =  "Name on Card cannot contain numbers or special characters.";
        this.setState({
          addressError
        });
        return false;
      } 
    }

    if (expirationDate == "" || expirationDate == null) {
      addressError.expDate = "Credit card expiration date is required."
      this.setState({ 
        addressError  
      });
      isValid = false;
    }else{
      monthCheck = expirationDate.substr(0, 2);
      yearCheck = expirationDate.substr(expirationDate.length -2 );
      if ((expirationDate.length < 4 ) || 
          (monthCheck > 12) || 
          (monthCheck < currentMonth && yearCheck == currentYear) || 
          (monthCheck < 1) || 
          (yearCheck < currentYear))
      {
        addressError.expDate = "Please enter valid card expiration date."
        this .setState({
          addressError
        });
        isValid = false;
      }
    }



    if (line1 == "") {
      addressError.line1 = "Address line one is required." 
      this.setState({ addressError });
      isValid = false;
    }

    if (city == "") {
      addressError.city = "City is required." 
      this.setState({ addressError });
      isValid = false;
    }

    if (countryCode == ""){
      addressError.countryCode = "Country is required."
      this.setState({
        addressError 
      });
      isValid = false;
    }

    if ((countryCode == "840") && (state == undefined)){
      addressError.state = "State is required."
      this.setState({
        addressError
      });
      isValid = false;
    }


    if (isValid){
      this.setState({
        addressError
      });
      const token = getToken();
      this.props.dispatch(editPaymentCard(customercard, token)).then((res) => {
        this.setEditCardVisibility();
        this.props.dispatch(getCardList(token)).then((res) => {
          const { cards } = this.props;
          this.setState({ payCards: cards, customercard: undefined });

          this.props.dispatch(setIsLoading(false));
          this.props.dispatch(
            showSnackBar({
              msg: "Card details updated successfully",
              type: "success",
              autoHideDuration: 3000,
            })
          );
        });
      });
    }else {
      this.props.dispatch(setIsLoading(false));
      addressError.alert = "Please fix the highlighted errors."
      this.setState({
        addressError
      });
      return false;
    }
  };

  onRemoveCard = async () => {
    // this.setEditCardVisibility();
    this.onEditCardFieldChange("isActive", false);
    this.setCardRemovePopupVisibily();
  };

  isValidPaymentMethod = (paymentDetails, billingDetails) => {
    const {
      expirationDate,
      cardNumber,
      cardType,
      name,
      nickName,
      cvv,
      countryCode,
      state
    } = paymentDetails;

    const {
      firstName,
      lastName,
      line1 = "",
      city = "",
    } = billingDetails;

    let addressError = { ...cleanAddressError }
    let cardCheck = cardNumber.substr(cardNumber.length - 4);
    let cardlist = this.state.payCards;
    let monthCheck = expirationDate.substr(0, 2);
    let yearCheck = expirationDate.substr(expirationDate.length -2 );
    let currentYear = new Date().getFullYear().toString();
    let currentMonth = new Date().getMonth();
    currentMonth = (currentMonth + 1).toString();
    if(currentMonth.length == 1){
      currentMonth = "0"+currentMonth;
    }
    currentYear = currentYear.substr(currentYear.length -2);
    let isValid = true;
    const cardNameExist = this.props.cards.find((c) => c.nickName === nickName);

    if(name == ""){
      addressError.name = "Card name is required.";
      this .setState({
        addressError
      });
      isValid = false;
    }else {
      if (/[^a-zA-Z-'\s]/.test(name)) {
        addressError.name =  "Name on Card cannot contain numbers or special characters.";
        this.setState({
          addressError
        });
        return false;
      } 
    }

    if (cardNumber == ""){
      addressError.cardNum = "Credit card number is required."
      this.setState({ addressError });
      isValid = false;
    }else {
      if (cardNameExist) {
        this.setState({
          error: `Payment method name: "${nickName}" already exist. Please use another name.`,
        });
        isValid = false;
      }
      if (cardNumber.length < 16){
        addressError.cardNum = "Invalid credit card number."
        this.setState({ addressError });
        isValid = false;
      }else{
        for(let i = 0; i != cardlist.length; i++){
          let keys = cardlist[i].nickName;
           if(keys.substr(keys.length - 4) === cardCheck){
            addressError.cardNum = "This card is already saved for this account."
             this.setState({ 
              addressError  
             });
             isValid = false;
           }
         }
      }

      if (
        cardType.toUpperCase() != "VISA" &&
        cardType.toUpperCase() != "MASTERCARD"
      ) {
        addressError.cardNum = "Invalid credit card number."
        this.setState({ addressError });
        isValid = false;
      }
  
      if (cardType.toUpperCase() == "VISA") {
        if (cardNumber.length != 13 && cardNumber.length != 16) {
          addressError.cardNum = "Incomplete Credit Card Number." 
          this.setState({ addressError });
          isValid = false;
        }
      }
  
      if (cardType.toUpperCase() == "MASTERCARD") {
        if (cardNumber.length != 16) {
          addressError.cardNum = "Incomplete Credit Card Number." 
          this.setState({ addressError });
          isValid = false;
        }
      }
    }

    if (expirationDate == "") {
      addressError.expDate = "Credit card expiration date is required."
      this.setState({ 
        addressError  
      });
      isValid = false;
    }else{
      if ((expirationDate.length < 4 ) || 
          (monthCheck > 12) || 
          (monthCheck < currentMonth && yearCheck == currentYear) || 
          (monthCheck < 1) || 
          (yearCheck < currentYear)){
        addressError.expDate = "Please enter valid card expiration date."
        this .setState({
          addressError
        });
        isValid = false;
      }
    }

    if (cvv == ""){
      addressError.cvv = "CVV is required."
      this.setState({
        addressError
      });
      isValid =  false;
    }else{
      if (cvv.length !== 3){
        addressError.cvv = "CVV must be three (3) digits."
        this.setState({
          addressError
        });
        isValid =  false;
      }
    }

    if (line1 == "") {
      addressError.line1 = "Address line one is required." 
      this.setState({ addressError });
      isValid = false;
    }

    if (city == "") {
      addressError.city = "City is required." 
      this.setState({ addressError });
      isValid = false;
    }

    if (countryCode == ""){
      addressError.countryCode = "Country is required."
      this.setState({
        addressError 
      });
      isValid = false;
    }
    if ((countryCode == "840") && (state == undefined)){
      addressError.state = "State is required."
      this.setState({
        addressError
      });
      isValid = false;
    }

    if (isValid){
      this.setState({
        addressError
      })
      return true;
    }else {
      addressError.alert = "Please fix the highlighted errors."
      this.setState({
        addressError
      });
      return false;
    }


  };

  onAddCard = async (paymentDetails, billingDetails) => {
    const { user } = this.state;
    let error = {
      alert:""
    }

    if (this.isValidPaymentMethod(paymentDetails, billingDetails)) {
      const token = getToken();
      billingDetails.countryCode = paymentDetails.countryCode; 
      const credential = {
        customerId: user.customerId,
        isActive: true,
        isPrimary: paymentDetails.isPrimary,
        isDeleted: false,
        cardNumber: paymentDetails.cardNumber,
        cardType: paymentDetails.cardType,
        expirationDate: paymentDetails.expirationDate,
        name: paymentDetails.name,
        nickName: paymentDetails.nickName,
        cvv: paymentDetails.cvv,
        billingAddress: billingDetails
      };
      this.props.dispatch(addNewPaymentCard(credential, token)).then((res) => {
        if (res.error) {
          error.alert = `Failed to add card: "${res.error}"`
          this.setState({ error });
          this.props.dispatch(setIsLoading(false));
        } else {
          this.setAddCardVisibility();
          // reset customercard property, so if we add again it isn't prefilled
          this.setState({customercard:undefined});

          this.props.dispatch(getCardList(token)).then((res) => {
            const { cards } = this.props;
            this.setState({ payCards: cards });
            this.props.dispatch(setIsLoading(false));
            this.props.dispatch(
              showSnackBar({
                msg: "Successfully added new card",
                type: "success",
                autoHideDuration: 3000,
              })
            );
          });
        }
      });
    }
  };

  setCardNumber = (digit, id) => {
    if (!Number(digit)) {
      this.setState({ error: "Card should be number only." });
    } else {
      this.setState({ error: "" });
      this.setState({ cardNum: digit });

      if (digit.length === 16) {
        let card = this.getCardType(digit);
        if (card) {
          if (card === "Visa") {
            this.setState({ cardImage: assets.card_logo2 });
          } else {
            //Mastercard
            this.setState({ cardImage: assets.card_logo1 });
          }
        }
      }
    }
  };

  replaceAt = (index, char) => {
    var a = this.split("");
    a[index] = char;
    return a.join("");
  };

  setMobileOtp = (digit, id) => {
    // let result =otp.replaceAt(id,digit);
    if (!Number(digit)) {
      this.setState({ otpError: "OTP should be number only." });
    } else {
      this.setState({ otpError: "" });
      this.setState({ otp: digit });
    }
  };

  resendOtp = () => {
    this.setState({ otp: "" });
    const { newMobile } = this.state;
    let cred = { phone: newMobile };
    this.props.dispatch(sendOtp(cred)).then((res) => {
      if (res.type === RESEND_OTP_SUCCESS) {
      }
      if (res.type === RESEND_OTP_FAILURE) {
        this.setState({ error: res.error.msg });
      }
    });
  };

  setAddCardVisibility = () => {
    this.setState({ error: "", addressError: { ...cleanAddressError} });
    const { isAddCardIsVisible } = this.state;
    this.setState({
      isAddCardIsVisible: !isAddCardIsVisible,
      isAddMobileVisible: false,
      isEditAccountDetail: false,
      isEditCardVisibility: false,
    });
  };

  setAddMobileVisibility = () => {
    this.setState({ error: "", addressError: { ...cleanAddressError} });
    const { isAddMobileVisible } = this.state;
    this.setState({
      isAddMobileVisible: !isAddMobileVisible,
      isAddCardIsVisible: false,
      isEditAccountDetail: false,
      isEditCardVisibility: false,
    });
  };

  setEditDetailsVisibility = () => {
    this.setState({ error: "", addressError: { ...cleanAddressError} });
    const { isEditAccountDetail } = this.state;
    this.setState({
      isEditAccountDetail: !isEditAccountDetail,
      isAddMobileVisible: false,
      isAddCardIsVisible: false,
      isEditCardVisibility: false,
    });
  };

  setEditCardVisibility = () => {
    this.setState({ error: "", addressError: { ...cleanAddressError} });
    const { isEditCardVisibility } = this.state;
    if(isEditCardVisibility)
      this.setState({
        customercard: undefined,
      });

    this.setState({
      isEditCardVisibility: !isEditCardVisibility,
      isEditAccountDetail: false,
      isAddMobileVisible: false,
      isAddCardIsVisible: false,
    });


  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  onCardRowClick = (field, value) => {
    this.setState({ ...this.state, [field]: value });
    this.setEditCardVisibility();
  };

  onCheckFormField = (event, field, value) => {
    if (event.target.checked) {
      this.setState({ ...this.state, [field]: value });
      this.setEditCardVisibility();
      this.setCardPrimaryVisibility();
    }
  };

  validateEditForm = (firstName, lastName) => {
    if (/[^a-zA-Z-'\s]/.test(firstName)) {
      this.setState({ editFormError: "First Name is invalid" });
      return false;
    }
    if (/[^a-zA-Z-'\s]/.test(lastName)) {
      this.setState({ editFormError: "Last Name is invalid" });
      return false;
    }
    return true;
  };

  onEditDetails = async (firstName, lastName) => {
    const { editUser } = this.state;
    const user = { ...editUser, firstName: firstName, lastName: lastName };
    this.setState({ editUser: { ...user } });

    if (this.validateEditForm(firstName, lastName)) {
      const token = getToken();
      this.props.dispatch(updateUserDetails(user, token)).then((res) => {
        if (res.type === DETAIL_EDIT_SUCCESS) {
          this.props.dispatch(getUserDetails(token)).then((res) => {
            const { details } = this.props;
            this.setState({ user: { ...details } });
            this.setEditDetailsVisibility();
            this.props.dispatch(
              showSnackBar({
                msg: "Profile updated successfully",
                type: "success",
                autoHideDuration: 3000,
              })
            );
          });
        }
      });
    }
  };

  validateCheckBox = (e) => {
    const { isTermChecked } = this.state;
    this.setState({ isTermChecked: !isTermChecked });
  };

  render() {
    const { accountReducer } = this.props;
    return (
      <Fragment>
        {accountReducer.isGetting || accountReducer.isFetching ? (
          <Loading />
        ) : (
          <UserAccounts
            {...this.state}
            {...this.props}
            onOtpVarify={this.onOtpVarify}
            resendOtp={this.resendOtp}
            setMobileOtp={this.setMobileOtp}
            inputRefs={this.inputRefs}
            otpRefs={this.otpRefs}
            onFieldChange={this.onChangeFormField}
            onCheckFormField={this.onCheckFormField}
            onCardRowClick={this.onCardRowClick}
            setCardNumber={this.setCardNumber}
            setVisibility={this.setAddCardVisibility}
            setMobileVisibility={this.setAddMobileVisibility}
            setEditVisibility={this.setEditDetailsVisibility}
            setVarifyVisibility={this.setVarifyVisibility}
            setMobilePrimaryVisibility={this.setMobilePrimaryVisibility}
            onMobilePrimary={this.onMobilePrimary}
            onPhoneRemove={this.onPhoneRemove}
            setCardPrimaryVisibility={this.setCardPrimaryVisibility}
            onCardPrimary={this.onCardPrimary}
            setCardRemovePopupVisibily={this.setCardRemovePopupVisibily}
            setCardPrimaryWarningPopupVisibility={
              this.setCardPrimaryWarningPopupVisibility
            }
            setPhoneRemovePopupVisibily={this.setPhoneRemovePopupVisibily}
            setPhonePrimaryWarningPopupVisibility={
              this.setPhonePrimaryWarningPopupVisibility
            }
            onCardRemove={this.onCardRemove}
            onEditFieldChange={this.onEditFieldChange}
            onAddMobile={this.onAddMobile}
            onAddCard={this.onAddCard}
            validatePaymentMethod={this.isValidPaymentMethod}
            editDetails={this.onEditDetails}
            setEditCardVisibility={this.setEditCardVisibility}
            onEditCardFieldChange={this.onEditCardFieldChange}
            onEditCard={this.onEditCard}
            onRemoveCard={this.onRemoveCard}
            validateCheckBox={this.validateCheckBox}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { accountReducer, entities } = state;
  return {
    accountReducer,
    smsCred: entities.accounts.smsCred,
    phoneList: entities.accounts.phoneList,
    cards: entities.accounts.payCards,
    details: entities.user.details,
  };
}

export default connect(mapStateToProps)(Accounts);
