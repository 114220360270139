import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "components/public-dashboard/components/iconHeader";

export default function FAQHeader() {
  const theme = useTheme();

  return (
    <Container>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <IconHeader
            title="FAQs"
            iconColor={theme.palette.primary.main}
            titleColor="#1ABDA0"
            titleLg="h2"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
