import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import TablePaginationActions from "./tablePaginationActions";
import Row from "./tableRowMobile";
import {TableSortLabel, TextField} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 500,
  },
  cell: {
    padding: "8px",
  },
  paginationStyles: {
    marginTop: 16,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  searchField: {
  float:"right",
  marginBottom: 5,
  marginRight: 5
}
}));

export default function CustomTableMobile(props) {
  const {
    pagedItems = [],
    totalItems = 10,
    handleFetch,
    pageIndex,
    handleQueryUpdate,
    columns,
    query,
    headStyle,
    searchValue = "",
    paginationEnabled=true,
    sortingEnabled = false,
    containerStyles,
    handleSort
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [pageSize, setPageSize] = useState(10);
  const [pageIndexLocal, setPageIndex] = useState(pageIndex - 1);
  const mobileHeadingColumns = columns.filter(
    (column) => column.isMobileHeader
  );
  const showCollapse = mobileHeadingColumns.length < columns.length;

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
    handleQueryUpdate({ ...query,  searchTerm: searchValue, pageNumber: ++newPage, pageSize });
    handleFetch();
  };
  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(pageSize);
    handleQueryUpdate({ ...query, searchTerm: searchValue, pageSize });
    handleFetch();
  };
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSort(order, orderBy);
  };
  return (
    <Paper className={classes.root} style={containerStyles}>
      <TableContainer className={paginationEnabled ? classes.container : null}>
        <Table stickyHeader aria-label="sticky table" className={classes.root}>
          <TableHead>
            <TableRow>
              {showCollapse && (
                <TableCell className={classes.cell} style={headStyle} />
              )}
              {mobileHeadingColumns.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      style={headStyle}
                  >

                    {!sortingEnabled && column.label}
                    {sortingEnabled &&
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                              <span className={classes.visuallyHidden}>
                  </span>
                          ) : null}
                        </TableSortLabel>
                    }
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedItems.map((row, index) => {
              return (
                <Row
                  row={row}
                  columns={columns}
                  key={index}
                  showCollapse={showCollapse}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationEnabled && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageIndexLocal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Paper>
  );
}
