import {
  Container,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment } from "react";
import billPay_img from "assets/images/billpay_image.png";
import revenue_img from "assets/images/more_revenue.png";
import getpPaid_img from "assets/images/foot-traffic.png";
import signUp_img from "assets/images/home_content_4.png";
import digi_bmobile_logo from "assets/images/digicel-and-bmobile-logos.png";
import topup_requirements from "assets/images/YOOZ_TOP_UP_REQUIREMENT_POST.jpg";
import billpay_requirements from "assets/images/YOOZ_BILL_PAYMENT_REQUIREMENT_POST.jpg";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import ContentList from "components/public-dashboard/components/contentList";
import TextList from "components/public-dashboard/components/TextList";
import PrimaryTextHeading from "components/public-dashboard/components/primaryTextHeading";

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
    color: "rgb(28, 197, 255)",
    fontSize: 34,
  },
  headingSubTitle: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
    color: "rgb(28, 197, 255)",
    fontSize: 18,
  },
  content: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {},
  },

  signUpContainer: {
    background: "#F2F2F2",
    margin: "auto",
    paddingTop: 20,
  },
  link: {
    color: theme.palette.secondary.main,
    fontFamily: "Akzidenz-Grotesk BQ, serif",
  },
  img: {
    width: "100%",
    maxWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 150,
    },
  },
  linktext: {
    color: theme.palette.primary.light,
    "&:hover": { color: `${theme.palette.primary.main} !important` },
  },
  largeGreenText: {
    fontSize: 30,
    color: "#57B56B",
    fontFamily: "Arial Rounded MT",
  },
  largeOrangeText: {
    fontSize: 30,
    color: "#FBAD18",
    fontFamily: "Arial Rounded MT",
  },
  largeBlueText: {
    fontSize: 30,
    color: "rgb(28, 197, 255)",
    fontFamily: "Arial Rounded MT",
  },
  LargePurpleText: {
    fontSize: 30,
    color: "#A43168",
    fontFamily: "Arial Rounded MT",
  },
  largeRedText: {
    fontSize: 30,
    color: "#CA0000",
    fontFamily: "Arial Rounded MT",
  },
  greenText: {
    color: "#57B56B",
  },
  redText: {
    color: "#CA0000",
  },
  yoozBlueLink: {
    color: "#00AFE2",
  },
  agentIncomes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  incomeSource: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
}));

export default function AgentContent() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const sections = [
    {
      title: "SELL TOP UP",
      img: digi_bmobile_logo,
      colorMappings: {
        SELL: "largeBlueText",
        TOP: "largeRedText",
        UP: "largeRedText",
      },
    },
    {
      title: "COLLECT BILL PAYMENTS",
      img: billPay_img,
      colorMappings: {
        COLLECT: "largeGreenText",
        BILL: "largeGreenText",
        PAYMENTS: "largeGreenText",
      },
    },
    {
      title: "EARN MORE REVENUE",
      img: revenue_img,
      colorMappings: {
        EARN: "largeBlueText",
        MORE: "LargePurpleText",
        REVENUE: "LargePurpleText",
      },
    },
    {
      title: "INCREASE FOOT TRAFFIC",
      img: getpPaid_img,
      colorMappings: {
        INCREASE: "largeBlueText",
        FOOT: "largeOrangeText",
        TRAFFIC: "largeOrangeText",
      },
    },
  ];

  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-between"
        className={classes.incomeSource}
      >
        {sections.map((section, index) => (
          <Grid key={index} item xs={6} md={3}>
            <div className={classes.agentIncomes}>
              <img
                src={section.img}
                alt={section.title}
                className={classes.img}
              />
              <div style={{ textAlign: "center" }}>
                {section.title.split(" ").map((word, index) => (
                  <Fragment key={index}>
                    <strong
                      className={classes[section.colorMappings[word]]}
                      style={{ fontSize: xs ? 18 : sm ? 24 : 36 }}
                    >
                      {word}
                    </strong>{" "}
                  </Fragment>
                ))}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <TextList
        noMargin={true}
        items={[
          <span>
            As a <strong>YOOZ Agent</strong> you provide the convenience of a{" "}
            <strong> one-stop shop </strong> to buy goods,
            <strong className={classes.greenText}> PAY BILLS</strong> and/or
            <strong className={classes.redText}> TOP UP </strong> in ONE PLACE.
          </span>,
          <span>
            You will now have access to the very large customer base of major
            utilities as they promote the payment of their bills via
            <strong> YOOZ Agents.</strong>
          </span>,
          <span>
            We operate using a per transaction pricing model and, as such, a{" "}
            <strong>YOOZ Agent </strong> can{" "}
            <strong>earn additional revenue </strong> on each transaction.
          </span>,
        ]}
      />
      <PrimaryTextHeading
        text="JOIN OUR AGENT NETWORK"
        styles={{
          textAlign: "center",
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: sm ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={topup_requirements}
          alt="billpay info 1"
          style={{
            width: sm ? "100%" : 600,
            maxWidth: 600,
            height: "auto",
            padding: "20px",
          }}
        />
        <img
          src={billpay_requirements}
          alt="billpay info 2"
          style={{
            width: sm ? "100%" : 600,
            maxWidth: 600,
            height: "auto",
            padding: "20px",
          }}
        />
      </div>

      <div className={classes.signUpContainer}>
        <ContentImageBlock
          title="READY TO GET STARTED ?"
          titleSm="h4"
          titleLg="h3"
          titleXs="h6"
          imgPosition="left"
          color="#1CC5FF"
          img={signUp_img}
          list={
            <ContentList
              items={[
                <span>
                  Call <strong>+1 (868) 235-6182</strong> to speak to one of our
                  representatives or email{" "}
                  <a
                    href="mailto: servicett@yoozit.today"
                    className={classes.yoozBlueLink}
                  >
                    servicett@yoozit.today
                  </a>{" "}
                  along with your Onboarding documents. To see all required
                  documents for registration check out our{" "}
                  <a
                    href="/AgentOnboardingDocuments"
                    className={classes.yoozBlueLink}
                  >
                    Onboarding Documents
                  </a>{" "}
                  page.
                </span>,
              ]}
            />
          }
        />
      </div>
    </Container>
  );
}
