import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PaymentMethodItem from "./paymentMethodItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PaymentMethodList(props) {
  const classes = useStyles();
  const { cards, handleClick } = props;

  return (
    <List className={classes.root}>
      {cards.map((card) => {
        return <PaymentMethodItem key={card.customerCardId} card={card} handleClick={handleClick} />;
      })}
    </List>
  );
}
