import { Typography, useTheme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { API_ROOT } from "constants/actionTypes";
import React from "react";

export default function DocumentLink(props) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { fileId, text, color = "#fff", transform = "uppercase" } = props;
  const docPath = `${API_ROOT}document/${fileId}`;
  return (
    <Link
      href={docPath}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textTransform: transform,
        color: color,
      }}
    >
      <Typography variant={xs ? "body2" : "body1"}>{text}</Typography>
    </Link>
  );
}
