import { setTxnDetails } from "components/public-dashboard/features/billpay/bpSlice";
import { getAsync } from "components/public-dashboard/services/api_util";
import { setAnonTokenApi } from "components/public-dashboard/services/utility";
import { API_ROOT } from "constants/actionTypes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function useCustomerBPTotal() {
  const [isLoading, setLoading] = useState(true);
  const [bpTotal, setBPTotal] = useState(0.0);
  const [convenienceFee, setConvenienceFee] = useState(0.0);
  const dispatch = useDispatch();
  const fetchCustomerPaymentAmount = async (txnDetails) => {
    setLoading(true);
    await setAnonTokenApi();
    const response = await getAsync(
      `${API_ROOT}transaction/CustomerPaymentAmount?billpayAmount=${txnDetails.amount}&merchantId=${txnDetails.providerId}`
    );

    if (response.state === 1) {
      const total = response.data.customerPaymentAmt;
      setBPTotal(total);
      setConvenienceFee(total - txnDetails.amount);
      const cardChargePercentage = response.data.customerCardRate * 100
      dispatch(
        setTxnDetails({ ...txnDetails, bpTotal: total })
      );
    } else {
      return "Error Calculating Total";
    }
    setLoading(false);
  };

  return { isLoading, bpTotal, convenienceFee, fetchCustomerPaymentAmount };
}

export default useCustomerBPTotal;
