import history from "history/history";
import { clearAuth, getAnonToken, getToken } from "middleware/storage";
import { jwtExpired } from "components/utils";

export async function postAsync(postData, url) {
  let token = getToken();
  if (token && jwtExpired(token)) {
    clearAuth();
    alert("Session Expired. Please login again");
    history.push("/login");
  }
  if (!token) {
    token = getAnonToken();
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    return {
      state: -1,
      msg: message,
      data: null,
    };
  }

  try {
    const result = await response.json();
    return result;
  } catch (error) {
    return {
      state: -1,
      msg: "An error has occurred. Please try back again later",
      data: null,
    };
  }
}

export async function getAsync(url) {
  let token = getToken();
  if (token && jwtExpired(token)) {
    clearAuth();
    history.push("/login");
  }
  if (!token) {
    token = getAnonToken();
  }

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    return {
      state: -1,
      msg: message,
      data: null,
    };
  }

  try {
    const result = await response.json();
    return result;
  } catch (error) {
    return {
      state: -1,
      msg: "An error has occurred. Please try back again later",
      data: null,
    };
  }
}

export async function getBlob(url) {
  let token = getToken();
  if (token && jwtExpired(token)) {
    clearAuth();
    history.push("/login");
  }
  if (!token) {
    token = getAnonToken();
  }

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    return {
      state: -1,
      msg: message,
      data: null,
    };
  }

  try {
    const result = await response.blob();
    return {
      state: 1,
      msg: "Successfully fetched file",
      data: result,
    };
  } catch (error) {
    return {
      state: -1,
      msg: "An error has occurred. Please try back again later",
      data: null,
    };
  }
}

export async function postBlob(postData, url) {
  let token = getToken();
  if (token && jwtExpired(token)) {
    clearAuth();
    alert("Session Expired. Please login again");
    history.push("/login");
  }
  if (!token) {
    token = getAnonToken();
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    return {
      state: -1,
      msg: message,
      data: null,
    };
  }

  try {
    const result = await response.blob();
    return result;
  } catch (error) {
    return {
      state: -1,
      msg: "An error has occurred. Please try back again later",
      data: null,
    };
  }
}

export async function downloadBlob(blob, fileName, type){
  if (type && blob.type !== type) {
    return;
  }

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
}

