import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "./customIconHeader";

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "5%",
    },
  },
  overlineText: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  img: {
    width: "100%",
  },
}));

export default function VerificationHeader(props) {
  const {
    title,
    title2,
    color,
    color2,
    list,
    img,
    imgPosition = "right",
    titleSm,
    titleLg,
    titleXs,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid
      item
      container
      direction={xs ? "column" : "row"}
      justify="center"
      alignItems="center"
    >
      {xs && (
        <Grid item xs={6}>
          <img src={img} alt="forgot password" className={classes.img}/>
        </Grid>
      )}
      {sm && imgPosition === "left" && (
        <Grid item xs={12} sm={6}>
          <img src={img} alt="forgot password" className={classes.img}/>
        </Grid>
      )}

      <Grid item xs={12}>
        <IconHeader
          title={title}
          title2={title2}
          titleColor={color}
          titleColor2={color2}
          titleSm={titleSm}
          titleLg={titleLg}
          titleXs={titleXs}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.body}
        spacing={2}
        xs={12}
        sm={6}
      >
        {list}
      </Grid>
      {sm && imgPosition === "right" && (
        <Grid item xs={12} sm={6}>
          <img src={img} alt="forgot password" className={classes.img}/>
        </Grid>
      )}
    </Grid>
  );
}
