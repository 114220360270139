import { ThemeProvider } from "@material-ui/styles";
import "fontsource-roboto";
import React from "react";
import theme from "./components/theme";
import "./css/App.css";
import "./css/loader.css";
import "./css/responsive.css";
import RouterHandler from "./routing/router";
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterHandler />
    </ThemeProvider>
  );
}
