import { createSlice } from "@reduxjs/toolkit";

const cleanState = {
  navIndex: 0,
};

const uiSecuredSlice = createSlice({
  name: "uiSecured",
  initialState: cleanState,
  reducers: {
    setNavIndex(state, action) {
      state.navIndex = action.payload;
    },
  },
});

export const { setNavIndex } = uiSecuredSlice.actions;

export default uiSecuredSlice.reducer;
