import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
  },
}));

export default function PrimaryText(props) {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { text, styles } = props;
  return (
    <div className={classes.root}>
      <Typography
        variant={xs ? "body2" : sm ? "body1" : md ? "body1" : "h5"}
        style={{ color: "#224E7F", ...styles }}
      >
        {text}
      </Typography>
    </div>
  );
}
