import Yooz3DS2 from "assets/images/YOOZ-3ds2_.png";
import PCIicon from "assets/images/pci-icon.png";
import APIicon from "assets/images/api-secure-icon.png";
import HTTPSicon from "assets/images/HTTPS-ICON.png";
import Firewallicon from "assets/images/Firewalls-ICON.png";
import BIicon from "assets/images/Business-Intelligence ICON.png";

export const securityFeatures = [
  {
    title: "3D SECURE 2.0 CERTIFIED",
    lines: [
      <span>✅ Prevents fraud</span>,
      <span>✅ Detects risk in real time</span>,
      <span>✅ Safeguards cardholders’ data</span>,
    ],
    color: "#1E7D83",
    img: Yooz3DS2,
  },
  {
    title: "PCI DSS COMPLIANT",
    lines: [
      <span>
        Operating under the standards set by PCI-DSS as managed by the PCI
        Security Standards Council, which is a joint effort of brands like Visa,
        Mastercard, American Express and Discover.
      </span>,
      <span>
        PCI-DSS requirements help ensure the secure handling of payment
        information.
      </span>,
    ],
    color: "#1E7D83",
    img: PCIicon,
  },
  {
    title: "API SECURE ENCRYPTION AND AUTHENTICATION PROTOCOL",
    lines: [
      <span>
        Encrypts merchant data and verifies that requests come from an
        authorized merchant.
      </span>,
    ],
    color: "#1E7D83",
    img: APIicon,
  },
  {
    title: "IMPLEMENTATION OF HTTPS",
    lines: [
      <span>
        Encrypts all traffic between the merchant, end-customer and API
        (Application Programming Interface) to prevent data tampering and
        eavesdropping.
      </span>,
    ],
    color: "#1E7D83",
    img: HTTPSicon,
  },
  {
    title: "IMPLEMENTATION OF FIREWALLS & WAF PROXIES",
    lines: [<span>Monitors and filters incoming and outgoing traffic.</span>],
    color: "#1E7D83",
    img: Firewallicon,
  },
  {
    title: "BUSINESS INTELLIGENCE",
    lines: [
      <span>
        Monitors and reports potentially fraudulent activity using data driven
        processes.
      </span>,
    ],
    color: "#1E7D83",
    img: BIicon,
  },
];
