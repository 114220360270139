import { useState, useEffect } from 'react';
import { getAsync } from 'components/public-dashboard/services/api_util';
import { setAnonTokenApi } from 'components/public-dashboard/services/utility';
import { API_ROOT } from 'constants/actionTypes';
import { setAnonToken } from 'middleware/storage';

function useServiceStatus(serviceId) {
  const [serviceLoading, setLoading] = useState(true);
  const [serviceStatus, setServiceStatus] = useState(null);

  useEffect(() => {
    async function getServiceStatus() {
      setLoading(true);
      const status = await fetchServiceStatus(serviceId);
      setServiceStatus(status);
      setLoading(false);
    }
    getServiceStatus();
  }, []);

  

  return { serviceLoading, serviceStatus };
}

async function fetchServiceStatus(serviceId) {
    await setAnonTokenApi();
    const response = await getAsync(
      `${API_ROOT}transaction/service/status?serviceId=`+serviceId
    );
  
    if (response.state === 1) {
      return response.data;
    }else{
      return "Service Temporarily Unavailable"
    }
  }
  

export default useServiceStatus;