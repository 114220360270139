import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import topUp_img from "assets/images/home_content_2.png";
import digicel from "assets/images/provider_3.png";
import bmobile from "assets/images/provider_ (2).png";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));


export default function TopupHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container>
      <Grid container direction="row" justify="center" alignItems="center">
        {xs && (
          <Grid item xs={6}>
            <IconHeader
              title="Top Up"
              iconColor="#cb010d"
              titleColor="#3A75BA"
              titleLg="h2"
            />
          </Grid>
        )}
        {xs && (
          <Grid item xs={6}>
            <img src={topUp_img} alt="topup" className={classes.img}/>
          </Grid>
        )}
        <Grid item container xs={12} sm={7}>
          {sm && (
            <Grid item>
              <IconHeader
                title="Top Up"
                iconColor="#cb010d"
                titleColor="#3A75BA"
                titleLg="h2"
              />
            </Grid>
          )}
          <Grid item container>
            <TextList
              items={[
                <div style={{ display: "flex", alignItems: "center" }}>
                <img src={bmobile} alt="bmobile" style={{ marginRight: "17px" }} />
                <img src={digicel} alt="digicel" style={{ width: "28%" }} />
              </div>,
                "Imagine being able to top up your phone or someone else’s, wherever you are, whenever you want, at no extra cost!",
                <span>
                  With <b>YOOZ Top Up</b> you can!
                </span>,
                "Scroll down to learn more!",
              ]}
              indentation="3%"
            />
          </Grid>
        </Grid>
        {sm && (
          <Grid item xs={6} sm={5}>
            <img src={topUp_img} alt="topup" className={classes.img}/>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
