import {  Button,Container, Grid, useMediaQuery } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import billPay_img from "assets/images/home_content_3.png";
import donateLogo from "assets/images/charity_logo.png";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";
import { setTabPosition } from "components/public-dashboard/uiSlice";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "70%"
  },
}));

export default function DonateHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();

  return (
    <Container>
      <Grid container direction="row" justify="center" alignItems="center">
        {xs && (
          <Grid item xs={6}>
            <IconHeader
              title="& Donate"
              iconColor="#C60B17"
              titleColor="#008bee"
              titleLg="h2"
              titleXs="h4"
            />
          </Grid>
        )}
        {xs && (
          <Grid item xs={6}>
            <img src={donateLogo} alt="xmasBox" className={classes.img} />
          </Grid>
        )}
        <Grid item container xs={12} sm={7}>
          {sm && (
            <Grid item>
              <IconHeader
                title="& Donate"
                iconColor="#C60B17"
                titleColor="#008bee"
                titleLg="h2"
              />
            </Grid>
          )}

          <Grid item container>
            <TextList
              items={[
               
                 <span>
                    <b>YOOZ</b> partners with local charities to receive donations on our bill payment platform.
                   </span>,
                   <span> 
                     If you wish to donate, feel free to click on the donate buttons below.
                    </span>,
                    <span>
                     For more information on the charities see below. 
                   </span>,
                   <span style={{fontWeight:'bold'}}>
                     YOOZ it to GIVE… YOOZ it Today!
                    </span>,
                   

                
                // <Button
                //   component={Link}
                //   to="/public/billpay/now"
                //   color="primary"
                //   size="large"
                //   variant="contained"
                //   onClick={() => {
                //     window.scrollTo(0, 0);
                //     dispatch(setTabPosition(1));
                //   }}
                //   style={{ borderRadius: 20, marginRight: 10 }}
                // >
                //   Pay Online Now
                // </Button>,
                // <b>No Registration Required!</b>,
                // "Scroll down to learn more!",
              ]}
              indentation="3%"
              
              
            />
          </Grid>
        </Grid>
        {sm && (
          <Grid item xs={6} sm={5}>
            <img src={donateLogo} alt="billpay" className={classes.img} />
          </Grid>
        )}      
      </Grid>
    </Container>
  );
}
