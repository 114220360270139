import { Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import signUp_img from "assets/images/home_content_2.png";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import ContentList from "components/public-dashboard/components/contentList";
import PrimaryTextHeading from "components/public-dashboard/components/primaryTextHeading";
import TabbedTables from "components/public-dashboard/components/tabbedTables";
import { columns, comment, tables, scanToPay } from "./constants";
import BaseTable from "components/public-dashboard/components/baseTable";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  yoozBlueLink: {
    color: "#00AFE2",
  },
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
    },
  },
}));

export default function PartnersContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container className={classes.root}>
      <ContentList
        title="YOOZ Payment Solutions"
        styles={{ textAlign: "center" }}
        items={[
          <span>
            Thank you for your interest in our YOOZ Payment Solutions. We would
            like to make your onboarding journey with us as seamless and
            hassle-free as possible. <br />
            <br />
            The first step is to fill out and submit your Onboarding documents
            including the{" "}
            <a
              href={`${API_ROOT}document/yo-letter-of-interest`}
              className={classes.yoozBlueLink}
              target="_blank"
            >
              Letter of Interest
            </a>{" "}
            (LOI). These document requirements vary based on your business
            structure. The list below identifies the requirements by business
            structure.
            <br />
            <br />
            Simply scroll down to find your business structure and send your
            documents to us via{" "}
            <a
              href="mailto: yooz@yoozit.today"
              className={classes.yoozBlueLink}
            >
              yooz@yoozit.today
            </a>
            .
            <br />
            <br />
            If you would like further clarification, feel free to contact our
            YOOZ Onboardng Team at +1 (868) 235-6182.
          </span>,
        ]}
      />
      <PrimaryTextHeading
        text="Payment Solutions"
        styles={{ textAlign: "center", marginBottom: "10px" }}
      />
      <PrimaryTextHeading
        text="YOOZ Online | YOOZ Payment Gateway | YOOZ Agent Network"
        styles={{ textAlign: "center", marginBottom: "20px" }}
      />
      <h4 style={{ textAlign: "center", color: theme.palette.secondary.main }}>
        YOOZ Scan2Pay is an added feature of the YOOZ Online Service.
      </h4>
      <TabbedTables columns={columns} tables={tables} />
      <h4 style={{ textAlign: "center", marginBottom: theme.spacing(6) }}>
        {comment}
      </h4>

      <PrimaryTextHeading
        text="YOOZ Scan2Pay (ONLY)"
        styles={{ textAlign: "center", marginBottom: "20px" }}
      />

      <BaseTable columns={columns} data={scanToPay} />

      <ContentList
        title="WHY DO WE NEED THESE DOCUMENTS? "
        styles={{ textAlign: "center", marginTop: theme.spacing(4) }}
        items={[
          <span>
            These Onboarding documents otherwise known as KYC (Know Your
            Customer) documents are required by law.
            <br /> <br />
            Due diligence on business entities is required for the monitoring of
            Anti-Money Laundering (AML) and Combatting the Financing of
            Terrorism (CFT) as per the Financial Obligations Regulations and the
            Central Bank of Trinidad and Tobago (CBTT) AML/CFT Guidelines.
            <br /> <br />
            YOOZ operates under the FIUTT (Financial Intelligence Unit of
            Trinidad and Tobago), FINTECH and CBTT guidelines and regulations.
          </span>,
        ]}
      />
      <ContentImageBlock
        title="READY TO GET STARTED?"
        titleSm="h4"
        titleLg="h3"
        titleXs="h6"
        imgPosition="left"
        img={signUp_img}
        color="#1CC5FF"
        list={
          <ContentList
            items={[
              <span>
                <a
                  href={`${API_ROOT}document/yo-letter-of-interest`}
                  className={classes.yoozBlueLink}
                  target="_blank"
                >
                  Click
                </a>{" "}
                to download the letter of interest and email it to{" "}
                <a
                  href="mailto: yooz@yoozit.today"
                  className={classes.yoozBlueLink}
                >
                  yooz@yoozit.today
                </a>{" "}
                along with your Onboarding documents.
              </span>,
            ]}
          />
        }
      />
    </Container>
  );
}
