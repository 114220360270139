import { Divider, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import { API_ROOT } from "constants/actionTypes";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  selected: {
    fontWeight: 600,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `#FFF !important`,
  },
  label: {
    paddingLeft: 20,
  },
  greyText: {
    color: "#000",
    fontWeight:900
  },
}));

export default function SelectableNavList(props) {
  const classes = useStyles();
  const { value, setValue, routes } = props;
  const handleListItemClick = (event, index) => {
    setValue(index);
  };

  function ListItemLinkA(props) {
    return (
      <Link href={props.path} target="_blank" rel="noopener">
        <Typography variant={"caption"}>{props.text}</Typography>
      </Link>
    );
  }

  return (
    <Fragment>
      <List
        component="nav"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.greyText}
          >
            Main
          </ListSubheader>
        }
      >
        {routes.map((route, index) => (
          <Fragment key={index}>
            {index === 5 && (
              <Fragment>
                <Divider />
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.greyText}
                >
                  More Info
                </ListSubheader>
              </Fragment>
            )}
            {route.show ? (
              <ListItem
                button
                selected={value === index}
                onClick={(event) => handleListItemClick(event, index)}
                classes={{
                  selected: classes.selected,
                }}
                component={Link}
                to={route.path}
              >
                <ListItemText primary={route.label} className={classes.label} />
              </ListItem>
            ) : (
              <ListItem
                style={{ display: "none" }}
                button
                selected={value === index}
                onClick={(event) => handleListItemClick(event, index)}
                classes={{
                  selected: classes.selected,
                }}
                component={Link}
                to={route.path}
              >
                <ListItemText primary={route.label} className={classes.label} />
              </ListItem>
            )}
          </Fragment>
        ))}
        <Divider />
        <ListItem
          button
          component={"a"}
          href={`${API_ROOT}document/yo-terms-and-conditions`}
          target="_blank"
          rel="noopener"
        >
          <ListItemText
            secondary={"Terms and Conditions"}
            className={classes.label}
          />
        </ListItem>
        <ListItem
          button
          component={"a"}
          href={`${API_ROOT}document/yo-privacy-policy`}
          target="_blank"
          rel="noopener"
        >
          <ListItemText
            secondary={"Privacy Policy"}
            className={classes.label}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={`© YOOZ. ${new Date().getFullYear()}`}
            className={classes.label}
          />
        </ListItem>
      </List>
    </Fragment>
  );
}
