import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "./iconHeader";

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%",
    },
  },
  overlineText: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  img: {
    width: "100%",
  },
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function ContentImageBlock(props) {
  const {
    title,
    color,
    list,
    img,
    special,
    imgPosition = "right",
    titleSm,
    titleLg,
    titleXs,
    path,
    tabPosition,
    imgStyles,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const renderImage = () => (
    <img
      src={img}
      alt="Home Header"
      className={classes.img}
      style={{
        height: "100%",
        maxHeight: 400,
        width: md ? 400 : xs ? 100 : 250,
        ...imgStyles,
      }}
    />
  );
  return (
    <Container className={classes.root}>
      {xs && (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={1}
        >
          {imgPosition === "left" && (
            <Grid item xs={6}>
              <img src={img} alt="Home Header" className={classes.img} />
            </Grid>
          )}
          <Grid item xs={6}>
            {special ? (
              special
            ) : (
              <IconHeader
                path={path}
                titleSm={titleSm}
                titleXs={titleXs}
                titleLg={titleLg}
                title={title}
                iconColor="#cb010d"
                titleColor={color}
                icon={false}
                tabPosition={tabPosition}
              />
            )}
          </Grid>

          {imgPosition === "right" && (
            <Grid item xs={6}>
              <img src={img} alt="Home Header" className={classes.img} />
            </Grid>
          )}
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        {sm && imgPosition === "left" && (
          <Grid item xs={6} sm={5} style={{ textAlign: "center" }}>
            {renderImage()}
          </Grid>
        )}
        <Grid item container xs={12} sm={7}>
          {sm && (
            <Grid item>
              {special ? (
                special
              ) : (
                <IconHeader
                  path={path}
                  titleSm={titleSm}
                  titleXs={titleXs}
                  titleLg={titleLg}
                  title={title}
                  iconColor="#cb010d"
                  titleColor={color}
                  icon={false}
                  tabPosition={tabPosition}
                />
              )}
            </Grid>
          )}
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.body}
            spacing={2}
          >
            {list}
          </Grid>
        </Grid>
        {sm && imgPosition === "right" && (
          <Grid item xs={6} sm={5} style={{ textAlign: "center" }}>
            {renderImage()}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
