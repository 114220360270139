import { Divider } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { default as React } from "react";

const useStyles = makeStyles((theme) => ({
  table: { border: "none", width: "100%" },
  right: { textAlign: "right" },
  total: { textAlign: "right", color: theme.palette.secondary.main },

  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  blinkText: {
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "10",
    color: theme.palette.primary.main,
  },
}));

export default function CostBreakdown(props) {
  const { amount, total, updated, vat } = props;
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="table"
      className={classes.table}
    >
      <tbody>
        <tr>
          <td>Top Up Amount</td>
          <td className={classes.right}>${amount.toFixed(2)}</td>
        </tr>
        <tr>
          <td>+ VAT</td>
          <td className={classes.right}> ${(amount * vat).toFixed(2)}</td>
        </tr>

        <tr>
          <td>Total</td>
          <td className={classes.total}>
            <b>
              <Divider />{" "}
              {updated > 0 ? <i className={classes.blinkText}>NEW</i> : ""} $
              {total.toFixed(2)}
            </b>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">
            Receiver gets after VAT: <b>${amount.toFixed(2)}</b>
          </td>
        </tr>
      </tfoot>
    </Typography>
  );
}
