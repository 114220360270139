import {Menu, MenuItem, TableSortLabel, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { API_ROOT } from "constants/actionTypes";
import {
  downloadBlob,
  postBlob
} from "components/public-dashboard/services/api_util";
import { renderTableCellContent } from "./tableHelper";
import TablePaginationActions from "./tablePaginationActions";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      maxHeight: 600,
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: 500,
    },
  },
  exportButton: {
    marginLeft: "16px",
    marginTop: "16px",
  },
  paginationStyles: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchField: {
    float:"right",
    marginBottom: 5,
    marginRight: 5
  }
}));

export default function CustomTable(props) {
  const {
    pagedItems = [],
    totalItems = 10,
    handleFetch,
    pageIndex,
    handleQueryUpdate,
    query,
    columns,
    excelExportUrl,
    csvExportUrl,
    headStyle,
    searchValue = "",
    paginationEnabled = true,
    sortingEnabled = false,
    containerStyles,
    handleSort
  } = props;
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [pageSize, setPageSize] = useState(10);
  const [pageIndexLocal, setPageIndex] = useState(pageIndex - 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
    handleQueryUpdate({ ...query, searchTerm: searchValue, pageNumber: ++newPage, pageSize });
    handleFetch();
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(pageSize);
    handleQueryUpdate({ ...query, pageSize, searchTerm: searchValue });
    handleFetch();
  };

  const handleExcelExport = async (e) => {
    const blob = await postBlob(
      { ...query, pageSize: null },
      `${API_ROOT}${excelExportUrl}`
    );
    downloadBlob(blob, "Transactions Report.xlsx", "application/vnd.ms-excel");
  };

  const handleCSVExport = async (e) => {
    const blob = await postBlob(
      { ...query, pageSize: null },
      `${API_ROOT}${csvExportUrl}`
    );
    downloadBlob(blob, "Transactions Report.csv", "text/plain");
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSort(order, orderBy);
  };

  return (
    <Paper className={classes.root} style={containerStyles}>
      <TableContainer className={paginationEnabled ? classes.container : null}>
        {(excelExportUrl || csvExportUrl) && (
          <Button
            onClick={(event) => {
              setAnchorEl(event.target);
            }}
          />
        )}
        <Menu
          id="export-button"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem onClick={handleExcelExport}>Excel</MenuItem>
          <MenuItem onClick={handleCSVExport}>CSV</MenuItem>
        </Menu>

        <Table stickyHeader aria-label="sticky table" className={classes.root}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={headStyle}
                >

                  {!sortingEnabled && column.label}
                  {sortingEnabled &&
                    <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                          <span className={classes.visuallyHidden}>
                  </span>
                      ) : null}
                    </TableSortLabel>
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedItems.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.link && row[column.id] && <a  href={renderTableCellContent(row, column)} target={"_blank"} >{column.render  ? column.render(row[column.id]) : renderTableCellContent(row, column)}</a> }
                      {column.link && !row[column.id] && "N/A" }
                      {!column.link && renderTableCellContent(row, column)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationEnabled && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageIndexLocal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Paper>
  );
}
