import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserDetail } from "middleware/storage";
import {checkEmailRegex} from "components/utils"

import {
  addValidatedStep,
  setErrors,
  removeValidatedStep,
  setTxnDetails
} from "./topupSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

export default function EmailEntry(props) {
  const { handleNext, errors } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { txnDetails, activeStep } = useSelector((state) => state.topup);
  const token = getToken();

  useEffect(() => {
    const checkEmailExist = () => {
      if (token && txnDetails.email == "") {
        const user = getUserDetail();
        setValue(user.email);
        dispatch(
          setTxnDetails({
            ...txnDetails,
            email: user.email,
          })
        );
        dispatch(addValidatedStep(activeStep));
      } else{
        dispatch(addValidatedStep(activeStep));
      }
    };

    checkEmailExist();
  }, [dispatch]);
  

  const [value, setValue] = useState(txnDetails.email);


  const handleChange = (e) => {
    const email = e.target.value;
    setValue(email);
    if (email === "") {
      dispatch(setErrors({...errors, email: "Email cannot be empty"}));
      dispatch(removeValidatedStep(activeStep));
    } else if (!checkEmailRegex(email)
    ) {
      dispatch(setErrors({...errors, email: "Invalid email address."}));
      dispatch(removeValidatedStep(activeStep));
    }

    if (checkEmailRegex(email)) {
      dispatch(dispatch(setErrors({ ...errors, email: "" })));
      dispatch(
        setTxnDetails({
          ...txnDetails,
          email: email,
        })
      );
      dispatch(addValidatedStep(activeStep));
      handleNext();
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        id="txnEmail"
        label="Email"
        helperText={errors.email.length > 0 ? errors.email : ""}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        className={classes.formControl}
        error={errors.email.length > 0}
        type="email"
        color="secondary"
      />
    </div>
  );
}
