import { InputAdornment, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle } from "@material-ui/icons";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { API_ROOT } from "constants/actionTypes";
import { postAsync } from "components/public-dashboard/services/api_util";
import {
  addValidatedStep,
  removeValidatedStep,
  setLoading,
  setTxnDetails
} from "./topupSlice";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+#(###) ### - ####"
      mask="_"
    />
  );
}

export default function MobileValidator(props) {
  const { handleNext } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { txnDetails, activeStep } = useSelector(
    (state) => state.topup
  );
  const [value, setValue] = useState(txnDetails.mobileNumber);
  const [error, setError] = useState("");

  const validateNumber = async (msisdn) => {
    const response = await postAsync(
      {},
      `${API_ROOT}topup/ValidateMSISDN?msisdn=${msisdn}`
    );
    if (response.state === 1) {
      return response.data;
    } else {
      setError(response.msg);
      return false;
    }
  };

  const handleChange = async (e) => {
    setError("");
    const number = e.target.value;
    setValue(number);
    if (number.length === 11) {
      dispatch(setLoading("pending"));
      const response = await validateNumber(number);
      dispatch(setLoading("idle"));

      if (response) {
        dispatch(
          setTxnDetails({
            ...txnDetails,
            mobileNumber: number,
            carrier: response.phoneProvider,
            amount: "",
            presetSelected: { id: "", amount: 0 },
            coverVat: false,
          })
        );
        dispatch(addValidatedStep(activeStep));
        handleNext();
      }
    } else {
      dispatch(removeValidatedStep(activeStep));
      dispatch(
        setTxnDetails({
          ...txnDetails,
          mobileNumber: number,
          amount: "",
          donationAmount: "",
          presetSelected: { id: "", amount: 0 },
          coverVat: false,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        id="mobileNumber"
        value={value}
        label="Mobile Number"
        autoFocus
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={error.length > 0}
        helperText={error.length > 0 ? error : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {txnDetails.carrier && txnDetails.carrier.name}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {txnDetails.carrier && (
                <div>
                  <CheckCircle style={{ color: green[500] }} />
                </div>
              )}
            </InputAdornment>
          ),
          inputComponent: NumberFormatCustom,
        }}
        color="secondary"
      />
    </div>
  );
}
