import { Box, Container, Paper, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import MasterLogo from "assets/images/master.png";
import FACLogo from "assets/images/poweredByFirstAtlantic.png";
import VisaLogo from "assets/images/verifiedByVisa.jpg";
import { getToken } from "middleware/storage";
import Loading from "components/shared/loader/loader";
import IconHeader from "components/public-dashboard/components/iconHeader";
import BillPayStepper from "./stepper";
import SystemMessage from "components/public-dashboard/components/systemMessage";
import useServiceStatus from "hooks/useServiceStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight:"100vh"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      boxShadow: "none",
      margin: theme.spacing(1),
    },
  },
  logo: {
    width: "100%",
    maxWidth: "120px",
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

export default function Billpay(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const token = getToken();
  const { loading } = useSelector((state) => state.billpay);
  const { serviceLoading, serviceStatus } = useServiceStatus("billpay");
  if (serviceLoading) {
    return <Loading />;
  }

  return (
    <Box className={classes.root}>
      {loading && <Loading />}
      <Container maxWidth="lg" className={classes.root}>
        {!token ? (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            className={classes.gridContainer}
          >
            <Grid item>
              <IconHeader
                title="Quick Bill Pay"
                iconColor={theme.palette.primary.main}
                titleColor={theme.palette.secondary.main}
                titleLg="h2"
              />
            </Grid>
          </Grid>
        ) : (
          <IconHeader
            title="Bill Pay"
            iconColor={theme.palette.primary.main}
            titleColor={theme.palette.secondary.main}
            titleLg="h2"
          />
        )}
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            direction="row"
            justify="center"
          >
            <Grid item xs={12}>
              {serviceStatus.isActive ? <BillPayStepper /> : <SystemMessage title={serviceStatus.statusTitle} message={serviceStatus.statusMessage} type={serviceStatus.messageType}/>}
            </Grid>
            {token && (
              <Grid item xs={12}>
                <Grid
                  item
                  container
                  spacing={2}
                  alignItems="center"
                  direction="row"
                  justify="center"
                >
                  <Grid item xs={4} sm={2}>
                    <img
                      src={MasterLogo}
                      alt="Mastercard SecureCode"
                      className={classes.logo}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <img
                      src={VisaLogo}
                      alt="Verified by Visa"
                      className={classes.logo}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <img
                      src={FACLogo}
                      alt="Powered By First Atlantic"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
