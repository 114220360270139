import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import EditMobileNumber from "./editMobileNumber";
import MobileNumberList from "./mobileNumberList";
import RemoveMobileNumber from "./removeMobileNumber";
import AddMobileNumber from "./addMobileNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      // height: "400px",
    },
  },
  cardHeader: {},
}));

function MobileNumber(props) {
  const classes = useStyles();
  const {
    phoneList,
    user,
    isMobilePrimaryVisible,
    setMobileVisibility,
    onFieldChange,
    setMobilePrimaryVisibility,
    onPhoneRemove,
    setPhoneRemovePopupVisibily,
    error,
    customerphone,
    onMobilePrimary,
    isPhoneRemovePopupVisibily,
    isAddMobileVisible,
    onAddMobile
  } = props;

  return (
    <Fragment>
      <Card className={classes.root}>
        <CardHeader
          title={<Typography variant="h6"> Mobile Numbers </Typography>}
        />
        <Divider />
        <CardContent className={classes.content}>
          <MobileNumberList
            phoneList={phoneList}
            user={user}
            handleChange={onFieldChange}
            handleClick={setMobilePrimaryVisibility}
          />
        </CardContent>
        {/* <CardActions className={classes.cardAction}>
          <div>
            <Typography
              align="center"
              variant={"subtitle2"}
              color="inherit"
              style={{ margin: 0 }}
            >
              Add another mobile number to your Yooz account
            </Typography>
            <Box textAlign="center">
              <Button color="primary" onClick={setMobileVisibility}>
                Add New Number
              </Button>
            </Box>
          </div>
        </CardActions> */}
      </Card>
      {isMobilePrimaryVisible && (
        <EditMobileNumber
          open={isMobilePrimaryVisible}
          error={error}
          phone={customerphone}
          handleSubmit={onMobilePrimary}
          handleRemove={setPhoneRemovePopupVisibily}
          handleClose={setMobilePrimaryVisibility}
          phoneList={phoneList}
          title="Edit Mobile Number"
        />
      )}
      {isPhoneRemovePopupVisibily && (
        <RemoveMobileNumber
          open={isPhoneRemovePopupVisibily}
          handleClose={setPhoneRemovePopupVisibily}
          phone={customerphone}
          handleSubmit={onPhoneRemove}
          title="Remove Mobile Number"
        />
      )}
      {isAddMobileVisible && (
        <AddMobileNumber
          open={isAddMobileVisible}
          handleClose={setMobileVisibility}
          error={error}
          handleSubmit={onAddMobile}
          title="Add Mobile Number"
        />
      )}
    </Fragment>
  );
}

export default MobileNumber;
