import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Loading from "components/shared/loader/loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FFF",
  },
});

export default function DialogWrapper(props) {
  const { loading, open = false, handleClose, title, children } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return loading ? (
    <Loading />
  ) : (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
    >
      <DialogTitle
        style={{ background: theme.palette.primary.main, color: "#FFF" }}
        onClose={handleClose}
      >
        {title}
      </DialogTitle>

      <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}
