import React from "react";

export default function YoozSvgIcon(props) {
  const { height, width, color, minWidth = 10, maxWidth = "100%", styles = {} } = props;
  return (
<svg  version="1.1" viewBox="0 0 550.68 405.36"
      width={width}
      height={height}
      style={{ paddingLeft: 5, paddingRight: 5, minWidth, maxWidth, ...styles}}
>
      <defs>

  <linearGradient id="linear-gradient" x1="129.49" y1="215.53" x2="193.67" y2="215.53" gradientTransform="translate(13.09 21.54)" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#fff"/>
    <stop offset="1" stop-color="#fff"/>
  </linearGradient>
  <linearGradient id="linear-gradient-2" x1="217.5" y1="210.49" x2="156.8" y2="327.71" gradientTransform="translate(13.09 21.54)" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#75797a"/>
    <stop offset="1" stop-color="#75797a"/>
  </linearGradient>
  <linearGradient id="linear-gradient-3" x1="115.85" y1="231.79" x2="150.05" y2="231.79" />
  <linearGradient id="linear-gradient-4" x1="162.75" y1="229.1" x2="130.4" y2="291.56" />
  <linearGradient id="linear-gradient-5" x1="100.22" y1="239.73" x2="119.76" y2="239.73" />
  <linearGradient id="linear-gradient-6" x1="127.01" y1="238.2" x2="108.53" y2="273.88" />
</defs>
  <g fill="none">
    <g id="Layer_3">
      <rect class="cls-7"  width="550.68" height="405.36"/>
      <g fill="none">
        <g>
          <rect class="cls-1" fill="none" x="245.27" y="181.78" width="10.24" height="180.58"/>
          <g>
            <rect class="cls-1" fill="none" x="489.76" y="287.04" width="10.24" height="23.47" transform="translate(793.66 -196.11) rotate(90)"/>
            <rect class="cls-1" fill="none" x="489.76" y="297.28" width="10.24" height="23.47" transform="translate(803.9 -185.87) rotate(90)"/>
          </g>
          <g>
            <rect class="cls-1" fill="none" x="294.81" y="-19.89" width="10.24" height="413.58" transform="translate(486.83 -113.03) rotate(90)"/>
            <rect class="cls-1" fill="none"  x="294.81" y="-9.65" width="10.24" height="413.58" transform="translate(497.07 -102.79) rotate(90)"/>
          </g>
          <g>
            <rect class="cls-1" fill="none" x="103.06" y="181.78" width="10.24" height="180.58" transform="translate(216.36 544.14) rotate(-180)"/>
            <rect class="cls-1" fill="none" x="92.82" y="181.78" width="10.24" height="180.58" transform="translate(195.88 544.14) rotate(-180)"/>
          </g>
          <g>
            <rect class="cls-1" fill="none" x="496.38" y="181.78" width="10.24" height="180.58" transform="translate(1003 544.14) rotate(-180)"/>
            <rect class="cls-1" fill="none" x="486.14" y="181.78" width="10.24" height="180.58" transform="translate(982.52 544.14) rotate(-180)"/>
          </g>
          <g>
            <rect class="cls-1" fill="none"  x="141.41" y="293.19" width="10.24" height="106.78" transform="translate(493.11 200.05) rotate(90)"/>
            <rect class="cls-1" fill="none" x="141.41" y="303.43" width="10.24" height="106.78" transform="translate(503.35 210.29) rotate(90)"/>
          </g>
          <rect class="cls-1" fill="none" x="183.66" y="268.88" width="10.24" height="6.44"/>
          <rect class="cls-1" fill="none" x="245.27" y="271.22" width="10.24" height="6.44"/>
          <g>
            <g>
              <g>
                <path class="cls-10" fill="#FFF" d="M269.44,236.09c4.63.11,12.48,8.14,17.38,15.76,5.62,8.73,8.35,14.99,10.06,20.38.09.18.82.37,1.19-.37,2.19-4.75,8.31-17.83,11.06-23.95,1.74-3.93,2.47-6.3,3.1-8.13.18-.55,1.19-1.64,1.74-1.64,1.55,0,12.78-.55,15.71-.55.91,0,3.01.73,3.01,2.01,0,1.01-.73,1.55-2.74,2.1-5.75,1.55-6.21,2.47-6.21,4.57,0,13.51.64,25.75.91,34.15.28,7.21,2.19,7.85,5.48,8.76,2.56.64,3.47,1.55,3.47,2.47s-1.46,2.01-3.38,2.01c-1.1,0-10.77-.55-14.52-.55s-5.75.37-7.94.37c-2.01,0-2.92-1.55-2.92-2.28,0-.82,1.37-1.46,2.28-1.73,4.11-1.37,4.84-2.47,4.93-7.49.09-2.37,0-20.09.18-28.85,0-.46-.82-.27-1.1.28-1.46,2.65-10.04,22.55-11.78,26.57-1.01,2.37-2.92,7.4-4.11,10.32-.82,2.19-1.66,3.35-3.01,3.35-1.18-.17-1.35-1.43-1.61-2.65-.7-3.23-2.08-8.44-4.39-15.16-3.74-10.32-9.67-18.58-12.35-21.41-.66-.83-1.1-.73-1.1-.09-.73,9.68-1.28,22.64-1.28,27.3,0,5.48.73,5.84,5.48,7.49,2.01.73,2.74,1.37,2.74,2.46,0,1.01-1.37,1.92-3.2,1.92-1.28,0-4.57-.37-7.21-.37-2.92,0-9.31.55-10.5.55-1.74,0-3.29-.82-3.29-2.01,0-.82.82-1.64,3.01-2.28,4.47-1.28,6.02-3.29,6.67-7.12,1.34-7.97,4.24-31.36,3.22-32.44-1.31-1.53-6.47,3.45-9.67,3.58-.87.07-1.92-.85-.81-3.07s2.64-4.86,4.69-7.58c2.13-2.85,4.65-6.52,6.81-6.64Z"/>
                <path class="cls-10" fill="#FFF" d="M346.44,251.56c0-7.76-.37-8.67-5.57-9.86-1.55-.37-2.38-1.37-2.38-2.19,0-1.19,1.92-1.74,3.01-1.74,1.74,0,6.67.37,10.68.37,5.48,0,9.31-.28,11.96-.28,1.28,0,2.65.73,2.65,1.74s-1.46,1.74-2.19,1.92c-4.75,1.1-5.02,2.28-5.3,6.94-.28,3.93-.37,12.51-.37,18.72,0,9.95.18,13.97.18,15.16.18,4.38.91,5.84,5.75,7.03,1.74.46,2.74,1.28,2.74,2.38s-2.01,1.64-3.11,1.64c-.91,0-9.04-.28-12.14-.28s-9.4.46-11.05.46c-1.19,0-2.92-.46-2.92-1.64,0-.92,1.37-2.01,2.65-2.38,3.93-1.37,4.57-2.37,4.93-4.84.27-1.92.46-7.4.46-14.88v-18.26Z"/>
                <path class="cls-10" fill="#FFF"  d="M376.3,237.87c.46,0,7.67.28,11.32.28s9.86-.28,11.69-.28c1.92,0,2.92.73,2.92,1.83,0,1-1.1,1.92-2.92,2.28-4.84,1.01-5.39,2.65-5.57,4.93-.18,2.1-.46,6.48-.46,15.8,0,11.05.18,18.9.46,22,.09,1.19,2.01,3.1,5.02,3.83,2.19.46,6.66.37,9.22-.18,3.1-.82,6.85-5.11,9.04-9.13.82-1.74,1.74-2.38,2.1-2.38,1.37,0,2.37,1.83,2.1,3.38-.46,2.19-2.38,9.13-3.02,10.95-.55,1.37-2.19,2.28-4.02,2.28-3.2,0-13.33-.37-24.65-.37-6.21,0-13.42.46-15.43.46s-3.38-.91-3.38-2.01c0-1.19,1.19-1.92,2.74-2.19,6.12-1.28,6.76-3.29,6.94-5.39.18-2.19.37-6.94.37-17.81,0-16.53.09-16.98-.09-19.45-.28-3.01-1.55-3.56-4.57-4.75-1.92-.82-2.65-1.37-2.65-2.37,0-1.28,1.55-1.74,2.83-1.74Z"/>
                <path class="cls-10" fill="#FFF"  d="M461.3,245.17c4.84,13.24,12.42,31.59,14.7,37.16,2.19,5.02,3.29,5.21,7.3,6.76,2.1.82,2.83,2.1,2.83,3.1,0,.91-1.37,1.37-2.92,1.37-3.47,0-9.4-.46-13.88-.46-4.02,0-5.66.37-8.86.37-2.28,0-3.1-.91-3.1-1.55,0-1.01.91-2.19,2.65-2.83,3.56-1.28,3.65-2.83,3.01-5.02-.73-2.37-1.55-4.66-2.74-7.4-.09-.37-1-1.28-2.37-1.37-1.64-.18-12.87-.28-15.25.09-.46.09-1.28.91-1.46,1.37-.91,2.47-2.19,7.03-2.19,8.49s.91,2.83,4.29,3.93c1.19.46,2.38,1.01,2.38,2.1.09.55-1.1,2.1-3.47,2.1-1.01,0-3.65-.28-6.48-.28-3.29,0-6.39.46-10.04.46-1.92,0-3.29-.73-3.29-1.83,0-.92.73-1.83,2.74-2.47,3.74-1.37,5.48-2.1,7.76-6.94,2.46-5.2,9.95-24.19,15.07-37.8.18-.37.37-1.19.37-2.1,0-.27.46-.91.82-1.19,1.1-.73,4.66-3.1,5.66-3.93.55-.55,1.83-1,2.19-1,1.46,0,1.92,2.74,4.29,8.86ZM457.47,268.55c-1.28-4.48-5.48-15.07-6.21-16.53-.37-.55-.91-.37-1.19,0-1.64,3.65-5.11,13.6-6.03,16.53-.09.46.28,1.1.92,1.1,1.37.09,10.41.09,11.87,0,.37,0,.82-.73.64-1.1Z"/>
              </g>
              <g>
                <path class="cls-10" fill="#FFF"  d="M259.02,315.59h-2.63v-1.33h6.78v1.33h-2.63v7.24h-1.53v-7.24Z"/>
                <path class="cls-10" fill="#FFF"  d="M269.74,314.26h5.68v1.33h-4.16v2.18h3.94v1.29h-3.94v2.42h4.37v1.36h-5.9v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M288.89,316.27c-.23-.31-.52-.53-.88-.67-.36-.14-.72-.21-1.07-.21-.44,0-.85.08-1.21.24-.36.16-.68.38-.94.67s-.46.61-.61.99-.21.79-.21,1.24c0,.47.07.9.21,1.28.14.39.33.72.59,1,.26.28.56.5.91.65.36.15.76.23,1.2.23s.87-.09,1.22-.27.64-.42.86-.72l1.22.86c-.38.48-.84.85-1.39,1.11-.55.26-1.19.39-1.92.39-.67,0-1.29-.11-1.85-.33-.56-.22-1.04-.53-1.45-.93-.4-.4-.72-.88-.94-1.43-.23-.55-.34-1.17-.34-1.84s.12-1.31.36-1.86c.24-.55.57-1.02.98-1.41s.91-.69,1.47-.9,1.18-.32,1.84-.32c.27,0,.56.03.86.08.3.05.59.13.86.24.27.11.53.25.77.41.24.16.45.36.62.58l-1.16.92Z"/>
                <path class="cls-10" fill="#FFF"  d="M296.7,314.26h1.53v3.46h4.01v-3.46h1.53v8.58h-1.53v-3.8h-4.01v3.8h-1.53v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M311.23,314.26h2.02l3.99,6.42h.02v-6.42h1.53v8.58h-1.94l-4.07-6.63h-.03v6.63h-1.53v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M325.84,318.52c0-.69.12-1.31.35-1.86.23-.55.55-1.02.96-1.41.41-.39.89-.69,1.45-.9.56-.21,1.17-.32,1.84-.32s1.29.11,1.85.32,1.05.51,1.47.9c.41.39.73.86.96,1.41.23.55.35,1.17.35,1.86s-.12,1.28-.35,1.84c-.23.55-.55,1.03-.96,1.43-.41.4-.9.71-1.47.93s-1.18.33-1.85.33-1.27-.11-1.84-.33c-.56-.22-1.04-.53-1.45-.93-.41-.4-.73-.88-.96-1.43-.23-.55-.35-1.17-.35-1.84ZM327.5,318.52c0,.46.07.88.21,1.27.14.38.34.72.6,1,.26.28.57.5.93.66.36.16.76.24,1.19.24s.84-.08,1.2-.24c.36-.16.68-.38.94-.66.26-.28.46-.62.61-1,.14-.38.21-.81.21-1.27s-.07-.86-.21-1.24c-.14-.38-.34-.71-.6-.99-.26-.28-.57-.5-.93-.67-.36-.16-.77-.24-1.21-.24s-.85.08-1.21.24-.67.38-.93.67-.46.61-.6.99c-.14.38-.21.79-.21,1.24Z"/>
                <path class="cls-10" fill="#FFF"  d="M342.11,314.26h1.53v7.22h3.65v1.36h-5.17v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M353.09,318.52c0-.69.12-1.31.35-1.86.23-.55.55-1.02.96-1.41.41-.39.89-.69,1.45-.9.56-.21,1.17-.32,1.84-.32s1.29.11,1.85.32,1.05.51,1.47.9c.41.39.73.86.96,1.41.23.55.35,1.17.35,1.86s-.12,1.28-.35,1.84c-.23.55-.55,1.03-.96,1.43-.41.4-.9.71-1.47.93s-1.18.33-1.85.33-1.27-.11-1.84-.33c-.56-.22-1.04-.53-1.45-.93-.41-.4-.73-.88-.96-1.43-.23-.55-.35-1.17-.35-1.84ZM354.75,318.52c0,.46.07.88.21,1.27.14.38.34.72.6,1,.26.28.57.5.93.66.36.16.76.24,1.19.24s.84-.08,1.2-.24c.36-.16.68-.38.94-.66.26-.28.46-.62.61-1,.14-.38.21-.81.21-1.27s-.07-.86-.21-1.24c-.14-.38-.34-.71-.6-.99-.26-.28-.57-.5-.93-.67-.36-.16-.77-.24-1.21-.24s-.85.08-1.21.24-.67.38-.93.67-.46.61-.6.99c-.14.38-.21.79-.21,1.24Z"/>
                <path class="cls-10" fill="#FFF"  d="M376.87,322.39c-.44.21-.91.37-1.44.49-.52.12-1.12.18-1.8.18s-1.33-.11-1.9-.33c-.57-.22-1.06-.53-1.48-.93s-.73-.88-.96-1.43c-.23-.55-.35-1.17-.35-1.84s.12-1.31.36-1.86c.24-.55.57-1.02.98-1.41s.91-.69,1.47-.9,1.18-.32,1.84-.32,1.31.1,1.88.3c.57.2,1.03.46,1.37.78l-1.05,1.14c-.22-.24-.52-.45-.91-.62s-.82-.25-1.28-.25-.86.08-1.23.24-.68.38-.94.67-.46.61-.6.99-.21.79-.21,1.24.07.88.21,1.27c.14.38.34.72.61,1,.26.28.58.5.96.66.38.16.8.24,1.28.24.65,0,1.22-.1,1.71-.3v-2.18h-1.8v-1.26h3.28v4.45Z"/>
                <path class="cls-10" fill="#FFF"  d="M385.79,319.12l-3.22-4.86h1.86l2.16,3.61,2.18-3.61h1.77l-3.22,4.86v3.72h-1.53v-3.72Z"/>
                <path class="cls-10" fill="#FFF"  d="M405.13,314.26h1.53v7.22h3.65v1.36h-5.17v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M416.9,314.26h1.53v8.58h-1.53v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M425.88,314.26h2.39l2.23,6.09h.04l2.21-6.09h2.4v8.58h-1.52v-6.89h-.02l-2.6,6.89h-1.07l-2.57-6.89h-.02v6.89h-1.45v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M442.6,314.26h1.53v8.58h-1.53v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M453.32,315.59h-2.63v-1.33h6.78v1.33h-2.63v7.24h-1.53v-7.24Z"/>
                <path class="cls-10" fill="#FFF"  d="M464.04,314.26h5.68v1.33h-4.16v2.18h3.94v1.29h-3.94v2.42h4.37v1.36h-5.9v-8.58Z"/>
                <path class="cls-10" fill="#FFF"  d="M477,314.26h3.04c.56,0,1.12.07,1.68.22.57.15,1.07.39,1.53.72s.82.78,1.1,1.32c.28.55.42,1.22.42,2.03,0,.75-.14,1.4-.42,1.94s-.65.98-1.1,1.33c-.45.35-.96.6-1.53.77-.56.17-1.13.25-1.68.25h-3.04v-8.58ZM479.8,321.5c.4,0,.79-.05,1.19-.14s.75-.25,1.07-.47.58-.52.78-.9c.2-.38.3-.86.3-1.44,0-.61-.1-1.12-.3-1.5s-.46-.69-.78-.91c-.32-.22-.68-.37-1.07-.45s-.79-.12-1.19-.12h-1.27v5.94h1.27Z"/>
              </g>
            </g>
            <g>
              <path class="cls-8" fill="url(#linear-gradient)" d="M201.9,219.22l-13.94-16.96h-45.38l54.04,69.61s18.73-21.33,5.29-52.66Z"/>
              <path class="cls-6" fill="url(#linear-gradient-2)" d="M245.28,271.88l-57.32,69.58h-45.38l54.04-69.58s18.73-21.33,5.29-52.66l43.37,52.66Z"/>
              <path class="cls-5" fill="url(#linear-gradient)" d="M160.55,243.81l-7.43-9.04h-24.18l28.8,37.1s9.98-11.37,2.82-28.06Z"/>
              <path class="cls-4" fill="url(#linear-gradient-2)" d="M183.66,271.87l-30.54,37.08h-24.18l28.8-37.08s9.98-11.37,2.82-28.06l23.11,28.06Z"/>
              <path class="cls-2" fill="url(#linear-gradient)" d="M131.36,255.84l-4.25-5.16h-13.82l16.45,21.19s5.7-6.49,1.61-16.03Z"/>
              <path class="cls-3" fill="url(#linear-gradient-2)" d="M144.57,271.87l-17.45,21.18h-13.82l16.45-21.18s5.7-6.49,1.61-16.03l13.2,16.03Z"/>
            </g>
          </g>
        </g>
        <text class="cls-9" fill="#FFF" fontSize="57.45px" fontFamily="AkzidenzGroteskBQ-Reg, 'Akzidenz-Grotesk BQ'" transform="translate(430.56 159.08)"><tspan x="0" y="0">by</tspan></text>
        <g>
          <g>
            <g  >
              <path class="cls-10" fill="#FFF" d="M114.43,89.85c1.67,7,3.39,17.13,4.76,31.35,0,0,1.33,12,1.33,13.16h.33s17.33-28.33,17.33-34.33c0-.94-.46-2.17-.46-2.17-.71-1.54-1.09-3.33-1.09-5.14,0-6.74,5.47-12.2,12.2-12.2s12.21,5.47,12.21,12.2l-.04.98c-.72,20.3-30.79,59.88-47.88,80.59-11.2,13.34-24.35,27.49-40.09,27.83l-.74-.06c-5.29-.41-9.44-4.83-9.44-10.22,0-5.66,4.59-10.24,10.24-10.24,1.22,0,2.39.22,3.47.6l.41.17c.4.17.89.25,1.49.25,6.67,0,21.25-20.26,21.41-20.6l1-1.33-2.17-17.49c-1.83-14.5-6.33-39.99-8.5-44.65-1.48-2.81-.16-.25-.16-.25-.98-1.84-1.52-3.92-1.52-6.14,0-7.21,5.84-13.06,13.05-13.06,6.32,0,11.58,4.47,12.79,10.43l.07.34Z"/>
              <path class="cls-10" fill="#FFF"  d="M206.06,77.55c29.48,0,34.15,21.33,34.15,30.99,0,12.82-4.84,27.82-13.83,39.15-5.67,7-14.99,14.33-32.65,14.33-33.48,0-33.82-29.66-33.82-33.16,0-15.99,9-51.31,46.15-51.31ZM195.23,146.36c16.66,0,21.33-29.66,21.33-39,0-11.15-6.83-14.16-11.66-14.16-15.5,0-21.66,25.49-21.66,38.49,0,7.66,3.83,14.66,12,14.66Z"/>
              <path class="cls-10" fill="#FFF"  d="M288.18,77.55c29.49,0,34.16,21.33,34.16,30.99,0,12.82-4.83,27.82-13.83,39.15-5.66,7-14.99,14.33-32.65,14.33-33.49,0-33.82-29.66-33.82-33.16,0-15.99,9-51.31,46.15-51.31ZM277.35,146.36c16.66,0,21.33-29.66,21.33-39,0-11.15-6.83-14.16-11.66-14.16-15.5,0-21.66,25.49-21.66,38.49,0,7.66,3.83,14.66,11.99,14.66Z"/>
              <path class="cls-10" fill="#FFF"  d="M382.03,80.52l-.7.03h-9.26s1.32-5.62,1.32-5.62c.04-.16.12-.3.15-.46.04-.16.03-.32.06-.48l.06-.24h-.03c.77-4.97-2.36-9.79-7.34-10.95-4.98-1.18-9.93,1.74-11.43,6.54l-.07-.02-.14.63s-.03.05-.03.08c-.01.03-.01.06-.02.08l-2.43,10.43h-14.1s-1.32.02-1.32.02c-5.29,0-9.49,4.26-9.49,9.57s4.31,9.6,9.61,9.6c1.76,0,3.42-.47,4.83-1.3l.47-.29c1.27-.8,3.05-1.09,5.79-1.09h14.33l-45.32,45.62-.56.5c-1.72,1.71-2.77,4.07-2.77,6.68,0,5.21,4.22,9.43,9.43,9.43l2.25-.08h8.24l-1.22,5.22s-.03.06-.03.1c-.01.03-.01.07-.02.1l-.3,1.26.13.03c-.4,4.73,2.68,9.17,7.43,10.29,4.76,1.11,9.5-1.5,11.22-5.92l.1.03.21-.92c.04-.15.1-.28.14-.43.04-.15.04-.31.06-.45l2.19-9.31h16.74l.74.02c4.95-.34,8.88-4.46,8.88-9.51s-4.26-9.51-9.51-9.51c-2.3,0-4.41.82-6.06,2.18l-.39.14c-1.48.68-5.74.68-7.09.68h-14.82l46.69-45.89c1.4-1.47,1.97-3.2,1.97-3.2l.02-.06.05-.25c.4-1.07.61-2.22.61-3.43,0-5.34-3.96-9.72-9.27-9.85Z"/>
            </g>
            <rect class="cls-1" x="20.35" y="35" width="433.16" height="209.65"/>
          </g>
          <path class="cls-10"  fill="#FFF"  d="M403.48,138.6c-6.73,0-12.21,5.48-12.21,12.21s5.48,12.21,12.21,12.21,12.21-5.48,12.21-12.21-5.48-12.21-12.21-12.21ZM401.65,148.31h-2.13v6.6h-2.07v-6.6h-2.14v-1.62h6.35v1.62ZM411.65,154.92h-1.98l.16-6.61-1.83,6.61h-1.78l-1.82-6.61.16,6.61h-2v-8.22h3l1.55,5.75,1.53-5.75h3v8.22Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  );
}
