import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import AddPaymentCard from "./addPaymentCard";
import DialogWrapper from "./DialogWrapper";
import EditPaymentCard from "./editPaymentCard";
import PaymentMethodList from "./paymentMethodList";
import RemovePaymentCard from "./removePaymentMethod";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  cardAction: {
    justifyContent: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      // height: "400px",
    },
  },
  cardHeader: {},
}));

// export default class UserAccounts extends PureComponent {
export default function AccountPaymentMethods(props) {
  const classes = useStyles();

  const {
    isAddCardIsVisible,
    setVisibility,
    isEditCardVisibility,
    isCardRemovePopupVisibily,
    error,
    addressError,
    accountReducer,
    setEditCardVisibility,
    customercard,
    onEditCard,
    onRemoveCard,
    setCardRemovePopupVisibily,
    onCardRemove,
    onAddCard,
    payCards,
    onCheckFormField,
    onCardRowClick,
    validatePaymentMethod
  } = props;

  return (
    <Box>
      <Card className={classes.root}>
        <CardHeader
          title={<Typography variant="h6"> Saved Cards </Typography>}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PaymentMethodList
            cards={payCards}
            handleToggle={onCheckFormField}
            handleClick={onCardRowClick}
          />
        </CardContent>
        <CardActions className={classes.cardAction}>
          <div>
            <Box textAlign="center">
              <Button color="primary" onClick={setVisibility}>
                Add New Card
              </Button>
            </Box>
          </div>
        </CardActions>
      </Card>
      {(isEditCardVisibility || isAddCardIsVisible) && (
        <EditPaymentCard
          handleAddSubmit={onAddCard}
          handleEditSubmit={onEditCard}

          error={error}
          addressError={addressError}
          handleRemove={onRemoveCard}
          card={customercard}
          cards={payCards}
          open={isEditCardVisibility || isAddCardIsVisible}
          validatePaymentMethod={validatePaymentMethod}
          handleClose={() => {
            if(isAddCardIsVisible)
              setVisibility();
            else
              setEditCardVisibility();
          }}
          title="Edit Payment Method"
        />
      )}
      {isCardRemovePopupVisibily && (
        <RemovePaymentCard
          handleClose={setCardRemovePopupVisibily}
          open={isCardRemovePopupVisibily}
          card={customercard}
          handleSubmit={onCardRemove}
          title="Remove Payment Method"
        />
      )}
    </Box>
  );
}
