import { Button, Checkbox, Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AgreementCheckboxes from "components/shared/agreement_checkboxes";
import BillingAddress from "components/shared/billing_address";
import MaskedCCInput from "components/shared/payments/masked_cc_input";
import MaskedCvv from "components/shared/payments/masked_cvv_input";
import MaskedExpiryDate from "components/shared/payments/masked_expiry_date";
import { getCardType } from "components/utils";
import React, { useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogWrapper from "./DialogWrapper";
import paymentCard, { fetchCountries, fetchCountryStates, setIsLoading } from "./pmslice";
import PageLoading from "components/shared/loader/pageLoader";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function EditPaymentCard(props) {
  const { error, handleAddSubmit, handleEditSubmit, addressError, handleRemove, handleClose, card, cards, validatePaymentMethod } = props;

  const { isLoading } = useSelector((state) => state.paymentCard);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([])
  const [countryStates, setCountryStates] = useState([])
  const [paymentDetails, editCardDetails] = useState({
    name: card?.name || "",
    cardNumber: "",
    countryCode:"780",
    expirationDate: card?.expirationDate|| "",
    cvv: "",
    isPrimary: card?.isPrimary || false,
    tac: false,
    ppolicy: false,
    nickName: card?.nickName|| "",
    cardType: ""
  });

  const [billingAddressFields,  updateBillingDetails] = useState({
    id: card?.billingAddress.id || "",
    firstName: card?.billingAddress.firstName|| "",
    lastName: card?.billingAddress.lastName|| "",
    line1: card?.billingAddress.line1|| "",
    line2: card?.billingAddress.line2|| "",
    state: card?.billingAddress.state|| "",
    postalCode: card?.billingAddress.postalCode|| "",
    countryCode: card?.billingAddress.countryCode|| "780",
    city: card?.billingAddress.city|| "",
    emailAddress: card?.billingAddress.emailAddress|| "",
    phoneNumber: card?.billingAddress.phoneNumber|| "",
  });


  const [customError, setCustomError] = useState(false);
  const [tosAgreed, setTosAgreed] = useState(false);
  const [tosError, setTosError] = useState("");

  let handleSubmit = handleEditSubmit;
  if(!card)
    handleSubmit = handleAddSubmit;



  const blankAddressDetails = {
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    city: ""
  }

  let btnRef = useRef();

  useEffect(() => {
    fetchCountryList();
  }, [])

  async function fetchCountryList() {
    const result = await dispatch(fetchCountries());
    setCountries(result.payload);

    const result2 = await dispatch(fetchCountryStates("840"));
    setCountryStates(result2.payload);
  }

  const handleChange = (prop) => (event) => {
    if (["isPrimary", "tac", "ppolicy"].includes(prop)) {
      editCardDetails({ ...paymentDetails, [prop]: event.target.checked });
    } else if (prop === "countryCode" && event.target.value !== "") {
      editCardDetails({
                            ...paymentDetails,
                              [prop]: event.target.value,
                              ...blankAddressDetails
                          });
      updateBillingDetails({...billingAddressFields, [prop]: event.target.value })
    }else {
      editCardDetails({ ...paymentDetails, [prop]: event.target.value });
      updateBillingDetails({...billingAddressFields, [prop]: event.target.value })
    }
  };


  const handleToSChange = (prop) => (event ) =>{
    setTosAgreed(event.target.checked);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    dispatch(setIsLoading( true));
    paymentDetails.cardType = getCardType(paymentDetails.cardNumber);
    let isValid = validatePaymentMethod(paymentDetails, paymentDetails)

    if(!tosAgreed || !isValid)
      dispatch(setIsLoading(false));


    if(!tosAgreed && (isValid || card)){
      setTosError("Agree to Terms and Conditions.");
      return;
    }

    if(!card){
      // means we are adding card
      await handleSubmit(paymentDetails, paymentDetails);
      return;
    }


    handleSubmit({
        ...card,
        isPrimary: paymentDetails.isPrimary,
        nickName: paymentDetails.nickName,
        expirationDate: paymentDetails.expirationDate,
        name: paymentDetails.name,
        billingAddress:{
          ...billingAddressFields
      }

    });
  };

  function onClose() {
    editCardDetails({
      isPrimary: card?.isPrimary || false,
      nickName: card?.nickName || "",
    });
    handleClose();
  }

  function onRemove() {
    handleRemove();
  }

  const generateCardNumberField = () =>{
    let cardNumberField = (<TextField
        id="nickName"
        label="Card Number"
        variant="outlined"
        disabled
        fullWidth
        value={paymentDetails.nickName}
        color="secondary"
    />) ;
    if(!card)
      cardNumberField = (<MaskedCCInput
          error={addressError.cardNum}
          color="secondary"
          handleChange={handleChange}
          value={paymentDetails.cardNumber}
      />)

    return cardNumberField;
  }

  const generateCvvMask = () => {
    if(!card)
      return (<Grid item xs={12} md={6}>
        <MaskedCvv
            error={addressError.cvv}
            handleChange={handleChange}
            value={paymentDetails.cvv}
        />
      </Grid>)
  }

  return (
    <DialogWrapper {...props} handleClose={onClose}>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container direction="row" spacing={2}>
        <FormLabel component="legend">Card Details</FormLabel>
        <Grid item sm={12} xs={12}>
          <TextField
            id="name"
            label="Name on card *"
            variant="outlined"
            fullWidth
            helperText={addressError.name?.length > 0 ? addressError.name:""}
            error={addressError.name?.length > 0}
            value={paymentDetails.name}
            onChange={handleChange("name")}
            className={classes.formControl}
            color="secondary"
            inputProps={{
              maxLength: 50,
            }}
            onInput={(e) => {
              e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {generateCardNumberField()}
        </Grid>
        <Grid item xs={12} md={!card ? 6 : 12}>
            <MaskedExpiryDate
              helperText={addressError.expDate?.length > 0 ? addressError.expDate:""}
              error={addressError.expDate?.length > 0 ? addressError.expDate:""}
              handleChange={handleChange}
              value={paymentDetails.expirationDate}
            />
          </Grid>
          {generateCvvMask()}
        <FormLabel component="legend">Billing Address Details</FormLabel>
          <Grid item sm={12} xs={12}>
            <BillingAddress handleChange={handleChange} countries={countries} countryStates={countryStates} errors={addressError} addressDetails={billingAddressFields} requireMinFields={true}/>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentDetails.isPrimary}
                  onChange={handleChange("isPrimary")}
                  required={cards.length === 0 ? true : false}
                />
              }
              label="Primary Payment Method"
            />
          </Grid><Grid item xs={12}>
          <AgreementCheckboxes applyDefaultStyles={false} value={tosAgreed} handleChange={handleToSChange} error={tosError} />
          </Grid>
          {addressError.alert && (
            <Grid item xs={12}>
              <Alert severity="error"> {addressError.alert} </Alert>
            </Grid>
          )}
          {customError && (
            <Grid item xs={12}>
              <Alert severity="error"> {customError} </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              onClick={onRemove}
              fullWidth
              color="secondary"
              variant="outlined"
            >
              Remove Card
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
      <PageLoading loading={isLoading} />
    </DialogWrapper>
  );
}
