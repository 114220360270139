import { Container } from "@material-ui/core";
import React, { Fragment } from "react";
import faqBanner from "assets/images/faq_banner.jpg";
import { getToken } from "middleware/storage";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import FAQContent from "./content";
import FAQHeader from "./header";

export default function FAQ() {
  const token = getToken();

  if (token) {
    return (
      <Container maxWidth="lg">
        <FAQHeader />
        <FAQContent />
      </Container>
    );
  }
  return (
    <Fragment>
      <PageBanner img={faqBanner} />
      <ContentWrapper>
        <FAQHeader />
        <FAQContent />
      </ContentWrapper>
    </Fragment>
  );
}
