import React, { PureComponent } from "react";
import history from "history/history";
import Button from '@material-ui/core/Button';

export class NotFound extends PureComponent {
  render() {
    return (
      <div className="error-box">
        <div>
          <h2>404</h2>
          <p>Page not found</p>
          <Button onClick={() => history.goBack()}>Go Back</Button>
        </div>
      </div>
    );
  }
}
