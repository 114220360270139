import {
    Box,
    Grid,
    Hidden,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import EmptyImg from "assets/images/empty-folder.png";
import React from "react";
import CustomTable from "./tableContent";
import CustomTableMobile from "./tableContentMobile";
const useStyles = makeStyles((theme) => ({
  searchField: {
    float: "right",
    marginBottom: 5,
    marginRight: 5,
  },
}));
export default function Table(props) {
  const {
    totalItems,
    handleNavUpdate,
    handleQueryUpdate,
    handleFetch,
    query,
    searchEnabled = false,
  } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [searchValue, setSearchValue] = React.useState("");
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    handleQueryUpdate({ ...query, searchTerm: event.target.value });
    handleFetch();
  };

  const classes = useStyles();

  return (
    <Box>
      {searchEnabled && (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="standard"
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Search…"
          className={classes.searchField}
          color="secondary"
        />
      )}
      {totalItems > 0 ? (
        <Grid container spacing={xs ? 1 : 3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <CustomTable {...props} searchValue={searchValue} />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <CustomTableMobile {...props} searchValue={searchValue} />
            </Grid>
          </Hidden>
        </Grid>
      ) : (
        <Paper>
          <Box p={3}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={12} style={{ opacity: 0.5 }}>
                <img
                  src={EmptyImg}
                  alt="empty folder icon"
                  style={{ height: 128 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  No transactions found. Try changing the filter.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
}
