import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import scan2pay_img from "assets/images/scan2pay_content.png";
import ContentList from "components/public-dashboard/components/contentList";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  goodsAndServices: {
    fontSize: 36,
    color: "#000",
  },
}));

export default function Scan2Header() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      <Grid
        container
        alignItems="center"
      >
        <Grid item xs={6}>
          <IconHeader
            title="SCAN<br>2PAY"
            subTitle="FOR GOODS & SERVICES"
            subTitleLg="h4"
            subTitleSm="h5"
            subTitleXs="caption"
            iconColor="#000"
            titleColor="#BD2126"
            titleLg="h3"
            titleSm="h4"
            titleXs="subtitle2"
          />

        </Grid>
        {sm && (
          <Grid item xs={6}>
            <img src={scan2pay_img} alt="topup" className={classes.img} />
          </Grid>
        )}
        <Grid item container sm={12} md={7}>
          <Grid item container>
            <TextList
              items={[
                <span>
                  PAY for your favourite goods and services, instore or wherever
                  you are with{" "}
                  <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                    YOOZ Scan2Pay.
                  </span>
                </span>,
                <span>
                  <span style={{ color: "#cb010d", fontWeight: "bold" }}>
                    Fast and simple, no App downloads and no sign-up necessary!
                  </span>
                </span>,
              ]}
            />
          </Grid>
          <Grid item container>
            <ContentList
              title="HOW DOES IT WORK?"
              items={[
                <span>
                  We provide businesses with a unique and secure code or payment
                  link which they can share with you in store, on their social
                  media pages, website, WhatsApp or messaging service.
                </span>,
                <span>
                  You pay online by scanning the <b>YOOZ QR Code</b> or clicking
                  the <b>YOOZ Payment Link</b>. Once your transaction is
                  successful, you and the salesperson will immediately and
                  simultaneously receive an e-receipt confirming success. At
                  that point you receive your goods and enjoy!
                </span>,
              ]}
            />
          </Grid>
        </Grid>
        {md && (
          <Grid item xs={6} sm={5}>
            <img src={scan2pay_img} alt="topup" className={classes.img} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
