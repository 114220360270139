export const actionRoutes = {
  login: { path: `/login`, label: "Login" },
  signup: { path: `/signup`, label: "Signup" },
  quickpay: { path: `/quickpay`, label: "QUICK PAY" },
  quicktopup: { path: `/quicktopup`, label: "⚡ TOP UP" },
};

export const quickPayRoutes = {
  title: "⚡ Quick Pay",
  routes: [
    { path: `/quickpay`, label: "Bill Pay" },
    { path: `/quicktopup`, label: "Top Up" },
  ],
};

export const waysToPayRoutes = {
  title: "PAY with YOOZ",
  routes: [
    {
      label: "Ways to Pay",
      show: true,
      path: "",
      subRoutes: [
        {
          path: `/billpay`,
          label: "Pay Bills",
          show: true,
        },
        {
          path: `/topup`,
          label: "Top Up",
          show: true,
        },
        {
          path: `/scan2payGnS`,
          label: "Pay For Goods",
          show: true,
        },
        {
          path: `/AgentLocations`,
          label: "Pay at Agent Locations",
          show: true,
        },
      ],
    },
    {
      label: "Donate",
      show: true,
      path: "/donate",
    },
  ],
};

export const yoozBusinessRoutes = {
  title: "YOOZ for Business",
  routes: [
    {
      label: "Services",
      show: true,
      path: "",
      subRoutes: [
        {
          path: `/partners`,
          label: "Collect Payments",
          show: true,
        },
        {
          path: `/scan2pay`,
          label: "YOOZ Scan2Pay",
          show: true,
        },
        {
          path: `/payment-gateway`,
          label: "YOOZ Payment Gateway",
          show: true,
        },
        {
          path: `/collect-donations`,
          label: "Collect Donations",
          show: true,
        },
        { path: `/agents`, label: "Become a YOOZ Agent", show: true },
      ],
    },
    {
      label: "Onboarding Documents",
      show: true,
      path: "",
      subRoutes: [
        {
          path: `/OnboardingDocuments`,
          label: "For Business",
          show: true,
        },
        {
          path: `/AgentOnboardingDocuments`,
          label: "For Agents",
          show: true,
        },
      ],
    },
  ],
};
