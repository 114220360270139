import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import PageLoading from "components/shared/loader/pageLoader";
import PrivateRouter from "./router";
import CustomSecuredAppBar from "./appbar";
import CustomSecuredDrawer from "./drawer";
import { Toolbar } from "@material-ui/core";
import {
  getUserDetail,
  removeToken,
  removeUserAuth,
  removeUserDetail,
} from "middleware/storage";
import history from "history/history";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  scrollUpBtn: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    background: grey[50],
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      background:"#FFF"
    },
  },
  appBarOffset: {
    marginTop: 70,
    [theme.breakpoints.up("sm")]: {
      marginTop: 90,
    },
  },
  [theme.breakpoints.up("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: 10,
      },
      "*::-webkit-scrollbar-track": {
        background: "lightGrey",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 20,
        border: "2px solid lightBlue",
      },
    },
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollUpBtn}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function SecuredApp(props) {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.billpay);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    async function validateLogin() {
      const user = getUserDetail();
      setUser(user);
    }

    validateLogin();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = async () => {
    removeUserAuth();
    removeToken();
    removeUserDetail();
    history.push("/");
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <CustomSecuredAppBar
        handleDrawerToggle={handleDrawerToggle}
        user={user}
        handleLogout={logout}
      />
      <CustomSecuredDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main className={classes.content}>
        {/* <Toolbar /> */}
        <span id="back-to-top-anchor" />
        <div className={classes.appBarOffset}>
          <PrivateRouter {...props} />
        </div>
      </main>

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <PageLoading loading={loading} />
    </div>
  );
}
