import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function SelectCountry(props) {
  const classes = useStyles();
  const { countryStates, value, handleChange, error } = props;

  return (
    <div className={classes.root}>
      <TextField
        id="State"
        error={error?.length > 0}
        helperText={error?.length ? error : ""}
        select
        label="State *"
        value={value}
        onChange={handleChange("state")}
        variant="outlined"
        fullWidth
        color="secondary"
      >
        {countryStates.map((option) => (
          <MenuItem key={option.alphaCode} value={option.alphaCode}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
