import React, { PureComponent } from "react";

export default class Loading extends PureComponent {
  render() {
    return (
      <div className="loader-main-box">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}
