import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import BillingAddress from "components/shared/billing_address";
import { addValidatedStep, fetchAddressStates, setActiveStep, setAddressDetails } from "components/public-dashboard/features/topup/topupSlice";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

const blankAddressDetails = {
  line1: "",
  line2: "",
  postalCode: "",
  state: "",
  city: "",
};

function TopupBillingAddress(props) {
  const { setAddressDetails, addressDetailsState } = props;
  const [addressDetails, updateAddressDetails] = useState(addressDetailsState);
  const { countryCode } = addressDetails;
  const [countryStates, setStates] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchStatesByCountryCode(countryCode);
    dispatch(addValidatedStep(4));
  }, [countryCode]);

  async function fetchStatesByCountryCode(countryCode) {
    if (countryCode > 0) {
      const result = await dispatch(fetchAddressStates(countryCode));
      setStates(result.payload);
    }
  }

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    let updatedDetails = { ...addressDetails, [prop]: value };
    if (prop === "countryCode" && event.target.value !== "") {
      updatedDetails = { ...updatedDetails, ...blankAddressDetails };
    }

    setAddressDetails({ ...addressDetails, ...updatedDetails });
    updateAddressDetails({ ...addressDetails, ...updatedDetails });
  };

  return (
    <BillingAddress
      {...props}
      addressDetails={addressDetails}
      handleChange={handleChange}
      countryStates={countryStates}
    />
  );
}

const mapStateToProps = (state) => ({
  addressDetailsState: state.topup.addressDetails,
  errors: state.topup.errors,
  countries: state.topup.countries,
});

const mapDispatch = { setAddressDetails };

export default connect(mapStateToProps, mapDispatch)(TopupBillingAddress);
