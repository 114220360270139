import React, { Fragment } from "react";
import scan2payBanner from "assets/images/scan2pay_banner.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import Scan2PayContent from "./content";
import Scan2PayHeader from "./header";

export default function Scan2Pay() {
  return (
    <Fragment>
      <PageBanner img={scan2payBanner} />
      <ContentWrapper>
        <Scan2PayHeader />
        <Scan2PayContent />
      </ContentWrapper>
    </Fragment>
  );
}
