import React from "react";

export default function PageLoading(props) {
  const { loading } = props;

  if (loading) {
    return (
      <div className="loader-main-box">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return <div />;
}
