import moment from "moment";

export const renderTableCellContent = (row, column) => {
  if (!row[column.id]) return "";
  
  if (column.format === "currency" && column.currency) {
    const amount = parseFloat(row[column.id]).toFixed(2);
    return ` ${row[column.currency.code]} ${row[column.currency.symbol]}${amount}  `;
  } else if (column.format === "date" && column.dateFormat) {
    return moment(row[column.id]).format(column.dateFormat);
  } else {
    return row[column.id];
  }
};
