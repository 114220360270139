import { Box, Paper, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { Fragment, useState } from "react";
import CustomTable from "components/shared/table/tableContent";
import CustomTableMobile from "components/shared/table/tableContentMobile";
import Table from "components/shared/table/table";

const useStyles = makeStyles((theme) => ({
  yoozBlueLink: {
    color: "#00AFE2",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: "#1E7D83",
    color: "#FFFFFF",
  },
  tableCell: {
    border: "1px solid #1E7D83",
    padding: "8px",
    textAlign: "center",
  },
  tabsContainer: {
    flexWrap: "wrap",
  },
  indicatorHidden: {
    display: "none",
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff !important",
  },
}));

export default function TabbedTables(props) {
  const { columns, tables } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <Fragment>
      <Paper>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
          centered={!sm}
          classes={{
            flexContainer: sm ? classes.tabsContainer : "",
            indicator: sm ? classes.indicatorHidden : "",
          }}
          variant={!sm ? "fullWidth" : "standard"}
        >
          {tables.map((table, index) => (
            <Tab
              key={index}
              label={table.title}
              {...a11yProps(index)}
              classes={{ selected: classes.selectedTab }}
            />
          ))}
        </Tabs>
      </Paper>

      {tables.map((table, index) => (
        <TabPanel value={activeTab} index={index} key={"table-" + index}>
          <Table
              columns={columns}
              pagedItems={table.data}
              totalItems={table.data.length}
              headStyle={{ background: "#57B56B", color: "#fff" }}
              paginationEnabled={false}/>
        </TabPanel>
      ))}
    </Fragment>
  );
}
