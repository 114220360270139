import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listItem: {
    padding:0,
    marginBottom: theme.spacing(1)
  },
  subheader: {
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
  },
}));

export default function TopUpTxnDetailListing(props) {
  const classes = useStyles();
  const {
    carrier,
    mobileNumber,
    country,
    authCode,
    donationAmount,
    charity,
    email,
    amount,
  } = props.values;

  const { vat } = props;
  const amountBV = amount / (1 + vat);
  return (
    <Fragment>
      <List className={classes.root} dense>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Country"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    {country.description}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Carrier"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>{carrier.name}</Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Mobile Topped Up"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    <b>
                      <NumberFormat
                        value={mobileNumber}
                        format="+#(###) ###-####"
                        displayType={"text"}
                      />
                    </b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary="Receiver's Amount"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ListItemText
                primaryTypographyProps={{ align: "left" }}
                primary={
                  <Typography className={classes.subheader}>
                    <b>
                      <NumberFormat
                        value={amountBV}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        isNumericString
                        prefix={"$"}
                        displayType="text"
                      />
                    </b>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        {charity !== "" && (
          <Fragment>
            <ListItem className={classes.listItem}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary="Charity"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary={
                      <Typography className={classes.subheader}>{charity.name}</Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary="Donation Amount"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ListItemText
                    primaryTypographyProps={{ align: "left" }}
                    primary={
                      <Typography className={classes.subheader}>
                        <NumberFormat
                          value={donationAmount || 0}
                          fixedDecimalScale
                          decimalScale={2}
                          thousandSeparator
                          isNumericString
                          prefix="$"
                          displayType="text"
                        />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Fragment>
        )}

        {authCode && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Authorization #"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={
                    <Typography className={classes.subheader}>{authCode}</Typography>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {email && (
          <ListItem className={classes.listItem}>
            <Grid container>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary="Email"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItemText
                  primaryTypographyProps={{ align: "left" }}
                  primary={<Typography className={classes.subheader}>{email}</Typography>}
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
    </Fragment>
  );
}
