import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "assets/images/provider_1.png";
import img2 from "assets/images/provider_2.png";
import img3 from "assets/images/provider_3.png";
import img4 from "assets/images/provider_4.png";
import img5 from "assets/images/provider_5.png";
import img6 from "assets/images/provider_6.png";
import img7 from "assets/images/provider_7.png";
import img8 from "assets/images/provider_8.png";
import img9 from "assets/images/provider_ (2).png";
import img11 from "assets/images/provider_ (4).png";
import img12 from "assets/images/provider_ (5).png";
import img13 from "assets/images/provider_13.png";
import img14 from "assets/images/provider_14.png";

const useStyles = makeStyles((theme) => ({
  carouselCointainer: {
    background: "#C0E8F8",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  item: {
    padding: "20%",
    [theme.breakpoints.down("sm")]: {
      padding: "10%",
    },
  },
  imgContainer: {
    height: "120px",
  },
  img: {
    minWidth: "100%",
    maxWidth: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export default function ProviderCarousel(props) {
  const classes = useStyles();
  return (
    <Carousel
      minimumTouchDrag={80}
      swipeable
      draggable={false}
      showDots={false}
      responsive={responsive}
      infinite
      autoPlay={props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={1000}
      customTransition="all 1s linear"
      keyBoardControl
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      deviceType={props.deviceType}
      className={classes.carouselCointainer}
      focusOnSelect={false}
    >
      {[img1, img2, img3, img4, img5, img6, img7, img8, img9, img11, img12, img13, img14].map((img, index) => (
        <div key={index} className={classes.item}>
          <div className={classes.imgContainer}>
            <img src={img} alt={"img" + index} className={classes.img} />
          </div>
        </div>
      ))}
    </Carousel>
  );
}
