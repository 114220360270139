const userSchema = {
  auth: {},
  smsCred: {},
  details: {},
  queryString: "",
  countries: [],
  tokens: {},
  reset: {},
};

const activitySchema = {
  activities: [],
  providers: [],
  searched: [],
  monthly: [],
  monthlystats: [],
  transactions: [],
  transactionstopups: [],
  transactionsbillpay: [],
};

const dashBoardSchema = {
  dash: [],
};

const billSchema = {
  billPays: [],
};

const accountSchema = {
  accounts: [],
  smsCred: {},
  phoneList: [],
  payCards: [],
};

const supportSchema = {
  help: {},
  conatact: {},
  aboutUs: [],
};

export const Schemas = {
  USER: userSchema,
  BILLPAY: billSchema,
  HOME: dashBoardSchema,
  ACTIVITY: activitySchema,
  SUPPORT: supportSchema,
  ACCOUNTS: accountSchema,
};
