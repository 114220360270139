import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import TextList from "./TextList";

const useStyles = makeStyles((theme) => ({
  overlineText: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    fontSize: 35,
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
    color: "rgb(28, 197, 255)",
  },
  img: {
    width: "100%",
    maxWidth: "45%"
  },
}));

export default function MerchantDescription(props) {
  const { title, items, logo,alt,link } = props;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    
    <Grid container direction="row">
  { sm &&(<Grid item xs={7}>  
    <Grid item xs={6}>
  <Typography
    variant={xs ? "body1" : sm ? "h6" : md ? "h5" : "h4"}
    className={classes.overlineText}
  >
    {title}
  </Typography>
  </Grid>        
 <Grid item container xs={12}>
  <TextList items={items} identation="5%" />
</Grid>  
</Grid> )} 

{ xs &&(<Grid item xs={12}>  
    <Grid item xs={6}>
  <Typography
    variant={xs ? "body1" : sm ? "h6" : md ? "h5" : "h4"}
    className={classes.overlineText}
  >
    {title}
  </Typography>
  </Grid>        
 <Grid item container xs={12}>
  <TextList items={items} identation="5%" />
</Grid>  
</Grid> )} 
{ sm && (<Grid item xs={5} >
    <a href={link} target="_blank">
      <img src={logo} alt={alt} className={classes.img} />
     </a> 
   </Grid>)}

   {xs && (
       <Grid item xs={12} >
       <a href={link} target="_blank">    
       <img src={logo} alt={alt} className={classes.img} />
       </a>
    </Grid>
   )}
</Grid>
  );
}
