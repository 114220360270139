import React, { Fragment } from "react";
import BannerCarousel from "./banner_carousel";
import ContentSection from "./content_section";
import ProviderCarousel from "./provider_carousel";
import TestimonyCarousel from "./testimony_carousel";

export default function Home() {
  return (
    <Fragment>
      <BannerCarousel />
      <ContentSection />
      <ProviderCarousel />
      <TestimonyCarousel />
    </Fragment>
  );
}
