import { Button, Grid, Typography, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import DialogWrapper from "./DialogWrapper";
import PaymentMethodItem from "./paymentMethodItem";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function RemovePaymentCard(props) {
  const classes = useStyles();

  const { error, handleSubmit, handleClose, card } = props;

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  return (
    <DialogWrapper {...props}>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Are you sure you want to remove the following payment method?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <PaymentMethodItem card={card} />
            </List>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error"> {error} </Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button fullWidth onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </DialogWrapper>
  );
}
