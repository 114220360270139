import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { postAsync } from "components/public-dashboard/services/api_util";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import { setNavIndex } from "components/secured-app/uiSlice";
import TxnResult from "components/shared/txn_result";
import SomethingWentWrong from "components/shared/ui/somethingWentWrong";
import { getCardType, isValidateExpDate } from "components/utils";
import { API_ROOT } from "constants/actionTypes";
import { getToken } from "middleware/storage";
import React, { useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "./address_details";
import AmountSelector from "./amount_selector";
import CountrySelector from "./country_selector";
import DonationEntry from "./enter_donation_amount";
import EmailEntry from "./enter_email";
import MobileValidator from "./mobile_validator";
import PaymentDetails from "./payment_details";
import {
  resetFromStep,
  setActiveStep,
  setErrors,
  setLoading,
} from "./topupSlice";
import TxnConfirmation from "./txn_confirmation";
import { Grid } from "@material-ui/core";
import CarrierSelector from "./carrier_selector";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepper-root": {
      padding: 0,
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 20,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    float: "right",
  },
  resetContainer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  resetBtn: {
    borderRadius: 20,
    marginTop: theme.spacing(2),
    float: "right",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  phoneNumberEntered: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
}));

const facState = { state: "pending", data: "" };

export default function TopupStepper() {
  const btnRef = useRef(null);
  const dispatch = useDispatch();

  const classes = useStyles();
  const {
    activeStep,
    validatedSteps,
    txnDetails,
    errors,
    submissionDetails,
    paymentDetails,
    addressDetails,
    showCCForm,
  } = useSelector((state) => state.topup);
  const [facResponse, setFacResponse] = useState(facState);
  const [emailError, setEmailError] = useState("");
  const token = getToken();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const steps = [
    {
      label: "Select Country",
      value: (
        <Typography color="secondary" variant="h6">
          {txnDetails.country.countryId >= 0
            ? txnDetails.country.description
            : ""}{" "}
        </Typography>
      ),
    },
    {
      label: "Enter PREPAID Mobile Number",
      value:
        txnDetails.mobileNumber.length === 11 ? (
          <Typography color="secondary" variant="h6">
            <div className={classes.phoneNumberEntered}>
              <img
                src={txnDetails.carrier.logo}
                style={{ height: 30 }}
                alt={txnDetails.carrier.name}
              ></img>
              <NumberFormat
                value={txnDetails.mobileNumber}
                format="+#(###) ### - ####"
                displayType="text"
              />
            </div>
          </Typography>
        ) : (
          ""
        ),
    },
    {
      label: "Select Top Up Amount",
      value: (
        <Typography color="secondary" variant="h6">
          {txnDetails.amount > 0 ? `$${txnDetails.amount.toFixed(2)} TTD` : ""}
        </Typography>
      ),
    },
    { label: "Enter Card Details" },
    { label: "Enter Billing Address Details (Optional)" },
    { label: "Enter Email for Receipt" },
    { label: "Payment Review" },
  ];
  const allStepsValidated =
    [0, 1, 2, 3, 4, 5, 6].every((v) => validatedSteps.includes(v)) &&
    activeStep !== 6;

  const handleNext = async (goToFinish = false) => {
    let isValid = true;
    let paymentErrors = {
      name: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      customerCardId: 0,
    };

    if (activeStep === 3) {
      const { name, cardNumber, expirationDate, cvv, customerCardId } =
        paymentDetails;

      if (!token || (token && showCCForm)) {
        if (name === "") {
          paymentErrors.name = "Name cannot be empty";
          isValid = false;
        } else if (/[^a-zA-Z-'\s]/.test(name)) {
          paymentErrors.name = "Name on Card is invalid.";
          isValid = false;
        }

        const cardType = getCardType(cardNumber);
        if (
          cardType.toUpperCase() !== "VISA" &&
          cardType.toUpperCase() !== "MASTERCARD"
        ) {
          paymentErrors.cardNumber = "Invalid credit card number.";
          isValid = false;
        }

        if (cardType.toUpperCase() === "VISA") {
          if (cardNumber.length !== 13 && cardNumber.length !== 16) {
            paymentErrors.cardNumber = "Incomplete Credit Card Number.";
            isValid = false;
          }
        }

        if (cardType.toUpperCase() === "MASTERCARD") {
          if (cardNumber.length !== 16) {
            paymentErrors.cardNumber = "Incomplete Credit Card Number.";
            isValid = false;
          }
        }

        if (expirationDate === "") {
          paymentErrors.expirationDate = "Expiration date cannot be empty.";
          isValid = false;
        } else if (expirationDate.length < 4) {
          paymentErrors.expirationDate = "Expiration date is incomplete.";
          isValid = false;
        }

        if (!isValidateExpDate(expirationDate)) {
          paymentErrors.expirationDate = "Expiration date is invalid.";
          isValid = false;
        }
      }

      if (token && cvv === "") {
        paymentErrors.cvv = "CVV cannot be empty.";
        isValid = false;
      } else if (cvv.length < 3) {
        paymentErrors.cvv = "CVV must be exactly 3 digits.";
        isValid = false;
      }

      if (token && !showCCForm && customerCardId === 0) {
        paymentErrors.customerCardId = "Must select a payment method.";
        isValid = false;
      }

      dispatch(setErrors({ ...errors, ...paymentErrors }));
      if (isValid) {
        dispatch(setActiveStep(1));
      }
    }
    if (activeStep === 5) {
      const { email } = txnDetails;
      if (email === "") {
        // actual validation happens in component.
        isValid = false;
        dispatch(
          setErrors({ ...errors, email: "Email address cannot be empty." })
        );
      } else {
        dispatch(setActiveStep(1));
      }
    }
    if (activeStep === 6 && btnRef.current && !btnRef.current.disabled) {
      btnRef.current.disabled = true;
      const { tac, ppolicy } = submissionDetails;
      const { carrier, mobileNumber, coverVat, country, email } = txnDetails;
      let googleRecaptchaToken = null;
      let charityId = 0;
      if (tac && ppolicy) {
        if (!executeRecaptcha) {
          googleRecaptchaToken = null;
        }
        const googleRecaptchaToken = await executeRecaptcha("postTopup");

        if (txnDetails.charity != "") {
          charityId = txnDetails.charity.providerId;
        }
        setFacResponse({ ...facState });
        const data = {
          phoneProviderId: carrier.phoneProviderId,
          msisdn: mobileNumber,
          coverVat: coverVat,
          countryId: country.countryId,
          amount: parseFloat(txnDetails.presetSelected.amount),
          donationAmount: parseFloat(
            txnDetails.donationAmount == "" ? 0 : txnDetails.donationAmount
          ),
          charityId: charityId,
          ...paymentDetails,
          billingAddress: addressDetails,
          email,
          googleRecaptchaToken,
          shouldSaveCard: paymentDetails.shouldSaveCard,
        };
        dispatch(setLoading("pending"));

        let response = await postAsync(
          data,
          `${API_ROOT}Topup/PostTopupTransaction`
        );
        dispatch(setLoading("idle"));
        btnRef.current.disabled = false;
        if (response.state === 1) {
          setFacResponse({ state: "success", data: response.data });
          dispatch(setActiveStep(1));
        } else {
          setFacResponse({ ...facState, state: "failed", msg: response.msg });
        }
      } else {
        dispatch(
          setErrors({
            ...errors,
            agreement:
              "Please agree to the Terms & Conditions and Privacy Policy to proceed.",
          })
        );
        btnRef.current.disabled = false;
      }
    }
    if ([0, 1, 2, 4].indexOf(activeStep) !== -1) {
      dispatch(setActiveStep(1));
    }
    if (goToFinish === true && activeStep < steps.length - 1 && isValid) {
      dispatch(resetFromStep(6));
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(-1));
  };

  const handleReset = () => {
    window.scrollTo(0, 0);
    dispatch(setActiveStep(0));
  };

  const validatedStep = () => {
    return validatedSteps.indexOf(activeStep) >= 0;
  };

  const handleStepChange = (targetStep) => {
    dispatch(resetFromStep(targetStep));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <CountrySelector handleNext={handleNext} />;
      case 1:
        return <MobileValidator handleNext={handleNext} />;
      case 2:
        return <AmountSelector handleNext={handleNext} />;
      case 3:
        return <PaymentDetails handleNext={handleNext} />;
      case 4:
        return <AddressForm handleNext={handleNext} />;
      case 5:
        return <EmailEntry handleNext={validatedStep} errors={errors} />;
      case 6:
        return (
          <TxnConfirmation
            handleNext={validatedStep}
            onChangeStep={handleStepChange}
          />
        );

      default:
        return "Unknown step";
    }
  };

  const renderFacError = () => {
    if (facResponse.msg.length > 0) {
      return <Alert severity="error"> {facResponse.msg} </Alert>;
    }
    return (
      <SomethingWentWrong
        redirect={token ? "/user/topup" : "/quicktopup"}
        redirect2={token ? "/user/contactus" : "/contact"}
        redirectText="Try Again"
        redirectText2="Contact Us"
        onClick1={() => window.location.reload()}
        onClick2={
          token
            ? () => dispatch(setNavIndex(6))
            : () => dispatch(setTabPosition(6))
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel optional={step.value}>{step.label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div style={{ marginTop: 20 }}>
                {facResponse.state === "failed" &&
                  index == steps.length - 1 &&
                  renderFacError()}
              </div>
              <div className={classes.actionsContainer}>
                <div>
                  {activeStep > 0 && (
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  {activeStep < steps.length - 1 && (
                    <Button
                      variant="contained"
                      color={allStepsValidated ? "default" : "primary"}
                      onClick={handleNext}
                      className={classes.button}
                      disabled={validatedSteps.indexOf(activeStep) === -1}
                    >
                      Next
                    </Button>
                  )}
                  {allStepsValidated && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleNext(true);
                      }}
                      className={classes.button}
                    >
                      FINISH
                    </Button>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length - 1 && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginBottom: 15 }}
        >
          <Grid item xs={10} sm={4}>
            <Button
              variant="contained"
              color={allStepsValidated ? "default" : "primary"}
              onClick={handleNext}
              className={classes.button}
              ref={btnRef}
              fullWidth
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      )}
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <div>
            {facResponse.state === "success" && (
              <TxnResult facResponse={facResponse} service="TopUp" />
            )}
            <Button
              onClick={handleReset}
              className={classes.resetBtn}
              color="primary"
              variant="contained"
            >
              New Transaction
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
}
