import { Grid, TextField, Typography, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SignalCellularNullOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { API_ROOT } from "constants/actionTypes";
import { showSnackBar } from "reducers/uiGlobal";
import { postAsync } from "components/public-dashboard/services/api_util";
import Loading from "components/shared/loader/loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FFF",
  },
});

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowLeadingZeros={true}
    />
  );
}

export default function ChangeUssdPin(props) {
  const { open = false, handleClose } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState({ newPin: "", confirmPin: "" });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setPin({ ...pin, [e.target.name]: e.target.value });
  };

  async function onSubmit(e) {
    e.preventDefault();
    const { newPin, confirmPin } = pin;
    if (newPin !== confirmPin) {
      setError(
        "Your New Passcode and Confirm New Passcode values do not match"
      );
    } else if (newPin.length !== 4 || confirmPin.length !== 4) {
      setError("You new passcode must be 4 digits long");
    } else {
      setLoading(true);
      setError("");

      let response = await postAsync(
        null,
        `${API_ROOT}customers/ChangeUssdPin?pin=${newPin}`
      );

      // let response = { state: 0 };
      setLoading(false);
      onClose();

      if (response.state === 1) {
        dispatch(
          showSnackBar({
            msg: "Yooz Mobile Passcode has been changed",
            type: "success",
            autoHideDuration: 10000,
          })
        );
      } else {
        dispatch(
          showSnackBar({
            msg:
              "We are unable to change your Yooz Mobile Passcode at the moment. Please try again later",
            type: "error",
            autoHideDuration: 10000,
          })
        );
      }
    }
  }

  function onClose() {
    setPin({
      newPin: "",
      confirmPin: "",
    });
    setError("");
    handleClose();
  }

  return (
    <Fragment>
      {loading && <Loading />}
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          style={{ background: theme.palette.primary.main, color: "#FFF" }}
          onClose={onClose}
        >
          Change Yooz Mobile Passcode
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <form onSubmit={onSubmit} className={classes.form}>
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="New Yooz Passcode"
                  value={pin.newPin}
                  onChange={handleChange}
                  name="newPin"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{ maxLength: 4 }}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Confirm New Passcode"
                  value={pin.confirmPin}
                  onChange={handleChange}
                  name="confirmPin"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{ maxLength: 4 }}
                  type="password"
                />
              </Grid>
              {error.length > 0 && (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button fullWidth onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
