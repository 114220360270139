import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import signUp_img from "assets/images/home_content_6.png";
import ContentList from "components/public-dashboard/components/contentList";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  videoContainer: {
    paddingLeft: 50,
  },

  yoozBlueLink: {
    color: "#00AFE2",
  },
}));

export default function Scan2PayContent() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const listItems = [
    <span>
      We give you your unique, secure{" "}
      <span style={{ color: "#cb010d", fontWeight: "bold" }}>YOOZ QR Code</span>{" "}
      and{" "}
      <span style={{ color: "#cb010d", fontWeight: "bold" }}>
        YOOZ Payment Link
      </span>
      .
    </span>,
    "Display the Code anywhere you sell: in-store, pop-up market, online, social media.",
    "Share the Link via all virtual sales channels e.g. WhatsApp.",
    "When the customer scans the code or clicks the link, they are taken to our secure payment portal with your store name prefilled.",
    "There they can enter their card details to pay.",
    "Once the transaction is successful both you and the customer immediately receive an e-receipt.",
    "At that point you can hand over the goods/service.",
  ];
  return (
    <Container>
      <Grid container direction={xs ? "column-reverse" : "row"}>
        <Grid container item sm={12} md={8}>
          <Grid item>
            <ContentList
              title="WHO CAN YOOZ IT?"
              items={[
                <span>
                  Wondering if your business or organization can benefit from
                  YOOZ Scan2Pay? The answer is YES!
                </span>,
                <span>
                  It's perfect for any business, non-profit or religious
                  organization, as well as events and fundraisers.
                </span>,
              ]}
            />
          </Grid>
          <Grid item>
            <ContentList
              title="HOW DOES IT WORK?"
              items={[
                <ol style={{ paddingLeft: 16 }}>
                  {listItems.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ol>,
              ]}
            />
          </Grid>
          <Grid item>
            <ContentList
              title="HOW MUCH DOES IT COST?"
              items={[
                <span>
                  We operate on a per transaction pricing model, which means you
                  only pay when you get paid!
                </span>,
                <span>
                  Our standard transaction fees are 3% (bank fees) + $5.63 per
                  transaction. You can manage your fees your way on YOOZ Online. Absorb, pass on or split your fees with your customers. The choice is yours!
                    <br/>
                    <br/>


                      Collections are remitted via ACH at a cost of $1.00 per bulk
                    transfer, 3 business days after transactions are made.

                </span>,
              ]}
            />
          </Grid>

          <Grid item>
            <ContentList
              title="READY TO GET STARTED? "
              items={[
                <span>
                  <a
                    href={`${API_ROOT}document/yo-letter-of-interest`}
                    className={classes.yoozBlueLink}
                    target="_blank"
                  >
                    Click
                  </a>{" "}
                  to download the letter of interest and email it to{" "}
                  <a
                    href="mailto: yooz@yoozit.today"
                    className={classes.yoozBlueLink}
                  >
                    yooz@yoozit.today
                  </a>{" "}
                  along with your Onboarding documents. To see all required
                  documents for registration check out our{" "}
                  <a
                    href="/OnboardingDocuments"
                    className={classes.yoozBlueLink}
                  >
                    Onboarding Documents
                  </a>{" "}
                  page.
                </span>,
              ]}
              img={signUp_img}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={4}>
          <video width={xs ? "100%" : 500} height={xs ? 400 : 500} controls>
          <source src={`${API_ROOT}document/yo-scan2pay-vid`} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        </Grid>
      </Grid>
    </Container>
  );
}
