import { IconButton } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch } from "react-redux";
import { closeSnackBar } from "reducers/uiGlobal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
  const {
    type,
    msg = "",
    open = false,
    autoHideDuration,
    action,
  } = props.snackbar;
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(closeSnackBar());
  }
  return (
    <div className={classes.root}>
      {type === "default" ? (
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          message={msg}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} action={action}>
            {msg}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
