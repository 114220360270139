import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Container, Typography } from "@material-ui/core";
import robotErrorImg from "assets/images/robot-error.png";
import { Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom:theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  img: {
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      padding: theme.spacing(3),
    },
  },
  button: {
    borderRadius: 50,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
    marginRight: theme.spacing(1),
  },
}));

function SomethingWentWrong(props) {
  const classes = useStyles();
  const {
    redirect = "/",
    redirect2 = "/",
    redirectText = "Try Again",
    redirectText2 = "Contact Us",
    width,
    onClick1,
    onClick2
  } = props;
  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
          <img src={robotErrorImg} alt="error" className={classes.img} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Typography
              variant={
                width === "xl"
                  ? "h4"
                  : width === "sm"
                  ? "h5"
                  : "h6"
              }
            >
              OOPs...Something went wrong.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={
                width === "xl" ? "h6" : width === "lg" ? "body1" : "body2"
              }
            >
              It happens to the best of us.  Please try again and if it still doesn't work, contact us.
            </Typography>
            <Typography
              variant={
                width === "xl" ? "h6" : width === "lg" ? "body1" : "body2"
              }
            >
               Our call centre is always ready to help you troubleshoot.
            </Typography>
          </Grid>
          <Grid item>
            <Box mt={1} mb={1}>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={redirect}
                className={classes.button}
                size={width == "xs" ? "small" : "medium"}
                onClick={onClick1}
              >
                {redirectText}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={redirect2}
                className={classes.button}
                size={width == "xs" ? "small" : "medium"}
                onClick={onClick2}
              >
                {redirectText2}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withWidth()(SomethingWentWrong);
