import React from "react";
import { Route, Switch } from "react-router-dom";
import accounts from "containers/accounts/accounts";
import About from "components/public-dashboard/features/about";
import Billpay from "components/public-dashboard/features/billpay";
import Contact from "components/public-dashboard/features/contact";
import FAQ from "components/public-dashboard/features/faq";
import TopUp from "components/public-dashboard/features/topup";
import SecuredActivities from "./features/activity";
import SecuredDashboard from "./features/dashboard";

export default function PrivateRouter(props) {
  return (
    <Switch>
      <Route path="/user" exact component={SecuredDashboard}></Route>
      <Route path="/user/topup" exact component={TopUp}></Route>
      <Route path="/user/billpay" exact component={Billpay}></Route>
      <Route path="/user/activity" exact component={SecuredActivities}></Route>
      <Route path="/user/account" exact component={accounts}></Route>
      <Route path="/user/aboutus" component={About} exact />
      <Route path="/user/contactus" component={Contact} exact />
      <Route path="/user/faq" component={FAQ} exact />
    </Switch>
  );
}
