import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./store/configureStore";
import { setLoading } from "./reducers/uiGlobal";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const store = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    setLoading(true);
    updateServiceWorker(reg && reg.waiting);
  },
});

const updateServiceWorker = (waitingWorker) => {
  if (waitingWorker) {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });

    waitingWorker.addEventListener("statechange", (e) => {
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  }
};
