import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Children, useEffect, useState } from "react";
import { getToken } from "middleware/storage";
import { getCardType } from "components/utils";
import AgreementCheckboxes from "components/shared/agreement_checkboxes";
import AddressBillingDetailListing from "./billing_address_detail_listing";
import PaymentDetailListingRegistered from "./payement_details_registered";
import TxnTotalListing from "./total_detail_listing";
import BPTxnDetailListing from "./bp_txn_details_listing";
import TopUpTxnDetailListing from "./topup_txn_details_listing";
import EditIcon from "@material-ui/icons/EditRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    textAlign: "center",
    fontSize: "1rem",
  },
  changeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: 20,
    width: "auto",
  },
}));

export default function TxnReview(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    txnDetails,
    paymentDetails,
    addressDetails,
    submissionDetails,
    providers,
    charities,
    cCards,
    errors,
    setSubmissionDetails,
    showCCForm,
    countries,
    onChangeStep,
    service,
    vat,
    coverVat,
  } = props;

  const cardType = getCardType(paymentDetails.cardNumber);

  function getProviderName(id) {
    const provider = providers.find((p) => p.providerId === id);
    return provider ? provider.name : "";
  }

  function getCharityName(id) {
    const charity = charities.find((c) => c.providerId === id);
    return charity ? charity.name : "";
  }

  function getCardDetails(id) {
    const card = cCards.find((c) => c.customerCardId === id);
    return card;
  }

  const [loggedIn, SetLogin] = useState(false);

  const handleStepChange = (targetStep) => {
    onChangeStep(targetStep);
  };

  useEffect(() => {
    async function validateLogin() {
      const token = getToken();
      if (!token) {
        SetLogin(false);
      } else {
        SetLogin(true);
      }
    }

    validateLogin();
  }, []);

  const [submissionDetailsState, updateSubmissionDetails] =
    useState(submissionDetails);

  const handleChange = (prop, prop2) => (event) => {
    setSubmissionDetails({
      ...submissionDetailsState,
      [prop]: event.target.checked,
      [prop2]: event.target.checked,
    });
    updateSubmissionDetails({
      ...submissionDetailsState,
      [prop]: event.target.checked,
      [prop2]: event.target.checked,
    });
  };
  const handleToSChange = (prop) => (event) => {
    setSubmissionDetails({
      "tac": event.target.checked,
       "ppolicy": event.target.checked,
    });
  };

  const CardWrapper = ({ title, handleChange, children }) => (
    <Card>
      <CardHeader
        style={{ paddingBottom: 0 }}
        title={<Typography>{title}</Typography>}
        action={ handleChange && 
          <IconButton color="primary" onClick={handleChange}>
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        {children}
      </CardContent>
    </Card>
  );

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={8} spacing={2}>
        <Grid item xs={12}>
          <CardWrapper
            title="Transaction Details"
            handleChange={() => handleStepChange(service == "billpay" ? 0 : 2)}
          >
            {service == "billpay" ? (
              <BPTxnDetailListing
                values={txnDetails}
                getProviderName={getProviderName}
                getCharityName={getCharityName}
              />
            ) : (
              <TopUpTxnDetailListing
                values={txnDetails}
                getCharityName={getCharityName}
                vat={vat}
                coverVat={coverVat}
              />
            )}
          </CardWrapper>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper
            title="Payment & Billing Details"
            handleChange={() => handleStepChange(service == "billpay" ? 1 : 3)}
          >
            {loggedIn ? (
              showCCForm ? (
                <>
                  <AddressBillingDetailListing
                    paymentDetails={paymentDetails}
                    addressDetails={addressDetails}
                    countries={countries}
                    cardType={cardType}
                  />
                </>
              ) : (
                <PaymentDetailListingRegistered
                  values={getCardDetails(paymentDetails.customerCardId)}
                  addressDetails={addressDetails}
                  countries={countries}
                />
              )
            ) : (
              <>
                <AddressBillingDetailListing
                  paymentDetails={paymentDetails}
                  addressDetails={addressDetails}
                  countries={countries}
                  cardType={cardType}
                />
              </>
            )}
          </CardWrapper>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <CardWrapper title="Total">
          <TxnTotalListing
            getCharityName={getCharityName}
            txnDetails={txnDetails}
            vat={vat}
            {...props}
          />
          <AgreementCheckboxes
              value={submissionDetails.tac}
              handleChange={handleToSChange}
              error={errors.agreement}
          />
        </CardWrapper>
      </Grid>
    </Grid>
  );
}
