import {
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Table from "components/shared/table/table";
import React from "react";

export default function ActivityTable(props) {
  const { totalItems, handleNavUpdate } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Table {...props}/>
  );
}
