import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  overlineText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    textTransform: "uppercase",
    padding: 0,
  },
}));

export default function IconHeader(props) {
  const {
    title,
    title2,
    titleColor,
    titleColor2,
    titleLg = "h1",
    titleSm = "h2",
    titleXs = "h3",
  } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.overlineText}>
      <Grid item>
        <Typography
          variant={xs ? titleXs : sm ? titleSm : titleLg}
          style={{
            color: titleColor,
            fontFamily: "Akzidenz-Grotesk BQ, serif",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={xs ? titleXs : sm ? titleSm : titleLg}
          style={{
            color: titleColor2,
          }}
        >
          {title2}
        </Typography>
      </Grid>
    </Grid>
  );
}
