import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    borderRadius: 20,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      fontSize: 14,
    },
  },
  route: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "&.Mui-disabled": {
      opacity: 1,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        fontWeight: 600,
      },
    },
  },
}))(MenuItem);

export default function RoutesDropdown(props) {
  const { dropdownRoutes, handleChange, styles } = props;
  const { title, routes } = dropdownRoutes;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = () => {
    handleClose();
    handleChange(null, 7);
  };

  const renderRoute = (route, index) => {
    return [
      <StyledMenuItem
        key={"route" + index}
        to={route?.path}
        component={Link}
        onClick={handleRedirect}
        disabled={!route.path}
      >
        <ListItemText primary={route.label} />
      </StyledMenuItem>,
      route.subRoutes?.map((subRoute, index) => (
        <StyledMenuItem
          key={"subRoute" + index}
          to={subRoute?.path}
          component={Link}
          onClick={handleRedirect}
        >
          <ListItemText
            primary={subRoute.label}
            style={{ marginLeft: theme.spacing(3) }}
          />
        </StyledMenuItem>
      )),
    ];
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        size={sm ? "small" : "medium"}
        className={classes.actionButton}
        style={{ ...styles }}
        endIcon={<ExpandMore />}
      >
        {title}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {routes.map((route, index) => renderRoute(route, index))}
      </StyledMenu>
    </div>
  );
}
