import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { resetUserPassword } from "actions/auth";
import ResetPasswordDialog from "components/public-dashboard/features/reset_password";
import { showSnackBar } from "reducers/uiGlobal";
import { PASSWORD_RESET_SUCCESS } from "constants/actionTypes";
import history from "history/history";
class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPwd: "",
      validString: "",
      error: "",
      passwordErr: "",
      confirmPasswordErr:""
    };
  }

  onChangeField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  validateForm = () => {
    let { password, confirmPwd } = this.state;
    this.setState({ error: "", passwordErr: "", confirmPasswordErr:"" });
    password = password.trim();
    confirmPwd = confirmPwd.trim()
    const validPassword = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$"
    );
    if (password.match(validPassword) == null) {
      this.setState({
        passwordErr:
          "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
      });
      return false;
    }
    if (confirmPwd.match(validPassword) == null) {
      this.setState({
        confirmPasswordErr:
          "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
      });
      return false;
    }
    if (password !== confirmPwd) {
      this.setState({ confirmPasswordErr: "Password not matched! please try again." });
      return false;
    }
    return true;
  };

  onResetPwd = () => {
    const { password } = this.state;
    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);

    if (this.validateForm()) {
      this.setState({ error: "", passwordErr: "", confirmPasswordErr:"" });
      const cred = {
        ValidString: parsed.ref,
        Password: password,
      };
      this.props.dispatch(resetUserPassword(cred)).then((res) => {
        if (res.type === PASSWORD_RESET_SUCCESS) {
          const { reset } = this.props;

          if (reset.state === 1) {
            history.push("/login");
            this.props.dispatch(
              showSnackBar({
                msg: "Password successfully reset",
                type: "success",
                autoHideDuration: 12000,
              })
            );
          } else {
            if (reset.message) {
              this.setState({ error: reset.msg });
            } else {
              this.setState({
                error: "Unable to reset password. Please try again later.",
              });
            }
          }
        } else {
          this.setState({
            error: "Unable to reset password. Please try again later.",
          });
        }
      });
    }
  };

  render() {
    return (
      // <ResetLoginPassword
      //   {...this.props}
      //   {...this.state}
      //   onChangeField = {this.onChangeField}
      //   onResetPwd = {this.onResetPwd}
      // />
      <ResetPasswordDialog
        {...this.props}
        {...this.state}
        title="Reset"
        title2="Password"
        onChangeField={this.onChangeField}
        onResetPwd={this.onResetPwd}
      />
    );
  }
}

function mapStateToProps(state) {
  const { authReducer, entities } = state;
  return {
    authReducer,
    //validString : entities.user.queryString,
    reset: entities.user.reset,
  };
}

export default connect(mapStateToProps)(ResetPassword);
