import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import img from "assets/images/home_content_4.png";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";
const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%",
    },
  },
  img: {
    width: "100%",
  },
}));

export default function PaymentSolutionsHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={6} sm={12}>
          <IconHeader
            iconColor="#000"
            titleColor="#000"
            titleLg="h1"
            titleSm="h1"
            titleXs="h2"
            yoozIt={true}
            styles={{ alignItems: "end" }}
            subTitle="To COLLECT PAYMENTS"
            subTitleColor="#F7517B"
            subTitleLg="h3"
            subTitleSm="h4"
            subTitleXs="h5"
            subtitleStyles={{ textAlign: "center" }}
          />
        </Grid>
        {sm && (
          <Grid item xs={6}>
            <img src={img} alt="topup" className={classes.img} />
          </Grid>
        )}
        <Grid item sm={12} md={7}>
          <TextList
            items={[

              <span>
               Grow sales and be more accessible to your customers by accepting payments through
                multiple channels including your own website.
              </span>,
              <span>
                Easy to implement without the hassle of processing and
                compliance requirements, the YOOZ team can guide you to find the
                payment solution that best suits your business and customer needs.

              </span>,
              <span>

                Secure, quick and easy to use, YOOZ Payment Solutions offer convenience, effective financial management and the reach needed
                to <b>Help You Grow!</b>

              </span>,
            ]}
          />
        </Grid>
        {md && (
          <Grid item xs={6} sm={5}>
            <img src={img} alt="topup" className={classes.img} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
