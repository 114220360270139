import { Container, Divider, useMediaQuery } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import yooz_scan2pay from "assets/images/YOOZScan2Pay-Image-to-resize-and-reformat_2.png";
import yooz_agent from "assets/images/YOOZ_AGENT.png";
import yooz_online from "assets/images/YOOZ_ONLINE.png";
import yooz_payments from "assets/images/YOOZ_PAYMENTS.png";
import signUp_img from "assets/images/home_content_6.png";
import TextList from "components/public-dashboard/components/TextList";
import ContentList from "components/public-dashboard/components/contentList";
import IconHeader from "components/public-dashboard/components/iconHeader";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  logoHeading: {
    width: 150,
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
    minWidth: 100,
  },
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 70,
      marginBottom: theme.spacing(10),
    },
  },
  overlineText: {
    display: "flex",
    alignItems: "left",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
  },
  it: {
    fontStyle: "italic",
    fontFamily: "Arial Rounded MT",
    textTransform: "none",
  },
  right: {
    textAlign: "right",
  },
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "75%",
  },
  ol: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  collectPayment: {
    color: "#008BC3",
  },
  yoozBlueLink: {
    color: "#00AFE2",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  productText: {
    fontSize: 44,
    color: "#F7517B",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    paddingLeft: "4.5%",
  },
}));

export default function PartnersContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const contentList = () => {
    return [
      {
        title: "Online",
        color: "grey",
        img: yooz_online,
        lines: [
          <span>
            Accept debit and credit card payments via our secure payment portal.
            Your customers can pay you quickly, safely and easily through our
            <LinkText navIndex={7} route="/quickpay" text=" Quick Pay" />,{" "}
            <LinkText navIndex={7} route="/scan2pay" text=" Scan2Pay" /> and{" "}
            <LinkText navIndex={7} route="/login" text="Login" /> features.
          </span>,
        ],
      },
      {
        title: "AGENTS",
        color: "#57B56B",
        img: yooz_agent,
        lines: [
          <span>
            Take advantage of our extensive agent network by allowing your
            customers to pay you at any of our
            150+ Authorized
            <LinkText
              navIndex={7}
              route="/AgentLocations"
              text=" YOOZ Bill Payment Agents"
            />{" "}

            located throughout Trinidad and Tobago.
          </span>,
        ],
      },
      {
        title: "SCAN2PAY",
        color: "#BD2126",
        img: yooz_scan2pay,
        lines: [
          <span>
            Leverage QR Codes and Payment Links to accept payments in-store, at
            pop-up events, on your social pages, and/or website. Avoid the
            hassle of monthly fees, terminals, online transfers, hidden fees,
            and payment delays with{" "}
            <LinkText navIndex={7} route="/scan2pay" text="YOOZ Scan2Pay" />.
          </span>,
        ],
      },
      {
        title: "PAYMENT <br> GATEWAY",
        color: "#A43168",
        img: yooz_payments,
        lines: [
          <span>
            Boost your business and start accepting payments online by
            integrating the
            <LinkText
              navIndex={7}
              route="/payment-gateway"
              text=" YOOZ Payment Gateway "
            />{" "}
            to your own website or e-commerce site, without the hassle of
            processing and compliance requirements.
          </span>,
        ],
      },
    ];
  };
  const LinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          dispatch(setTabPosition(navIndex));
        }}
        component={RouterLink}
        to={route}
        className={classes.linktext}
      >
        <b>{text}</b>
      </Link>
    );
  };
  return (
    <Container>
      {contentList().map((c, index) => (
        <Fragment key={index}>
          <ContentImageBlock
            titleSm="h2"
            titleLg="h1"
            titleXs="h6"
            imgPosition={index % 2 == 0 ? "left" : "right"}
            special={
              <IconHeader
                title={c.title}
                iconColor="#000"
                titleColor={c.color}
                titleLg="h2"
                titleSm="h2"
                titleXs="h6"
              />
            }
            list={<TextList items={c.lines} />}
            img={c.img}
          />
          {xs && <Divider variant="middle" />}
        </Fragment>
      ))}
      <ContentList
        title="WHAT'S THE COST?"
        items={[
          <span>
            We operate on a per transaction pricing model, which means you pay only when you get paid!
          </span>,
          <span>
            Our standard transaction fees are 3% (bank fees) + $5.63 per transaction. Collections are remitted via ACH at a cost of $1.00 per bulk
transfer, 3 business days after transactions are made.

          </span>,
        ]}
      />
      <ContentImageBlock
        title="READY TO GET STARTED?"
        titleSm="h4"
        titleLg="h3"
        titleXs="h6"
        imgPosition="left"
        img={signUp_img}
        color="#1CC5FF"
        list={
          <ContentList
            items={[
              <span>
                <a
                  href={`${API_ROOT}document/yo-letter-of-interest`}
                  className={classes.yoozBlueLink}
                  target="_blank"
                >
                  Click
                </a>{" "}
                to download the letter of interest and email it to{" "}
                <a
                  href="mailto: yooz@yoozit.today"
                  className={classes.yoozBlueLink}
                >
                  yooz@yoozit.today
                </a>{" "}
                along with your Onboarding documents. To see all required
                documents for registration check out our{" "}
                <a href="/OnboardingDocuments" className={classes.yoozBlueLink}>
                  Onboarding Documents
                </a>{" "}
                page.
              </span>,
            ]}
          />
        }
      />
    </Container>
  );
}
