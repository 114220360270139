import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";
import { React } from "react";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function SelectProvider(props) {
  const classes = useStyles();
  const { value = null, providers, handleChange, error } = props;
  
  const providerOptions = providers.map((option) => (
    {
      value: option.providerId,
      label: option.name,
    }
  ))

  const [provider, setProvider] = useState(null)

  useEffect(() => {
    setProvider(providerOptions.find(x => x.value === value))
  }, [value, providers])

  const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: base => ({
      ...base,
      height: 55,
      borderColor: error?.length > 0 ? 'red' : 'grey',

    }),
  };

  const onHandleChange = (event) => {
    handleChange({ target: { value: event.value, name: 'providerId', id: 'providerId' } })
  }

  return (
    <div className={classes.root}>
      <Select
        id="providerId"
        styles={selectStyles}
        options={providerOptions}
        value={provider}
        error={error.length > 0}
        required
        placeholder='Payee'
        onChange={(event) => onHandleChange(event)}

        variant="outlined"
        fullWidth
        color="secondary"
      />
      {error?.length > 0 && <p style={{ color: 'red', fontSize: 12 }}>{error}</p>}
    </div>
  );
}
