import { Fade, Grid, IconButton, Divider, Typography } from "@material-ui/core";
import { amber } from "@material-ui/core/colors";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getToken, getUserDetail } from "middleware/storage";
import { setNavIndex } from "components/secured-app/uiSlice";
import SomethingWentWrong from "components/shared/ui/somethingWentWrong";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import PhoneNumber from "components/shared/billing_address/masked_phone_number";
import { setTxnDetails, setAddressDetails, resetTxn } from "components/public-dashboard/features/billpay/bpSlice";
import EnterAmount from "./enter_amount";
import SelectCharity from "./select_charity";
import SelectProvider from "./select_provider";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
    maxWidth: "97%",
    wordBreak: "break-word",
    zIndex: 100,
  },
  commentField: {
    height: 200,
  }
}));

function TxnDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { providers, charities, loading } = useSelector((state) => state.billpay);
  const token = getToken();
  let { providerId = "", accountNumber = "", amount = "", email = "", charityId = "", donationAmount = "" } = useParams();

  if (providerId != "") {
    providerId = parseInt(providerId);
  }
  if (charityId != "") {
    charityId = parseInt(charityId);
  }
  const { setTxnDetails, txnDetailsState, errors, addressDetails, setAddressDetails } = props;
  const [helperText, setHelperText] = useState("");
  const [helperDisclaimerText, setHelperDisclaimerText] = useState("");
  const [customerFeeDisclaimer, setCustomerFeeDisclaimerText] = useState("");

  const [txnDetails, updateTxnDetails] = useState(txnDetailsState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    if (helperDisclaimerText) {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setTxnDetails({ ...txnDetails, [prop]: event.target.value });

    //prefill billing address section of email and phone number
    if (prop === "email") {
      setAddressDetails({ ...addressDetails, emailAddress: event.target.value })
    } else if (prop === "phoneNumber") {
      setAddressDetails({ ...addressDetails, phoneNumber: event.target.value })
    } 
    updateTxnDetails({ ...txnDetails, [prop]: event.target.value });
    if (prop === "providerId") {
      const provider = providers.find((p) => p.providerId === event.target.value);
  
      if (provider) {
        setHelperText(provider.accountHelperMessage);
        setHelperDisclaimerText(provider.accountDisclaimerMessage);
        if ((provider.customerCardRate || provider.customerFee) > 0) {
          //setCustomerFeeDisclaimerText(provider.customerFeesDisclaimer);
          updateTxnDetails({ ...txnDetails, customerFeesDisclaimer: provider.customerFeesDisclaimer, customerCardRate: provider.customerCardRate, customerFee: provider.customerFee, [prop]: event.target.value });
        }else {
          setCustomerFeeDisclaimerText("");
        }
      }
  
      if (provider.type === 'Charity') {
        setTxnDetails({ ...txnDetails, charityId: "", donationAmount: 0, [prop]: event.target.value });
        updateTxnDetails({ ...txnDetails, charityId: "", donationAmount: 0, [prop]: event.target.value });
      }
    }
  
    if (prop === "charityId") {
      if (event.target.value === "") {
        setTxnDetails({ ...txnDetails, charityId: event.target.value, donationAmount: 0 });
        updateTxnDetails({ ...txnDetails, charityId: event.target.value, donationAmount: 0 });
      }
    }
  };

  const checkIfProviderIsCharity = (providerId, providers) => {
    if (providerId === "" || providers.length === 0) {
      return true;
    }
    else {
      let provider = providers.find(
        (p) => p.providerId === txnDetails.providerId
      );
      if (typeof provider === 'undefined') {
        return true;
      }
      if (provider.type != "Charity") {
        return true;
      }
      else return false;
    }
  }

  const checkIfCommentFieldAllowed = (providerId, providers) => {
    if (providerId === "" || providers.length === 0) {
      return false;
    }
    else {
      let provider = providers.find(
        (p) => p.providerId === txnDetails.providerId
      );
      if (typeof provider === 'undefined') {
        return false;
      }
      return provider.commentsField;
    }
  }

  const history = useHistory();

  const unlisten = history.listen((location) => {
    // Call your function here or perform any other logic
    if (!["/quickpay", "/user/billpay"].includes(location.pathname)) {
      dispatch(resetTxn()); // Call the resetTxn function when navigating away from the current route
    }

  });


  useLayoutEffect(() => {
    const prefillLoggedInDetails = () => {
      if (token) {
        const user = getUserDetail();
        updateTxnDetails({
          ...txnDetails,
          email: user.email,
          phoneNumber: user.phoneNumber
        });
        setTxnDetails({
          ...txnDetailsState,
          email: user.email,
          phoneNumber: user.phoneNumber
        });
        if (addressDetails.emailAddress == "") {
          setAddressDetails({
            ...addressDetails,
            emailAddress: user.email,
            phoneNumber: user.phoneNumber
          })
        }
      }
    };
    prefillLoggedInDetails();

    // Clean up the listener when the component unmounts
    return () => {
      //listen for router change
      unlisten()
    };

  }, [history])

  useEffect(() => {
    const prefillTxnDetails = () => {
      if (providerId !== "") {
        const updatedTxnDetails = {
          providerId: providerId,
          charityId: charityId !== "" ? charityId : txnDetails.charityId,
          accountNumber: accountNumber !== "" ? accountNumber : txnDetails.accountNumber,
          amount: amount !== "" ? amount : txnDetails.amount,
          email: email !== "" ? email : txnDetails.email,
          donationAmount: donationAmount !== "" ? donationAmount : txnDetails.donationAmount
        };

        setTxnDetails({ ...txnDetailsState, ...updatedTxnDetails });
        updateTxnDetails({ ...txnDetailsState, ...updatedTxnDetails });
      }
    }

    prefillTxnDetails();

  }, []);

  if (!loading && providers.length === 0) {
    return (
      <SomethingWentWrong
        redirect={token ? "/user/topup" : "/quicktopup"}
        redirect2={token ? "/user/contactus" : "/contact"}
        redirectText="Try Again"
        redirectText2="Contact Us"
        onClick1={() => window.location.reload()}
        onClick2={
          token
            ? () => dispatch(setNavIndex(6))
            : () => dispatch(setTabPosition(6))
        }
      />
    );
  }
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <SelectProvider
            error={errors.providerId}
            handleChange={handleChange('providerId')}
            value={txnDetails.providerId}
            providers={providers}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="txnAccNo"
            label="Account Number"
            helperText={
              errors.accountNumber.length > 0
                ? errors.accountNumber
                : helperText
            }
            onFocus={handleClick("top-start")}
            onBlur={handleClose}
            required
            InputProps={{
              endAdornment: helperDisclaimerText ? (
                <IconButton color="secondary">
                  <WarningIcon style={{ color: amber[500] }} />
                </IconButton>
              ) : null,
            }}
            variant="outlined"
            fullWidth
            value={txnDetails.accountNumber}
            onChange={handleChange("accountNumber")}
            className={classes.formControl}
            error={errors.accountNumber.length > 0}
            inputProps={{ maxLength: 25 }}
            onInput={(e) => {
              e.target.value = e.target.value.match(/[A-Za-z0-9-]+/); //only accept letters and numbers
            }}
            color="secondary"
            disabled={txnDetails.providerId === ""}
          />
        </Grid>
        <Grid item sm={12} xs={12} style={{ marginBottom: 10 }}>
          <EnterAmount
            handleChange={handleChange}
            value={txnDetails.amount}
            error={errors.amount}
            disabled={txnDetails.providerId === ""}
            elemId={"txnAmount"}
            label={"Amount (TTD)"}
            fieldName={"amount"}
            helperText={customerFeeDisclaimer}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="txnEmail"
            label="Email"
            helperText={errors.email.length > 0 ? errors.email : ""}
            variant="outlined"
            fullWidth
            value={txnDetails.email}
            onChange={handleChange("email")}
            className={classes.formControl}
            error={errors.email.length > 0}
            type="email"
            color="secondary"
            required
            disabled={txnDetails.providerId === ""}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber
            handleChange={handleChange}
            value={txnDetails.phoneNumber}
            disabled={txnDetails.providerId === ""}
            error={errors.phoneNumber}
          />
        </Grid>
        {
          checkIfCommentFieldAllowed(txnDetails.providerId, providers) && (
            <Grid item sm={12} xs={12}>
              <TextField
                id="txnComments"
                label="Comments (Optional)"
                helperText={errors.comment.length > 0 ? errors.comment : ""}
                variant="outlined"
                fullWidth
                value={txnDetails.comment}
                onChange={handleChange("comment")}
                className={classes.formControl}
                error={errors.comment.length > 0}
                type="comment"
                color="secondary"
                multiline
                rowsMax={5}
                disabled={txnDetails.providerId === ""}
              />
            </Grid>
          )}

      </Grid>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Alert severity="warning" className={classes.alert}>
              <AlertTitle>Note</AlertTitle>
              {ReactHtmlParser(helperDisclaimerText)}
            </Alert>
          </Fade>
        )}
      </Popper>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  txnDetailsState: state.billpay.txnDetails,
  errors: state.billpay.errors,
  providers: state.billpay.providers,
  charities: state.billpay.charities,
  addressDetails: state.billpay.addressDetails
});

const mapDispatch = { setTxnDetails, setAddressDetails };

export default connect(mapStateToProps, mapDispatch)(TxnDetails);
