import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useRef } from "react";
import SelectCountry from "./select_country";
import SelectState from "./select_state";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

export default function BillingAddress(props) {
  const classes = useStyles();
  const ref = useRef(null);
  const { errors, handleChange, addressDetails, countryStates, countries, requireMinFields = false } =
    props;

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <SelectCountry
            error={errors.countryCode}
            handleChange={handleChange("countryCode")}
            label="Country"
            value={addressDetails.countryCode}
            countries={countries}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="line1"
            label={"Address Line 1" + (requireMinFields ? " *" : "")}
            variant="outlined"
            fullWidth
            helperText={errors.line1?.length > 0 ? errors.line1:""}
            error={errors.line1?.length > 0}
            value={addressDetails.line1}
            onChange={handleChange("line1")}
            className={classes.formControl}
            color="secondary"
            ref={ref}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="line2"
            label="Address Line 2"
            variant="outlined"
            fullWidth
            value={addressDetails.line2}
            onChange={handleChange("line2")}
            className={classes.formControl}
            color="secondary"
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        {addressDetails.countryCode === "840" && (
          <Grid item sm={6} xs={12}>
            <SelectState
              error={errors.state}
              handleChange={handleChange}
              value={addressDetails.state}
              countryStates={countryStates}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <TextField
            value={addressDetails.city}
            id="city"
            label={"City" + (requireMinFields ? " *" : "")}
            variant="outlined"
            fullWidth
            helperText={errors.city?.length > 0 ? errors.city:""}
            error={errors.city?.length  > 0}
            onChange={handleChange("city")}
            className={classes.formControl}
            color="secondary"
            inputProps={{
              maxLength: 50,
            }}
            onInput={(e) => {
              e.target.value = e.target.value.match(/[a-zA-Z-.'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="postalCode"
            label="Postal Code"
            variant="outlined"
            fullWidth
            value={addressDetails.postalCode}
            onChange={handleChange("postalCode")}
            className={classes.formControl}
            color="secondary"
            onInput={(e) => {
              e.target.value = e.target.value.match(/[0-9a-zA-Z]+/); //only accept letters and numbers
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
