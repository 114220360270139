import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { OpacityRounded } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function SelectCharity(props) {
  const classes = useStyles();
  const { value, charities, handleChange, disabled,error } = props;
  return (
    <div className={classes.root}>
      <TextField
        id="txnCharityNo"
        error={error.length > 0}
        select
        label="Charity"
        disabled={disabled}
        value={value}
        onChange={handleChange("charityId")}
        helperText={
          error.length > 0 ? error : "This is optional. Please leave empty if you do not wish to donate"
        }
        variant="outlined"
        fullWidth
        color="secondary"
      >
        {<MenuItem key={0} value={""}>
          {"No Donation"}
         </MenuItem>
         }
        
        {charities.map((option) => (            
          <MenuItem key={option.providerId} value={option.providerId}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
