import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(3),
    },
    background:
      "linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(254,254,254,1) 63%, rgba(255,255,255,1) 100%)",
  },
}));

export default function ContentWrapper(props) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}
