import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { getToken } from "middleware/storage";
import { isCardDuplicate } from "components/utils";
import MaskedCCInput from "./masked_cc_input";
import MaskedCvv from "./masked_cvv_input";
import MaskedExpiryDate from "./masked_expiry_date";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PaymentDetailsUnregistered(props) {
  const classes = useStyles();
  const {
    setPaymentDetails,
    paymentDetailsState,
    errors,
    addressDetails,
    setAddressDetails,
    cCards
  } = props;
  const [loggedIn, SetLogin] = useState(false);
  const [paymentDetails, updatePaymentDetails] = useState(paymentDetailsState);
  const [duplicateCardText, setDuplicateCardText] = useState("");
  const [disableSaveCard, setDisableSaveCard] = useState(true);

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    if (prop === "shouldSaveCard") {
      value = event.target.checked;
    }

    if (prop === "cardNumber") {
      if (cCards.length > 0 && value.length == 16) {
        const isCcardDuplicate = isCardDuplicate(cCards, value);
        if (isCcardDuplicate) {
          setDuplicateCardText("This card is already saved for this account.");
          setDisableSaveCard(true);
        } else {
          setDuplicateCardText("");
          setDisableSaveCard(false);
        }
      } else {
        setDuplicateCardText("");
        setDisableSaveCard(false);
      }
    }

    setPaymentDetails({ ...paymentDetails, [prop]: value });
    updatePaymentDetails({ ...paymentDetails, [prop]: value });

    if (prop === "name") {
      const names = extractName(value);
      setAddressDetails({
        ...addressDetails,
        firstName: names.firstName,
        lastName: names.lastName,
      });
    }
  };

  function extractName(fullName) {
    // Remove extra whitespace from the beginning and end of the string
    fullName = fullName.trim();

    // Check if the fullName contains only one word
    if (fullName.split(/\s+/).length === 1) {
      // If it's a single word, consider it as the first name
      return {
        firstName: fullName,
        lastName: "", // No last name available
      };
    } else {
      // If it contains multiple words, split it into first name and last name
      const nameParts = fullName.split(/\s+/);
      const lastName = nameParts.pop(); // Remove and get the last word as last name
      const firstName = nameParts.join(" "); // Join remaining words as first name
      return {
        firstName: firstName,
        lastName: lastName,
      };
    }
  }

  useEffect(() => {
    async function validateLogin() {
      const token = getToken();
      if (!token) {
        SetLogin(false);
      } else {
        SetLogin(true);
      }
    }

    validateLogin();
  }, []);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <TextField
            id="nameOnCard"
            label="Name on Card"
            helperText={errors.name.length > 0 ? errors.name : ""}
            variant="outlined"
            fullWidth
            required
            value={paymentDetails.name}
            onChange={handleChange("name")}
            error={errors.name.length > 0}
            className={classes.formControl}
            color="secondary"
            inputProps={{
              maxLength: 50,
            }}
            onInput={(e) => {
              e.target.value = e.target.value.match(/[a-zA-Z-'\s]+/); //only accept letters (inclusive of spaces and hyphens and apostrophe for odd names)
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <MaskedCCInput
            handleChange={handleChange}
            value={paymentDetails.cardNumber}
            error={errors.cardNumber}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MaskedExpiryDate
            handleChange={handleChange}
            value={paymentDetails.expirationDate}
            error={errors.expirationDate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MaskedCvv
            handleChange={handleChange}
            value={paymentDetails.cvv}
            error={errors.cvv}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          {loggedIn && (
            <FormControlLabel
              control={
                <Checkbox
                  id="shouldSaveCard"
                  disabled={disableSaveCard}
                  checked={paymentDetails.shouldSaveCard}
                  onChange={handleChange("shouldSaveCard")}
                />
              }
              label={<b>Save Card</b>}
              defaultChecked
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12}>
          {loggedIn && (
            <FormControlLabel control={<b>{duplicateCardText}</b>} />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
