import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "constants/actionTypes";
import {
  getAsync,
  postAsync,
} from "components/public-dashboard/services/api_util";

export const fetchTxns = createAsyncThunk(
  "activity/fetchTxns",
  async (data, { getState, requestId }) => {
    const { currentRequestId, loading, query } = getState().activity;
    const post = {
      pageNumber: query.pageNumber,
      pageSize: query.pageSize,
      tranType: query.selectedTranTypes,
      phoneProvider: query.selectedCarriers,
      provider: query.selectedProviders,
      startDate: query.startDate ? new Date(query.startDate) : null,
      endDate: query.endDate ? new Date(query.endDate) : null,
      tranStatus:query.selectedTranStatuses,
    };
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await postAsync(post, `${API_ROOT}transaction/filtered`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchProviders = createAsyncThunk(
  "activity/fetchProviders",
  async (data, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().activity;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}transaction/GetProviders`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchCarriers = createAsyncThunk(
  "activity/fetchCarriers",
  async (data, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().activity;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}topup/GetPhoneProviders`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchMonthlyStats = createAsyncThunk(
  "activity/fetchMonthlyStats",
  async (data, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().activity;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getAsync(`${API_ROOT}transaction/getmonthlystats`);
    if (response.state === 1) {
      return MonthlyStatsArray(response.data);
    }
    return [];
  }
);

function MonthlyStatsArray(data) {
  let arr = [];
  arr.push(["Month", "Bill Pay", "Top Up"]);
  data.map((item, id) => {
    arr.push([item.month, item.billPay, item.topUps]);
    return null;
  });
  return arr;
}

const cleanState = {
  billpayTxns: [],
  topupTnxs: [],
  txns: undefined,
  providers: [],
  carriers: [],
  tranTypes: ["Billpay", "Topups"],
  currentRequestId: undefined,
  loading: "idle",
  monthlyStats: undefined,
  query: {
    startDate: null,
    endDate: null,
    selectedProviders: [],
    selectedCarriers: [],
    selectedTranTypes: [],
    pageSize: 10,
    pageNumber: 1,
  },
};

export const cleanQuery = {
  startDate: null,
  endDate: null,
  selectedProviders: [],
  selectedCarriers: [],
  selectedTranTypes: [],
  selectedTranStatuses: [],
  pageSize: 10,
  pageNumber:1,
};

const activitySlice = createSlice({
  name: "activity",
  initialState: cleanState,
  reducers: {
    setQuery(state, action) {
      state.query = { ...state.query, ...action.payload };
    },
    resetQuery(state, action) {
      state.query = { ...cleanQuery };
    },
  },
  extraReducers: {
    [fetchTxns.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchTxns.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.txns = action.payload;
        state.currentRequestId = undefined;
      }
    },
    [fetchTxns.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchCarriers.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchCarriers.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.carriers = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchCarriers.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchProviders.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchProviders.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.providers = [...action.payload];
        state.currentRequestId = undefined;
      }
    },
    [fetchProviders.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [fetchMonthlyStats.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchMonthlyStats.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.monthlyStats = action.payload;
        state.currentRequestId = undefined;
      }
    },
    [fetchMonthlyStats.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const { setQuery, resetQuery } = activitySlice.actions;

export default activitySlice.reducer;
